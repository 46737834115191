import { FormControlLabel, Checkbox } from '@mui/material';
import { VariableKeys } from '../../../utils';
import { useApplyFilters } from '../../hooks/useApplyFilters';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import { useReportPage } from '../../hooks/useReportPage';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

export const YZoom = () => {
  const coercedQueryParams = useCoercedQueryParams(useReportPage());
  const { draftParams } = useFilterContext(useReportPage());
  const apply = useApplyFilters(useReportPage());

  if (!(VariableKeys.y_zoom in coercedQueryParams)) {
    throw new Error();
  }

  return VariableKeys.y_zoom in draftParams ? (
    <FormControlLabel
      control={
        <Checkbox
          checked={
            coercedQueryParams[VariableKeys.y_zoom] === 'true' ? true : false
          }
          onChange={(e) => {
            apply({
              y_zoom: e.target.checked ? 'true' : 'false',
            });
          }}
        />
      }
      label="Autoscale"
    />
  ) : null;
};
