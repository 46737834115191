export const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, // dont show cents
});
export const percent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2, // show 2 decimals
});
export const unit = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0, // dont show decimals
});
