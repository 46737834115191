import { BusinessType } from '../../../gql-generated';
import {
  brand_title_column,
  BusinessTitleCell,
  company_title_column,
} from '../../components/Cells/BusinessTitleCell';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ChartCell } from './Cells/ChartCell';
import { BrandNode, Node, CompanyNode } from './types';
import { IndustryCell } from './Cells/IndustryCell';
import { numberColumn } from '../../components/Cells/NumberCell';
import { Column } from '../../react-table-column-types';
import { DefaultCell } from '../../components/Cells/DefaultCell';
import { percentChangeColumn } from '../../components/Cells/PercentChangeCell';
import { stringColumn } from '../../components/Cells/StringCell';
import { tickerColumn } from '../../components/Cells/TickerCell';
import { r2Column } from '../../components/Cells/R2Cell';
import { currencyColumn } from '../../components/Cells/CurrencyCell';
import { staticColumn } from '../../components/Cells/StaticCell';
import { BRAND_TYPE_LABEL, REPORT_PAGES } from '../../constants';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import { assertUnreachable } from '@groundwater/shared-util';

const row_number: Column<Node> = {
  ...staticColumn(),
  id: 'row_number',
  width: 30,
  Header: '#',
  Cell: ({ row_number }) => <DefaultCell>{row_number}</DefaultCell>,

  // https://react-table.tanstack.com/docs/api/useTable#column-options
  // Technically speaking, this field isn't required if you have a unique
  // id for a column. This is used for things like expander or row
  // selection columns. Warning: Only omit accessor if you really know
  // what you're doing.
  accessor: null as any,
};

const ultimate_parent: Column<BrandNode> = {
  ...stringColumn(),
  id: 'ultimate_parent',
  width: 200,
  Header: 'Parent Company',
  accessor: (row) => row.brand.ultimate_parent?.title ?? '',
  Cell: ({ row, column }) =>
    row.original.brand.ultimate_parent ? (
      <BusinessTitleCell
        business={row.original.brand.ultimate_parent}
        width={column.width as number}
        reportPage={REPORT_PAGES.trends}
      />
    ) : null,
};

const naics: Column<BrandNode> = {
  ...stringColumn(),
  width: 200,
  id: 'naics',
  Header: () => {
    return (
      <Box display="inline-flex">
        NAICs code
        <Tooltip
          title="2022 NAICS"
          placement="top"
          sx={{ mx: 0.5 }}
          // We don't want to trigger column sort upon the tooltip click
          onClick={(e) => e.stopPropagation()}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Box>
    );
  },
  accessor: (row) => `${row.brand.industry?.id} ${row.brand.industry?.title}`,
  Cell: ({ row, column }) => (
    <IndustryCell brand={row.original.brand} width={column.width as number} />
  ),
};

const figi: Column<CompanyNode> = {
  ...stringColumn(),
  width: 200,
  id: 'figi',
  Header: 'FIGI',
  accessor: (row) => row.company.figi || '',
};

const bloomberg_id: Column<CompanyNode> = {
  ...stringColumn(),
  width: 200,
  id: 'bloomberg_id',
  Header: 'Bloomberg ID',
  accessor: (row) => row.company.bloomberg_id || '',
};

const observed_sales: Column<Node> = {
  ...currencyColumn(),
  width: 165,
  Header: 'Sales',
  accessor: 'observed_sales_dollars',
};

const observed_sales_yoy: Column<Node> = {
  ...percentChangeColumn(),
  width: 165,
  accessor: 'observed_sales_yoy_percent',
  Header: 'Sales YoY',
};

const observed_sales_ltm_chart: Column<Node> = {
  ...staticColumn(),
  width: 165,
  accessor: 'observed_sales_ltm_chart',
  Header: 'Sales LTM',
  Cell: ({ row, column, isScrolling }) => (
    <ChartCell
      valueFormat="currency"
      isScrolling={isScrolling}
      data={row.original.observed_sales_ltm_chart}
      width={column.width as number}
    />
  ),
};

const observed_customers: Column<Node> = {
  ...numberColumn(),
  width: 165,
  Header: 'Customers',
  accessor: 'observed_customers',
};

const observed_customers_yoy_percent: Column<Node> = {
  ...percentChangeColumn(),
  width: 165,
  Header: 'Customers YoY',
  accessor: 'observed_customers_yoy_percent',
};

const observed_customers_ltm_chart: Column<Node> = {
  ...staticColumn(),
  width: 165,
  accessor: 'observed_customers_ltm_chart',
  Header: 'Customers LTM',
  Cell: ({ row, column, isScrolling }) => (
    <ChartCell
      valueFormat="unit"
      isScrolling={isScrolling}
      data={row.original.observed_customers_ltm_chart}
      width={column.width as number}
    />
  ),
  disableFilters: true,
  disableSortBy: true,
};

const observed_transactions: Column<Node> = {
  ...numberColumn(),
  width: 165,
  Header: 'Transactions',
  accessor: 'observed_transactions',
};

const observed_transactions_yoy_percent: Column<Node> = {
  ...percentChangeColumn(),
  width: 175,
  Header: 'Transactions YoY',
  accessor: 'observed_transactions_yoy_percent',
};

const observed_transactions_ltm_chart: Column<Node> = {
  width: 165,
  accessor: 'observed_transactions_ltm_chart',
  Header: 'Transactions LTM',
  Cell: ({ row, column, isScrolling }) => (
    <ChartCell
      valueFormat="unit"
      isScrolling={isScrolling}
      data={row.original.observed_transactions_ltm_chart}
      width={column.width as number}
    />
  ),
  disableFilters: true,
  disableSortBy: true,
};

const brand_type_column: Column<BrandNode> = {
  ...stringColumn(),
  width: 200,
  id: 'brand_type',
  Header: 'Brand Type',
  accessor: (row) => BRAND_TYPE_LABEL[row.brand.type],
};

function widen(a: Column<BrandNode> | Column<CompanyNode>): Column<Node> {
  return a as Column<Node>;
}

export const makeColumns = (business_type: BusinessType): Column<Node>[] => {
  const common = [
    observed_sales,
    observed_sales_yoy,
    observed_sales_ltm_chart,
    observed_customers,
    observed_customers_yoy_percent,
    observed_customers_ltm_chart,
    observed_transactions,
    observed_transactions_yoy_percent,
    observed_transactions_ltm_chart,
  ];
  switch (business_type) {
    case BusinessType.Brand: {
      return [
        row_number,
        widen(
          brand_title_column<BrandNode>({ reportPage: REPORT_PAGES.trends })
        ),
        widen(ultimate_parent),
        widen(brand_type_column),
        widen(naics),
        ...common,
      ];
    }
    case BusinessType.Company:
      return [
        row_number,
        widen(
          company_title_column<CompanyNode>({ reportPage: REPORT_PAGES.trends })
        ),
        widen(tickerColumn<CompanyNode>()),
        widen(figi),
        widen(bloomberg_id),
        widen(r2Column<CompanyNode>()),
        ...common,
      ];
    default:
      assertUnreachable(business_type);
  }
};
