import {
  Card,
  CardContent,
  Typography,
  Stack,
  Grid,
  Skeleton,
  CardActions,
} from '@mui/material';
import { OvrResponse } from '../../../gql-generated';
import { SCALING_MODEL_LABELS } from '../../constants';
import { format } from 'd3-format';

export const OvrStatBoxesLoadingSkeleton = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3 }}
      data-testid="ovr-statbox-loading-skeleton"
    >
      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <Skeleton variant="rectangular" height={150} />
      </Grid>

      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <Skeleton variant="rectangular" height={150} />
      </Grid>
    </Grid>
  );
};

export const OvrStatBoxes: React.FC<{ ovr: OvrResponse }> = ({ ovr }) => {
  const selectedModel = ovr.annotations.scaling_model;
  const r2 = ovr.annotations.r2 ? format(',.2')(ovr.annotations.r2) : null;
  const [from, to] = ovr.annotations.historical_percent_observed;

  const historicalPercentObserved = () => {
    return `${format(',.2%')(Number(from))} - ${format(',.2%')(Number(to))}`;
  };

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3 }}
      data-testid="ovr-statbox"
    >
      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <ModelCard
          r2={r2}
          selectedModel={SCALING_MODEL_LABELS[selectedModel]}
        />
      </Grid>
      {null !== from && null !== to ? (
        <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
          <ObservedCard data={historicalPercentObserved()} />
        </Grid>
      ) : null}
    </Grid>
  );
};

function ModelCard({
  r2,
  selectedModel,
}: {
  r2: string | null;
  selectedModel: string;
}) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" component="span">
            {r2 === null ? 'N/A' : r2}
          </Typography>
          <Typography variant="h3" color="text.secondary" component="span">
            R²
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {selectedModel}
          </Typography>
        </Stack>
      </CardContent>
      {r2 == null && (
        <CardActions>
          <Stack direction="row" sx={{ mx: 2 }} alignItems="center">
            <Typography variant="caption">
              Insufficient information to calculate R²
            </Typography>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
}

function ObservedCard({ data }: { data: string }) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h3" component="span">
            {data}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Stack direction="row" sx={{ mx: 2 }} alignItems="center">
          <Typography variant="caption">
            Historical percent observed range
          </Typography>
        </Stack>
      </CardActions>
    </Card>
  );
}
