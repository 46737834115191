import { isEmpty } from 'lodash-es';
import {
  OvrRequest,
  PercentObservedScalingModel,
  PercentObservedScalingModelR2,
  QtdRequest,
  useGetOvrPercentObservedQuery,
  useGetQtdPercentObservedQuery,
} from '../../../../../gql-generated';
import { REPORT_PAGES } from '../../../../constants';

export const useScalingModelR2Value = (
  page: REPORT_PAGES,
  request: Readonly<OvrRequest | QtdRequest>
): {
  r2Values: PercentObservedScalingModelR2 | null;
  bestFit: PercentObservedScalingModel | null;
  isLoading: boolean;
} => {
  let r2Values = null;
  let bestFit = null;
  let isLoading = false;
  const ovrR2request = request as Readonly<OvrRequest>;
  const ovrR2Response = useGetOvrPercentObservedQuery(
    {
      request: {
        company_id: ovrR2request.company_id,
        start_date: ovrR2request.start_date,
        end_date: ovrR2request.end_date,
      },
    },
    {
      suspense: false,
      enabled: page === REPORT_PAGES.ovr,
    }
  );

  const qtdR2request = request as Readonly<QtdRequest>;
  const qtdR2Response = useGetQtdPercentObservedQuery(
    {
      request: {
        company_id: qtdR2request.company_id,
        analysis_quarter: qtdR2request.analysis_quarter,
      },
    },
    {
      suspense: false,
      /**
       * When QtdPage gets mounted initially analysis_quarter could be empty. We need to make sure that
       * the value gets populated before making the request to the query.
       */
      enabled:
        page === REPORT_PAGES.qtd && !isEmpty(qtdR2request.analysis_quarter),
    }
  );

  if (ovrR2Response?.data) {
    const { percent_observed_v2: percent_observed } = ovrR2Response.data;
    if (percent_observed.__typename === 'DataError') {
      return { r2Values: null, bestFit: null, isLoading: false };
    }
    r2Values = percent_observed.r2;
    bestFit = percent_observed.best_fit;
  } else if (qtdR2Response?.data) {
    const { qtd_percent_observed_v2: percent_observed } = qtdR2Response.data;
    if (percent_observed.__typename === 'DataError') {
      return { r2Values: null, bestFit: null, isLoading: false };
    }
    r2Values = percent_observed.r2;
    bestFit = percent_observed.best_fit;
  }

  switch (page) {
    case REPORT_PAGES.qtd:
      isLoading = qtdR2Response.isLoading;
      break;
    case REPORT_PAGES.ovr:
      isLoading = ovrR2Response.isLoading;
      break;
    default:
      isLoading = false;
      break;
  }
  return { r2Values, bestFit, isLoading };
};
