import { AnalysisGuideButton } from '@groundwater/app/ui';
import { Stack } from '@mui/material';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { ReportLayout } from '../../layouts/ReportLayout';
import { RetentionSummaryCharts } from './RetentionSummaryCharts';
import { RetentionSummaryExportAllButton } from './RetentionSummaryExportAllButton';

const page_title = 'Bloomberg Second Measure - Retention Summary';

export function RetentionSummaryPage() {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <ReportLayout
        page_title={page_title}
        filterSubHeaderActionContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <AnalysisGuideButton fileName="retention_summary.pdf" />
            <RetentionSummaryExportAllButton />
          </Stack>
        }
      >
        <RetentionSummaryCharts />
      </ReportLayout>
    </WithCompanyIdPrompt>
  );
}
