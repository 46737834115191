import { useQpsQueryQuery } from '../../../gql-generated';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { ReportLayout } from '../../layouts/ReportLayout';
import { Suspense, useDeferredValue } from 'react';
import { QpsTable } from './QpsTable';
import { QpsExportAllButton } from './QpsExportAllButton';
import { CircularProgress, Skeleton, Stack } from '@mui/material';
import { AnalysisGuideButton } from '@groundwater/app/ui';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

export function QpsPage() {
  const variables = useVariables(REPORT_PAGES.qps);
  const deferredVariables = useDeferredValue(variables);

  const isLoading = variables !== deferredVariables;

  const response = useQpsQueryQuery(deferredVariables);

  if (!response.data) throw new Error('Query did not return any data.');

  return (
    <ReportLayout
      page_title="Bloomberg Second Measure - Quarterly performance"
      filterSubHeaderActionContent={
        <Stack direction="row" alignItems="center" spacing={2}>
          <AnalysisGuideButton fileName="quarterly_performance_screening.pdf" />
          <QpsExportAllButton />
        </Stack>
      }
    >
      {isLoading && <CircularProgress size={15} sx={{ mx: 1 }} />}
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height="70vh" />}
      >
        <QpsTable
          deferredVariables={deferredVariables}
          nodes={response.data.qps.nodes}
        />
      </Suspense>
    </ReportLayout>
  );
}
