import {
  GetCompanyOrBrandQuery,
  useGetCompanyOrBrandQuery,
} from '../../../gql-generated';

/**
 * React hook that provides the company/brand data. It references the URL_QUERY_PARAMETER_KEYS.companies parameter values
 * and refetches once the parameters change.
 */
export function useCompanyOrBrands(
  companies: string[]
): GetCompanyOrBrandQuery['get_brands_or_companies'] {
  const props = useGetCompanyOrBrandQuery(
    {
      ids: companies as string[],
    },
    // Disable the query if URL_QUERY_PARAMETER_KEYS.companies parameter does not exist in the URL query or no company ids passed
    { enabled: companies.length > 0 }
  );

  if (
    // Throw an exception if the react-query was enabled but the response came back undefined
    companies.length > 0 &&
    (props.data === undefined ||
      props.data.get_brands_or_companies === undefined)
  ) {
    throw new Error(
      'data is missing, this is unexpected because react-query should throw on error in suspense mode and field is non nullable in backend'
    );
  }

  return props.data?.get_brands_or_companies ?? [];
}
