import { useTheme } from '@mui/material';
import moment from 'moment';
import { StyledApexChart } from '../../StyledApexChart';
import {
  extentOfDataset,
  optimize,
  formatPercent,
} from '../../../utils/chartUtils';
export interface PercentObservedDataset {
  quarter_end_date: string[];
  /**
   * historical serie could contain null as the last index if looking at the latest unreported quarter
   */
  historical: (string | null)[];
  /**
   * modeled serie could contain null as the last serie index when sclaing_model is custom
   */
  modeled?: (string | null)[];
}

interface PercentObservedChartProps {
  group?: string;
  dataset: PercentObservedDataset;
  customProjection?: boolean;
  yZoom: boolean;
}

export const PercentObservedChart: React.FC<PercentObservedChartProps> = ({
  group,
  dataset,
  customProjection,
  yZoom,
}) => {
  const muiTheme = useTheme();
  const chartId = 'percent-observed';

  const xSeries = dataset.quarter_end_date;

  const ySeries = [
    {
      name: 'Historical % Observed',
      data: dataset.historical,
    },
  ];

  if (dataset.modeled) {
    ySeries.push({
      name: 'Modeled % Observed',
      data: dataset.modeled,
    });
  }

  const { minFromDataset, maxFromDataset } = extentOfDataset(ySeries);

  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    true,
    minFromDataset,
    maxFromDataset
  );

  return (
    <StyledApexChart
      // Forcefully trigger updates to unmount chart annotations
      key={customProjection ? 1 : 0}
      type="line"
      height={300}
      series={ySeries}
      options={{
        theme: {
          palette: 'palette6',
        },

        ...(customProjection &&
          dataset.modeled && {
            annotations: {
              points: [
                // Custom model projection
                {
                  x: xSeries[xSeries.length - 1],
                  y: ySeries.find((i) => i.name === 'Modeled % Observed')?.data[
                    xSeries.length - 1
                  ],

                  marker: {
                    size: 4,
                    fillColor: muiTheme.palette.common.white,
                    strokeColor: muiTheme.palette.chart.colorGraph7,
                    radius: 2,
                  },
                },
                // Last historical observed
                {
                  x: xSeries[xSeries.length - 2],
                  y: ySeries.find((i) => i.name === 'Modeled % Observed')?.data[
                    xSeries.length - 2
                  ],
                  marker: {
                    size: 4,
                    fillColor: muiTheme.palette.common.white,
                    strokeColor: muiTheme.palette.chart.colorGraph4,
                    radius: 2,
                  },
                },
              ],
            },
          }),
        fill: {
          opacity: 1,
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
            shadeIntensity: 0,
            stops: [0, 100],
            type: 'vertical',
          },
        },
        grid: {
          borderColor: muiTheme.palette.grey[300],
          strokeDashArray: 3,
        },
        states: {
          active: {
            filter: {
              type: 'darken',
              value: 0.88,
            },
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.04,
            },
          },
        },
        tooltip: {
          marker: {
            show: true,
          },
          x: {
            // Hide tooltip header
            show: false,
            formatter: (
              val: number,
              { dataPointIndex }: { dataPointIndex: number }
            ): string => moment(xSeries[dataPointIndex]).format('MMM D, yyyy'),
          },
          y: {
            formatter: formatPercent,
          },
        },
        chart: {
          id: chartId,
          group,
          animations: {
            enabled: false,
          },
          foreColor: muiTheme.palette.grey[500],
          events: {
            mounted: function (chart: any) {
              chart.windowResizeHandler();
            },
          },
          // Disable toolbar functionality for trends charts
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        markers: {
          size: 4,
          strokeWidth: 0,
          hover: {
            size: 5,
          },
        },
        // Configure the chart lines (width, curve, etc.)
        stroke: {
          width: 3,
          curve: 'straight',
          // Make projection line dashed if custom scaling model selected
          ...(customProjection && {
            dashArray: ySeries.map((i) =>
              // Creates dashes in borders of Forecast Sales serie line. The number represents the distance between dashes in px (e.g. 7px)
              i.name === 'Modeled % Observed' ? 7 : 0
            ),
          }),
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount,
          labels: {
            formatter: formatPercent,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: xSeries,
          title: {
            text: 'Fiscal Quarter End Date',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '13',
          fontWeight: 500,
          offsetX: 5,
        },
      }}
    />
  );
};
