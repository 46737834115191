import { CohortIndex } from '@groundwater/shared-ui';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { VariableKeys } from '../../../../utils';
import { entriesFromRecord } from '../../../../utils/record-map';
import { COHORT_INDEX_LABELS } from '../../../constants';
import { useReportPage } from '../../../hooks/useReportPage';
import { useFilterContext } from '../../FilterSubHeader/hooks/controller';

export const CohortIndexFilter: React.FC<{}> = () => {
  const page = useReportPage();

  const { handleDraftChange, draftParams } = useFilterContext(page);

  if (
    !(VariableKeys.cohort_index in draftParams) &&
    !(VariableKeys.xaxis in draftParams)
  ) {
    throw new Error();
  }

  const selected =
    VariableKeys.cohort_index in draftParams
      ? draftParams[VariableKeys.cohort_index]
      : draftParams[VariableKeys.xaxis];

  const onClick = (value: CohortIndex) => {
    if (VariableKeys.cohort_index in draftParams) {
      handleDraftChange((prevParams) => ({
        ...prevParams,
        cohort_index: value,
      }));
    } else if (VariableKeys.xaxis in draftParams) {
      handleDraftChange((prevParams) => ({ ...prevParams, xaxis: value }));
    } else {
      throw new Error(
        'CohortIndexFilter onClick fired, but the draftParams it controls are not present on this page'
      );
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      onChange={(_event, value: CohortIndex) => onClick(value)}
      size="small"
    >
      {entriesFromRecord(COHORT_INDEX_LABELS).map(([value, label]) => (
        <ToggleButton key={value} value={value} selected={selected === value}>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
