import { VariableKeys, variablesSchema } from './reportPageConfigs';
import { REPORT_PAGES } from '../app/constants';
import { RawVariables } from '../app/hooks';
import { Variables } from './reportPageConfigs';

/**
 * Create params for a new page using existing params (used to link from one report page to another).
 *
 * - If all existing param's value applies to the new page, they will all be preserved
 * - If an existing param's key doesn't apply to the new page, it will be removed
 * - If any param's key does apply, but the value does not, all of the params will be reset to the default!
 *
 * Why is this a type assertion? This is un-assignable due a
 * {@link  https://stackoverflow.com/a/61399457 | known limitation with higher level type params in Typescript}
 *
 * however, we know that:
 *
 * 1) `filterSchema` is annotated with the
 *  {@link https://www.typescriptlang.org/docs/handbook/2/mapped-types.html | mapped type}
 *  `{ [T in REPORT_PAGES]: SchemaOf<QueryFilters[T]> }`
 *
 * 2) `filterSchema[page]` is of type `SchemaOf<QueryFilters[T]>` since we're just indexing the mapped type
 *
 * 3) yup's {@link https://github.com/jquense/yup/blob/pre-v1/docs/typescript.md#typescript-support | SchemaOf}
 *    will ensure the yup schema conforms to the higher level type param `QueryFilters[T]`
 *
 * 4) accordingly, the return value of validateSync() will be QueryFilters[T]
 */
export const createURLQuery = <T extends REPORT_PAGES>(
  page: T,
  currentURLquery: RawVariables
): Readonly<Variables[T]> => {
  const schema = variablesSchema[page];
  const currentURLqueryWithDefaults = {
    ...(schema.getDefault() as Variables[T]),
    ...currentURLquery,
  };
  if (schema.isValidSync(currentURLqueryWithDefaults, { stripUnknown: true })) {
    return Object.freeze(
      schema.validateSync(currentURLqueryWithDefaults, {
        stripUnknown: true,
      }) as Variables[T]
    );
  } else {
    return {
      ...(schema.getDefault() as Variables[T]),
      company_id: currentURLquery[VariableKeys.company_id],
    };
  }
};
