import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { REPORT_PAGES } from '../../../constants';
import { useCoercedQueryParams } from '../../../hooks/useCoercedQueryParams';
import { Variables, variablesSchema, VariableKeys } from '../../../../utils';
import { isEqual, isEqualWith, omit } from 'lodash-es';
import { useApplyFilters } from '../../../hooks/useApplyFilters';
import usePreviousValue from '@utilityjs/use-previous-value';
/**
 * The updater is the callback that the consumer provides
 * the consumer writes an updater to take in prev params and
 * return the new params they want to set.
 *
 * This updater callback is passed as the single argument to handleDraftChange
 */
export type VariablesUpdater = <T extends REPORT_PAGES>(
  // typed as such so consumers can specify the generic
  prevParams: Variables[T]
  // typed as such so consumers can specify the generic
) => Variables[T];

/**
 * This callback is how consumers update the "draft params". It's a thin wrapper
 * around the setState method, but forces consumers to provide an "updater function"
 * so that they do not forget to safely reference "previous props"
 */
export type HandleDraftChange = (updater: VariablesUpdater) => void;

export type FilterContextValue<T extends REPORT_PAGES> = {
  draftParams: Variables[T];
  handleDraftChange: HandleDraftChange;
  apply: () => void;
  canApply: boolean;
};

export const FilterContext = createContext<FilterContextValue<REPORT_PAGES>>(
  null as any
);
FilterContext.displayName = 'FilterContext';

export const autoAppliedParams = [
  VariableKeys.y_zoom,
  VariableKeys.transaction_value_min,
  VariableKeys.transaction_value_max,
  VariableKeys.cohort_view,
];

const comparator = (value: unknown, otherValue: unknown) =>
  Array.isArray(value) && Array.isArray(otherValue)
    ? isEqual(new Set(value), new Set(otherValue))
    : // If customizer returns undefined, comparisons are handled by the method instead.
      undefined;

export const FilterContextController = <Page extends REPORT_PAGES>({
  page,
  children,
}: {
  page: Page;
  children: React.ReactElement;
}) => {
  const appliedParams = useCoercedQueryParams(page);

  const [draftParams, setDraftParams] =
    useState<Variables[Page]>(appliedParams);

  const handleDraftChange: HandleDraftChange = useCallback(
    (updater) => {
      setDraftParams(updater);
    },
    [setDraftParams]
  );

  const previousAppliedParams = usePreviousValue(appliedParams);

  /**
   * If an applied parameter changes in the URL via in-app navigation
   * rather than the apply button, we should overwrite whatever is currently
   * in the "draft" state for this param! This is mainly here for handling
   * automatically applied parameters like y zoom, otherwise when you hit apply
   * on out of sync "draft y zoom" could be applied
   */
  useEffect(() => {
    if (!previousAppliedParams) {
      // initial params, no previous value!
      return;
    }

    for (const key of Object.values(VariableKeys)) {
      const appliedValue = (appliedParams as any)[key];
      const previousAppliedValue = (previousAppliedParams as any)[key];
      if (!isEqualWith(appliedValue, previousAppliedValue, comparator)) {
        // a key changed, reset draft param corresponding to this key!
        handleDraftChange((prevDraftParams) => ({
          ...prevDraftParams,
          // reset to currently applied param
          [key]: (appliedParams as any)[key],
        }));
      }
    }
  }, [appliedParams, handleDraftChange, previousAppliedParams]);

  const canApply =
    variablesSchema[page].isValidSync(draftParams) &&
    // This method is like _.isEqual except that it accepts customizer which is invoked to compare values
    !isEqualWith(
      omit(draftParams, autoAppliedParams),
      omit(appliedParams, autoAppliedParams),
      comparator
    );

  const apply = useApplyFilters(page);

  return (
    <FilterContext.Provider
      value={{
        draftParams,
        handleDraftChange,
        apply: () => apply(omit(draftParams, autoAppliedParams)),
        canApply,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = <Page extends REPORT_PAGES>(page: Page) => {
  return useContext(FilterContext);
};
