import { SearchFilter } from '@groundwater/app/ui';
import { DefaultCell } from './DefaultCell';
import { matchSorter } from 'match-sorter';

export function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export const stringColumn = () => ({
  disableFilters: false,
  Filter: SearchFilter,
  Cell: ({ value }: { value: string }) => <DefaultCell>{value}</DefaultCell>,
  filter: fuzzyTextFilterFn as any,
  sortType: 'alphanumeric' as const,
});
