import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, Stack } from '@mui/material';
import {
  IllustrationEmailSent,
  MotionContainer,
  PageLayout,
  varBounceIn,
} from '@groundwater/shared-ui';
import { useQuery } from '../../hooks';
import { PublicLayout } from '../../layouts/PublicLayout';

const RootStyle = styled(PageLayout)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const RequestPasswordResetSuccessPage: React.FC<{}> = () => {
  const { email } = useQuery();
  return (
    <PublicLayout>
      <RootStyle title="Request Reset Password Success">
        <Container>
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                <Box
                  component={IllustrationEmailSent}
                  sx={{ height: 175, mx: 'auto' }}
                />
              </motion.div>
              <Stack spacing={0} sx={{ my: 3 }}>
                <Typography variant="h3" paragraph>
                  Request received
                </Typography>
                <Typography>
                  If an account exists for <strong>{email}</strong>, we have
                  sent a link which can be used to complete the password reset
                  process. Please note this link expires in 24 hours.
                  <br />
                  Please check your email.
                </Typography>
              </Stack>
              <Button
                to="/"
                size="large"
                variant="contained"
                component={RouterLink}
              >
                Back
              </Button>
            </Box>
          </MotionContainer>
        </Container>
      </RootStyle>
    </PublicLayout>
  );
};
