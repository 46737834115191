import { TrendsRollup } from '@groundwater/shared-ui';

// Note that the order of object keys represents the order of charts on the page
export enum TRENDS_CHART_KEYS {
  observed_sales = 'observed_sales',
  observed_primary_sales = 'observed_primary_sales',
  observed_intermediary_sales = 'observed_intermediary_sales',
  observed_customers = 'observed_customers',
  observed_transactions = 'observed_transactions',
  sales_per_customer = 'sales_per_customer',
  average_transaction_value = 'average_transaction_value',
  transactions_per_customer = 'transactions_per_customer',
  share_of_sales = 'share_of_sales',
  share_of_primary_sales = 'share_of_primary_sales',
  share_of_intermediary_sales = 'share_of_intermediary_sales',
  share_of_transactions = 'share_of_transactions',
}

export const AXIS_LABELS: Record<TrendsRollup, string> = {
  [TrendsRollup.Day]: 'Day',
  [TrendsRollup.Week]: 'Week',
  [TrendsRollup.Month]: 'Month',
  [TrendsRollup.Quarter]: 'Quarter',
  [TrendsRollup.Year]: 'Year',
  [TrendsRollup.FiscalQuarter]: 'Fiscal quarter',
  [TrendsRollup.Trailing_7Days]: 'Trailing 7 days',
  [TrendsRollup.Trailing_30Days]: 'Trailing 30 days',
  [TrendsRollup.Trailing_90Days]: 'Trailing 90 days',
  [TrendsRollup.Trailing_12Months]: 'Trailing 12 months',
};
