import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------
export const PageLayout = forwardRef(
  ({ children, title = '', ...other }: PageLayoutProps, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
);

interface PageLayoutProps {
  title: string;
  children: React.ReactChild;
}
