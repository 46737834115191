import { FilterButtonMenuOption } from '@groundwater/shared-ui';
import { entriesFromRecord } from '../../../../utils/record-map';
import { BreakoutBy, ChannelsV2 } from '../../../../gql-generated';
import { CHANNEL_LABELS } from '../../../constants';
import { useMemo } from 'react';
import { useReportPage } from '../../../hooks/useReportPage';
import { useCoercedQueryParams } from '../../../hooks/useCoercedQueryParams';
import { VariableKeys } from '../../../../utils';
import {
  Alert,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface ChannelFilterProps {
  selected: ChannelsV2[];

  // TODO - rename onToggle
  onClick: (value: ChannelsV2) => void;
}

export const ChannelFilter: React.FC<ChannelFilterProps> = ({
  selected,
  onClick,
}) => {
  const menuOptions: FilterButtonMenuOption<ChannelsV2>[] = useMemo(
    () =>
      entriesFromRecord(CHANNEL_LABELS).map(([value, label]) => {
        return {
          label,
          value,
        };
      }),
    []
  );

  return (
    <List dense>
      <ListItem>
        <ListItemText id="channel-section" primary={'Channel'} />
      </ListItem>
      {menuOptions.map((option) => {
        return (
          <ListItem key={option.value} dense>
            <ListItemButton
              role={undefined}
              onClick={() => {
                onClick(option.value);
              }}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.indexOf(option.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': option.value }}
                />
              </ListItemIcon>
              <ListItemText id={option.value} primary={option.label} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
