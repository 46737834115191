import { useSpring, animated, config } from 'react-spring';

export const Fade: React.FC<{
  children: React.ReactElement;
  in: boolean;
  style?: React.CSSProperties;
}> = ({ children, in: _in, style }) => {
  const props = useSpring({ opacity: _in ? 1 : 0, config: config.stiff });
  return <animated.div style={{ ...props, ...style }}>{children}</animated.div>;
};

export const MockTransition: React.FC<{
  children: React.ReactElement;
  in: boolean;
}> = ({ children, in: _in }) => {
  return _in ? children : null;
};
