import { Maybe, TrendsTargetDimension } from '@groundwater/shared-ui';
import { notEmpty } from '@groundwater/shared-util';
import { max, min } from 'd3';
import { TRENDS_CHART_KEYS } from '../pages/trends/constants';

export function getOrderOfMagnitude(extentOfDataset: number) {
  if (extentOfDataset === 0) return 0;
  if (extentOfDataset < 1) {
    let numDigits = 0;
    let remaining = extentOfDataset;
    while (remaining < 1) {
      numDigits++;
      remaining = remaining * 10;
    }
    return -1 * numDigits;
  }
  let numDigits = 0;
  let remaining = extentOfDataset;
  // while there is still a remainder
  while (remaining > 0) {
    // keep incrementing numDigits, and removing digits from remaining
    numDigits++;
    remaining = Math.floor(remaining / 10);
  }
  return numDigits;
}

// utility function to count # of decimals in a #
export function countDecimals(num: number) {
  const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

export function extentOfDataset(
  ySeries: {
    name?: string;
    data: Maybe<(Maybe<number | string> | undefined)[]>;
  }[],
  hiddenSeriesId?: string[]
) {
  let minFromDataset: number | undefined = Infinity;
  for (const series of ySeries) {
    if (!series.data) continue;
    if (series.name && hiddenSeriesId?.includes(series.name)) continue;
    const numbers: number[] = series.data
      .filter(notEmpty)
      .map((n) => (typeof n === 'string' ? parseFloat(n) : n));

    const newMin = min(numbers);
    if (undefined === newMin) continue;
    minFromDataset =
      undefined === minFromDataset || isNaN(minFromDataset)
        ? newMin
        : Math.min(minFromDataset, newMin!);
  }

  let maxFromDataset: number | undefined = -Infinity;
  for (const series of ySeries) {
    if (!series.data) continue;
    if (series.name && hiddenSeriesId?.includes(series.name)) continue;
    const numbers: number[] = series.data
      .filter(notEmpty)
      .map((n) => (typeof n === 'string' ? parseFloat(n) : n));

    const newMax = max(numbers);
    if (undefined === newMax) continue;
    maxFromDataset =
      undefined === maxFromDataset || isNaN(maxFromDataset)
        ? newMax
        : Math.max(maxFromDataset, newMax!);
  }

  if (
    undefined === minFromDataset ||
    isNaN(minFromDataset) ||
    undefined === maxFromDataset ||
    isNaN(maxFromDataset) ||
    Math.abs(minFromDataset) === Infinity ||
    Math.abs(maxFromDataset) === Infinity
  ) {
    // either all series were hidden, or the only visible series
    // contain all NULL. just provide dummy values so we render
    // an empty chart instead of crashing
    return { minFromDataset: 0, maxFromDataset: 1 };
  }

  return { minFromDataset, maxFromDataset };
}

/**
 * We want yMin / yMax to be "nice" numbers. What constitutes "nice"
 * depends on the order of magnitude (numDigits). For example if the dataset
 * goes from -1% through 101%, we want to snap to -10% through 110%
 *
 * In the example -1% through 101% the extent is (1.01 - -0.1) = 1.02
 * there is 1 [signifcant] digits, so we will snap to the nearest 0.1
 */
export function optimize(
  yMinZoom: boolean,
  yMaxZoom: boolean,
  minFromDataset: number,
  maxFromDataset: number
) {
  if (minFromDataset === maxFromDataset) {
    return {
      tickAmount: 1,
      yMin: yMinZoom ? Math.min(minFromDataset, 0) : 0,
      yMax: maxFromDataset,
    };
  }
  const extentOfDataset = fixFloat(maxFromDataset - minFromDataset);
  /**
   * Determine how many [significant] digits (orders of magnitude) that
   * there are in the extent of the domain (yMax-yMin)
   */
  const orderOfMagnitude = getOrderOfMagnitude(extentOfDataset);

  /**
   * Initialize these infinitely high/low,
   * since we want to minimize/maximize these
   * in the loop that follows
   */
  let yMin = Infinity;
  let yMax = -Infinity;
  let bestScore = Infinity;
  let tickAmount = Infinity;

  for (const snapToNearestCandidate of possibleSnapTo(orderOfMagnitude)) {
    const { yMaxCandidate, yMinCandidate } = extendChart(
      yMinZoom,
      yMaxZoom,
      minFromDataset,
      maxFromDataset,
      snapToNearestCandidate
    );

    /**
     * Now that we have finalized the optimal yMin/yMax,
     * we still need to determine how many "ticks" to draw. For example,
     * even if the yMin and yMax are 0 - 1, drawing 3 ticks will space them 33.33333%
     * apart which is not a "nice" number.
     *
     * What we'll do is run some quick math to determine the spacing that a given
     * number of ticks would result in, and we'll try all the possible numbers of ticks
     * measuring the amount of decimals it results in, and we'll pick the "best" amount
     * based on which one results in fewest decimals, within our range
     */

    /**
     * Loop over each possible # of ticks as `t`, our candidate # of ticks
     * */
    for (let numberOfTicks = 2; numberOfTicks <= 13; numberOfTicks++) {
      const { badDigits, crossZero } = simulateTickPlacement({
        numberOfTicks,
        yMin: yMinCandidate,
        yMax: yMaxCandidate,
      });
      const normalizedDistance = getNormalizedDistance(
        yMinCandidate,
        yMaxCandidate,
        minFromDataset,
        maxFromDataset,
        yMinZoom,
        yMaxZoom
      );
      const score = getScore(
        badDigits,
        yMinZoom,
        yMaxZoom,
        crossZero,
        normalizedDistance,
        numberOfTicks
      );

      /**
       * Update `tickAmount` to the candidate amount `t` only if `t` beats the
       * "best score" for current `tickAmount`
       */
      if (score <= bestScore) {
        bestScore = score;
        tickAmount = numberOfTicks;
        yMin = yMinCandidate;
        yMax = yMaxCandidate;
      }
    }
  }
  return { tickAmount, yMin, yMax };
}

export const numberCompactFormat = Intl.NumberFormat('en', {
  notation: 'compact',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const numberFormat = Intl.NumberFormat('en', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

/** Round away floating point errors so we don't overlook otherwise good results */
export function fixFloat(num: number): number {
  return parseFloat(parseFloat(num as any).toPrecision(11));
}

/**
 * If we drew t ticks, how many units spacing would that have?
 * Round away floating point errors so we don't overlook otherwise good results
 */
export function simulateTickPlacement({
  numberOfTicks,
  yMin,
  yMax,
}: {
  numberOfTicks: number;
  yMin: number;
  yMax: number;
}) {
  const extent = fixFloat(yMax - yMin);
  const orderOfMagnitude = getOrderOfMagnitude(extent);
  const intervalCandidate = fixFloat(extent / numberOfTicks);

  if (intervalCandidate === 0) {
    throw new Error('interval candidate cannot be 0');
  }

  /**
   * If its a negative chart, does it cross zero?
   */
  let crossZero = false;
  let badDigits = 0;
  const ticks = [];
  /** Simulate tick placement by incrementing `i` by `interval` */
  let i = yMin;

  // Thinking about changing the exponent because you are confused
  // why a whole number has "bad digits"? Please do not change! Read
  // the unit tests first, and the comments there about the "known limitation"
  // which is actually a feature that helps the results
  const divisor = fixFloat(
    Math.pow(10, orderOfMagnitude /** <--- DO NOT CHANGE!!! */)
  );

  while (i <= yMax) {
    ticks.push(i);

    if (i === 0) crossZero = true;

    /**
     * In the simple case, we can simply count the number of decimals. However,
     * consider the dataset can be large with numbers like $100,000,250.00
     * This has no decimals, but is not a "nice" number since it would be formatted
     * as $100.00025M instead of $100M, therefore it has 5 "bad" digits
     */
    const float = fixFloat(i / divisor);

    const numBad = countDecimals(float);
    badDigits += numBad;
    i = fixFloat(i + intervalCandidate);
  }
  return { ticks, badDigits, crossZero };
}

function getScore(
  badDigits: number,
  yMinZoom: boolean,
  yMaxZoom: boolean,
  crossZero: boolean,
  normalizedDistance: number,
  ticks: number
) {
  let score = badDigits;

  // When not zooming, boost the score of charts that have a tick
  // at 0, this is to prefer charts with ticks at 0% instead of only
  // -10% or 10%
  if (!yMinZoom) {
    score += crossZero ? -1 : 0;
  }

  score += Math.max(
    0,
    // penalize dead space more when zooming
    // in either case multiply by these amounts
    // because 100% (normalized distance 1) of the chart
    // being unused is far worse than having a "bad digit".
    // eg 10X multiplier = 10% unused is the same as 1 bad digit
    normalizedDistance * (yMinZoom || yMaxZoom ? 10 : 6)
  );

  // Apply a small penalty for drawing too few ticks
  // it sort of looks odd, but is a good "last resort"
  // to avoid extra bad digits, sometimes
  score += ticks < 3 ? 3 : 0;

  // apply a small penalty for drawing too many ticks
  // it makes the chart look crowded, but again can be
  // a good alternative to avoid extra bad digits
  score += ticks > 8 ? 3 : 0;

  return score;
}

/**
 * Based on how we have extended the chart's yMin/yMax
 * tells us how much of the chart will be "wasted" or unused
 * based on the actual data's yMin/yMax
 *
 * @returns proportion (0-1) of chart that is unused after extending
 */
export function getTopDistance(
  yMin: number,
  yMax: number,
  minFromDataset: number,
  maxFromDataset: number,
  yMinZoom: boolean,
  yMaxZoom: boolean
) {
  // if chart data are outside of -1 and 1, just apply penalty for portion extended
  // also ensure that if we are zoomed in, we always apply a penalty for the portion extended
  if (maxFromDataset > 1 || yMaxZoom)
    return fixFloat(Math.max(0, yMax - maxFromDataset));

  // penalizes for extending the chart beyond -1 and 1, but only for the amount it was extended
  // only applies if not zooming, otherwise we'd hit the condition above
  if (maxFromDataset < 1 && yMax > 1) return Math.max(0, yMax - 1);

  // does not penalize chart w/ values within -1 to 1, no zoom
  // we know that `maxFromDataset` <= 1 since we'd hit the condition above otherwise
  if (!yMaxZoom) return 0;

  return Math.max(0, yMax - maxFromDataset);
}
export function getBottomDistance(
  yMin: number,
  yMax: number,
  minFromDataset: number,
  maxFromDataset: number,
  yMinZoom: boolean,
  yMaxZoom: boolean
) {
  // if data extends below -1, penalize for extending the chart beyond the data
  // also, if data extends below 0 (has negatives) and we are zooming Y axis, apply
  // a bottom penalty for the amount extended beyond the data
  if (minFromDataset < -1 || (minFromDataset < 0 && yMinZoom))
    return fixFloat(Math.max(0, minFromDataset - yMin));

  // penalizes for extending the chart beyond -1 and 1, but only for the amount it was extended
  // do not apply the penalty for the portions of the chart within -1 and 1
  if (yMin < -1 && minFromDataset > -1 && !yMinZoom)
    return Math.max(0, -1 - yMin);

  // does not penalize percent chart w/ values within -1 to 1, no zoom
  if (!yMinZoom && minFromDataset > -1 && minFromDataset < 1) return 0;

  // (I think) this is just for zooming + data outside of -1 and 1
  // applies the penalty for extending beyond the data itself, and not -1 or 1
  return fixFloat(Math.max(0, minFromDataset - yMin));
}
export function getNormalizedDistance(
  yMin: number,
  yMax: number,
  minFromDataset: number,
  maxFromDataset: number,
  yMinZoom: boolean,
  yMaxZoom: boolean
) {
  const extent = yMax - yMin;

  const top = getTopDistance(
    yMin,
    yMax,
    minFromDataset,
    maxFromDataset,
    yMinZoom,
    yMaxZoom
  );
  const bottom = getBottomDistance(
    yMin,
    yMax,
    minFromDataset,
    maxFromDataset,
    yMinZoom,
    yMaxZoom
  );

  // penalized distanced based on visual proportion of the chart that is empty
  const normalizedDistance = (bottom + top) / extent;
  return normalizedDistance;
}

function extendChart(
  yMinZoom: boolean,
  yMaxZoom: boolean,
  minFromDataset: number,
  maxFromDataset: number,
  snapToNearestCandidate: number
) {
  /**
   * Ymin applies to all charts
   *
   * When not zooming, we want to extend yMin downward
   * to 0, even if the dataset does not go down that far.
   * As such, we initialize it to 0 if not zooming. If zooming,
   * we initialize it infinitely high so that the value yMin
   * takes on will be the lowest point from the dataset
   */
  let yMinCandidate = yMinZoom ? Infinity : minFromDataset < 0 ? -1 : 0;

  /**
   * yMax applies to all charts
   *
   * When not zooming, we want to extend yMax upward
   * to 1 (100%), even if the data set does not go up that far.
   * As such, we initialize it to 1 if not zooming. If zooming,
   * we initialize it to infinitely low so that the value yMax
   * takes on will be the highest point from the data set
   */
  let yMaxCandidate = yMaxZoom ? -Infinity : 1;

  /**
   * Update yMin / yMax to extend it as far as the data goes
   * (in many cases the case the data goes further than 0-1,
   * eg (-0.1 - 1), (0 - 4), etc..)
   */
  yMinCandidate = Math.min(yMinCandidate, minFromDataset);
  yMaxCandidate = Math.max(yMaxCandidate, maxFromDataset);
  if (
    Infinity === Math.abs(yMinCandidate) ||
    Infinity === Math.abs(yMaxCandidate)
  ) {
    throw new Error(`Unable to determine extent of data, maybe empty?`);
  }

  /**
   * Now extend yMin/yMax to snap to the nearest increment. To do this,
   * find out how many increments it currently is then use floor/ceil to
   * round the number of increment(s) up or down. Lastly, multiply by the number
   * of units in each increment
   */
  yMinCandidate =
    Math.floor(yMinCandidate / snapToNearestCandidate) * snapToNearestCandidate;
  yMaxCandidate =
    Math.ceil(yMaxCandidate / snapToNearestCandidate) * snapToNearestCandidate;

  return {
    yMaxCandidate: fixFloat(yMaxCandidate),
    yMinCandidate: fixFloat(yMinCandidate),
  };
}

export function possibleSnapTo(numDigits: number) {
  return [
    Math.pow(10, numDigits + 1) * 2,
    Math.pow(10, numDigits + 1),
    Math.pow(10, numDigits + 1) / 2,
    Math.pow(10, numDigits + 1) / 3,
    Math.pow(10, numDigits + 1) / 4,
    Math.pow(10, numDigits + 1) / 5,
    Math.pow(10, numDigits),
    Math.pow(10, numDigits) / 2,
    Math.pow(10, numDigits) / 4,
    Math.pow(10, numDigits) / 5,
    Math.pow(10, numDigits - 1),
    Math.pow(10, numDigits - 1) / 2,
    Math.pow(10, numDigits - 1) / 4,
    Math.pow(10, numDigits - 1) / 5,
    Math.pow(10, numDigits - 2),
    Math.pow(10, numDigits - 2) / 2,
    Math.pow(10, numDigits - 2) / 4,
    Math.pow(10, numDigits - 2) / 5,
    Math.pow(10, numDigits - 3),
    Math.pow(10, numDigits - 3) / 2,
    Math.pow(10, numDigits - 3) / 4,
    Math.pow(10, numDigits - 3) / 5,
    Math.pow(10, numDigits - 4),
    Math.pow(10, numDigits - 4) / 2,
    Math.pow(10, numDigits - 4) / 4,
    Math.pow(10, numDigits - 4) / 5,
  ].map(fixFloat);
}

export const limitPrecision = (num: number, precision: number) =>
  Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);

export const formatDollar = (num: number | null) => {
  if (num === null) return 'null';
  const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return dollarFormatter.format(num);
};

export const formatDollarCompact = (num: number | null) => {
  if (num === null) return 'null';
  const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    notation: 'compact',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return dollarFormatter.format(num);
};

export const formatPercent = (num: number | null) => {
  if (num === null) return 'null';
  const float = fixFloat(fixFloat(num) * 100);
  return `${limitPrecision(float, 4)}%`;
};

export const formatUnitCompact = (num: number | null) =>
  num === null ? 'null' : numberCompactFormat.format(fixFloat(num));

export const formatUnit = (num: number | null) =>
  num === null ? 'null' : `${numberFormat.format(fixFloat(num))}`;

export function getFormatters(
  targetDimension: TrendsTargetDimension,
  isPercentChart: boolean,
  chartDefaultType: string
) {
  let yAxisFormatter: (n: number | null) => string;
  let tooltipYFormatter: (n: number | null) => string;

  if (TrendsTargetDimension.Indexed === targetDimension) {
    yAxisFormatter = formatUnit;
    tooltipYFormatter = formatUnit;
  } else if (isPercentChart) {
    // Certain params force all charts to be percent,
    // regardless of their default type
    yAxisFormatter = formatPercent;
    tooltipYFormatter = formatPercent;
  } else {
    // the charts "default type"
    switch (chartDefaultType) {
      case 'dollar':
        yAxisFormatter = formatDollarCompact; // dollar compact
        tooltipYFormatter = formatDollar;
        break;
      case 'percent':
        yAxisFormatter = formatPercent;
        tooltipYFormatter = formatPercent;
        break;
      case 'unit':
        yAxisFormatter = formatUnitCompact; // unit compact
        tooltipYFormatter = formatUnit;
        break;
    }
  }
  return {
    tooltipYFormatter: tooltipYFormatter!,
    yAxisFormatter: yAxisFormatter!,
  };
}
