import { TypographyPercent } from '../TypographyPercent';
import { DefaultCell } from './DefaultCell';
import { numberColumn } from './NumberCell';

export const percentChangeColumn = () => ({
  ...numberColumn(),
  Cell: PercentChangeCell,
});

export const PercentChangeCell = ({ value }: { value: number }) => {
  return (
    <DefaultCell>
      <TypographyPercent value={value / 100} />
    </DefaultCell>
  );
};
