import { Column } from '../../react-table-column-types';
import { types } from '@groundwater/shared-ui';
import { stringColumn } from './StringCell';

export const tickerColumn = <
  T extends { company: Pick<types.Company, 'ticker' | 'exchange'> }
>(): Column<T> => ({
  ...stringColumn(),
  filter: 'text' as const,
  width: 200,
  Header: 'Ticker',
  id: 'ticker',
  accessor: ({ company }) =>
    company.ticker && company.exchange
      ? `${company.exchange}:${company.ticker}`
      : '',
});
