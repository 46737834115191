import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Typography, Container, Stack } from '@mui/material';
import {
  IllustrationEmailSent,
  MotionContainer,
  PageLayout,
  varBounceIn,
} from '@groundwater/shared-ui';
import { PublicLayout } from '../../layouts/PublicLayout';

const RootStyle = styled(PageLayout)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const DemoRequestSuccessPage: React.FC<{}> = () => {
  return (
    <PublicLayout redirectHome={false}>
      <RootStyle title="Demo Request Success">
        <Container>
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                <Box
                  component={IllustrationEmailSent}
                  sx={{ height: 175, mx: 'auto' }}
                />
              </motion.div>
              <Stack spacing={0} sx={{ my: 3 }}>
                <Typography variant="h3" paragraph>
                  Demo request sent.
                </Typography>
                <Typography>
                  Thank you! Someone will be in touch with you soon.
                </Typography>
              </Stack>
            </Box>
          </MotionContainer>
        </Container>
      </RootStyle>
    </PublicLayout>
  );
};
