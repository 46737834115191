import { DownloadAllCsvButton } from '@groundwater/app/ui';
import { useToast } from '@groundwater/shared-ui';
import { useDeferredValue, useState } from 'react';
import { useGetDollarRetentionExportAllQuery } from '../../../gql-generated';
import { triggerFileDownload } from '../../../utils';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';

export const DollarRetentionExportAllButton: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const variables = useVariables(REPORT_PAGES.dollar_retention);
  const deferredVariables = useDeferredValue(variables);

  const { company_id, rollup, start_date, end_date, xaxis, bucket } =
    deferredVariables.request;

  const csvDownload = useGetDollarRetentionExportAllQuery.fetcher({
    request: {
      company_id,
      rollup,
      start_date,
      end_date,
      xaxis,
      bucket,
    },
  });

  const { setToast } = useToast();

  const showToast = () => {
    setToast({
      id: `csv-download-${Math.random()}`,
      open: true,
      severity: 'error',
      message: `Failed to download CSVs.`,
    });
  };

  const handleCSVDownload = async () => {
    try {
      setIsError(false);
      setIsLoading(true);

      const blob = await csvDownload();

      triggerFileDownload(
        blob.dollar_retention_export_all.filename,
        blob.dollar_retention_export_all.data
      );
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);

      return showToast();
    }
  };

  return (
    <DownloadAllCsvButton
      isLoading={isLoading}
      isError={isError}
      tooltipTitle="Download all dollar retention reports as CSV"
      handleOnClick={handleCSVDownload}
    />
  );
};
