import { Autocomplete, FilterOptionsState, TextField } from '@mui/material';
import { VariableKeys } from '../../../utils';
import { useGetTransactionValuesQuery } from '../../../gql-generated';
import { REPORT_PAGES } from '../../constants';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import { selectContiguousValues } from '../../pages/TransactionValues/selector';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';
import { useReportPage } from '../../hooks/useReportPage';
import { useApplyFilters } from '../../hooks/useApplyFilters';

const currencyCentsFormat = Intl.NumberFormat('en', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

interface Props {
  field:
    | VariableKeys.transaction_value_min
    | VariableKeys.transaction_value_max;
  placeholder: string;
}

const filterOptions = (
  options: number[],
  state: FilterOptionsState<number>
) => {
  const value = state.inputValue;
  // not using filter so we can stop early, for perf
  const matches = [];
  for (const option of options) {
    if (
      value === '' ||
      // strip commas (and other chars) but not decimals
      option
        .toString()
        .indexOf(parseFloat(value.replace(/[^0-9.]/, '')).toString()) === 0
    ) {
      matches.push(option);
    }
    if (matches.length > 10) break;
  }
  return matches;
};

export const TransactionAmountFilter = ({ placeholder, field }: Props) => {
  const apply = useApplyFilters(useReportPage());
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (
    !(VariableKeys.transaction_value_min in draftParams) ||
    !(VariableKeys.transaction_value_max in draftParams)
  ) {
    throw new Error();
  }

  /** Selection in domain values (dollar amounts, not pixels) */
  const coercedQueryParams = useCoercedQueryParams(
    REPORT_PAGES.transaction_values
  );

  const { data } = useGetTransactionValuesQuery({
    request: {
      company_id: coercedQueryParams.company_id,
      start_date: coercedQueryParams.start_date,
      end_date: coercedQueryParams.end_date,
      filter_brand_types: coercedQueryParams.filter_brand_types,
    },
  });

  if (!data) {
    throw new Error(`dataset missing`);
  }

  const { transaction_values_v2: transaction_values } = data;

  // just hide the [client side] filter on error, the chart will present the error
  if (transaction_values.__typename === 'DataError') {
    return null;
  }

  const rows = transaction_values.data;

  const options = selectContiguousValues(rows, field);

  const value = draftParams[field];

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={options}
      value={value}
      onChange={(_e, value) => {
        if (field === VariableKeys.transaction_value_min) {
          apply({
            [VariableKeys.transaction_value_min]: value,
          });
        } else {
          apply({
            [VariableKeys.transaction_value_max]: value,
          });
        }
      }}
      getOptionLabel={(number) => currencyCentsFormat.format(number)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder}
          sx={{ width: 200 }}
          value={value === null ? '' : currencyCentsFormat.format(value)}
          size="small"
        />
      )}
    />
  );
};
