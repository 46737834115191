import FileSaver from 'file-saver';

function fileDownload(data: Blob, filename: string) {
  FileSaver.saveAs(data, filename);
}

export const triggerFileDownload = (filename: string, data: string) => {
  // Download csv file
  fileDownload(new Blob(Array.from(data), { type: 'text/csv' }), filename);
};
