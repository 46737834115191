import { styled } from '@mui/material';
import { StyledApexChart } from '../../../components/StyledApexChart';

export const StyledReportedRevenueChart = styled(StyledApexChart)(() => ({
  '& .ovr-custom-tooltip': {
    emptyCells: 'show',
    '& th': {
      textAlign: 'left',
      padding: '2px 5px',
      fontSize: '13px',
    },
    '& tr': {
      border: '1px solid #CCC',
    },
    '& td': {
      textAlign: 'left',
      fontSize: '12px',
      padding: '2px 5px',
      '& img': {
        display: 'unset',
        width: '12px',
        height: '12px',
      },
      '& span': {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        borderRadius: '15px',
        marginRight: '6px',
      },
    },
  },
}));
