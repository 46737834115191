import { Suspense, useMemo, useState } from 'react';
import { CircularProgressInStack, MHidden } from '@groundwater/shared-ui';
import {
  Alert,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Skeleton,
  Stack,
} from '@mui/material';
import { useCompanyOrBrands } from '../../hooks';
import { CompareByFilterSearch } from './CompareByFilter/Search';
import { useCompanyId } from '../../hooks/useCompanyId';
import { Menu } from '@groundwater/shared-ui';
import { useReportPage } from '../../hooks/useReportPage';
import {
  COMPARE_BY_LIMIT,
  VariableKeys,
  variablesSchema,
  VariablesUnion,
} from '../../../utils';
import { rollup_conflicts_compare_by } from '../../../utils/reportPageHelpers/rollup_conflicts_compare_by';
import { breakout_by_conflicts_compare_to } from '../../../utils/reportPageHelpers/breakout_by_conflicts_compare_to';

import { BreakoutBy } from '../../../gql-generated';
import { reach } from 'yup';
import { ROLLUP_LABELS } from '../../constants';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

const useConflicts = (draftParams: VariablesUnion) => {
  const rollupConflicts: boolean = rollup_conflicts_compare_by(draftParams);

  const breakoutByConflicts: boolean =
    breakout_by_conflicts_compare_to(draftParams);
  return {
    rollupConflicts,
    breakoutByConflicts,
  };
};

const CompareByFilterSelection: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (!(VariableKeys.compare_by_company_ids in draftParams)) {
    throw new Error();
  }
  const draftCompaniesMetadata = useCompanyOrBrands(
    draftParams.compare_by_company_ids
  );

  return (
    <>
      {draftCompaniesMetadata.map((brandOrCompany) => (
        <Chip
          key={brandOrCompany.id}
          label={brandOrCompany.title}
          onDelete={() => {
            handleDraftChange((prevParams) => {
              if (!(VariableKeys.compare_by_company_ids in prevParams)) {
                throw new Error();
              }
              return {
                ...prevParams,
                compare_by_company_ids:
                  prevParams.compare_by_company_ids.filter(
                    (id) => id !== brandOrCompany.id
                  ),
              };
            });
          }}
          size="small"
        />
      ))}
    </>
  );
};

export const CompareByFilterContent: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (!(VariableKeys.compare_by_company_ids in draftParams)) {
    throw new Error();
  }

  const { breakoutByConflicts, rollupConflicts } = useConflicts(draftParams);

  const company_id = useCompanyId();

  const hiddenIds = useMemo(
    () => [company_id, ...draftParams.compare_by_company_ids],
    [company_id, draftParams.compare_by_company_ids]
  );

  const renderSearchInput = () => {
    if (draftParams.compare_by_company_ids.length < COMPARE_BY_LIMIT) {
      return (
        <CompareByFilterSearch
          doHandleSelect={(brandOrCompany) => {
            handleDraftChange((prevParams) => {
              if (!(VariableKeys.compare_by_company_ids in prevParams)) {
                throw new Error();
              }
              return {
                ...prevParams,
                compare_by_company_ids: [
                  ...prevParams.compare_by_company_ids,
                  brandOrCompany.id,
                ],
              };
            });
          }}
          hiddenIds={hiddenIds}
        />
      );
    } else {
      return (
        <Alert severity="info">
          You have reached the maximum number of comparisons — For comparing
          companies in bulk, we recommend utilizing data feeds, CSV exports, and
          other off-line analysis!
        </Alert>
      );
    }
  };

  const page = useReportPage();

  return (
    <Box sx={{ py: 2, mx: 2, maxWidth: 500 }} data-testid="compare-by">
      <Stack spacing={1}>
        <Box sx={{ p: 2 }}>{renderSearchInput()}</Box>
        <Stack
          direction="row"
          spacing={1}
          rowGap={2}
          sx={{ p: 2 }}
          flexWrap="wrap"
          data-testid="compare-by-chips"
        >
          <Suspense
            fallback={
              <>
                <Skeleton variant="rectangular" width="100%" />
                <Skeleton variant="rectangular" width="100%" />
                <Skeleton variant="rectangular" width="100%" />
              </>
            }
          >
            <CompareByFilterSelection />
          </Suspense>
        </Stack>
        {breakoutByConflicts ? (
          <Alert severity="error">
            Can't combine 'Compare To' with 'Breakout By' unless breaking out by
            a single geographical area. Adjust your 'Break Out By' selection to
            contain 1 geographical area, or reset selection.
            <br />
            <Button
              onClick={() => {
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  breakout_by: BreakoutBy.None,
                }));
              }}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Break Out By' to default
            </Button>
          </Alert>
        ) : null}
        {rollupConflicts ? (
          <Alert severity="error">
            'Compare To' conflicts with 'Roll Up'{' '}
            {ROLLUP_LABELS[draftParams.rollup]}. Remove 'Compare To' selections,
            or reset 'Roll Up' selection to supported option.
            <br />
            <Button
              onClick={() => {
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  rollup: reach(variablesSchema[page], 'rollup').getDefault(),
                }));
              }}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Roll Up' to default
            </Button>
          </Alert>
        ) : null}
      </Stack>
    </Box>
  );
};

export const CompareByFilter: React.FC<{}> = () => {
  const { draftParams } = useFilterContext(useReportPage());
  if (!(VariableKeys.compare_by_company_ids in draftParams)) {
    throw new Error();
  }
  const buttonLabel = useMemo(() => {
    return draftParams.compare_by_company_ids.length === 0
      ? `Compare To`
      : `Comparing To ${draftParams.compare_by_company_ids.length} ${
          draftParams.compare_by_company_ids.length > 1
            ? 'Businesses'
            : 'Business'
        }`;
  }, [draftParams.compare_by_company_ids.length]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((o) => !o);
  };
  const handleClose = () => setOpen(false);

  const { rollupConflicts, breakoutByConflicts } = useConflicts(draftParams);

  const isError = breakoutByConflicts || rollupConflicts;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <span>
        <Button
          aria-controls={open ? `filter-menu-compareby` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          size="medium"
          color={isError ? 'error' : 'primary'}
          sx={{
            ...(isError ? { backgroundColor: 'error.dark' } : {}),
          }}
          onClick={handleClick}
          aria-invalid={isError}
        >
          {buttonLabel}
        </Button>
        <Menu anchorEl={anchorEl} open={open} handleClose={handleClose}>
          <Suspense fallback={<CircularProgressInStack />}>
            <CompareByFilterContent />
          </Suspense>
        </Menu>
      </span>
    </ClickAwayListener>
  );
};
