import queryString from 'query-string';
import { REPORT_PAGES } from '../constants';
import { Variables } from '../../utils/reportPageConfigs';
import { useNavigateSearch } from '@groundwater/shared-ui';

export const createReportPageURL = <T extends REPORT_PAGES>(
  page: T,
  variables: Variables[T]
) => {
  const query = queryString.stringify(variables, { arrayFormat: 'bracket' });
  return `${page}?${query}`;
};

export { useNavigateSearch };
