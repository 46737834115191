export interface LoginRequest {
  password: string;
  username: string;
}

export enum AUTH_TOKENS {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
}

export enum LOGIN_GRANT_TYPES {
  PASSWORD = 'password',
  MFA_OOB = 'mfa-oob',
  REFRESH_TOKEN = 'refresh_token',
}
