import { Suspense, useMemo } from 'react';
import { Box, styled, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { VariableKeys } from '../../utils';
import { useReportPage } from '../hooks/useReportPage';
import { DateRangeFilter } from './filters/DateRangeFilter';
import { FilterContextController } from './FilterSubHeader/hooks/controller';
import { ApplyButton } from './ApplyButton';
import { RollupFilter } from './filters/RollupFilter';
import { TargetDimensionFilter } from './filters/TargetDimensionFilter';
import { FilterByControl } from './filters/FilterByControl/FilterByControl';
import { CompareByFilter } from './filters/CompareByFilter';
import { BreakoutByFilter } from './filters/BreakoutByFilter';
import { ScalingModelFilter } from './filters/ScalingModelFilter/ScalingModelFilter';
import { SelectedQuarterFilter } from './filters/SelectedQuarterFilter';
import { BusinessTypeFilter } from './filters/BusinessTypeFilter';
import { CohortTypeFilter } from './filters/CohortTypeFilter/CohortTypeFilter';
import { CohortIndexFilter } from './filters/CohortIndexFilter/CohortIndexFilter';
import { useCoercedQueryParams } from '../hooks/useCoercedQueryParams';
import { QuarterFilter } from './filters/QuarterFilter';
import { TransactionAmountFilter } from './filters/TransactionAmountFilter';
import { YZoom } from './filters/YZoom';
import { CohortByPriceFilter } from './filters/TransactionValueSelector';
import { REPORT_PAGES } from '../constants';

const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexWrap: 'wrap',
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
  },
  '& > :not(style)': {
    // adds space between filter buttons
    marginRight: theme.spacing(1),
    // adds top space between the filter buttons in case if they wrap
    marginTop: theme.spacing(1),
  },
}));

export interface FilterSubHeaderProps {}

export const FilterSubHeader: React.FC<{}> = () => {
  const page = useReportPage();
  const params = useCoercedQueryParams(page);

  const analysisQuarter = useMemo(() => {
    return (
      VariableKeys.analysis_quarter in params && (
        <QuarterFilter param={VariableKeys.analysis_quarter} />
      )
    );
  }, [params]);

  const comparisonQuarter = useMemo(() => {
    return (
      VariableKeys.comparison_quarter in params && (
        <QuarterFilter param={VariableKeys.comparison_quarter} />
      )
    );
  }, [params]);
  return (
    <BoxStyle>
      <Suspense
        fallback={
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rectangular" width={150} height={36} />
            <Skeleton variant="rectangular" width={150} height={36} />
            <Skeleton variant="rectangular" width={150} height={36} />
            <Skeleton variant="rectangular" width={150} height={36} />
            <Skeleton variant="rectangular" width={150} height={36} />
          </Stack>
        }
      >
        <FilterContextController page={page}>
          <>
            {VariableKeys.start_date in params ||
            VariableKeys.end_date in params ? (
              <DateRangeFilter />
            ) : null}

            {VariableKeys.rollup in params ? <RollupFilter /> : null}

            {VariableKeys.target_dimension in params ? (
              <TargetDimensionFilter />
            ) : null}

            {VariableKeys.filter_brand_types in params ||
            VariableKeys.filter_channels in params ? (
              <FilterByControl />
            ) : null}

            {VariableKeys.compare_by_company_ids in params ? (
              <CompareByFilter />
            ) : null}

            {VariableKeys.breakout_by in params ? <BreakoutByFilter /> : null}

            {VariableKeys.scaling_model in params ? (
              <ScalingModelFilter />
            ) : null}

            {VariableKeys.selected_quarter in params ? (
              <SelectedQuarterFilter />
            ) : null}
            {VariableKeys.businesses_type in params ? (
              <BusinessTypeFilter />
            ) : null}

            {page !== REPORT_PAGES.trends && VariableKeys.bucket in params ? (
              <CohortByPriceFilter />
            ) : null}

            {VariableKeys.cohort_index in params ||
            VariableKeys.xaxis in params ? (
              <CohortIndexFilter />
            ) : null}

            {analysisQuarter}
            {comparisonQuarter}

            <ApplyButton />

            {VariableKeys.cohort_view in params ? <CohortTypeFilter /> : null}

            {VariableKeys.transaction_value_min in params && (
              <Suspense
                fallback={
                  <Skeleton variant="rectangular" width={150} height={36} />
                }
              >
                <TransactionAmountFilter
                  field={VariableKeys.transaction_value_min}
                  placeholder="Transaction Minimum"
                />
              </Suspense>
            )}

            {VariableKeys.transaction_value_max in params && (
              <Suspense
                fallback={
                  <Skeleton variant="rectangular" width={150} height={36} />
                }
              >
                <TransactionAmountFilter
                  field={VariableKeys.transaction_value_max}
                  placeholder="Transaction Maximum"
                />
              </Suspense>
            )}

            {VariableKeys.y_zoom in params && <YZoom />}
          </>
        </FilterContextController>
      </Suspense>
    </BoxStyle>
  );
};
