/// <reference types="react/experimental" />
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import React from 'react';
import { FilterButtonMenuOption } from './FilterMenuList';

export interface FilterMenuOptionProps<OptionValue> {
  menuOption: FilterButtonMenuOption<OptionValue>;
  onClick: (value: FilterButtonMenuOption<OptionValue>['value']) => void;
  selected: boolean;
}
export const FilterMenuOption = <OptionValue,>({
  menuOption,
  onClick,
  selected,
}: FilterMenuOptionProps<OptionValue>): React.ReactElement => {
  return (
    <Box key={menuOption.label}>
      <MenuItem
        onClick={() => {
          onClick(menuOption.value);
        }}
        data-testid={`menu-option-${menuOption.value}`}
        disabled={menuOption.disabled}
        selected={selected}
      >
        {selected && (
          <ListItemIcon sx={{ mr: 0 }}>
            <Check />
          </ListItemIcon>
        )}
        <ListItemText inset={!selected}>{menuOption.label}</ListItemText>
        {menuOption.secondaryValue && (
          <Typography variant="caption" color="text.secondary">
            {menuOption.secondaryValue}
          </Typography>
        )}
        {menuOption.tooltip ? menuOption.tooltip : null}
      </MenuItem>
    </Box>
  );
};
