import moment from 'moment';
import { string } from 'yup';

export * from './lib/shared-util';
export * from './lib/doConcurrently';
export * from './lib/unpacked';

// Date range filter shortcuts
export enum DATE_SHORTCUT_KEYS {
  last_6_months = '-6m',
  last_12_months = '-12m',
  last_2_years = '-2y',
  last_3_years = '-3y',
  last_5_years = '-5y',
  max = 'earliest',
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const MIN_START_DATE = moment(
  '2017-1-1',
  DEFAULT_DATE_FORMAT
).toISOString();

type Parent = {
  start_date: string;
  end_date: string;
};

export const start_date_schema = string()
  .default(DATE_SHORTCUT_KEYS.last_2_years)
  .test(
    'start-date-is-valid-date',
    'Start date must be a valid date or shortcut',
    (start_date) =>
      moment(start_date).isValid() ||
      Object.values(DATE_SHORTCUT_KEYS).includes(start_date as any)
  )
  .test(
    'start-and-end-homogenous',
    'Start and end date must be homogenous',
    (start_date, { parent }: { parent: Parent }) => {
      const start_is_shortcut = Object.values(DATE_SHORTCUT_KEYS).includes(
        start_date as any
      );
      const end_is_shortcut = 'latest' === parent.end_date;
      return start_is_shortcut === end_is_shortcut;
    }
  )
  .test(
    'start-date-before-the-end-date',
    'Start date can not be greater than the end date',
    (start_date, { parent }: { parent: Parent }) => {
      // if start date is a shortcut, we basically skip this validation
      if (Object.values(DATE_SHORTCUT_KEYS).includes(start_date as any)) {
        return true;
      }

      // this is subtle -- if end date is "latest", or is invalid
      // we don't want this validation (about start being after end) to be the failure message,
      // we want to return true and allow the validation rule for the end
      // date to generate the failure message
      if (
        'latest' === parent.end_date ||
        !end_date_schema.isValidSync(parent.end_date)
      ) {
        return true;
      }

      // valid if start date is before end date
      return moment(start_date).isBefore(parent.end_date);
    }
  )
  .test(
    'start-date-greater-then-min-date',
    `Start date must be greater than ${moment(MIN_START_DATE).format(
      DEFAULT_DATE_FORMAT
    )}`,
    (start_date) => {
      // if start date is a shortcut, we basically skip this validation
      if (Object.values(DATE_SHORTCUT_KEYS).includes(start_date as any)) {
        return true;
      }

      // valid if start date is same or after the minimum
      return moment(start_date).isSameOrAfter(MIN_START_DATE);
    }
  );

export const end_date_schema = string()
  .default('latest')
  .test(
    'end-date-is-valid-date',
    'End date must be a valid date',
    (end_date) =>
      // valid if a date (that is valid according to the moment JS library) is passed
      // note, this also means start_date *has* to be a date, which is skipped here for redundancy
      moment(end_date).isValid() ||
      // valid if its a shortcut
      // note, this also means start_date *has* to be a shortcut, which is skipped here for redundancy
      'latest' === end_date
  );

export type Unpacked<T> = T extends (infer U)[] ? U : T;
