import React from 'react';
import { Input, Stack } from '@mui/material';

export function DateRangeFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: any) {
  return (
    <Stack direction="column">
      <Input
        value={filterValue[0]}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val, old[1]]);
        }}
        placeholder={`Min`}
      />
      <Input
        value={filterValue[1]}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val]);
        }}
        placeholder={`Max`}
      />
    </Stack>
  );
}
