import {
  QtdScalingModel,
  useGetQtdPercentObservedQuery,
} from '../../../gql-generated';
import React, { useDeferredValue } from 'react';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { PercentObservedChart } from '@groundwater/app/ui';
import { Alert, Skeleton } from '@mui/material';

export const QtdPercentObservedChartSkeleton = () => (
  <Skeleton variant="rectangular" width="100%" height={370} />
);

export const QtdPercentObservedChart: React.FC<{}> = () => {
  const variables = useVariables(REPORT_PAGES.qtd);
  const deferredVariables = useDeferredValue(variables);

  const response = useGetQtdPercentObservedQuery({
    request: {
      company_id: deferredVariables.request.company_id,
      analysis_quarter: deferredVariables.request.analysis_quarter,
    },
  });

  if (!response.data) throw new Error();

  const { qtd_percent_observed_v2: qtd_percent_observed } = response.data;

  if (qtd_percent_observed.__typename === 'DataError') {
    return <Alert severity="info">{qtd_percent_observed.message}</Alert>;
  }

  /**
   * At this point we fetched the dataset that contains series for all scaling models.
   * That's why we want the chart to update instantly upon model change
   * hense we are using variables instead of deferredVariables.
   */
  const selectedScalingModel = variables.request.scaling_model;
  const customScalingModelValue =
    variables.request.scaling_model_custom_percent;
  const {
    best_fit,
    scaling_models,
    quarter_end_date,
    historical_proportion_observed,
  } = qtd_percent_observed;

  let chartSeries;

  // 1. if custom model selected manually construct `modeled` serie
  if (selectedScalingModel === QtdScalingModel.Custom) {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
      modeled: historical_proportion_observed.map((i, ind) => {
        // Append the custom model value as the last serie index (end of serie range)
        if (ind === historical_proportion_observed.length - 1) {
          return customScalingModelValue.toString();
        } else if (ind === historical_proportion_observed.length - 2) {
          // Use the last reported proportion observed as the start of serie range
          return i;
        }
        // Add null padding to the rest of the serie indexes
        return null;
      }),
    };
  }

  // 2. Map the best fit model value to the respective scaling model serie
  else if (selectedScalingModel === QtdScalingModel.BestFit) {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
      modeled: scaling_models[best_fit],
    };
  }

  // 3. Unscaled model only represents historical observed serie
  else if (selectedScalingModel === QtdScalingModel.Unscaled) {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
    };
  }

  // 4. Directly map scaling model
  else {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
      modeled: scaling_models[selectedScalingModel],
    };
  }

  return (
    <PercentObservedChart
      dataset={chartSeries}
      customProjection={selectedScalingModel === QtdScalingModel.Custom}
      yZoom={variables.request.y_zoom === 'true'}
    />
  );
};
