import React, { Suspense, useDeferredValue, useMemo } from 'react';
import { ChartFrame, RetentionSummaryChart } from '@groundwater/app/ui';
import { useGetRetentionSummaryQuery } from '../../../gql-generated';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useVariables } from '../../hooks/useVariables';
import { REPORT_PAGES } from '../../constants';
import { Alert, Grid, Skeleton, Tooltip } from '@mui/material';
import {
  getChartTitle,
  getChartTooltip,
  getXAxisLabel,
  getYSerieData,
} from './utils';
import { useChartResetKeys } from '../../hooks/useResetKeys';
import { omit } from 'lodash-es';

const LoadingSkeleton = () => (
  <Grid
    container
    spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    data-testid="retention-summary-charts-skeleton"
  >
    <Grid item xs={12}>
      <Skeleton variant="rectangular" width="100%" height={350} />
    </Grid>
    <Grid item xs={12}>
      <Skeleton variant="rectangular" width="100%" height={350} />
    </Grid>
    <Grid item xs={12}>
      <Skeleton variant="rectangular" width="100%" height={350} />
    </Grid>
  </Grid>
);

export function RetentionSummaryCharts() {
  return (
    <Suspense fallback={<LoadingSkeleton />}>
      <RetentionSummaryChartsData />
    </Suspense>
  );
}

export const RetentionSummaryChartsData: React.FC<{}> = () => {
  const variables = useVariables(REPORT_PAGES.retention_summary);
  const deferredVariables = useDeferredValue(variables);

  const resetKeys = useChartResetKeys();

  const keys = resetKeys.join(',');

  const response = useGetRetentionSummaryQuery(
    omit(deferredVariables, 'request.y_zoom')
  );

  if (!response.data) {
    throw new Error('Dataset is not present in the response payload.');
  }

  const { retention_summary_v2: retention_summary } = response.data;

  const chartGroup = 'retention-summary';

  const xAxisTitle = useMemo(
    () => getXAxisLabel(deferredVariables.request.rollup),
    [deferredVariables.request.rollup]
  );

  const isLoading = variables !== deferredVariables;

  if (retention_summary.__typename === 'DataError') {
    return isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Alert severity="info">{retention_summary.message}</Alert>
    );
  }
  const { periods, y_series } = retention_summary;

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    >
      {Object.keys(y_series).map((id) => {
        return (
          <Grid key={id} item xs={12}>
            <ChartFrame
              actionContent={
                <Tooltip title={getChartTooltip(id)}>
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              }
              chartTitle={getChartTitle(id, deferredVariables.request.rollup)}
              isLoading={isLoading}
            >
              <RetentionSummaryChart
                yZoom={deferredVariables.request.y_zoom === 'true'}
                key={`${id}-${keys}`}
                id={id}
                group={chartGroup}
                data={{
                  x: periods,
                  y: getYSerieData(id, y_series),
                }}
                xAxisTitle={xAxisTitle}
              />
            </ChartFrame>
          </Grid>
        );
      })}
    </Grid>
  );
};
