import {
  FilterButtonGroupedMenuOption,
  FilterButtonMenu,
} from '@groundwater/shared-ui';
import { BreakoutBy, TrendsRollup } from '../../../gql-generated';
import {
  BREAKOUT_BY_LABELS,
  GlobalRollupOption,
  ROLLUP_GROUPS,
  ROLLUP_LABELS,
} from '../../constants';
import { FiscalQuarterOptionValueLoader } from './RollupFilter/FiscalQuarterOption';
import { useReportPage } from '../../hooks/useReportPage';
import { VariableKeys, variablesSchema, VariablesUnion } from '../../../utils';
import { rollup_conflicts_compare_by } from '../../../utils/reportPageHelpers/rollup_conflicts_compare_by';
import { breakout_by_conflicts_rollup } from '../../../utils/reportPageHelpers/breakout_by_conflicts_rollup';
import { reach } from 'yup';
import { useMemo } from 'react';
import { Alert, Button } from '@mui/material';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

const useConflicts = (draftParams: VariablesUnion) => {
  const compareByConflicts: boolean = rollup_conflicts_compare_by(draftParams);
  const breakoutByConflicts: boolean =
    breakout_by_conflicts_rollup(draftParams);
  return { compareByConflicts, breakoutByConflicts };
};

export const RollupFilter = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (!(VariableKeys.rollup in draftParams)) {
    throw new Error();
  }
  const page = useReportPage();
  // TODO - memoize this!
  const groupedOptions: FilterButtonGroupedMenuOption<GlobalRollupOption>[] =
    Object.entries(ROLLUP_GROUPS)
      .filter(([group, children]) =>
        children.find((rollup) => {
          const valid = reach(
            variablesSchema[page],
            VariableKeys.rollup
          ).isValidSync(rollup);
          return valid;
        })
      )
      .map(([group, children]) => {
        const option: FilterButtonGroupedMenuOption<GlobalRollupOption> = {
          label: group,
          groupItems: children
            .filter((rollup) => {
              const valid = reach(
                variablesSchema[page],
                VariableKeys.rollup
              ).isValidSync(rollup);
              return valid;
            })
            .map((rollup) => ({
              label: ROLLUP_LABELS[rollup],
              value: rollup,
              component:
                rollup === TrendsRollup.FiscalQuarter
                  ? FiscalQuarterOptionValueLoader
                  : undefined,
            })),
        };
        return option;
      });

  const { compareByConflicts, breakoutByConflicts } = useConflicts(draftParams);

  const renderMenuOptionContentSection = useMemo(() => {
    return (
      <>
        {compareByConflicts ? (
          <Alert severity="error">
            Selected 'Roll Up' conflicts with 'Compare To'. Adjust 'Roll Up' to
            supported option, or reset selection.
            <br />
            <Button
              onClick={() =>
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  compare_by_company_ids: [],
                }))
              }
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Compare To' to default
            </Button>
          </Alert>
        ) : null}
        {VariableKeys.breakout_by in draftParams && breakoutByConflicts ? (
          <Alert severity="error">
            Selected 'Roll Up' conflicts with 'Break Out By'{' '}
            {BREAKOUT_BY_LABELS[draftParams[VariableKeys.breakout_by]]}. Adjust
            'Roll Up' to supported option, or reset selection.
            <br />
            <Button
              onClick={() =>
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  breakout_by: BreakoutBy.None,
                }))
              }
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Break Out By' to default
            </Button>
          </Alert>
        ) : null}
      </>
    );
  }, [breakoutByConflicts, compareByConflicts, draftParams, handleDraftChange]);

  return (
    <FilterButtonMenu
      autoClose
      isError={compareByConflicts || breakoutByConflicts}
      id="rollup-filter"
      filterLabel={`Roll Up: ${ROLLUP_LABELS[draftParams.rollup]}`}
      menuOptions={groupedOptions}
      // TODO this arg is inferred as any
      onClick={(rollup: GlobalRollupOption) => {
        handleDraftChange((prevParams) => ({ ...prevParams, rollup }));
      }}
      selected={draftParams.rollup}
      withGroups
      menuOptionContentSection={renderMenuOptionContentSection}
    />
  );
};
