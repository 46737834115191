import { DATE_SHORTCUT_KEYS, Unpacked } from '@groundwater/shared-util';
import {
  BreakoutBy,
  ChannelsV2,
  TrendsRollup,
  CohortedRetentionRollup,
  TrendsTargetDimension,
  QtdScalingModel,
  OvrTargetDimension,
  OvrScalingModel,
  QpsSelectedQuarter,
  BusinessType,
  CohortIndex,
  RetentionSummaryRollup,
  QpsScalingModel,
  GeographicalAreaType,
  BrandTypes,
  BrandType,
  DollarRetentionRollup,
  GrowthAccountingRollup,
} from '../gql-generated';

export const ASSETS_BASE_URL = 'https://assets.secondmeasure.com';

export const DEFAULT_TRANSACTION_SPEND_VALUE =
  '0.00-4.99,5.00-19.99,20.00-99.99,100.00-499.99,500.00+';

/**
 * Lists all available report pages where value represents the pathname.
 */
export enum REPORT_PAGES {
  browse_businesses = '/reports/browse_businesses',
  cohorted_retention = '/reports/cohorted_retention',
  dollar_retention = '/reports/dollar_retention',
  growth_accounting = '/reports/growth_accounting',
  ovr = '/reports/ovr',
  qps = '/reports/qps',
  qtd = '/reports/qtd',
  retention_summary = '/reports/retention_summary',
  transaction_values = '/reports/transaction_values',
  trends = '/reports/trends',
}

export enum COHORT_VIEW {
  Line = 'line',
  Table = 'table',
}

export const DATE_SHORTCUT_LABELS = {
  [DATE_SHORTCUT_KEYS.last_6_months]: 'Last 6 Months',
  [DATE_SHORTCUT_KEYS.last_12_months]: 'Last 12 Months',
  [DATE_SHORTCUT_KEYS.last_2_years]: 'Last 2 Years',
  [DATE_SHORTCUT_KEYS.last_3_years]: 'Last 3 Years',
  [DATE_SHORTCUT_KEYS.last_5_years]: 'Last 5 Years',
  [DATE_SHORTCUT_KEYS.max]: 'Max',
};

const globalRollupValues = [
  ...Object.values(TrendsRollup),
  ...Object.values(CohortedRetentionRollup),
  ...Object.values(DollarRetentionRollup),
  ...Object.values(RetentionSummaryRollup),
  ...Object.values(GrowthAccountingRollup),
];

export type GlobalRollupOption = Unpacked<typeof globalRollupValues>;

const globalBreakoutByValues = [...Object.values(BreakoutBy)];

export type GlobalBreakoutByOption = Unpacked<typeof globalBreakoutByValues>;

const globalTargetDimensionValues = [
  ...Object.values(TrendsTargetDimension),
  ...Object.values(OvrTargetDimension),
];
export type GlobalTargetDimension = Unpacked<
  typeof globalTargetDimensionValues
>;

const globalScalingModelValues = [
  ...Object.values(QtdScalingModel),
  ...Object.values(OvrScalingModel),
];
export type GlobalScalingModel = Unpacked<typeof globalScalingModelValues>;

/**
 * Note the ordering of the nodes in this tree directly affects the order
 * of the filter options in <RollupFilter />.
 * T
 * The `validOptions` in `reportPageConfigs.ts` controls which subset of
 * this "global" tree of options are shown on each page
 */
export const ROLLUP_GROUPS: {
  Calendar: GlobalRollupOption[];
  Fiscal: GlobalRollupOption[];
  Trailing: GlobalRollupOption[];
} = {
  Calendar: [
    TrendsRollup.Day, // in the case of overlapping options, any of them will suffice
    TrendsRollup.Week,
    TrendsRollup.Month,
    TrendsRollup.Quarter,
    TrendsRollup.Year,
  ],
  Fiscal: [TrendsRollup.FiscalQuarter],
  Trailing: [
    TrendsRollup.Trailing_7Days,
    TrendsRollup.Trailing_30Days,
    TrendsRollup.Trailing_90Days,
    TrendsRollup.Trailing_12Months,
  ],
};

export const ROLLUP_LABELS: Record<GlobalRollupOption, string> = {
  [TrendsRollup.Day]: 'Day', // in the case of overlapping options, any of them will suffice
  [TrendsRollup.Week]: 'Week',
  [TrendsRollup.Month]: 'Month',
  [TrendsRollup.Quarter]: 'Quarter',
  [TrendsRollup.Year]: 'Year',
  [TrendsRollup.FiscalQuarter]: 'Quarter',
  [TrendsRollup.Trailing_7Days]: '7-day',
  [TrendsRollup.Trailing_30Days]: '30-day',
  [TrendsRollup.Trailing_90Days]: '90-day',
  [TrendsRollup.Trailing_12Months]: '12-month',
};

export const TARGET_DIMENSION_LABELS: Record<GlobalTargetDimension, string> = {
  [TrendsTargetDimension.ObservedVolume]: 'Observed Volume',
  [TrendsTargetDimension.PopGrowth]: 'PoP Growth',
  [TrendsTargetDimension.YoyGrowth]: 'YoY Growth',
  [TrendsTargetDimension.Indexed]: 'Indexed Growth',
  [OvrTargetDimension.YoySpread]: 'YoY Spread',
};

export const SCALING_MODEL_LABELS: Record<GlobalScalingModel, string> = {
  [QtdScalingModel.BestFit]: 'Best Fit',
  [QtdScalingModel.AllTimeAverage]: 'All Time Average',
  [QtdScalingModel.FourQuarterAverage]: 'Average of Previous 4 Quarters',
  [QtdScalingModel.ExponentialSmoothing]: 'Exponential Smoothing',
  [QtdScalingModel.PreviousQuarter]: 'Previous Quarter',
  [QtdScalingModel.PreviousYear]: 'Previous Year',
  [QtdScalingModel.Unscaled]: 'Unscaled',
  [QtdScalingModel.Custom]: 'Custom',
};

export const QPS_SCALING_MODEL_LABELS: Record<QpsScalingModel, string> =
  SCALING_MODEL_LABELS;

export const CHANNEL_LABELS: Record<ChannelsV2, string> = {
  [ChannelsV2.Online]: 'Online Channels',
  [ChannelsV2.Retail]: 'Retail Channels',
};

export const BRAND_TYPE_LABELS: Record<BrandTypes, string> = {
  [BrandTypes.Primary]: 'Primary',
  [BrandTypes.Intermediary]: 'Intermediary',
};

// Note the sorting order of the labels directly affects the order of the filter options
// https://github.com/secondmeasure/groundwater/blob/1180605e14a9d9642e76758b01fb5664279655bd/apps/app/src/app/components/filters/BreakoutByFilter.tsx#L69
export const BREAKOUT_BY_LABELS: Record<GlobalBreakoutByOption, string> = {
  [BreakoutBy.None]: 'None',
  [BreakoutBy.Brand]: 'Brands Within The Company',
  [BreakoutBy.Veterancy]: 'New vs. Returning Customers',
  [BreakoutBy.Channel]: 'Online vs. Retail',
  [BreakoutBy.Geo]: 'Geographical Areas',
  [BreakoutBy.TransactionValue]: 'Transaction Spend Bands',
  [BreakoutBy.CustomerSpend]: 'Customer Spend Bands',
};

export const SELECET_QUARTER_LABELS: Record<QpsSelectedQuarter, string> = {
  [QpsSelectedQuarter.CurrentQuarter]: 'Current Quarter',
  [QpsSelectedQuarter.UnreportedCompletedQuarter]:
    'Unreported, Completed Quarter',
  [QpsSelectedQuarter.LastReportedQuarter]: 'Last Reported Quarter',
};

/** Used to determine which options are considered "break out by transaction value" / "spend bands" */
export const SPEND_BAND_OPTION_NAMES = [
  BreakoutBy.CustomerSpend,
  BreakoutBy.TransactionValue,
];

export const BUSINESS_TYPE_LABELS: Record<BusinessType, string> = {
  [BusinessType.Brand]: 'Brand',
  [BusinessType.Company]: 'Company',
};

export const COHORT_TYPE_LABELS: Record<COHORT_VIEW, string> = {
  [COHORT_VIEW.Table]: 'Table',
  [COHORT_VIEW.Line]: 'Line',
};

export const COHORT_INDEX_LABELS: Record<CohortIndex, string> = {
  [CohortIndex.Cohort]: 'Cohort Period',
  [CohortIndex.Calendar]: 'Calendar Period',
};

export const GEOGRAPHICAL_AREA_LABEL = {
  [GeographicalAreaType.City]: 'City',
  [GeographicalAreaType.State]: 'State',
  [GeographicalAreaType.Metro]: 'CBSA',
};

export const BRAND_TYPE_LABEL = {
  [BrandType.Financing]: 'Financing',
  [BrandType.Marketplace]: 'Marketplace',
  [BrandType.PaymentServiceProvider]: 'Payment Service Provider',
  [BrandType.PrimaryMerchant]: 'Primary Merchant',
  [BrandType.ThirdPartyDelivery]: 'Third Party Delivery',
  [BrandType.Wallet]: 'Wallet',
  [BrandType.Reservations]: 'Reservations',
  [BrandType.Saas]: 'SaaS',
  [BrandType.Gig]: 'Gig',
  [BrandType.AccountFunding]: 'Account Funding',
};
