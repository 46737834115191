import { TrendsQueryResponseFragment } from '../../../gql-generated';

const QUARTER_LABEL = 'quarter_label';

export const isEmptyDataset = (trends: TrendsQueryResponseFragment) => {
  let isEmpty = true;

  for (const dataset of trends.data) {
    for (const serie of dataset.data) {
      if (serie.id === QUARTER_LABEL || serie.id === trends.rollup) {
        continue;
      }
      const allZero = serie.data.every((value: string | null) => {
        const asFloat: number | null =
          value !== null ? parseFloat(value) : null;
        return asFloat === 0;
      });
      if (!allZero) {
        isEmpty = false;
      }
    }
  }
  return isEmpty;
};
