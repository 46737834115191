import { Button, Stack } from '@mui/material';
import {
  useTotpEnrollMutation,
  useTotpUnenrollMutation,
} from '../../../gql-generated';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import QRCode from 'qrcode';
import { useLayoutEffect, useState } from 'react';
import { useAuth } from '@groundwater/shared-ui';

export const SettingsPage = () => {
  const [uri, setUri] = useState<string | null>(null);
  const totpEnroll = useTotpEnrollMutation();
  const totpUnenroll = useTotpUnenrollMutation();

  const auth = useAuth();

  useLayoutEffect(() => {
    if (!uri) return;
    const canvas = document.getElementById('canvas');

    QRCode.toCanvas(canvas, uri, function (error: any) {
      if (error) console.error(error);
      console.log('success!');
    });
  }, [uri]);

  return (
    <PrivateLayout page_title="Home">
      <h1>User Settings</h1>
      <hr />
      <br />
      <h2>E-Mail 2FA</h2>
      {auth.user?.require_2fa ? 'Enrolled' : 'Not Enrolled'}
      <br />
      <br />
      <hr />
      <h2>TOTP 2FA</h2>
      {auth.user?.require_2fa && auth.user?.has_setup_totp
        ? 'Enrolled'
        : 'Not Enrolled'}
      <br />
      {auth.user?.has_setup_totp ? (
        <>
          <Button
            color="error"
            variant="contained"
            onClick={async () => {
              const r = await totpUnenroll.mutateAsync({});
              setUri(null);
              auth.meQuery.refetch();
            }}
          >
            Un-enroll from TOTP
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              const r = await totpEnroll.mutateAsync({});
              setUri(r.totp_enroll);
              auth.meQuery.refetch();
            }}
          >
            Show QR Code
          </Button>
        </>
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={async () => {
            const r = await totpEnroll.mutateAsync({});
            setUri(r.totp_enroll);
            auth.meQuery.refetch();
          }}
        >
          Enroll in TOTP
        </Button>
      )}
      <br />
      <canvas id="canvas"></canvas>
      <br />
      After setting up TOTP, scan the QR code with the Google Authenticator app,
      or your preferred TOTP app.
    </PrivateLayout>
  );
};
