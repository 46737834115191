import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Context, createContext, useEffect, useMemo, useState } from 'react';

export const useToast = () => {
  return useMemo(
    () => ({
      setToast: ({
        message,
        severity,
      }: {
        id: string;
        open: boolean;
        severity: string;
        message: string;
      }) =>
        Toastify({
          text: message,
          duration: 3000,
          style: {
            background:
              severity === 'error'
                ? 'linear-gradient(to right, #ff5f6d, #ffc371)'
                : 'linear-gradient(to right, #00b09b, #96c93d)',
          },
        }).showToast(),
    }),
    []
  );
};
