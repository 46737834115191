import { OvrResponse } from '../../../gql-generated';
import { Suspense, useDeferredValue } from 'react';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { SpreadChart } from '@groundwater/app/ui';
import { useChartResetKeys } from '../../hooks/useResetKeys';
import { Variables } from '../../../utils';

export interface OvrSpreadChartProps {
  variables: { request: Variables[REPORT_PAGES.ovr] };
  deferredVariables: { request: Variables[REPORT_PAGES.ovr] };
}

export const OvrSpreadChart: React.FC<{ ovr: OvrResponse }> = ({ ovr }) => {
  const variables = useVariables(REPORT_PAGES.ovr);
  const deferredVariables = useDeferredValue(variables);
  const resetKeys = useChartResetKeys();
  const keys = resetKeys.join(',');

  return (
    <SpreadChart
      key={`spread-${keys}`}
      group="ovr"
      dataset={ovr}
      yZoom={deferredVariables.request.y_zoom === 'true'}
    />
  );
};
