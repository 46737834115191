import { AnalysisGuideButton, ChartFrame } from '@groundwater/app/ui';
import { Alert, Grid, Stack } from '@mui/material';
import { useGetGrowthAccountingQuery } from '../../../gql-generated';
import { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { ReportLayout } from '../../layouts/ReportLayout';
import {
  CustomerGrowthAccountingChart,
  GrowthAccountingSeriesIds,
  SalesGrowthAccountingChart,
} from '../../components/GrowthAccountingChart';
import { ExportCustomerGrowthAccountingButton } from './ExportCustomerGrowthAccountingButton';
import { ExportSalesGrowthAccountingButton } from './ExportSalesGrowthAccountingButton';
import moment from 'moment';
import { GrowthAccountingQuickRatio } from '../../components/GrowthAccountingQuickRatio';
import { useChartResetKeys } from '../../hooks/useResetKeys';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Growth Accounting';

export const GrowthAccountingPage = () => {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <ReportLayout
        page_title={page_title}
        filterSubHeaderActionContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <AnalysisGuideButton fileName="growth_accounting.pdf" />
            {/* 
                <RetentionSummaryExportAllButton /> */}
          </Stack>
        }
      >
        <GrowthAccountingPageContent />
      </ReportLayout>
    </WithCompanyIdPrompt>
  );
};

export const GrowthAccountingPageContent = () => {
  const [hiddenSerieIds, setHiddenSerieIds] = useState<
    GrowthAccountingSeriesIds[]
  >([]);

  const onLegendClick = useCallback((idToToggle: GrowthAccountingSeriesIds) => {
    setHiddenSerieIds((ids) => {
      // if currently hidden
      if (ids.includes(idToToggle)) {
        // remove it from hidden IDs
        return ids.filter((id) => id !== idToToggle);
      } else {
        // add it to hidden IDs
        return [...ids, idToToggle];
      }
    });
  }, []);

  const variables = useVariables(REPORT_PAGES.growth_accounting);
  const deferredVariables = useDeferredValue(variables);

  const isLoading = variables !== deferredVariables;

  const { request } = deferredVariables;

  const response = useGetGrowthAccountingQuery({
    request,
  });

  if (!response.data) {
    throw new Error('Dataset is not present in the response payload.');
  }

  const sortedCustomerPeriods = useMemo(() => {
    if (response.data.customer_growth_accounting.__typename === 'DataError')
      return [];
    return response.data.customer_growth_accounting.periods.sort((a, b) => {
      return moment(a.date).isBefore(b.date) ? -1 : 1;
    });
  }, [response.data.customer_growth_accounting]);

  const sortedSalesPeriods = useMemo(() => {
    if (response.data.sales_growth_accounting.__typename === 'DataError')
      return [];
    return response.data.sales_growth_accounting.periods.sort((a, b) => {
      return moment(a.date).isBefore(b.date) ? -1 : 1;
    });
  }, [response.data.sales_growth_accounting]);

  const resetKeys = useChartResetKeys();

  if (response.data.customer_growth_accounting.__typename === 'DataError') {
    return isLoading ? (
      <>loading</>
    ) : (
      // over page is wrapped in grid container, need to add grid item wrapper
      <Grid item xs={12}>
        <Alert severity="info">
          {response.data.customer_growth_accounting.message}
        </Alert>
      </Grid>
    );
  }

  if (response.data.sales_growth_accounting.__typename === 'DataError') {
    return isLoading ? (
      <>loading</>
    ) : (
      // over page is wrapped in grid container, need to add grid item wrapper
      <Grid item xs={12}>
        <Alert severity="info">
          {response.data.sales_growth_accounting.message}
        </Alert>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3 }}
    >
      <Grid item xs={6}>
        <ChartFrame
          chartTitle="Customer Growth Accounting"
          isLoading={isLoading}
          actionContent={<ExportCustomerGrowthAccountingButton />}
        >
          <CustomerGrowthAccountingChart
            periods={sortedCustomerPeriods}
            hiddenSerieIds={hiddenSerieIds}
            onLegendClick={onLegendClick}
            resetKeys={resetKeys}
          />
          <GrowthAccountingQuickRatio
            periods={sortedCustomerPeriods}
            formula={'(New+Res.)/Churn'}
            hiddenSerieIds={hiddenSerieIds}
            onLegendClick={onLegendClick}
          />
        </ChartFrame>
      </Grid>
      <Grid item xs={6}>
        <ChartFrame
          chartTitle="Sales Growth Accounting"
          isLoading={isLoading}
          actionContent={<ExportSalesGrowthAccountingButton />}
        >
          <SalesGrowthAccountingChart
            periods={sortedSalesPeriods}
            hiddenSerieIds={hiddenSerieIds}
            onLegendClick={onLegendClick}
            resetKeys={resetKeys}
          />
          <GrowthAccountingQuickRatio
            periods={sortedSalesPeriods}
            formula={'(New+Res.+Exp.)/(Contr.+Churn)'}
            hiddenSerieIds={hiddenSerieIds}
            onLegendClick={onLegendClick}
          />
        </ChartFrame>
      </Grid>
    </Grid>
  );
};
