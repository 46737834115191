import { Icon, IconifyIcon } from '@iconify/react';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material';
// components
import { MenuPopover } from '.';
import { getInitials } from '../utils';
import { MeQuery, Role } from '../../gql-generated';

// ----------------------------------------------------------------------
export interface AccountMenuOptions {
  label: string;
  icon: IconifyIcon;
  linkTo: string;
  role: Role[];
  external?: boolean;
}

interface AccountPopoverProps {
  user: NonNullable<MeQuery['me']>;
  onLogout: () => Promise<void>;
  accountPopoverMenuItems: AccountMenuOptions[];
}

export function AccountPopover({
  user,
  onLogout,
  accountPopoverMenuItems,
}: AccountPopoverProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        {user ? getInitials(`${user.first_name} ${user.last_name}`) : null}
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.first_name} {user.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {accountPopoverMenuItems.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            href={option.linkTo}
            target={option.external ? '_blank' : undefined}
            component={option.external ? 'a' : RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={onLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
