import { useLocation } from 'react-router-dom';
import { REPORT_PAGES } from '../constants';

/** get the current report page */
export const useReportPage = (): REPORT_PAGES => {
  const location = useLocation();
  const page = location.pathname;
  const pages: string[] = Object.values(REPORT_PAGES);
  if (!pages.includes(page))
    throw new Error(
      'useReportPage mounted on a route not added to REPORT_PAGES'
    );
  return page as REPORT_PAGES; // we just checked that the string is in the enum's values
};
