import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import errorOutline from '@iconify/icons-ic/error-outline';
import { Icon } from '@iconify/react';
import { Stack, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  resetKeys?: unknown[];
  onError?:
    | ((
        error: Error,
        info: {
          componentStack: string;
        }
      ) => void)
    | undefined;
  onResetKeysChange?: (
    prevResetKeys: Array<unknown> | undefined,
    resetKeys: Array<unknown> | undefined
  ) => void;
  fallbackRender?: ({ error }: { error: any }) => JSX.Element;
}

export const DefaultErrorMessage = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Sorry, something went wrong.</Typography>
      <Typography variant="subtitle2">
        Please reload the page, try again later, or contact support.
      </Typography>
    </Stack>
  );
};

export const DefaultError: React.FC<{ error: unknown }> = ({ error }) => {
  let message: string = (error as any)?.message;
  if (
    (error as any).response?.data?.errors &&
    (error as any).response?.data?.errors[0]?.message
  ) {
    message = (error as any).response.data.errors[0].message;
  }
  return (
    <div style={{ textAlign: 'center', padding: 40 }}>
      <Icon icon={errorOutline} width={100} height={100} />
      <DefaultErrorMessage />
      <pre style={{ whiteSpace: 'normal' }}>{message}</pre>
    </div>
  );
};

export const DefaultErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  fallbackRender,
  onError,
  resetKeys,
  onResetKeysChange,
}) => {
  const d = ({ error }: any) => <DefaultError error={error} />;
  const [error, setError] = useState(false);
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender ?? d}
      onError={(error, info) => {
        captureExceptionToSentry(error, info);
        setError(true);
        onError && onError(error, info);
      }}
      resetKeys={resetKeys}
      onResetKeysChange={onResetKeysChange}
    >
      {children}
    </ErrorBoundary>
  );
};

export const captureExceptionToSentry = (
  error: Error,
  info?: {
    componentStack: string;
  }
) => {
  Sentry.captureException(error, {
    extra: info,
  });
};
