import queryString from 'query-string';
import { useCallback } from 'react';
import { RawVariables, useNavigateSearch } from '.';
import { NavigateOptions } from 'react-router-dom';
import {
  validateURLQuery,
  VariableKeys,
  Variables,
  VariablesUnion,
} from '../../utils';
import {
  GlobalBreakoutByOption,
  REPORT_PAGES,
  SPEND_BAND_OPTION_NAMES,
} from '../constants';
import { BreakoutBy } from '../../gql-generated';
import { isEmpty } from 'lodash-es';

/**
 * A hook that returns a callback for applying new filters. Internally
 * it applied filters by updating the URL, which is the source of truth
 */
export const useApplyFilters = <Page extends REPORT_PAGES>(page: Page) => {
  const search = useNavigateSearch();

  return useCallback(
    (_paramsToApply: Partial<Variables[Page]>, options?: NavigateOptions) => {
      // unsafe to consume the params w/ a [render phase] react hook
      // see https://github.com/remix-run/react-router/issues/9757
      // read "previous params" directly from the browser API, just prior to the update
      const previousQueryParams = queryString.parse(window.location.search, {
        arrayFormat: 'bracket',
        parseNumbers: true,
      });

      const paramsToOverride: VariablesUnion =
        _paramsToApply as any as VariablesUnion;

      // the breakout_by to apply, we will mutate this below before applying it...
      let breakout_by: GlobalBreakoutByOption = BreakoutBy.None;

      /**
       * Because the backend expects at least one geo/txn value selected
       * when breaking out by geo/txn value, and only allows explicitly setting
       * the "breakout by" to "none", we will detect if the user is
       * trying to break out by 0 geos/txn values (invalid) and we will implicitly
       * convert that to a "breakout by" selection of "none"
       */
      (() => {
        if (VariableKeys.breakout_by in paramsToOverride) {
          breakout_by = paramsToOverride.breakout_by;

          if (
            VariableKeys.geos in paramsToOverride &&
            paramsToOverride.geos !== undefined &&
            paramsToOverride.geos !== null &&
            paramsToOverride.geos.length === 0 &&
            paramsToOverride.breakout_by === BreakoutBy.Geo
          ) {
            breakout_by = BreakoutBy.None;
          } else if (
            VariableKeys.bucket in paramsToOverride &&
            isEmpty(paramsToOverride.bucket) &&
            SPEND_BAND_OPTION_NAMES.includes(paramsToOverride.breakout_by)
          ) {
            breakout_by = BreakoutBy.None;
          }
        }
      })();

      // update URL
      const rawParamsToApply: RawVariables = {
        ...previousQueryParams,
        ...paramsToOverride,
        breakout_by,
      };
      const validatedParamsToApply = validateURLQuery(page, rawParamsToApply);
      search({
        params: validatedParamsToApply,
        options,
      });
    },
    [search, page]
  );
};
