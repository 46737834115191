import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { ChartStyles, Tooltip } from './LineChart/styles';
import { brushX, select, selectAll } from 'd3';
import { BrushSelection, TxnDataMap, TxnDataRow } from './types';
import { GetTransactionValuesQuery } from '../../../gql-generated';
import { CHART_IDS } from './TransactionValuesPage';
import { assertUnreachable } from '@groundwater/shared-util';

const LineChartContainer = styled.div`
  position: relative;

  ${ChartStyles}
`;
LineChartContainer.displayName = 'LineChartContainer';

export interface ILineChartProps {
  rows: TxnDataRow[];
  selection: BrushSelection | undefined;
  id:
    | CHART_IDS.CUMULATIVE_PERCENT_OF_TRANSACTIONS
    | CHART_IDS.CUMULATIVE_PERCENT_OF_SALES;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  width: number;
  height: number;
  fill: string;
  onBrushMove: (selection: BrushSelection) => void;
  onBrushMoveEnd: (selection: BrushSelection) => void;
}

const currencyDollarsFormat = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 0,
});

const currencyCentsFormat = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const percentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const LineChart: React.FC<ILineChartProps> = ({
  id,
  selection,
  rows,
  onBrushMove,
  onBrushMoveEnd,
  margin,
  width,
  height,
}) => {
  const lineSeries: {
    x: number[];
    y: number[];
  } = useMemo(() => {
    return {
      x: [0.01, ...rows.map((row) => row.transaction_value_max)],
      y: [
        0,
        ...rows.map((row) => {
          switch (id) {
            case CHART_IDS.CUMULATIVE_PERCENT_OF_TRANSACTIONS:
              return row.cumulative_percent_transactions;
            case CHART_IDS.CUMULATIVE_PERCENT_OF_SALES:
              return row.cumulative_percent_sales;
            default:
              assertUnreachable(id);
          }
        }),
      ],
    };
  }, [id, rows]);

  const xScale = useMemo(() => {
    // clipping anything below 0.75 otherwise its too difficult to drag
    // the selection away from the left edge when there is a
    // $0.01 - $0.99 bucket due to the log scale. this also preserves space
    const xMinValue = Math.max(0.75, rows[0]!.transaction_value_min);
    const xMaxValue = d3.max(lineSeries.x) ?? 0;

    const xScale = d3
      .scaleLog()
      .domain([xMinValue, xMaxValue])
      .clamp(true)
      .range([0, width]);
    return xScale;
  }, [rows, lineSeries.x, width]);

  const rowsReverse = useMemo(() => {
    return [...rows].reverse();
  }, [rows]);

  const dollarSelectionToIndex = useCallback(
    (selection: BrushSelection): BrushSelection => {
      // find 1st row where it's "max" is >= than the user's requested min
      // TODO - tests!!
      let start_index = rows.findIndex(
        (row) => row.transaction_value_max >= selection[0]
      );

      // find first row (in reverse) where the user's requested max is >= the bin's start
      // Using this particular approach because there can be gaps.
      // The users selection may not fall within any bin at all, so we need to find the
      // first "end" bin that would encompass the user's selection. We can do this by taking
      // the array in reverse. For example if the last bin starts before the users selection ends,
      // we need to take that as the "end" bin, since the next bin (in reverse) would definitely
      // end before the user's selection ends!
      // TODO - tests!!
      let end_index =
        // Since we are finding the index in the reversed array, once we found it we need to
        // subtract it from the array's total length to get the real index in the forwards array
        rows.length -
        rowsReverse.findIndex(
          (row) => selection[1] >= row.transaction_value_min
        ) -
        1;

      if (start_index == -1) start_index = 0;
      if (end_index == -1) end_index = rows.length - 1;
      return [start_index, end_index];
    },
    [rows, rowsReverse]
  );

  const brushmove = useCallback(() => {
    // Only transition after input.
    if (!d3.event.sourceEvent) return;
    // Ignore empty selections.
    if (!d3.event.selection) return;
    // d3 gives the selection in pixels, convert to dollar amount
    const selection = d3.event.selection.map(xScale.invert, xScale);

    onBrushMove(dollarSelectionToIndex(selection));
  }, [dollarSelectionToIndex, onBrushMove, xScale]);

  const brushend = useCallback(() => {
    // Only transition after input.
    if (!d3.event.sourceEvent) return;
    // Pass empty selections.
    if (!d3.event.selection) return onBrushMoveEnd([0, 0]);

    // d3 gives the selection in pixels, convert to dollar amount
    const selection = d3.event.selection.map(xScale.invert, xScale);

    onBrushMoveEnd(dollarSelectionToIndex(selection));
  }, [dollarSelectionToIndex, onBrushMoveEnd, xScale]);

  const getFormattedDatum = (datum: { x: number[]; y: number[] }) => {
    return datum.x.map((d, ind) => [d, datum.y[ind]]);
  };

  useLayoutEffect(() => {
    const chartArea = d3.select(`#${id}-container`).select('.lineChartArea');

    chartArea.selectAll('g').remove();

    const yScale = d3.scaleLinear().range([height, 0]).domain([0, 1]);

    const logFormat10 = xScale.tickFormat(
      10,
      ((d: number | { valueOf(): number }) =>
        `$${(d < 1 ? currencyCentsFormat : currencyDollarsFormat).format(
          d as number
        )}`) as unknown as string
    );

    // Append background
    chartArea
      .append('g')
      .attr('class', 'chart-bg')
      .append('g')
      .attr('class', 'chart-background-region')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height);

    chartArea
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(-0,${height})`)
      .call(d3.axisBottom(xScale).tickSize(10).tickFormat(logFormat10));

    chartArea.append('g').attr('class', 'grid');

    chartArea
      .append('g')
      .attr('class', 'y-axis')
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(-width)
          .tickFormat((val) => percentFormat.format(val as number))
      );

    // Generate line
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const txnLine = d3
      .line()
      .x((d) => xScale(d[0]))
      .y((d) => yScale(d[1]))
      .curve(d3.curveMonotoneX);

    // Draw the line
    chartArea
      .append('path')
      .datum(getFormattedDatum(lineSeries))
      .attr('fill', 'none')
      .attr('stroke', '#3366cc')
      .attr('stroke-width', 2)
      .attr('class', 'line')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .attr('d', txnLine);

    // Add brushing
    const brush = d3
      .brushX() // Add the brush feature using the d3.brush function
      .extent([
        [0, 0],
        [width, height],
      ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
      .on('brush', brushmove)
      .on('end', brushend);
    // Add the brushing
    chartArea
      .append('g')
      .attr('class', 'd3-brush')
      .attr('data-id', id)
      .call(brush);
  }, [
    brushend,
    brushmove,
    id,
    height,
    margin.left,
    margin.right,
    width,
    xScale,
    lineSeries,
  ]);

  const percentSelected = useMemo(() => {
    if (!selection) return 0;

    const end = rows[selection[1]]!;

    // TODO - tests!!
    if (selection[0] === 0) return end[id];

    const start = rows[selection[0] - 1]!;
    return end[id] - start[id];
  }, [id, rows, selection]);

  useLayoutEffect(() => {
    const chartContainer = d3.select(`#${id}-container`);
    if (selection) {
      chartContainer
        .select('.lineChart-tooltip')
        .text(
          `${percentFormat.format(percentSelected)} of ${
            id === CHART_IDS.CUMULATIVE_PERCENT_OF_TRANSACTIONS
              ? 'transactions'
              : 'sales'
          }`
        )
        .style('opacity', 1)
        .style(
          'left',
          (xScale(selection[0])! + xScale(selection[1])!) / 2 + 'px'
        )
        .style('top', '32px');
    } else {
      chartContainer.select('.lineChart-tooltip').style('opacity', 0);
    }
  }, [id, percentSelected, selection, xScale]);

  /**
   * TODO - this is extremely brittle, it relies on React running the above "draw" effect
   * which blows away the DOM and resets it, then this effect runs after and draws the brush
   * if these effects are in reverse order, the brushing gets blown away
   */
  useLayoutEffect(() => {
    if (!selection) return;
    const [startIndex, endIndex] = selection;

    const startRow = rows[startIndex];
    const endRow = rows[endIndex];

    if (!startRow || !endRow) {
      throw new Error(
        'start/end row doesnt exist corresponding to selected indexes'
      );
    }

    d3.select(`#${id}-container`)
      .select('.d3-brush')
      .each(function () {
        select(this).call((brushX() as any).move, [
          // convert domain value (dollar amount) back to pixels
          xScale(startRow.transaction_value_min),
          xScale(endRow.transaction_value_max),
        ]);
      });

    /**
     *  We hide the native D3 brush and show a custom one that snaps to the nearest increment
     * (avoids issues with D3 wanting to control the DOM element, We just render our own controlled Dom element and hide theirs)
     */
    const chartArea = d3.select(`#${id}-container`).select('.lineChartArea');
    chartArea.select('.react-brush').remove();
    chartArea
      .append('g')
      .attr('class', `react-brush`)
      .append('rect')
      .attr('x', xScale(startRow.transaction_value_min)!)
      .attr('y', 0)
      .attr(
        'width',
        xScale(endRow.transaction_value_max)! -
          xScale(startRow.transaction_value_min)!
      )
      .attr('height', height)
      .attr('fill', '#777')
      .attr('opacity', '0.3');
  }, [height, id, rows, selection, xScale]);

  return (
    <LineChartContainer id={`${id}-container`}>
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`}
        preserveAspectRatio="xMinYMin meet"
        style={{
          width: '100%',
          height: 'min-content',
        }}
      >
        <text className="chartLabel" x={width / 2} y={height + margin.bottom}>
          Transaction Amount
        </text>
        <g
          className="lineChartArea"
          transform={`translate(${margin.left},${margin.top})`}
        />
      </svg>
      <Tooltip data-id={id} className="lineChart-tooltip" />
    </LineChartContainer>
  );
};
