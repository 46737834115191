import { select, Selection } from 'd3-selection';
import { createWhiskers, QTDChartConfig } from './qtdChartUtils';

const removeInvisibleLegendItems = (
  chartArea: Selection<any, unknown, null, undefined>
) => {
  chartArea
    .select('.apexcharts-legend-series[seriesname="consensusxlow"]')
    .remove();
  chartArea
    .select('.apexcharts-legend-series[seriesname="consensusxhigh"]')
    .remove();
};

export const drawConsensusErrorAnnotation = (
  chart: QTDChartConfig,
  drawNewWhiskers: boolean,
  lastProjectedDayIndex: number
) => {
  const chartArea = select(chart.el);

  // Remove consensus_low and consensus_high legend items as these series only used to produce whiskers and are not appearing on the chart
  removeInvisibleLegendItems(chartArea);

  if (
    !chartArea.selectAll(`${chart.opts.chart.id}-ibeam-consensus-error`).empty()
  )
    // Remove old instances of whiskers when chart re-renders
    chartArea
      .selectAll(`${chart.opts.chart.id}-ibeam-consensus-error`)
      .remove();

  // Create consensus range indicator only for scaled models
  if (drawNewWhiskers)
    createWhiskers(chart, {
      lastProjectedDayIndex,
      lowerBoundSerieName:
        chart.opts.chart.id === 'sales'
          ? 'consensus_low'
          : 'consensus_growth_low',
      upperBoundSerieName:
        chart.opts.chart.id === 'sales'
          ? 'consensus_high'
          : 'consensus_growth_high',
      strokeColor: '#5f7293',
    });
};
