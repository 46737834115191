import { Menu } from '@groundwater/shared-ui';
import {
  BrandTypes,
  BreakoutBy,
  ChannelsV2,
  useFilterQuery,
} from '../../../../gql-generated';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Alert, Button, ClickAwayListener } from '@mui/material';
import { isEqual } from 'lodash-es';
import { ChannelFilter } from './ChannelFilter';
import { BrandTypeFilter } from './BrandTypeFilter';
import { VariableKeys, VariablesUnion } from '../../../../utils';
import { breakout_by_conflicts_filter } from '../../../../utils/reportPageHelpers/breakout_by_conflicts_filter';
import { useFilterContext } from '../../FilterSubHeader/hooks/controller';
import {
  BRAND_TYPE_LABELS,
  BREAKOUT_BY_LABELS,
  CHANNEL_LABELS,
} from '../../../constants';
import { useReportPage } from '../../../hooks/useReportPage';

const useConflicts = (draftParams: VariablesUnion) => {
  const breakoutConflicts: boolean = breakout_by_conflicts_filter(draftParams);
  return { breakoutConflicts };
};

export const FilterByControl: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (
    !(VariableKeys.filter_channels in draftParams) &&
    !(VariableKeys.filter_brand_types in draftParams)
  ) {
    throw new Error();
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [bounds, setBounds] = useState<number[]>([0, 0]);
  const ref = useRef<HTMLDivElement | null>(null);

  const ids = [];
  if (VariableKeys.company_id in draftParams) {
    ids.push(draftParams.company_id);
  }
  if (VariableKeys.compare_by_company_ids in draftParams) {
    ids.push(...draftParams.compare_by_company_ids);
  }
  const companyOrBrands = useFilterQuery({ ids });

  if (!companyOrBrands.data) {
    throw new Error();
  }

  const channels = new Set();
  for (const business of companyOrBrands.data.get_brands_or_companies) {
    for (const channel of business.channels) {
      channels.add(channel);
    }
  }

  const hasMixedChannels = channels.size > 1;

  const hasChannelControl =
    VariableKeys.filter_channels in draftParams && hasMixedChannels;

  const hasBrandTypeControl =
    VariableKeys.filter_brand_types in draftParams &&
    // no point filtering if there are not companies with "mixed" brand types (nothing to filter!)
    companyOrBrands.data.get_brands_or_companies.some(
      (business) =>
        business?.__typename === 'Company' && business.has_mixed_brand_types
    );

  const { breakoutConflicts } = useConflicts(draftParams);

  /**
   * The lint warning is expected.
   *
   * Trigger PopperJS update when position changes, see
   * https://popper.js.org/docs/v2/modifiers/event-listeners/#when-the-reference-element-moves-or-changes-size
   *
   * The state updater function prevents updating if the bounds have not changed. This will measure the offsetLeft
   * and offsetTop and update the `bounds` which gets passed to the <Popper />, so that when it changes it forces
   * the update in PopperJS
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setBounds((bounds) => {
      if (!ref.current) return bounds;
      const updated = [ref.current.offsetLeft, ref.current.offsetTop];
      if (isEqual(bounds, updated)) return bounds;
      return updated;
    });
  });

  const filterTextParts: string[] = [];

  if (
    hasChannelControl &&
    draftParams.filter_channels.length < Object.values(ChannelsV2).length
  ) {
    filterTextParts.push(
      draftParams.filter_channels
        .map((channel) => CHANNEL_LABELS[channel])
        .join(', ')
    );
  }

  if (
    hasBrandTypeControl &&
    VariableKeys.filter_brand_types in draftParams && // redundant but needed for TS narrowing
    draftParams.filter_brand_types !== null &&
    draftParams.filter_brand_types.length < Object.values(BrandTypes).length
  ) {
    filterTextParts.push(
      draftParams.filter_brand_types
        .map((brandType) => BRAND_TYPE_LABELS[brandType])
        .join(', ')
    );
  }

  const hasError = hasChannelControl && breakoutConflicts;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <span ref={ref}>
        <Button
          disabled={!hasBrandTypeControl && !hasChannelControl}
          ref={setAnchorEl}
          data-testid={`filterby-button`}
          aria-controls={open ? `filterby-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          size="medium"
          color={hasError ? 'error' : 'primary'}
          sx={{
            ...(hasError ? { backgroundColor: 'error.dark' } : {}),
          }}
          aria-invalid={hasError}
          onClick={toggleOpen}
        >
          {filterTextParts.length > 0
            ? `Filter By: ${filterTextParts.join(', ')}`
            : `Filter By`}
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open && (hasBrandTypeControl || hasChannelControl)}
          handleClose={handleClose}
          forceUpdate={bounds}
        >
          <Box width="400px" data-testid="filter-by">
            {hasChannelControl && (
              <ChannelFilter
                selected={draftParams.filter_channels}
                // TODO - rename onToggle
                onClick={(channelToToggle: ChannelsV2) => {
                  handleDraftChange((previousParams) => {
                    if (!(VariableKeys.filter_channels in previousParams)) {
                      throw new Error();
                    }
                    if (
                      previousParams.filter_channels.includes(channelToToggle)
                    ) {
                      // remove it
                      return {
                        ...previousParams,
                        filter_channels: previousParams.filter_channels.filter(
                          (value) => value !== channelToToggle
                        ),
                      };
                    } else {
                      // add it
                      return {
                        ...previousParams,
                        filter_channels: [
                          ...previousParams.filter_channels,
                          channelToToggle,
                        ],
                      };
                    }
                  });
                }}
              />
            )}
            {hasBrandTypeControl &&
              VariableKeys.filter_brand_types in draftParams &&
              draftParams.filter_brand_types !== null && (
                <BrandTypeFilter
                  selected={draftParams.filter_brand_types}
                  // TODO - rename onToggle
                  onClick={(brandTypeToToggle: BrandTypes) => {
                    handleDraftChange((previousParams) => {
                      if (
                        !(VariableKeys.filter_brand_types in previousParams) ||
                        previousParams.filter_brand_types === null
                      ) {
                        throw new Error();
                      }
                      if (
                        previousParams.filter_brand_types.includes(
                          brandTypeToToggle
                        )
                      ) {
                        // remove it
                        return {
                          ...previousParams,
                          filter_brand_types:
                            previousParams.filter_brand_types.filter(
                              (value) => value !== brandTypeToToggle
                            ),
                        };
                      } else {
                        // add it
                        return {
                          ...previousParams,
                          filter_brand_types: [
                            ...previousParams.filter_brand_types,
                            brandTypeToToggle,
                          ],
                        };
                      }
                    });
                  }}
                />
              )}
            {hasChannelControl && breakoutConflicts ? (
              <Box sx={{ py: 2, mx: 2 }}>
                <Alert severity="error">
                  'Filter By' channel conflicts with 'Break Out By' selection{' '}
                  {BREAKOUT_BY_LABELS[draftParams.breakout_by]}. Adjust 'Break
                  Out By' to supported option, or reset selection.
                  <br />
                  <Button
                    onClick={() =>
                      handleDraftChange((prevParams) => ({
                        ...prevParams,
                        breakout_by: BreakoutBy.None,
                      }))
                    }
                    variant="contained"
                    color="warning"
                    size="small"
                  >
                    Reset 'Break Out By' to default
                  </Button>
                </Alert>
              </Box>
            ) : null}
          </Box>
        </Menu>
      </span>
    </ClickAwayListener>
  );
};
