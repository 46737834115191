import { OvrResponse } from '../../../gql-generated';
import { useDeferredValue } from 'react';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { ReportedRevenueChart } from '@groundwater/app/ui';
import { Skeleton } from '@mui/material';
import { useChartResetKeys } from '../../hooks/useResetKeys';

export const OvrReportedRevenueChartLoadingSkeleton = () => (
  <Skeleton
    variant="rectangular"
    data-testid="ovr-reported-revenue-chart-skeleton"
    width="100%"
    height={370}
  />
);

export const OvrReportedRevenueChart: React.FC<{
  ovr: OvrResponse;
}> = ({ ovr }) => {
  const variables = useVariables(REPORT_PAGES.ovr);
  const deferredVariables = useDeferredValue(variables);
  const resetKeys = useChartResetKeys();
  const keys = resetKeys.join(',');

  return (
    <ReportedRevenueChart
      key={`reported-revenue-${keys}`}
      group="ovr"
      dataset={ovr}
      yZoom={deferredVariables.request.y_zoom === 'true'}
    />
  );
};
