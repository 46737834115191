import queryString from 'query-string';
import { useCallback } from 'react';
import { useQuery } from './useQuery';
import {
  useNavigate,
  useLocation,
  NavigateOptions,
  createSearchParams,
} from 'react-router-dom';

/** The raw variables, which could be "anything" (type is specific to the options passed to queryString.parse) */
export type RawVariables = queryString.ParsedQuery<string | number>;

// Hook to stringify filter selections and set the URL query
export const useNavigateSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const callback = useCallback(
    ({
      pathname,
      params,
      options,
    }: {
      pathname?: string;
      params: Readonly<RawVariables>;
      options?: NavigateOptions;
    }) => {
      const newQuery = queryString.stringify(
        {
          ...query,
          ...params,
        },
        { arrayFormat: 'bracket' }
      );

      return navigate(
        {
          pathname: pathname ?? location.pathname,
          search: `?${createSearchParams(newQuery)}`,
        },
        options
      );
    },
    [location.pathname, navigate, query]
  );

  return callback;
};
