import { Variables } from '../../../utils/reportPageConfigs';
import moment from 'moment';
import { Suspense, useDeferredValue, useMemo, useState } from 'react';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { ReportLayout } from '../../layouts/ReportLayout';
import { useBrowseBusinessesQuery } from '../../../gql-generated';
import {
  AnalysisGuideButton,
  VirtualTableWithAutoSizer,
} from '@groundwater/app/ui';
import { Node } from './types';
import { makeColumns } from './columns';
import { Column } from '../../react-table-column-types';
import { Alert, Box, CircularProgress, Skeleton, Stack } from '@mui/material';
import { BrowseBusinessesExportAllButton } from './BrowseBusinessesExportAllButton';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Businesses';

export function BrowseBusinessesPage() {
  const variables = useVariables(REPORT_PAGES.browse_businesses);
  const deferredVariables = useDeferredValue(variables);

  const isLoading = variables !== deferredVariables;

  const response = useBrowseBusinessesQuery(deferredVariables, {
    cacheTime: 3600 * 12,
    staleTime: Infinity,
  });
  if (!response.data) throw Error();
  const { nodes, data_as_of_month } = response.data.browse_businesses;

  const columns: Column<Node>[] = useMemo(() => {
    return makeColumns(deferredVariables.request.business_type);
  }, [deferredVariables.request.business_type]);

  return (
    <ReportLayout
      page_title={page_title}
      filterSubHeaderActionContent={
        <Stack direction="row" alignItems="center" spacing={2}>
          <AnalysisGuideButton fileName="browse_businesses.pdf" />
          <BrowseBusinessesExportAllButton />
        </Stack>
      }
    >
      {isLoading && <CircularProgress size={15} sx={{ mx: 1 }} />}
      <Suspense
        fallback={<Skeleton variant="rectangular" width="100%" height="70vh" />}
      >
        <Alert severity="info">
          Data based on {moment(data_as_of_month).format('MMMM YYYY')}
        </Alert>

        <VirtualTableWithAutoSizer
          key={deferredVariables.request.business_type}
          columns={columns}
          data={nodes}
          fixedColumnCount={2}
        />
      </Suspense>
    </ReportLayout>
  );
}
