import { Maybe, Quarter } from '@groundwater/shared-ui';
import moment from 'moment';
import { format } from 'd3-format';
import { select } from 'd3-selection';
import { initializeWhiskers } from '../../utils/whiskers';

export type QtdChartType = 'sales' | 'growth';
export type QTDChartGlobalConfig = {
  globals: {
    seriesXvalues: number[][];
    seriesYvalues: number[][];
    initialSeries: any[];
    seriesNames: string[];
  };
  config: { tooltip: { y: { formatter: (arg0: any) => string } } };
};
export type QTDChartConfig = {
  getChartArea: () => HTMLElement;
  el: HTMLElement;
  w: QTDChartGlobalConfig;
  opts: {
    chart: {
      id: QtdChartType;
    };
  };
};

type QtdRevenueEstimateAnnotationSeries =
  | 'revenue_estimate_low'
  | 'revenue_estimate_high';

type QtdConsensusAnnotationSeries = 'consensus_low' | 'consensus_high';

type QtdRevenueEstimateGrowthAnnotationSeries =
  | 'revenue_estimate_growth_low'
  | 'revenue_estimate_growth_high';

type QtdConsensusGrowthAnnotationSeries =
  | 'consensus_growth_low'
  | 'consensus_growth_high';

type QtdSalesChartSerie =
  | QtdRevenueEstimateAnnotationSeries
  | QtdConsensusAnnotationSeries
  | 'observed'
  | 'projected'
  | 'comparing'
  | 'consensus';

type QTDGrowthChartSerie =
  | QtdRevenueEstimateGrowthAnnotationSeries
  | QtdConsensusGrowthAnnotationSeries
  | 'observed_growth'
  | 'projected_growth'
  | 'consensus';

export type QtdChartSerie = QtdSalesChartSerie | QTDGrowthChartSerie;

export const getChartSeries = (
  chartType: QtdChartType,
  hasProjection: boolean,
  showConsensus: boolean,
  errorBarOnProjected: boolean,
  errorBarOnConsensus: boolean
): QtdChartSerie[] => {
  const projectedAnnotationSeries: QtdRevenueEstimateAnnotationSeries[] =
    errorBarOnProjected
      ? ['revenue_estimate_low', 'revenue_estimate_high']
      : [];
  const consensusAnnotationSeries: QtdConsensusAnnotationSeries[] =
    errorBarOnConsensus ? ['consensus_low', 'consensus_high'] : [];

  const projectedGrowthAnnotationSeries: QtdRevenueEstimateGrowthAnnotationSeries[] =
    errorBarOnProjected
      ? ['revenue_estimate_growth_low', 'revenue_estimate_growth_high']
      : [];

  const consensusGrowthAnnotationSeries: QtdConsensusGrowthAnnotationSeries[] =
    errorBarOnConsensus
      ? ['consensus_growth_low', 'consensus_growth_high']
      : [];

  switch (chartType) {
    case 'sales':
      if (hasProjection && showConsensus)
        return [
          'observed',
          'projected',
          'comparing',
          'consensus',
          ...projectedAnnotationSeries,
          ...consensusAnnotationSeries,
        ];
      else if (hasProjection)
        return [
          'observed',
          'projected',
          'comparing',
          ...projectedAnnotationSeries,
        ];
      else if (showConsensus)
        return [
          'observed',
          'comparing',
          'consensus',
          ...consensusAnnotationSeries,
        ];
      else return ['observed', 'comparing'];

    case 'growth':
      if (hasProjection && showConsensus)
        return [
          'observed_growth',
          'projected_growth',
          'consensus',
          ...projectedGrowthAnnotationSeries,
          ...consensusGrowthAnnotationSeries,
        ];
      else if (hasProjection)
        return [
          'observed_growth',
          'projected_growth',
          ...projectedGrowthAnnotationSeries,
        ];
      else if (showConsensus)
        return [
          'observed_growth',
          'consensus',
          ...consensusGrowthAnnotationSeries,
        ];
      else return ['observed_growth'];
  }
};

export const formatConsensus = (
  yAxisFormat: 'percent' | 'dollar',
  consensus: number,
  confidenceInterval?: [number, number]
): string => {
  const consensusFormatter =
    yAxisFormat === 'percent' ? format('>-0.1~%') : format('>-$,.2~s');

  const formattedValue = consensusFormatter(consensus).replace(/G/, 'B');

  let formattedCI;
  if (confidenceInterval && confidenceInterval[0] && confidenceInterval[1])
    formattedCI = `(Low: ${consensusFormatter(confidenceInterval[0]).replace(
      /G/,
      'B'
    )} - High: ${consensusFormatter(confidenceInterval[1]).replace(/G/, 'B')})`;

  return (
    `Consensus: ${formattedValue}` + (formattedCI ? ` ${formattedCI}` : '')
  );
};

const formattedRange = (start_date: string, end_date: string) =>
  `(${moment(start_date).format('MMM D')} - ${moment(end_date).format(
    'MMM D, YYYY'
  )})`;

export const generateSeriesLabels = ({
  comparison_quarter,
  analysis_quarter,
  projected_start_date,
  hasProjection,
  chartType,
  yAxisFormat,
  consensus,
}: {
  comparison_quarter: Quarter;
  analysis_quarter: Quarter;
  projected_start_date: string;
  hasProjection: boolean;
  chartType: QtdChartType;
  yAxisFormat: 'percent' | 'dollar';
  consensus: {
    consensus_average: number;
    consensus_low: number;
    consensus_high: number;
    consensus_growth_average: number;
  };
}) => ({
  comparing: `Comparing: FY${comparison_quarter.fiscal_year} Q${
    comparison_quarter.fiscal_quarter
  } ${formattedRange(
    comparison_quarter.start_date,
    comparison_quarter.end_date
  )}`,
  projected: `Projected: FY${analysis_quarter.fiscal_year} Q${
    analysis_quarter.fiscal_quarter
  }  ${formattedRange(projected_start_date, analysis_quarter.end_date)}`,
  observed:
    (hasProjection ? 'To date: ' : 'Analyzing: ') +
    `FY${analysis_quarter.fiscal_year} Q${
      analysis_quarter.fiscal_quarter
    } ${formattedRange(
      analysis_quarter.start_date,
      analysis_quarter.end_date
    )}`,
  observed_growth: 'Growth',
  projected_growth: 'Projected growth',
  consensus:
    chartType === 'sales'
      ? formatConsensus(yAxisFormat, consensus.consensus_average, [
          consensus.consensus_low,
          consensus.consensus_high,
        ])
      : formatConsensus(yAxisFormat, consensus.consensus_growth_average),
  // No need to generate legend label for the annotation series. We only use them to generate consensus/prediction whiskers
  revenue_estimate_low: '',
  revenue_estimate_high: '',
  consensus_low: '',
  consensus_high: '',
  consensus_growth_low: '',
  consensus_growth_high: '',
  revenue_estimate_growth_low: '',
  revenue_estimate_growth_high: '',
});

export const getAllSeries = ({
  colors,
  chartType,
  xSeries,
  last_observed_day,
  lastProjectedDay,
  analysis_period,
  comparison_period,
  growth,
  revenue: {
    revenue_estimate_low,
    revenue_estimate_high,
    revenue_estimate_growth_low,
    revenue_estimate_growth_high,
  },
  consensus: {
    consensus_low,
    consensus_high,
    consensus_average,
    consensus_growth_average,
    consensus_growth_low,
    consensus_growth_high,
  },
}: {
  colors: string[];
  chartType: QtdChartType;
  xSeries: number[];
  last_observed_day: number;
  lastProjectedDay: number;
  analysis_period: Maybe<string>[];
  comparison_period: Maybe<string>[];
  growth: Maybe<string>[];
  revenue: {
    revenue_estimate_low: number;
    revenue_estimate_high: number;
    revenue_estimate_growth_low: number;
    revenue_estimate_growth_high: number;
  };
  consensus: {
    consensus_low: number;
    consensus_high: number;
    consensus_average: number;
    consensus_growth_average: number;
    consensus_growth_low: number;
    consensus_growth_high: number;
  };
}) => ({
  projected: {
    name: 'projected',
    color: colors[6],
    data: xSeries.map((day, index) => {
      if (day < last_observed_day) return null;
      else if (day > lastProjectedDay) return null;
      else return analysis_period[index];
    }),
  },
  observed_growth: {
    name: 'observed_growth',
    color: colors[3],
    data: xSeries.map((day, index) => {
      if (day > last_observed_day) return null;
      else return growth[index];
    }),
  },
  projected_growth: {
    name: 'projected_growth',
    color: colors[6],
    data: xSeries.map((day, index) => {
      if (day < last_observed_day) return null;
      else if (day > lastProjectedDay) return null;
      else return growth[index];
    }),
  },
  observed: {
    name: 'observed',
    color: colors[3],
    data: xSeries.map((day, index) => {
      if (day > last_observed_day) return null;
      else return analysis_period[index];
    }),
  },
  comparing: {
    name: 'comparing',
    color: colors[4],
    data: xSeries.map((day, index) => {
      if (day > comparison_period.length) return null;
      else return comparison_period[index];
    }),
  },
  consensus: {
    name: 'consensus',
    color: colors[0],
    data: xSeries.map((day, index) => {
      if (index !== xSeries.length - 1) return null;
      else
        return chartType === 'sales'
          ? consensus_average
          : consensus_growth_average;
    }),
  },
  // Generate revenue estimate series in order to produce projected range annotation
  revenue_estimate_low: {
    name: `revenue_estimate_low`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      // X series represent days. To compensate for array index starting at 0
      // we need to subtract 1 from lastProjectedDay which will give us a matching x-serie value.
      if (ind === lastProjectedDay - 1) {
        return revenue_estimate_low?.toString();
      }
      return null;
    }),
  },
  revenue_estimate_high: {
    name: `revenue_estimate_high`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      if (ind === lastProjectedDay - 1) {
        return revenue_estimate_high?.toString();
      }
      return null;
    }),
  },
  revenue_estimate_growth_low: {
    name: `revenue_estimate_growth_low`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      // X series represent days. To compensate for array index starting at 0
      // we need to subtract 1 from lastProjectedDay which will give us a matching x-serie value.
      if (ind === lastProjectedDay - 1) {
        return revenue_estimate_growth_low?.toString();
      }
      return null;
    }),
  },
  revenue_estimate_growth_high: {
    name: `revenue_estimate_growth_high`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      if (ind === lastProjectedDay - 1) {
        return revenue_estimate_growth_high?.toString();
      }
      return null;
    }),
  },
  // Generate consensus estimate series in order to produce consensus range annotation
  consensus_low: {
    name: `consensus_low`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      // X series represent days. To compensate for array index starting at 0
      // we need to subtract 1 from lastProjectedDay which will give us a matching x-serie value.
      if (ind === lastProjectedDay - 1) {
        return consensus_low?.toString();
      }
      return null;
    }),
  },
  consensus_high: {
    name: `consensus_high`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      // X series represent days. To compensate for array index starting at 0
      // we need to subtract 1 from lastProjectedDay which will give us a matching x-serie value.
      if (ind === lastProjectedDay - 1) {
        return consensus_high?.toString();
      }
      return null;
    }),
  },
  consensus_growth_low: {
    name: `consensus_growth_low`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      // X series represent days. To compensate for array index starting at 0
      // we need to subtract 1 from lastProjectedDay which will give us a matching x-serie value.
      if (ind === lastProjectedDay - 1) {
        return consensus_growth_low?.toString();
      }
      return null;
    }),
  },
  consensus_growth_high: {
    name: `consensus_growth_high`,
    color: 'transparent',
    data: xSeries.map((i, ind) => {
      // X series represent days. To compensate for array index starting at 0
      // we need to subtract 1 from lastProjectedDay which will give us a matching x-serie value.
      if (ind === lastProjectedDay - 1) {
        return consensus_growth_high?.toString();
      }
      return null;
    }),
  },
});

export const createWhiskers = (
  chart: QTDChartConfig,
  {
    lowerBoundSerieName,
    upperBoundSerieName,
    lastProjectedDayIndex,
    strokeColor,
  }: {
    lowerBoundSerieName: QtdChartSerie;
    upperBoundSerieName: QtdChartSerie;
    lastProjectedDayIndex: number;
    strokeColor?: string;
  }
) => {
  const chartArea = select(chart.getChartArea());

  // Get the x serie. By default Apex generates one x-serie per each y-serie. They are identical, hence we can get the first one.
  const x = chart.w.globals.seriesXvalues[0]?.[lastProjectedDayIndex];

  const revenueEstimateLowIndex = chart.w.globals.initialSeries.findIndex(
    (i) => i.name === lowerBoundSerieName
  );
  const revenueEstimateHighIndex = chart.w.globals.initialSeries.findIndex(
    (i) => i.name === upperBoundSerieName
  );

  if (x === undefined) {
    throw new Error('x serie must be defined');
  }

  const lower =
    chart.w.globals.seriesYvalues[revenueEstimateLowIndex]?.[
      lastProjectedDayIndex
    ];

  const upper =
    chart.w.globals.seriesYvalues[revenueEstimateHighIndex]?.[
      lastProjectedDayIndex
    ];

  if (lower === undefined || upper === undefined) {
    throw new Error('upper and lower bounds must be defined');
  }

  initializeWhiskers({
    targetNode: chartArea,
    className: `${chart.opts.chart.id}-ibeam-projected-error`,
    x,
    lower,
    upper,
    strokeColor,
  });
};
