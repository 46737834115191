import { currency } from '../../pages/BrowseBusinessesPage/formatters';
import { DefaultCell } from './DefaultCell';
import { numberColumn } from './NumberCell';

export const currencyColumn = () => ({
  ...numberColumn(),
  Cell: CurrencyCell,
});

export const CurrencyCell = ({ value }: { value: number }) => {
  return <DefaultCell>{currency.format(value)}</DefaultCell>;
};
