import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FilterButtonMenuOption,
  MHidden,
  FilterButtonMenu,
  FilterMenuList,
  useForm,
  FilterMenuOption,
  FilterMenuOptionProps,
} from '@groundwater/shared-ui';
import {
  GlobalScalingModel,
  REPORT_PAGES,
  SCALING_MODEL_LABELS,
} from '../../../constants';
import { object, reach } from 'yup';
import { commonSchema, VariableKeys, variablesSchema } from '../../../../utils';
import { useReportPage } from '../../../hooks/useReportPage';
import { entriesFromRecord } from '../../../../utils/record-map';
import { useVariables } from '../../../hooks/useVariables';
import { format } from 'd3-format';
import { useScalingModelR2Value } from './hooks/useScalingModelR2Value';
import {
  OvrRequest,
  OvrScalingModel,
  QtdRequest,
  QtdScalingModel,
} from '../../../../gql-generated';
import {
  CustomModelValueInput,
  scaling_model_custom_percent,
} from './CustomModelValueInput';
import { Box, styled } from '@mui/system';
import { Button, Skeleton } from '@mui/material';
import { isNil } from 'lodash-es';
import { useFilterContext } from '../../FilterSubHeader/hooks/controller';

const DEFAULT_SCALING_MODEL_CUSTOM_PERCENT_VALUE = 1;

const Form = styled('form')(() => ({
  display: 'inline-flex',
}));

export const ScalingModelFilter: React.FC<{}> = () => {
  const page = useReportPage();
  const { handleDraftChange, draftParams } = useFilterContext(page);

  if (!(VariableKeys.scaling_model in draftParams)) {
    throw new Error();
  }

  const selected = draftParams[VariableKeys.scaling_model];

  const onChange = useCallback(
    (selectedValues: {
      scaling_model: GlobalScalingModel;
      scaling_model_custom_percent?: number;
    }) => {
      handleDraftChange((prevParams) => ({
        ...prevParams,
        ...selectedValues,
      }));
    },
    [handleDraftChange]
  );

  const [selectedModel, setSelectedModel] = useState(selected);

  // Validate the supported pages in order to narrow down the request variables
  // passed to useScalingModelR2Value hook
  if (page !== REPORT_PAGES.qtd && page !== REPORT_PAGES.ovr) {
    throw new Error('Page does not support scaling model filter');
  }

  const variables = useVariables(page);

  const { r2Values, bestFit, isLoading } = useScalingModelR2Value(
    page,
    variables.request as Readonly<OvrRequest | QtdRequest>
  );

  const { register, formProps, getFieldState, getValues, watch } = useForm({
    // Custom override of form submit implementation
    onSubmit: () => null,
    defaultValues: {
      // Use scaled percent value in the filter textarea
      scaling_model_custom_percent:
        VariableKeys.scaling_model_custom_percent in variables.request
          ? variables.request.scaling_model_custom_percent * 100
          : DEFAULT_SCALING_MODEL_CUSTOM_PERCENT_VALUE,
    },
    validationSchema: object({
      [VariableKeys.scaling_model_custom_percent]:
        commonSchema[VariableKeys.scaling_model_custom_percent],
    }),
  });

  const watchCustomPercent = watch(scaling_model_custom_percent);

  const handleOnChange = (scaling_model: GlobalScalingModel) => {
    setSelectedModel(scaling_model);
    onChange({ scaling_model });
  };

  const menuOptionContentSection =
    selectedModel === QtdScalingModel.Custom ? (
      <CustomModelValueInput register={register} />
    ) : null;

  const renderCustomModelOption = (
    props: FilterMenuOptionProps<GlobalScalingModel>
  ) => {
    return (
      <FilterMenuOption
        {...props}
        // In the case of Custom model we do not want to trigger the default event which causes the Popper to close,
        // hence there is an override to just update the selected model value.
        onClick={() => setSelectedModel(QtdScalingModel.Custom)}
      />
    );
  };

  const getR2Value = (value: GlobalScalingModel) => {
    // No need to render R² for the best_fit/custom models
    if (
      value === QtdScalingModel.BestFit ||
      value === QtdScalingModel.Custom ||
      value === OvrScalingModel.BestFit
    )
      return null;

    // Render loading skeleton when the R² values are being fetched
    if (isLoading)
      return {
        secondaryValue: (
          <Skeleton
            animation="wave"
            variant="text"
            width={50}
            data-testid="scaling-model-loading-skeleton"
          />
        ),
      };

    // The R² value does not exist or null for the current model
    if (isNil(r2Values) || isNil(r2Values[value])) {
      return null;
    }

    return {
      secondaryValue: `R²=${format(',.2')(Number(r2Values[value]))}`,
    };
  };
  const menuOptions: FilterButtonMenuOption<GlobalScalingModel>[] =
    entriesFromRecord(SCALING_MODEL_LABELS)
      .filter(([value, label]) =>
        reach(variablesSchema[page], VariableKeys.scaling_model).isValidSync(
          value
        )
      )
      .map(([value, label]) => {
        return {
          label,
          value,
          ...getR2Value(value),
          ...(value === QtdScalingModel.Custom && {
            component: renderCustomModelOption,
          }),
        };
      });

  const getFilterLabel = () => {
    if (selectedModel === QtdScalingModel.BestFit && bestFit) {
      return `Model: Best Fit (${SCALING_MODEL_LABELS[bestFit]})`;
    }
    return `Model: ${SCALING_MODEL_LABELS[selectedModel]}`;
  };

  const pageHasCustomScalingModel = useMemo(
    () => VariableKeys.scaling_model_custom_percent in draftParams,
    [draftParams]
  );

  useEffect(() => {
    if (!pageHasCustomScalingModel) {
      return;
    }
    // Custom form submission event is needed in order to handle the Popper dismissal upon
    // applying the scaling_model_custom_percent value
    onChange({
      scaling_model_custom_percent:
        // Pass unscaled value to the callback function
        getValues('scaling_model_custom_percent') / 100,
      scaling_model: selectedModel,
    });
  }, [
    getValues,
    onChange,
    pageHasCustomScalingModel,
    selectedModel,
    watchCustomPercent,
  ]);

  const desktopFilter = (
    <FilterButtonMenu
      id="scaling-model-filter"
      filterLabel={getFilterLabel()}
      menuOptions={menuOptions}
      onClick={handleOnChange}
      selected={selectedModel}
      menuOptionContentSection={menuOptionContentSection}
    />
  );

  return <Form {...formProps}>{desktopFilter}</Form>;
};
