/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  extentOfDataset,
  formatUnit,
  optimize,
  StyledApexChart,
} from '@groundwater/app/ui';
import { useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import {
  GrowthAccountingPeriodCustomers,
  GrowthAccountingPeriodSales,
} from '../../gql-generated';
import { useTheme } from '@mui/material';
import { useChartResetKeys } from '../hooks/useResetKeys';
import moment from 'moment';

export interface GrowthAccountingProps {
  periods: GrowthAccountingPeriodCustomers[] | GrowthAccountingPeriodSales[];
  formula: string;
  hiddenSerieIds: GrowthAccountingSeriesIds[];
  onLegendClick: (serieId: GrowthAccountingSeriesIds) => void;
}

export type GrowthAccountingSeriesIds = Exclude<
  keyof GrowthAccountingPeriodSales,
  'date' | '__typename'
>;

export const GrowthAccountingQuickRatio: React.FC<GrowthAccountingProps> = ({
  periods,
  formula,
  hiddenSerieIds,
  onLegendClick,
}) => {
  const muiTheme = useTheme();

  const resetKeys = useChartResetKeys();

  const series: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: formula,
        type: 'line',
        data: periods.map((period) => period.quick_ratio),
      },
    ],
    [formula, periods]
  );

  const { minFromDataset, maxFromDataset } = extentOfDataset(
    series.map((serie) => ({
      ...serie,
      data: serie.data.map((i) => (i === null ? null : Number(i))),
    })),
    hiddenSerieIds
  );
  const yZoom = false;
  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    yZoom,
    minFromDataset,
    maxFromDataset
  );

  const options: ApexOptions = useMemo(
    (): ApexOptions => ({
      annotations: {
        yaxis: [
          {
            opacity: 0.1,
            y: 1,
            borderColor: '#000',
            label: {
              borderColor: '#000',
              style: {
                color: '#fff',
                background: '#000',
              },
              text: '1',
            },
          },
        ],
      },
      colors: [muiTheme.palette.chart.colorGraph22],
      states: {
        active: {
          filter: {
            type: 'darken',
            value: 0.88,
          },
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.88,
          },
        },
      },
      chart: {
        width: '100%',
        id: formula + resetKeys.join(','),
        group: 'growth-accounting',
        type: 'line',
        animations: { enabled: false },
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        events: {
          legendClick: function (
            chartContext: any,
            seriesIndex: number,
            config: any
          ) {
            const serieID = config.globals.initialSeries[seriesIndex].name;
            onLegendClick(serieID);
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 3,
      },
      legend: {
        inverseOrder: true,
      },
      grid: {
        borderColor: muiTheme.palette.grey[300],
        strokeDashArray: 3,
      },
      markers: { size: 0, hover: { size: 6 } },
      tooltip: {
        marker: {
          show: true,
        },
        shared: true,
        intersect: false,
        y: {
          formatter: formatUnit,
        },
        inverseOrder: true,
      },
      xaxis: {
        type: 'category',
        tickAmount: 12,
        categories: periods.map((period) => period.date),
        tickPlacement: 'on',
        crosshairs: { show: false },
        tooltip: { enabled: false },
        labels: {
          formatter: (date) => moment(date).format("MMM D, 'YY"),
        },
      },
      yaxis: {
        seriesName: formula,
        title: { text: formula },
        labels: {
          formatter: formatUnit,
        },
        crosshairs: { show: false },
        tooltip: { enabled: false },
        min: yMin,
        max: yMax,
        tickAmount,
      },
    }),
    [
      muiTheme.palette.chart.colorGraph22,
      muiTheme.palette.grey,
      formula,
      resetKeys,
      periods,
      yMin,
      yMax,
      tickAmount,
      onLegendClick,
    ]
  );

  return (
    <StyledApexChart
      key={resetKeys.join(',')}
      hiddenSerieId={hiddenSerieIds}
      options={options}
      series={series}
      type="line"
      width="100%"
      height="300px"
    />
  );
};
