import { Box, SxProps, Theme } from '@mui/material';
import { BBSMLogoOnLight, BBSMLogoOnDark } from '../../assets';

interface LogoProps {
  sx?: SxProps<Theme> | undefined;
  variation: 'onLight' | 'onDark';
}

export function Logo({ sx, variation }: LogoProps) {
  const getLogo = () => {
    if (variation === 'onDark') return BBSMLogoOnDark;
    return BBSMLogoOnLight;
  };

  return <Box component={getLogo()} sx={{ width: 254, height: 60, ...sx }} />;
}
