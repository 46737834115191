import { upperFirst } from 'lodash-es';

export type CohortedRetentionLineChartGlobalConfig = {
  globals: {
    seriesXvalues: number[][];
    seriesYvalues: number[][];
    initialSeries: any[];
    seriesNames: string[];
  };
  config: { tooltip: { y: { formatter: (arg0: any) => string } } };
};

export function cohortedRetentionLineChartTooltip(props: {
  w: CohortedRetentionLineChartGlobalConfig;
  dataPointIndex: string | number;
}) {
  const series = props.w.globals.initialSeries;

  const valueFormat = props.w.config.tooltip.y.formatter;

  // Header
  let text = `
    <table class="cohorted-retention-custom-tooltip">
      <tr>
        <th></th>
        <th></th>
      </tr>
  `;
  // debugger;
  series.forEach(
    (serie: { data: { [x: string]: any }; name: string; color: string }) => {
      if (!serie.data[props.dataPointIndex]) {
        return;
      }
      // Derive the serie name from the Apex config.
      // The serie definition could be located in https://github.com/secondmeasure/groundwater/blob/14e5d37acf9284d19b7fbafebed9f08344e3aa33/apps/api/src/modules/qtd/qtd-schema.graphql#L45
      const serieName = upperFirst(serie.name.split('_').join(' '));
      const value = valueFormat(serie.data[props.dataPointIndex]);

      text += `<tr>
            <td class='name'><span style='background-color:${serie.color}'></span>${serieName}</td>
            <td class='value'>${value}</td>
          </tr>
        `;
    }
  );

  return text + '</table>';
}
