import { ExternalLink } from '@groundwater/shared-ui';
import ArticleIcon from '@mui/icons-material/Article';
import { Button } from '@mui/material';

const BASE_URL = 'https://assets.secondmeasure.com';

interface AnalysisGuideButtonProps {
  fileName: string;
  label?: string;
}

export const AnalysisGuideButton: React.FC<AnalysisGuideButtonProps> = ({
  fileName,
  label,
}) => {
  const btnLabel = label ?? 'Analysis Guide';

  return (
    <Button
      href={`${BASE_URL}/${fileName}`}
      target="_blank"
      component={ExternalLink}
      variant="text"
      sx={{ width: 'max-content' }}
    >
      <ArticleIcon /> {btnLabel}
    </Button>
  );
};
