import React, { createContext, useContext, useMemo, useState } from 'react';
import { LoginRequest } from './types';
import type { AxiosInstance } from 'axios';
import { MeQuery, useMeQuery } from '../../gql-generated';
import { useToast } from '../hooks';
import { UseQueryResult } from 'react-query';

export interface AuthContextValue {
  login: ({ username, password }: LoginRequest) => Promise<void>;
  logout: () => Promise<void>;
  user: MeQuery['me'];
  isSubmitting: boolean;
  isAuthenticated: boolean;
  meQuery: UseQueryResult<MeQuery>;
}

export const authContext = createContext<AuthContextValue | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
  apiClient: AxiosInstance;
}

/**
 * Provider component that wraps our app and makes auth object.
 * It is available to any child component that calls useAuth().
 */
export function ProvideAuth({ children, apiClient }: AuthProviderProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const meQuery = useMeQuery();
  const { setToast } = useToast();

  const showToast = (message: string) => {
    return setToast({
      id: `auth`,
      open: true,
      severity: 'error',
      message,
    });
  };

  const login = async ({ username, password }: LoginRequest) => {
    setIsSubmitting(true);
    try {
      await apiClient.post('/login', {
        username,
        password,
      });
      await meQuery.refetch();
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        showToast(
          error?.response?.data ?? `Incorrect credentials, please try again`
        );
      } else {
        showToast(`There was an issue, please try again`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const logout = async () => {
    setIsSubmitting(true);
    try {
      await apiClient.post('/logout');
      await meQuery.refetch();
    } catch (error) {
      console.error(error);
      showToast(`There was an issue, please try again`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const user = useMemo(() => {
    return meQuery.data?.me || null;
  }, [meQuery.data?.me]);

  return (
    <authContext.Provider
      value={{
        login,
        logout,
        user,
        isSubmitting,
        isAuthenticated: !!user && (!user.require_2fa || user.verified_2fa),
        meQuery,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

/**
 * Hook for child components to get the auth object and re-render when it changes.
 */
export const useAuth = () => {
  const authContextValue = useContext(authContext);
  if (!authContextValue)
    throw new Error(
      'Tried to consume auth context. Wrap your app in a <ProvideAuth />'
    );
  return authContextValue;
};
