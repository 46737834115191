import { Column } from '../../react-table-column-types';
import { coefficientColumn } from './CoefficientCell';

export const r2Column = <
  T extends { r2_coefficient: number | null }
>(): Column<T> => ({
  ...coefficientColumn(),
  id: 'r2',
  width: 100,
  Header: 'R^2',
  accessor: (row) => row.r2_coefficient || 'n/a',
  // ignoring desc because although it exists in the types
  // and is documented here https://react-table.tanstack.com/docs/api/useSortBy#column-options
  // I noticed it is not actually used here https://github.com/TanStack/react-table/blob/v7.7.0/src/plugin-hooks/useSortBy.js#L385
  // it is passed in, but the comment clearly says "This function should always return in ascending order".
  // this seems to be related to sorting being able to chain (sort by multiple fields)
  sortType: (rowA, rowB, _columnId, desc) => {
    const a = rowA.original.r2_coefficient;
    const b = rowB.original.r2_coefficient;

    // Because react table will reverse the results on its own
    // After calling all chained sort functions, we cannot just
    // sort n/a to the end naively. We need to sort them to the
    // start when we know that react-table will reverse the list
    // this way they always end up at the end!
    if (a === null) return desc ? -1 : 1; // sort a after b
    if (b === null) return desc ? 1 : -1; // sort b after a
    return a - b;
  },
});
