import { omit } from 'lodash-es';
import { DownloadAllCsvButton } from '@groundwater/app/ui';
import { useToast } from '@groundwater/shared-ui';
import { useDeferredValue, useState } from 'react';
import { useGetTrendsExportAllQuery } from '../../../gql-generated';
import { triggerFileDownload } from '../../../utils';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';

export const TrendsExportAllButton: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const variables = useVariables(REPORT_PAGES.trends);
  const deferredVariables = useDeferredValue(variables);

  const csvDownload = useGetTrendsExportAllQuery.fetcher(
    omit(deferredVariables, 'request.y_zoom')
  );

  const { setToast } = useToast();

  const showFailureToast = (message: string) => {
    setToast({
      id: `csv-download-${Math.random()}`,
      open: true,
      severity: 'error',
      message,
    });
  };

  const handleCSVDownload = async () => {
    try {
      setIsError(false);
      setIsLoading(true);

      const { trends_export_all_v3: trends_export_all } = await csvDownload();

      if (trends_export_all.__typename === 'DataError') {
        setIsLoading(false);
        return showFailureToast(trends_export_all.message);
      }

      triggerFileDownload(trends_export_all.filename, trends_export_all.data);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);

      return showFailureToast('Failed to download CSVs.');
    }
  };

  return (
    <DownloadAllCsvButton
      isLoading={isLoading}
      isError={isError}
      tooltipTitle="Download all Trends reports as CSV"
      handleOnClick={handleCSVDownload}
    />
  );
};
