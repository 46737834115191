import { format } from 'd3-format';
import moment from 'moment';
import consensusIcon from '../../assets/ConsensusIcon.svg';
import upArrowIcon from '../../assets/UpArrowIcon.svg';
import downArrowIcon from '../../assets/DownArrowIcon.svg';
import { QTDChartGlobalConfig } from './qtdChartUtils';

export function qtdSalesTooltip(props: {
  w: QTDChartGlobalConfig;
  dataPointIndex: string | number;
  growth: { [x: string]: any };
  analysis_period_dates: { [x: string]: any };
  comparison_period_dates: { [x: string]: any };
  consensus_low: number;
  consensus_high: number;
  revenue_estimate_low: number;
  revenue_estimate_high: number;
}) {
  const seriesNames = props.w.globals.seriesNames;
  const series = props.w.globals.initialSeries;
  const valueFormat = props.w.config.tooltip.y.formatter;
  const yoyFormatter = format(',.2%');
  const formatDate = (date: string): string => {
    return moment(date).format('MMM D, YYYY');
  };
  const analysis_period_date =
    props.analysis_period_dates[props.dataPointIndex];

  const percent_diff = props.growth[props.dataPointIndex];

  const includeCIColumn =
    seriesNames.includes('consensus') || seriesNames.includes('projecting');

  const arrow = `<img src="${
    percent_diff >= 0 ? upArrowIcon : downArrowIcon
  }">`;

  let text: string | null = null;
  series.forEach(
    (serie: { data: { [x: string]: any }; name: string; color: string }) => {
      // Tooltip header
      if (!text) {
        text = `
          <table class="qtd-custom-tooltip">
            <tr>
              <th></th>
              <th>Cumulative Sales</th>
              <th>% Growth</th>
        `;
        if (includeCIColumn) {
          text += `<th>Prediction Interval</th>`;
        }
        text += `</tr>`;
      }

      if (!serie.data[props.dataPointIndex]) {
        return;
      }

      const value = valueFormat(serie.data[props.dataPointIndex]);

      if (serie.name === 'consensus') {
        text += `
          <tr>
            <td class='title'><img src='${consensusIcon}'></img> Consensus</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'></td>
        `;
        if (includeCIColumn) {
          text += `
            <td class='conf_interval'>${valueFormat(
              props.consensus_low
            )} - ${valueFormat(props.consensus_high)}</td>
          `;
        }
        text += '</tr>';
      }

      if (serie.name === 'observed') {
        text += `
          <tr>
            <td class='name'><span style='background-color:${
              serie.color
            }'></span>${formatDate(analysis_period_date)}</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'>${
              percent_diff ? yoyFormatter(percent_diff) + ' ' + arrow : ''
            }</td>
        `;

        if (includeCIColumn) {
          text += `
            <td class='conf_interval'></td>
          `;
        }

        text += '</tr>';
      }

      // at point where observed series swaps to projected series, only show observed series in legend
      if (
        serie.name === 'projected' &&
        series.find((serie: { name: string }) => serie.name === 'observed')
          .data[props.dataPointIndex] === null
      ) {
        text += `
          <tr>
            <td class='name'><span style='background-color:${
              serie.color
            }'></span>${formatDate(analysis_period_date)}</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'>${
              percent_diff ? yoyFormatter(percent_diff) + ' ' + arrow : ''
            }</td>
        `;
        if (includeCIColumn) {
          text += `
            <td class='conf_interval'>${valueFormat(
              props.revenue_estimate_low
            )} - ${valueFormat(props.revenue_estimate_high)}</td>
          `;
        }

        text += '</tr>';
      }

      if (serie.name === 'comparing') {
        const comparison_period_dates =
          props.comparison_period_dates[props.dataPointIndex];

        text += `
          <tr>
            <td class='name'>
              <span style="background-color:${serie.color}"></span>${formatDate(
          comparison_period_dates
        )}</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'></td>
        `;
        if (includeCIColumn) {
          text += `<td class='conf_interval'></td>`;
        }

        text += '</tr>';
      }
    }
  );
  return text + '</table>';
}
