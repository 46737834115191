import { isNil } from 'lodash-es';
import moment from 'moment';
import { OvrChartConfig } from '../ovrChartUtils';
import { SpreadYSeries } from './Spread';

export function spreadTooltip(props: OvrChartConfig) {
  const series = props.w.globals.initialSeries;
  const xSerieLabel = props.w.config.xaxis.categories[props.dataPointIndex];
  const valueFormat = props.w.config.tooltip.y.formatter;

  // Do not render the tooltip if all the series at the hovered index contain nulls.
  if (series.every((serie) => serie.data[props.dataPointIndex] === null))
    return '';

  let text = `
    <table class="spread-custom-tooltip">
      <tr>
        <th>${moment(xSerieLabel).format('MMM D, YYYY')}</th>
        <th>YoY Spread</th>
        </tr>
  `;
  series.forEach((serie) => {
    // Do not render values if they are null
    if (isNil(serie.data[props.dataPointIndex])) {
      return;
    }

    const value = valueFormat(Number(serie.data[props.dataPointIndex]));

    // Tooltip body
    if (serie.name === SpreadYSeries['Consensus Range Low']) {
      // Create custom label for consensus range low serie
      text += `
        <tr>
        <td class='name'><span style='background-color:#ffffff; border: 2px solid #CC2529'></span>${serie.name}</td>
        <td class='value'>${value}</td>
      `;
    } else {
      // Tooltip body
      text += `
      <tr>
        <td class='name'><span style='background-color:${serie.color}'></span>${serie.name}</td>
        <td class='value'>${value}</td>
      </tr>
    `;
    }
  });

  return text + '</table>';
}
