import { Fade, Collapse } from '@groundwater/shared-ui';
import { Backdrop, Box, Paper } from '@mui/material';
import {
  BusinessSearchResults,
  BusinessSearchResultsProps,
} from '@groundwater/shared-ui';
import { environment } from '../../../environments/environment';

/**
 * Wraps BusinessAutocompleteResults in a Paper, Collapse, and Box
 * for the "full screen" with Backdrop experience in the primary search
 */
export const PrimarySearchResults: React.FC<
  BusinessSearchResultsProps & {
    isSearchOpen: boolean;
    setIsSearchOpen: (isOpen: boolean) => void;
  }
> = ({
  searchResult,
  onSelect,
  isSelected,
  query,
  isSearchOpen,
  setIsSearchOpen,
}) => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          zIndex: 9999,
          pointerEvents: isSearchOpen ? 'all' : 'none',
        }}
        onClick={() => setIsSearchOpen(false)} // required so that clicking the obscured page contents "behind" the search causes search to close
      >
        <Collapse in={isSearchOpen}>
          <Paper
            elevation={4}
            sx={(theme) => ({
              overflow: 'auto',
              maxHeight: 500,
              maxWidth: 700,
              margin: theme.spacing(3),
            })}
          >
            <BusinessSearchResults
              api_url={environment.api_url}
              query={query ?? ''}
              searchResult={searchResult}
              isSelected={isSelected}
              onSelect={onSelect}
            />
          </Paper>
        </Collapse>
      </Box>
      {/*
      Need to absolute position, since stacking context changes when opacity
      becomes 1 otherwise.
       */}
      <Fade
        in={isSearchOpen}
        style={{
          position: 'absolute',
          inset: 0,
          zIndex: 9998,
          pointerEvents: 'none',
        }}
      >
        {/* Need to override the "position: fixed" from MUI to contain it */}
        <Backdrop open={true} style={{ position: 'absolute', inset: 0 }} />
      </Fade>
    </>
  );
};
