import { object, string } from 'yup';
import { useForm } from '../hooks';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface RequestPasswordResetFormProps {
  onSubmit: (email: string) => void;
  isSubmitting: boolean;
  loginRoute: string;
}

const validationSchema = object().shape({
  email: string()
    .email('Email must be a valid email address')
    .required('Email is required'),
});

export function RequestPasswordResetForm(props: RequestPasswordResetFormProps) {
  const onSubmit = (values: { email: string }) => props.onSubmit(values.email);
  const { register, formProps } = useForm({
    defaultValues: {
      email: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <form {...formProps}>
      <Stack>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          {...register('email')}
        />
      </Stack>
      <Stack justifyContent="center" spacing={1} sx={{ my: 3 }}>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={props.isSubmitting}
        >
          Reset Password
        </LoadingButton>
        <Button
          fullWidth
          size="large"
          component={RouterLink}
          variant="text"
          to={props.loginRoute}
        >
          Back
        </Button>
      </Stack>
    </form>
  );
}
