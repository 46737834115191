import { select, selectAll } from 'd3-selection';
import { isNil } from 'lodash-es';
import { initializeWhiskers } from '../../utils/whiskers';

/** TODO - is this just re-defining [parts of] the Apex types, can we delete it? */
export interface OvrChartConfig {
  el: HTMLElement;
  getChartArea: () => HTMLElement;
  w: {
    globals: {
      chartID: string;
      seriesXvalues: number[][];
      seriesYvalues: number[][];
      initialSeries: { name: string; data: [number | null]; color: string }[];
      categoryLabels: { [x: string]: string };
    };
    config: {
      xaxis: any;
      tooltip: { y: { formatter: (val: number) => string } };
    };
  };
  dataPointIndex: number;
}

const createWhiskers = (
  chart: OvrChartConfig,
  lowerBoundSerieName: string,
  upperBoundSerieName: string
) => {
  const chartID = chart.w.globals.chartID;
  const chartArea = select(chart.getChartArea());
  const seriesXvalues = chart.w.globals.seriesXvalues[0];

  const consensusRangeLowIndex = chart.w.globals.initialSeries.findIndex(
    (i) => i.name === lowerBoundSerieName
  );
  const consensusRangeHighIndex = chart.w.globals.initialSeries.findIndex(
    (i) => i.name === upperBoundSerieName
  );

  const yMin = chart.w.globals.seriesYvalues[consensusRangeLowIndex];
  const yMax = chart.w.globals.seriesYvalues[consensusRangeHighIndex];

  if (seriesXvalues === undefined || yMin === undefined || yMax === undefined) {
    throw new Error('series must be defined');
  }

  seriesXvalues.forEach((x: number, index: number) => {
    /**
     * Do not render whiskers if the matching serie value is null
     */
    if (
      isNil(
        chart.w.globals.initialSeries[consensusRangeLowIndex]?.data[index]
      ) ||
      isNil(chart.w.globals.initialSeries[consensusRangeHighIndex]?.data[index])
    )
      return;
    initializeWhiskers({
      targetNode: chartArea,
      className: `${chartID}-ibeam`,
      x,
      lower: Number(yMin[index]),
      upper: Number(yMax[index]),
    });
  });
};

const removeInvisibleLegendItems = () => {
  selectAll('.apexcharts-legend-series[seriesname="consensusxlow"]').remove();
  selectAll('.apexcharts-legend-series[seriesname="consensusxhigh"]').remove();
  selectAll(
    '.apexcharts-legend-series[seriesname="ConsensusxRangexLow"]'
  ).remove();
  selectAll(
    '.apexcharts-legend-series[seriesname="ConsensusxRangexHigh"]'
  ).remove();
};

export const drawCustomAnnotiations = (props: {
  chart: OvrChartConfig;
  scaled: boolean;
  lowerBoundSerieName: string;
  upperBoundSerieName: string;
}) => {
  const chartArea = select(props.chart.el);
  const chartID = props.chart.w.globals.chartID;

  if (!chartArea.selectAll(`${chartID}-ibeam`).empty())
    // Remove old instances of whiskers when chart re-renders
    chartArea.selectAll(`${chartID}-ibeam`).remove();

  // Create consensus range indicator only for scaled models
  if (props.scaled)
    createWhiskers(
      props.chart,
      props.lowerBoundSerieName,
      props.upperBoundSerieName
    );

  // Remove consensus_low and consensus_high legend items as these series only used to produce whiskers and are not appearing on the chart
  removeInvisibleLegendItems();
};
