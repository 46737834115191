import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box } from '@mui/material';
export interface PageProps {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * Configures React helmet (for page title)
 */
export const Page = forwardRef(
  ({ children, title = '', ...other }: PageProps, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
);
