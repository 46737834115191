import { Input } from '@mui/material';
import { startTransition, useState } from 'react';
import { FilterProps } from 'react-table';

export const SearchFilter: React.FC<FilterProps<{}>> = ({ column }) => {
  const { preFilteredRows, setFilter } = column;

  const filterValue: string | null | undefined = column.filterValue;

  const count = preFilteredRows.length;

  const [value, setValue] = useState<string>(filterValue || '');

  const onChange = (value: string) => {
    startTransition(() => setFilter(value || undefined));
  };

  return (
    <Input
      data-testid="default-column-filter"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search`}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
