import moment from 'moment';
import { FilterType } from 'react-table';
import { DateRangeFilter } from './DateCell/DateRangeFilter';
import { DefaultCell } from './DefaultCell';

const filter =
  <D extends object>(): FilterType<D> =>
  (rows, ids, filterValue: [number, number]) => {
    const [min, max] = filterValue || [];
    return rows.filter((row) => {
      return ids.some((id) => {
        const value = moment(row.values[id]);
        if (max && !min) {
          return value.isSameOrBefore(max, 'day');
        }
        if (min && !max) {
          return value.isSameOrAfter(min, 'day');
        }
        return value.isBetween(
          min,
          max,
          'day',
          '[]' // inclusive
        );
      });
    });
  };

export const dateColumn = <D extends object>() => ({
  disableFilters: false,
  Cell: ({ value }: { value: string }) => <DefaultCell>{value}</DefaultCell>,
  Filter: DateRangeFilter,
  filter: filter<D>(),
  sortType: 'alphanumeric' as const,
});
