import { fixFloat } from '@groundwater/app/ui';
import { TxnDataRow } from './types';

type Row = Pick<TxnDataRow, 'transaction_value_max' | 'transaction_value_min'>;

export const selectContiguousValues = (
  rows: Row[],
  field: 'transaction_value_max' | 'transaction_value_min'
): number[] => {
  const out: number[] = [];

  let last: Row;

  for (let i = 0; i < rows.length; i++) {
    const size = fixFloat(
      rows[i]!.transaction_value_max - rows[i]!.transaction_value_min
    );
    if (i === 0) {
      const isValidPennyBucket =
        rows[i]!.transaction_value_min === 0.01 &&
        rows[i]!.transaction_value_max === 0.99;
      if (!isValidPennyBucket && size !== 0.99) {
        console.warn('invalidly sized first bucket');
      }
      last = rows[i]!;
      out.push(last[field]);
      continue;
    }

    if (size !== 0.99) {
      console.warn('invalidly sized bucket');
    }
    if (rows[i]!.transaction_value_min < last!.transaction_value_max) {
      throw new Error('invalid overlapping buckets');
    }

    // while a gap exists (we cannot take a bucket)
    // using previous max instead of using min since the 1st bucket
    // is a special case that can sometimes start at $0.01 or $1.00
    // but all the end values should be equally spaced
    while (
      last!.transaction_value_max <
      rows[i]!.transaction_value_min - 0.01
    ) {
      const row = {
        transaction_value_min: last!.transaction_value_max + 0.01,
        transaction_value_max: last!.transaction_value_max + 1.0,
      };
      out.push(row[field]);
      last = row;
    }

    out.push(rows[i]![field]);
    last = rows[i]!;
  }
  return out;
};
