import { TableCell } from '@mui/material';
import { BrandNode } from '../types';
import { VirtualTableCellUserProps } from '@groundwater/app/ui';

export const IndustryCell: React.FC<
  { brand: BrandNode['brand'] } & Pick<VirtualTableCellUserProps, 'width'>
> = ({ brand, width }) => {
  return (
    <TableCell
      component="div"
      variant="body"
      style={{
        width,
        display: 'block',
        height: '100%',
      }}
    >
      {brand.industry?.id} - {brand.industry?.title}
    </TableCell>
  );
};
