/* eslint-disable react/jsx-pascal-case */
import { useMemo, useState } from 'react';
import { useToast } from '@groundwater/shared-ui';
import { uniq } from 'lodash-es';
import { TextField, Chip, Badge } from '@mui/material';
import {
  validateRange,
  validationRegex,
  normalizeBucketString,
  formatTransactionValueTag,
  RawBuckets,
  ParsedBuckets,
  ParsedBucket,
  VariableKeys,
  parseBuckets,
  isValidURLQuery,
} from '../../../utils';
import { useReportPage } from '../../hooks/useReportPage';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import moment from 'moment';
export interface TransactionValueSelectorProps {
  buckets: RawBuckets;
  onBucketsChange: (bucket: RawBuckets) => void;
}

const useParsedBuckets = (buckets: RawBuckets): ParsedBuckets => {
  return useMemo(() => parseBuckets(buckets), [buckets]);
};

/**
 * Wraps the filter in a "new" badge until 5/1
 * TODO - kill this wrapper on or after 5/1
 */
export const CohortByPriceFilter: React.FC<{}> = () => {
  // wrap it up until 5/1
  return moment().isSameOrBefore('2023-05-01') ? (
    <Badge badgeContent="new" color="error">
      <_CohortByPriceFilter />
    </Badge>
  ) : (
    //  after 5/1 do not wrap it
    <_CohortByPriceFilter />
  );
};

const _CohortByPriceFilter: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  const reportPage = useReportPage();
  const params = useCoercedQueryParams(reportPage);

  if (!(VariableKeys.bucket in draftParams)) {
    throw new Error();
  }

  const draftIsValid = isValidURLQuery(reportPage, {
    ...params,
    [VariableKeys.bucket]: draftParams.bucket,
  });

  return (
    <TextField
      color={draftIsValid ? 'primary' : 'error'}
      value={draftParams.bucket}
      label="Filter By Acquisition Price"
      sx={{ width: 315 }}
      size="small"
      onChange={(event) => {
        return handleDraftChange((prevParams) => ({
          ...prevParams,
          bucket: event.target.value,
        }));
      }}
      placeholder="Use hyphens (1-50) or plus signs (50+)"
    />
  );
};

export const TransactionValueSelector: React.FC<
  TransactionValueSelectorProps
> = ({ buckets, onBucketsChange }) => {
  const { setToast } = useToast();

  const [inputVal, setInputVal] = useState<string>('');

  const parsedBuckets = useParsedBuckets(buckets);

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      if (!inputVal) return;
      const isValid = validateRange(inputVal) && validationRegex.test(inputVal);

      if (isValid) {
        const combinedValues =
          parsedBuckets === null
            ? [inputVal]
            : (uniq([...parsedBuckets, inputVal]) as string[]);
        onBucketsChange(normalizeBucketString(combinedValues));
      } else {
        setToast({
          id: 'spend_band_value_invalid',
          open: true,
          severity: 'warning',
          message: `${inputVal} is not a valid value`,
        });
      }
      setInputVal('');
    }
  };

  return (
    <TextField
      autoFocus
      fullWidth
      value={inputVal}
      onChange={(e) => setInputVal(e.target.value)}
      label="Transaction value"
      onKeyPress={handleKeyPress}
      placeholder="Use hyphens (1-50) or plus signs (50+)"
    />
  );
};

export const TransactionValueSelection: React.FC<
  TransactionValueSelectorProps
> = ({ buckets, onBucketsChange }) => {
  const parsedBuckets = useParsedBuckets(buckets);

  const handleDelete = (bucketToDelete: ParsedBucket) => {
    const filteredVals = buckets
      ?.split(',')
      ?.filter((s) => s !== bucketToDelete);
    const result = filteredVals?.length ? filteredVals?.join(',') : null;

    onBucketsChange(result);
  };

  return (
    <>
      {parsedBuckets.map((bucket: ParsedBucket) => {
        return (
          <Chip
            key={bucket}
            label={formatTransactionValueTag(bucket)}
            onDelete={() => handleDelete(bucket)}
            color="primary"
            size="small"
          />
        );
      })}
    </>
  );
};
