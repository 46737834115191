import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
import { MHidden } from '../@material-extend';
import { LoginForm, LoginFormProps } from '../components/LoginForm';
import { ExternalLink } from '../components/ExternalLink';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.primary.dark,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export interface LoginLayoutProps extends LoginFormProps {
  callToActionText?: string;
  introText?: string;
  supportEmail?: string;
  withContactDisclaimer?: boolean;
  isSubmitting: boolean;
  bssoLink?: string;
}

export function LoginLayout(props: LoginLayoutProps) {
  const renderContactDisclaimer = () => {
    return (
      <StyledTypography>
        If you don't have an account,{' '}
        <ExternalLink
          light
          href={props.supportEmail ?? 'mailto:bsmsupport@bloomberg.net'}
          target="_blank"
        >
          contact us.
        </ExternalLink>
      </StyledTypography>
    );
  };
  return (
    <RootStyle>
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack
            alignItems="start"
            justifyContent="space-between"
            sx={{ px: 5, mt: 10, mb: 5 }}
          >
            <StyledTypography variant="h3">
              {props.introText ?? 'Hi, Welcome Back!'}
            </StyledTypography>
            {props.withContactDisclaimer && renderContactDisclaimer()}
          </Stack>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {props.callToActionText ?? 'Please sign in'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your details below.
            </Typography>
          </Stack>

          <LoginForm
            onSubmit={props.onSubmit}
            isSubmitting={props.isSubmitting}
            resetPasswordRoute="/request-password-reset"
            bssoLink={props.bssoLink}
          />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
