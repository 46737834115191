import React, { Suspense, useDeferredValue } from 'react';
import { ReportLayout } from '../../layouts/ReportLayout';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { QuarterToDateChartGrowth, QuarterToDateChartSales } from './QtdChart';
import {
  QtdPercentObservedChart,
  QtdPercentObservedChartSkeleton,
} from './QtdPercentObservedChart';
import { Alert, Grid, Stack } from '@mui/material';
import { QtdStatBoxes, QtdStatBoxesLoadingSkeleton } from './QtdStatBoxes';
import { QtdExportAllButton } from './QtdExportAllButton';
import {
  AnalysisGuideButton,
  ChartFrame,
  QtdChartLoadingSkeleton,
} from '@groundwater/app/ui';

import { WithCompanyOnlyPrompt } from '../../components/WithCompanyOnlyPrompt';
import { useVariables } from '../../hooks/useVariables';
import { REPORT_PAGES } from '../../constants';
import { QtdScalingModel } from '@groundwater/shared-ui';
import { useGetQtdQuery } from '../../../gql-generated';
import { omit } from 'lodash-es';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Quarter-to-Date';

export const QtdPage: React.FC<{}> = () => {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <WithCompanyOnlyPrompt page_title={page_title} publicOnly>
        <ReportLayout
          page_title={page_title}
          filterSubHeaderActionContent={
            <Stack direction="row" alignItems="center" spacing={2}>
              <AnalysisGuideButton fileName="quarter_to_date.pdf" />
              <QtdExportAllButton />
            </Stack>
          }
        >
          <Suspense fallback={<QtdPageLoadingSkeleton />}>
            <QtdPageContent />
          </Suspense>
        </ReportLayout>
      </WithCompanyOnlyPrompt>
    </WithCompanyIdPrompt>
  );
};

const QtdPageLoadingSkeleton = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    >
      <Grid item xs={12}>
        <QtdStatBoxesLoadingSkeleton />
      </Grid>
      <Grid item xs={12}>
        <QtdChartLoadingSkeleton />
      </Grid>
      <Grid item xs={12}>
        <QtdChartLoadingSkeleton />
      </Grid>
      <Grid item xs={12}>
        <QtdPercentObservedChartSkeleton />
      </Grid>
    </Grid>
  );
};

const QtdPageContent = () => {
  const variables = useVariables(REPORT_PAGES.qtd);
  const deferredVariables = useDeferredValue(variables);
  const response = useGetQtdQuery(omit(deferredVariables, 'request.y_zoom'), {
    enabled: !!deferredVariables.request.analysis_quarter,
  });
  // If the user has not made a "quarter" selection
  //  or we haven't fetched it and defaulted it, render loading state
  if (!deferredVariables.request.analysis_quarter) {
    return <QtdPageLoadingSkeleton />;
  }
  if (!response.data) {
    throw new Error();
  }
  const { qtd_v2: qtd } = response.data;
  const isLoading = variables !== deferredVariables;

  if (qtd.__typename === 'DataError') {
    return isLoading ? (
      <QtdChartLoadingSkeleton />
    ) : (
      <Alert severity="info">{qtd.message}</Alert>
    );
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    >
      <Grid item xs={12}>
        <QtdStatBoxes qtd={qtd} />
      </Grid>

      <Grid item xs={12}>
        <ChartFrame
          chartTitle={`Quarter-to-Date (${
            qtd.__typename === 'QtdResponse'
              ? qtd.annotations.scaling_model === QtdScalingModel.Unscaled
                ? 'unscaled'
                : 'scaled'
              : 'error'
          })`}
          isLoading={isLoading}
        >
          <QuarterToDateChartSales qtd={qtd} />
        </ChartFrame>
      </Grid>

      <Grid item xs={12}>
        <ChartFrame chartTitle={'Growth'} isLoading={isLoading}>
          <QuarterToDateChartGrowth qtd={qtd} />
        </ChartFrame>
      </Grid>

      <Grid item xs={12}>
        <ChartFrame chartTitle="Percent Observed Model" isLoading={isLoading}>
          <QtdPercentObservedChart />
        </ChartFrame>
      </Grid>
    </Grid>
  );
};
