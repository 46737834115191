// material
import { Link, LinkBaseProps, styled } from '@mui/material';
import React from 'react';

export interface ExternalLinkProps extends LinkBaseProps {
  light?: boolean;
}

const StyledLink = styled(Link)<ExternalLinkProps>(({ theme, light }) => ({
  color: light ? theme.palette.info.light : theme.palette.info.dark,
}));

export const ExternalLink: React.FC<ExternalLinkProps> =
  React.forwardRef<HTMLAnchorElement>((props, ref) => (
    <StyledLink {...props} ref={ref}>
      {props.children}
    </StyledLink>
  ));
