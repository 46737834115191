import { AxiosInstance, AxiosRequestConfig } from 'axios';

let globalApiClient: AxiosInstance | undefined = undefined;

/**
 * Each of the real apps sets a global variable, which is expected to be set by the "shared"
 * library. This is a code smell, but the reason this exists is so that the apps (and not the library) controls things
 * like which URL the API client will use, and which environment variables it's controlled by, etc.
 */
export const setGlobalApiClient = (client: AxiosInstance) =>
  (globalApiClient = client);

export const fetchGQL = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  _options?: RequestInit['headers'],
  config?: AxiosRequestConfig
): (() => Promise<TData>) => {
  return async () => {
    if (undefined === globalApiClient) {
      throw new Error(
        'trying to call fetchGQL without an apiClient injected into the module first. Call setGlobalApiClient(apiClient);'
      );
    }
    const queryMatches = query.match(/query ([a-zA-Z]+)/);
    const mutationMatches = query.match(/mutation ([a-zA-Z]+)/);
    const queryName = queryMatches
      ? queryMatches[1]
      : mutationMatches
      ? mutationMatches[1]
      : null;
    // console.log(queryName, variables);
    const res = await globalApiClient.post(
      `/graphql?queryName=${queryName}`,
      {
        query,
        variables,
      },
      config
    );

    if (res.data.errors && res.data.errors[0]?.extensions?.code === 401) {
      window.location.href = '/';
      throw new Error('Unauthorized');
    }
    if (res.data.errors) {
      const { message } = res.data.errors[0] || 'Error..';
      throw new Error(message);
    }

    return res.data.data;
  };
};
