import { Box, Alert } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';

export const Announcement: React.FC<{
  localStorageKey: string;
  endDate: string;
  message: string | React.ReactElement;
}> = ({ localStorageKey, endDate, message }) => {
  const [isVisible, setIsVisible] = useState<Boolean>(
    // Check for the key existance and not necessarily for the key value
    !localStorage.getItem(localStorageKey) && moment().isSameOrBefore(endDate)
  );

  if (isVisible) {
    return (
      <Box my={1}>
        <Alert
          onClose={() => {
            localStorage.setItem(localStorageKey, 'true');
            setIsVisible(false);
          }}
          severity="info"
        >
          {message}
        </Alert>
      </Box>
    );
  }
  return null;
};
