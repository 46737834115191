/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  formatDollar,
  formatDollarCompact,
  formatUnit,
  formatUnitCompact,
  StyledApexChart,
} from '@groundwater/app/ui';
import { useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import {
  GrowthAccountingPeriodCustomers,
  GrowthAccountingPeriodSales,
} from '../../gql-generated';
import { useTheme } from '@mui/material';
import moment from 'moment';

export interface GrowthAccountingProps {
  formatShort: (num: number) => string;
  formatLong: (num: number) => string;
  periods: GrowthAccountingPeriodCustomers[] | GrowthAccountingPeriodSales[];
  yAxisLabel: string;
  hiddenSerieIds: GrowthAccountingSeriesIds[];
  onLegendClick: (serieId: GrowthAccountingSeriesIds) => void;
  resetKeys: string[];
}

export type GrowthAccountingSeriesIds = Exclude<
  keyof GrowthAccountingPeriodSales,
  'date' | '__typename'
>;

interface SchemaEntry {
  name?: string;
  type?: 'bar' | 'line';
  color: string;
  stroke: {
    width: number;
    curve: 'straight' | 'straight';
  };
}

export const CustomerGrowthAccountingChart: React.FC<
  Omit<GrowthAccountingProps, 'formatShort' | 'formatLong' | 'yAxisLabel'>
> = (props) => {
  return (
    <GrowthAccountingChart
      {...props}
      yAxisLabel={'Customers'}
      formatShort={formatUnitCompact}
      formatLong={formatUnit}
    />
  );
};

export const SalesGrowthAccountingChart: React.FC<
  Omit<GrowthAccountingProps, 'formatShort' | 'formatLong' | 'yAxisLabel'>
> = (props) => {
  return (
    <GrowthAccountingChart
      {...props}
      yAxisLabel={'Sales'}
      formatShort={formatDollarCompact}
      formatLong={formatDollar}
    />
  );
};

const GrowthAccountingChart: React.FC<GrowthAccountingProps> = ({
  periods,
  formatShort,
  formatLong,
  yAxisLabel,
  hiddenSerieIds,
  onLegendClick,
  resetKeys,
}) => {
  const muiTheme = useTheme();

  const schema: Map<GrowthAccountingSeriesIds, SchemaEntry> = useMemo(() => {
    const map = new Map<GrowthAccountingSeriesIds, SchemaEntry>();
    map.set('expansion', {
      name: 'Expansion',
      type: 'bar',
      color: muiTheme.palette.chart.colorGraph11,
      stroke: {
        width: 0,
        curve: 'straight',
      },
    });
    map.set('new', {
      name: 'New',
      type: 'bar',
      color: muiTheme.palette.chart.colorGraph14,
      stroke: {
        width: 0,
        curve: 'straight',
      },
    });
    map.set('resurrected', {
      name: 'Resurrected',
      type: 'bar',
      color: muiTheme.palette.chart.colorGraph18,
      stroke: {
        width: 0,
        curve: 'straight',
      },
    });
    map.set('retained', {
      name: 'Retained',
      type: 'bar',
      color: muiTheme.palette.chart.colorGraph1,
      stroke: {
        width: 0,
        curve: 'straight',
      },
    });
    map.set('churned', {
      name: 'Churned',
      type: 'bar',
      color: muiTheme.palette.error.main,
      stroke: {
        width: 0,
        curve: 'straight',
      },
    });
    map.set('contraction', {
      name: 'Contraction',
      type: 'bar',
      color: muiTheme.palette.chart.colorGraph8,
      stroke: {
        width: 0,
        curve: 'straight',
      },
    });
    return map;
  }, [
    muiTheme.palette.chart.colorGraph1,
    muiTheme.palette.chart.colorGraph11,
    muiTheme.palette.chart.colorGraph14,
    muiTheme.palette.chart.colorGraph18,
    muiTheme.palette.chart.colorGraph8,
    muiTheme.palette.error.main,
  ]);

  const dataById: Partial<Record<GrowthAccountingSeriesIds, number[]>> =
    useMemo(() => {
      const map: Partial<Record<GrowthAccountingSeriesIds, number[]>> = {};

      if (periods[0]?.__typename === 'GrowthAccountingPeriodSales') {
        map.expansion = (periods as GrowthAccountingPeriodSales[]).map(
          (period: GrowthAccountingPeriodSales) => period.expansion
        );
      }

      map.new = periods.map((period) => period.new);
      map.resurrected = periods.map((period) => period.resurrected);
      map.retained = periods.map((period) => period.retained);
      map.churned = periods.map((period) => period.churned);

      if (periods[0]?.__typename === 'GrowthAccountingPeriodSales') {
        map.contraction = (periods as GrowthAccountingPeriodSales[]).map(
          (period) => period.contraction
        );
      }

      return map;
    }, [periods]);

  const ids = useMemo(() => {
    const idsThatHaveData = new Set(
      Object.keys(dataById) as GrowthAccountingSeriesIds[]
    );
    return Array.from(schema.keys()).reduce(
      (ids: GrowthAccountingSeriesIds[], id) =>
        idsThatHaveData.has(id) ? [id, ...ids] : ids,
      []
    );
  }, [dataById, schema]);

  const series: ApexAxisChartSeries = useMemo(
    () =>
      ids.map((id) => {
        const { name, type } = schema.get(id)!;
        const data = dataById[id];
        if (!data) {
          throw new Error('data not present');
        }
        return {
          name,
          type,
          data,
        };
      }),
    [ids, schema, dataById]
  );

  const options: ApexOptions = useMemo(
    (): ApexOptions => ({
      colors: ids.map((id) => schema.get(id)!.color),
      states: {
        active: {
          filter: {
            type: 'darken',
            value: 0.88,
          },
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.88,
          },
        },
      },
      chart: {
        width: '100%',
        id: yAxisLabel + resetKeys.join(','),
        group: 'growth-accounting',
        type: 'bar',
        stacked: true,
        animations: { enabled: false },
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        events: {
          legendClick: function (
            chartContext: any,
            seriesIndex: number,
            config: any
          ) {
            const serieID = config.globals.initialSeries[seriesIndex].name;
            onLegendClick(serieID);
          },
          mounted: function (chart: any) {
            chart.windowResizeHandler();
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: ids.map((id) => schema.get(id)!.stroke.curve),
        width: ids.map((id) => schema.get(id)!.stroke.width),
      },
      legend: {
        inverseOrder: true,
      },
      grid: {
        borderColor: muiTheme.palette.grey[300],
        strokeDashArray: 3,
      },
      tooltip: {
        marker: {
          show: true,
        },
        shared: true,
        intersect: false,
        y: {
          formatter: formatLong,
        },
        inverseOrder: true,
      },
      xaxis: {
        type: 'category',
        categories: periods.map((period) => period.date),
        tickAmount: 12,
        tickPlacement: 'on',
        crosshairs: {
          show: false,
        },
        tooltip: { enabled: false },
        labels: {
          formatter: (date) => moment(date).format("MMM D, 'YY"),
        },
      },
      yaxis: {
        title: { text: yAxisLabel },
        labels: {
          formatter: formatShort,
        },
        crosshairs: { show: false },
        tooltip: { enabled: false },
      },
    }),
    [
      ids,
      yAxisLabel,
      resetKeys,
      muiTheme.palette.grey,
      formatLong,
      periods,
      formatShort,
      schema,
      onLegendClick,
    ]
  );

  return (
    <StyledApexChart
      key={resetKeys.join(',')}
      hiddenSerieId={hiddenSerieIds}
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="500px"
    />
  );
};
