import ApexCharts from 'apexcharts/dist/apexcharts.common';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit, cloneDeep, merge } from 'lodash-es';

window.ApexCharts = ApexCharts;

export class ApexChart extends Component {
  constructor(props) {
    super(props);
    if (React.createRef) {
      this.chartRef = React.createRef();
    } else {
      this.setRef = (el) => (this.chartRef = el);
    }
    this.chart = null;
  }

  render() {
    const { ...props } = this.props;
    return React.createElement('div', {
      ref: React.createRef ? this.chartRef : this.setRef,
      ...omit(props, 'hiddenSerieId'),
    });
  }

  componentDidMount() {
    const current = React.createRef ? this.chartRef.current : this.chartRef;
    this.chart = new ApexCharts(current, this.getConfig());
    this.chart.render();
    this.updateHiddenSeries();
  }

  getConfig() {
    const { type, height, width, series, options } = this.props;
    const newOptions = {
      chart: {
        type,
        height,
        width,
      },
      series,
    };
    const opt = cloneDeep(options);
    /**
     * Recursively merge existing and new chart configuration into a new destination object.
     */
    return merge({}, opt, newOptions);
  }

  isObject(item) {
    return (
      item && typeof item === 'object' && !Array.isArray(item) && item != null
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.chart) return null;
    const { options, series } = this.props;
    const prevOptions = JSON.stringify(prevProps.options);
    const prevSeries = JSON.stringify(prevProps.series);
    const currentOptions = JSON.stringify(options);
    const currentSeries = JSON.stringify(series);

    if (prevOptions !== currentOptions || prevSeries !== currentSeries) {
      // trying to update yMin/yMax/tickAmount
      // corrupts the Apex chart. Force a "remount"
      if (
        options?.yaxis?.min !== prevOptions?.yaxis?.min ||
        options?.yaxis?.max !== prevOptions?.yaxis?.max ||
        options?.yaxis?.tickAmount !== prevOptions?.yaxis?.tickAmount
      ) {
        if (this.chart && typeof this.chart.destroy === 'function')
          this.chart.destroy();
        const current = React.createRef ? this.chartRef.current : this.chartRef;
        this.chart = new ApexCharts(current, this.getConfig());
        this.chart.render();
      } else if (prevSeries === currentSeries) {
        // series is not changed,but options are changed
        this.chart.updateOptions(this.getConfig());
      } else if (prevOptions === currentOptions) {
        // options are not changed, just the series is changed
        this.chart.updateSeries(series);
      } else {
        // both might be changed
        this.chart.updateOptions(this.getConfig());
      }
    }
    this.updateHiddenSeries();
  }

  updateHiddenSeries() {
    for (const serie of this.props.series) {
      if (!serie?.name) continue;
      if (this.props.hiddenSerieId?.includes(serie.name)) {
        this.chart.hideSeries(serie.name);
      } else {
        this.chart.showSeries(serie.name);
      }
    }
  }

  componentWillUnmount() {
    if (this.chart && typeof this.chart.destroy === 'function')
      this.chart.destroy();
  }
}

ApexChart.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.any,
  height: PropTypes.any,
  series: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  hiddenSerieId: PropTypes.array,
};

ApexChart.defaultProps = {
  type: 'line',
  width: '100%',
  height: 'auto',
};
