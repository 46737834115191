import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { ReportLayout } from '../../layouts/ReportLayout';
import { Alert, Skeleton, Stack, Tooltip } from '@mui/material';
import { useVariables } from '../../hooks/useVariables';
import { COHORT_VIEW, REPORT_PAGES, ROLLUP_LABELS } from '../../constants';
import { CohortedRetentionExportAllButton } from './CohortedRetentionExportAllButton';
import { AnalysisGuideButton, ChartFrame } from '@groundwater/app/ui';
import { Suspense, useDeferredValue, useEffect } from 'react';
import { useGetCohortedRetentionQuery } from '../../../gql-generated';
import { RetentionLine } from '../../components/RetentionLine';
import { getAxisLabel, getPeriod0Label } from './utils';
import { RetentionHeatmap } from '../../components/RetentionHeatmap';
import { useNavigateSearch, useQuery } from '../../hooks';
import { formatTransactionValueTag, VariableKeys } from '../../../utils';
import { omit } from 'lodash-es';
import { numberFormat } from '@groundwater/app/ui';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Cohorted Retention';

export function CohortedRetentionPage() {
  const rawQuery = useQuery();
  const navigateSearch = useNavigateSearch();

  useEffect(() => {
    if (
      VariableKeys.cohort_index in rawQuery &&
      rawQuery[VariableKeys.cohort_index]
    ) {
      const params = {
        ...omit(rawQuery, 'cohort_index'),
        xaxis: rawQuery[VariableKeys.cohort_index]!,
        cohort_index: null,
      };
      navigateSearch({
        params,
        options: { replace: true },
      });
    }
  }, [navigateSearch, rawQuery]);

  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <ReportLayout
        page_title={page_title}
        filterSubHeaderActionContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <AnalysisGuideButton fileName="cohorted_retention.pdf" />
            <CohortedRetentionExportAllButton />
          </Stack>
        }
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <CohortedRetentionPageContent />
        </Suspense>
      </ReportLayout>
    </WithCompanyIdPrompt>
  );
}

const LoadingSkeleton = () => (
  <Skeleton
    data-testid="cohorted-retention-charts-skeleton"
    variant="rectangular"
    width="100%"
    height={370}
  />
);

const CohortedRetentionPageContent = () => {
  const variables = useVariables(REPORT_PAGES.cohorted_retention);
  const deferredVariables = useDeferredValue(variables);
  const isLoading = variables !== deferredVariables;

  const { cohort_view } = variables.request;

  const { company_id, rollup, start_date, end_date, xaxis, bucket, y_zoom } =
    deferredVariables.request;

  const response = useGetCohortedRetentionQuery({
    request: {
      company_id,
      rollup,
      start_date,
      end_date,
      xaxis,
      bucket,
    },
  });

  if (!response.data) {
    throw new Error('Dataset is not present in the response payload.');
  }

  const { cohorted_retention_v4: cohorted_retention } = response.data;

  if (cohorted_retention.__typename === 'DataError') {
    return isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Alert severity="info">{cohorted_retention.message}</Alert>
    );
  }

  const period_0_label = getPeriod0Label(
    deferredVariables.request.rollup,
    'customers'
  );

  return (
    <>
      {bucket !== null && bucket.trim() !== '' ? (
        <Alert color="warning" sx={{ my: 1 }}>
          Cohorts are filtered to members whose first transaction was in the
          range {formatTransactionValueTag(bucket)}
        </Alert>
      ) : null}
      <ChartFrame
        actionContent={
          <Tooltip title="Percentage of cohort that returned N periods after first purchase.">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        }
        chartTitle={'Customer Retention'}
        isLoading={isLoading}
      >
        {cohort_view === COHORT_VIEW.Table ? (
          <RetentionHeatmap
            period_0_label={period_0_label}
            cohorts={cohorted_retention.cohorts}
            periods={cohorted_retention.periods}
            period_0={cohorted_retention.period_0_customers.map((number) =>
              numberFormat.format(number)
            )}
            xaxis={xaxis}
            y_zoom={y_zoom.toLowerCase().trim() === 'true'}
          />
        ) : (
          <RetentionLine
            cohorts={cohorted_retention.cohorts}
            periods={cohorted_retention.periods}
            period_0={cohorted_retention.period_0_customers.map((number) =>
              numberFormat.format(number)
            )}
            xaxis={xaxis}
            yZoom={variables.request.y_zoom === 'true'}
            axisLabel={
              xaxis === 'calendar'
                ? ROLLUP_LABELS[rollup]
                : getAxisLabel(rollup)
            }
          />
        )}
      </ChartFrame>
    </>
  );
};
