import { useEffect } from 'react';
import { REPORT_PAGES } from '../constants';
import {
  GroundwaterFeature,
  useRecordFeatureUsageMutation,
} from '../../gql-generated';
import { useReportPage } from './useReportPage';
import { useCoercedQueryParams } from './useCoercedQueryParams';

const pageMapping: Record<REPORT_PAGES, GroundwaterFeature> = {
  [REPORT_PAGES.browse_businesses]: GroundwaterFeature.BrowseBusinesses,
  [REPORT_PAGES.cohorted_retention]: GroundwaterFeature.CohortedRetention,
  [REPORT_PAGES.dollar_retention]: GroundwaterFeature.DollarRetention,
  [REPORT_PAGES.growth_accounting]: GroundwaterFeature.GrowthAccounting,
  [REPORT_PAGES.ovr]: GroundwaterFeature.ObservedVsReported,
  [REPORT_PAGES.qps]: GroundwaterFeature.QuarterlyPerformanceScreening,
  [REPORT_PAGES.qtd]: GroundwaterFeature.QuarterToDate,
  [REPORT_PAGES.retention_summary]: GroundwaterFeature.RetentionSummary,
  [REPORT_PAGES.transaction_values]: GroundwaterFeature.TransactionValues,
  [REPORT_PAGES.trends]: GroundwaterFeature.Trends,
};

/**
 * Record the feature usage. This hook tracks initial page loads as well as the consequent views (i.e. page filter change)
 */
export const useRecordFeatureUsage = () => {
  const page = useReportPage();
  const pageParams = useCoercedQueryParams(page);

  if (!pageMapping[page]) {
    throw new Error('The current page is not supported.');
  }

  useEffect(() => {
    const record = async () => {
      useRecordFeatureUsageMutation.fetcher({
        params: {
          feature: pageMapping[page],
          parameters: JSON.stringify(pageParams),
        },
      })();
    };
    record();
  }, [page, pageParams]);
};
