import { useTheme } from '@mui/material';
import { OvrScalingModel } from '@groundwater/shared-ui';
import { drawCustomAnnotiations, OvrChartConfig } from '../ovrChartUtils';
import { spreadTooltip } from './SpreadTooltip';
import { StyledSpreadChart } from './Spread.styles';
import { ReportedRevenueChartProps } from '../reportedRevenue/ReportedRevenue';
import {
  extentOfDataset,
  optimize,
  formatPercent,
} from '../../../utils/chartUtils';

export enum SpreadYSeries {
  'Consensus Range Low' = 'Consensus Range Low',
  'Consensus Range High' = 'Consensus Range High',
  'Consensus Spread' = 'Consensus Spread',
  'Scaled Spread' = 'Scaled Spread',
}
export interface SpreadChartProps
  extends Omit<ReportedRevenueChartProps, 'chartTitle'> {}

export const SpreadChart: React.FC<SpreadChartProps> = ({ group, dataset }) => {
  const muiTheme = useTheme();
  const scaled = dataset.scaling_model !== OvrScalingModel.Unscaled;
  const xSeries = dataset.x_series;

  /**
   * y-series derived from the annotations because this chart is visualizing the ReportedRevenue tooltip values.
   */
  const ySeries = [
    {
      color: 'transparent',
      name: SpreadYSeries['Consensus Range Low'],
      data: dataset.annotations.consensus_range_vs_reported.map((i) => i[0]),
    },
    {
      color: String(muiTheme.palette.chart.base[7]),
      name: SpreadYSeries['Consensus Spread'],
      data: dataset.annotations.consensus_mean_vs_reported,
    },
    {
      color: 'transparent',
      name: SpreadYSeries['Consensus Range High'],
      data: dataset.annotations.consensus_range_vs_reported.map((i) => i[1]),
    },
    {
      color: String(
        scaled ? muiTheme.palette.chart.base[6] : muiTheme.palette.chart.base[1]
      ),
      name: SpreadYSeries['Scaled Spread'],
      data: dataset.annotations.scaled_sales_vs_reported,
    },
  ];

  const { minFromDataset, maxFromDataset } = extentOfDataset(ySeries);

  const yZoom = false;

  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    yZoom,
    minFromDataset,
    maxFromDataset
  );

  return (
    <StyledSpreadChart
      type="line"
      height={350}
      series={ySeries}
      options={{
        theme: {
          palette: 'palette6',
        },
        // Configure the chart lines (width, curve, etc.)
        stroke: {
          width: 2,
          curve: 'straight',
        },
        fill: {
          opacity: 1,
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
            shadeIntensity: 0,
            stops: [0, 100],
            type: 'vertical',
          },
        },
        grid: {
          borderColor: muiTheme.palette.grey[300],
          strokeDashArray: 3,
        },
        states: {
          active: {
            filter: {
              type: 'darken',
              value: 0.88,
            },
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.04,
            },
          },
        },
        tooltip: {
          custom: function (opts: any) {
            return spreadTooltip({
              ...opts,
            });
          },
          marker: {
            show: true,
          },
          x: {
            // Hide tooltip header
            show: false,
          },
          y: {
            formatter: formatPercent,
          },
        },
        markers: {
          size: 3,
          strokeWidth: 0,
          hover: {
            size: 4,
          },
        },
        chart: {
          id: 'spread',
          group,
          foreColor: muiTheme.palette.grey[500],
          animations: {
            enabled: false,
          },
          events: {
            mounted: function (chart: OvrChartConfig) {
              drawCustomAnnotiations({
                chart,
                scaled,
                lowerBoundSerieName: SpreadYSeries['Consensus Range Low'],
                upperBoundSerieName: SpreadYSeries['Consensus Range High'],
              });
            },
            updated: function (chart: OvrChartConfig) {
              drawCustomAnnotiations({
                chart,
                scaled,
                lowerBoundSerieName: SpreadYSeries['Consensus Range Low'],
                upperBoundSerieName: SpreadYSeries['Consensus Range High'],
              });
            },
          },
          // Disable toolbar functionality for trends charts
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount,

          labels: {
            formatter: formatPercent,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: xSeries,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '12',
          fontWeight: 500,
          offsetX: 5,
          labels: {
            colors: muiTheme.palette.text.primary,
          },
        },
      }}
    />
  );
};
