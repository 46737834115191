import { Button } from '@mui/material';
import { useReportPage } from '../hooks/useReportPage';
import { useFilterContext } from './FilterSubHeader/hooks/controller';

export const ApplyButton: React.FC<{}> = () => {
  const page = useReportPage();
  const { apply, canApply } = useFilterContext(page);
  return (
    <Button
      variant="contained"
      size="medium"
      color="info"
      onClick={apply}
      disabled={!canApply}
    >
      Apply
    </Button>
  );
};
