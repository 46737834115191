import { DefaultError } from '../../../index';
import { Box } from '@mui/material';

export const BusinessSearchResultsError: React.FC<{ error: unknown }> = ({
  error,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <DefaultError error={error} />
    </Box>
  );
};
