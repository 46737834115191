import {
  CircularProgressInStack,
  PageErrorBoundary,
} from '@groundwater/shared-ui';
import { PrivateLayout } from './PrivateLayout';
import { FilterSubHeader } from '../components';
import { Suspense, useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useFilterResetKeys } from '../hooks/useResetKeys';
import { useRecordFeatureUsage } from '../hooks/useRecordFeatureUsage';
import { Help } from '../components/Help/Help';
import { REPORT_PAGES } from '../constants';
import { useReportPage } from '../hooks/useReportPage';
import { OpenPanelAnnouncement } from '../components/OpenPanelAnnouncement';

interface ReportLayoutProps {
  page_title: string;
  filterSubHeaderActionContent?: React.ReactNode;
}
interface ReportLayoutDataProps {
  filterSubHeaderActionContent?: React.ReactNode;
}

const LoadingIndicator = () => <CircularProgressInStack />;

export const ReportLayout: React.FC<ReportLayoutProps> = ({
  children,
  page_title,
  filterSubHeaderActionContent,
}) => {
  return (
    <PrivateLayout page_title={page_title}>
      <ReportLayoutData
        filterSubHeaderActionContent={filterSubHeaderActionContent}
      >
        {children}
      </ReportLayoutData>
    </PrivateLayout>
  );
};

/**
 * This is split out so that if there is any error, PrivateLayout remains mounted above it
 * and it's error boundary shows its fallback. Otherwise, any error here would crash the whole page
 */
const ReportLayoutData: React.FC<ReportLayoutDataProps> = ({
  children,
  filterSubHeaderActionContent,
}) => {
  const queryClient = useQueryClient();
  const resetKeys = useFilterResetKeys();
  const page = useReportPage();

  useRecordFeatureUsage();

  // this is a hack, see https://github.com/tannerlinsley/react-query/discussions/3487
  // TLDR; force react-query to retry queries that previously resulted in an error
  // by clearing the cache. Apparently they really want to cache errors 🙄
  const onResetKeysChange = useCallback(
    () => queryClient.clear(),
    [queryClient]
  );

  return (
    /* If the filters crash, this fallback will replace both the filters and the charts */
    <PageErrorBoundary
      resetKeys={resetKeys}
      onResetKeysChange={onResetKeysChange}
    >
      <Box display="flex" alignItems="center" py={2}>
        <FilterSubHeader />
        <Help />
        {filterSubHeaderActionContent}
      </Box>

      <OpenPanelAnnouncement />

      {/* If the chart crashes, this fallback will replace the charts */}
      <PageErrorBoundary
        resetKeys={resetKeys}
        onResetKeysChange={onResetKeysChange}
      >
        {/* While the children (charts) are loading, this fallback will replace the children (charts) */}
        <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
      </PageErrorBoundary>
    </PageErrorBoundary>
  );
};
