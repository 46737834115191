import { QtdResponse } from '../../../gql-generated';
import React, { useDeferredValue } from 'react';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { QuarterToDateChart } from '@groundwater/app/ui';
import { useChartResetKeys } from '../../hooks/useResetKeys';

export const QuarterToDateChartSales: React.FC<{
  qtd: QtdResponse;
}> = ({ qtd }) => {
  const variables = useVariables(REPORT_PAGES.qtd);
  const deferredVariables = useDeferredValue(variables);
  const resetKeys = useChartResetKeys();
  const keys = resetKeys.join(',');

  return (
    <QuarterToDateChart
      key={`sales-${keys}`}
      chartType="sales"
      dataset={qtd}
      yZoom={deferredVariables.request.y_zoom === 'true'}
    />
  );
};

export const QuarterToDateChartGrowth: React.FC<{
  qtd: QtdResponse;
}> = ({ qtd }) => {
  const variables = useVariables(REPORT_PAGES.qtd);
  const deferredVariables = useDeferredValue(variables);
  const resetKeys = useChartResetKeys();
  const keys = resetKeys.join(',');

  return (
    <QuarterToDateChart
      key={`growth-${keys}`}
      chartType="growth"
      dataset={qtd}
      yZoom={deferredVariables.request.y_zoom === 'true'}
    />
  );
};
