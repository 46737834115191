import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import {
  Stack,
  Chip,
  Button,
  Autocomplete,
  Typography,
  TextField,
} from '@mui/material';
import {
  useGeoAutocompleteQuery,
  useGetGeosQuery,
} from '../../../../gql-generated';
import { GEOGRAPHICAL_AREA_LABEL } from '../../../constants';

export interface GeoFilterControlProps {
  /** Array of geo codes that are selected */
  value: string[];

  /** callback to update the selection (array of geo codes) */
  onChange: (val: string[]) => void;
}

/**
 * This is a controlled component. The parent maintains the "draft selection" state,
 * and passes it to this component as the selected "value".
 *
 * This component shows the user their current selection, and allows them to
 * search for new geos to add to the selection. Upon selecting a new geo,
 * it calls the `onChange` callback to notify the parent which owns the "draft selection" state
 */
export const GeoFilterControl: React.FC<GeoFilterControlProps> = ({
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  // Fetch the geos for the codes in the parent's "draft selection"
  const geos = useGetGeosQuery(
    { codes: value },
    {
      keepPreviousData: true,
      suspense: false,
    }
  );

  const cbsaAutocompleteResponse = useGeoAutocompleteQuery(
    { query: inputValue },
    { enabled: !isEmpty(inputValue), suspense: false }
  );

  const requiredGeoType =
    geos.data?.geographical_areas?.results[0]?.type ?? null;

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        autoComplete
        autoHighlight
        multiple
        filterSelectedOptions
        // We are constructing our custom tags below the input field,
        // because of that we don't want to prevent the default tags from rendering within the input field.
        renderTags={() => null}
        // Disable the default functionality as we are handling it ourseves below
        disableClearable
        getOptionDisabled={(option) => {
          // Disable already selected options
          if (value.find((code) => code === option.code)) {
            return true;
          }
          // Prevent selection of different Geo types
          return requiredGeoType ? requiredGeoType !== option.type : false;
        }}
        disabled={geos.isLoading}
        loading={cbsaAutocompleteResponse.isLoading}
        id="sm_cbsa_search"
        getOptionLabel={(option) => option.title ?? ''}
        options={
          cbsaAutocompleteResponse.data?.search_geographical_areas.results ?? []
        }
        isOptionEqualToValue={(option, value) => option.title === value.title}
        value={geos.data?.geographical_areas.results || []}
        onChange={(_event, newValue) => {
          onChange(newValue.map(({ code }) => code));
          // we reset the controlled input ourselves, see https://github.com/mui/material-ui/issues/35467
          setInputValue('');
        }}
        onInputChange={(event, newInputValue, reason) => {
          // ignore reset and do it ourselves, see https://github.com/mui/material-ui/issues/35467
          if (reason !== 'reset') {
            setInputValue(newInputValue);
          }
        }}
        inputValue={inputValue}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoFocus
              fullWidth
              label="Geographical area"
              placeholder="Search state or CBSA"
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Stack
                width="100%"
                direction={'row'}
                spacing={1}
                justifyContent="space-between"
              >
                <Typography>{option.title}</Typography>
                <Typography variant="caption">
                  {GEOGRAPHICAL_AREA_LABEL[option.type]}
                </Typography>
              </Stack>
            </li>
          );
        }}
      />
    );
  };

  return (
    <Stack spacing={1}>
      {renderAutocomplete()}

      <Stack direction="row" rowGap={1} spacing={1}>
        {!isEmpty(value) && (
          <Button variant="text" size="small" onClick={() => onChange([])}>
            Clear all
          </Button>
        )}
      </Stack>

      <Stack direction="row" spacing={1} rowGap={1} flexWrap="wrap">
        {geos.data?.geographical_areas.results.map((geo) => {
          return (
            <Chip
              key={geo.id}
              label={geo.title}
              onDelete={() => {
                onChange(value.filter((code) => code !== geo.code));
              }}
              size="small"
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
