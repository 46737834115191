import { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import React from 'react';

export const Collapse = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement;
    in: boolean;
    style?: React.CSSProperties;
  }
>(({ children, in: _in, style }, forwardRef) => {
  const [state, setState] = useState<
    'opened' | 'closed' | 'opening' | 'closing'
  >('closed');

  const props = useSpring({
    transform: `translateY(${_in ? '0%' : '-100%'})`,
    onRest: (a) => {
      if ((a.value as any).transform === 'translateY(-100%)') {
        setState('closed');
      } else {
        setState('opened');
      }
    },
    config: config.stiff,
  });

  if (state === 'closed' && _in) {
    setState('opening');
  } else if (state === 'opened' && !_in) {
    setState('closing');
  }

  return (
    // We'll animate the inner div by updating it's scaleX() from 0 to -100,
    // if the top edge is below the top of the screen, when the animation is done
    // the bottom edge will also be below the top of the screen and thus still visible.
    // so we wrap it in an "overflow hidden" so that the div animates away fully
    <div style={{ overflow: state === 'opened' ? 'visible' : 'hidden' }}>
      <animated.div
        style={{
          ...props,
          ...style,
          pointerEvents: _in ? 'all' : 'none',
          transformOrigin: 'top',
        }}
      >
        {/* 
        Create a new block to insulate the animation styling from affecting margin collapse of the components
        being animated

        https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
        https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Block_formatting_context
        */}
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>{state !== 'closed' && children}</div>
        </div>
      </animated.div>
    </div>
  );
});
