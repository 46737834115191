import { Stack, Typography } from '@mui/material';
import { MetadataChildFragment } from '../../../gql-generated';
import { BrandRelationship } from './BrandRelationship';

interface BusinessTypeRowProps {
  title: string;
  brands: MetadataChildFragment[];
}

export const BusinessTypeRow: React.FC<BusinessTypeRowProps> = ({
  title,
  brands,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      justifyContent="flex-start"
    >
      <Typography variant="subtitle2">{title}:&nbsp;</Typography>
      {brands.map((brand, index) => {
        return (
          <>
            <BrandRelationship relationship={brand} key={brand.brand.id} />
            {index < brands.length - 1 ? (
              <Typography variant="caption">,&nbsp;</Typography>
            ) : (
              ''
            )}
          </>
        );
      })}
    </Stack>
  );
};
