import { Alert, Grid, Skeleton, Stack } from '@mui/material';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { ReportLayout } from '../../layouts/ReportLayout';
import { OvrStatBoxes, OvrStatBoxesLoadingSkeleton } from './OvrStatBoxes';
import {
  OvrPercentObservedChartContainer,
  OvrPercentObservedChartLoadingSkeleton,
} from './OvrPercentObservedChart';
import {
  OvrReportedRevenueChart,
  OvrReportedRevenueChartLoadingSkeleton,
} from './OvrReportedRevenueChart';
import { useVariables } from '../../hooks/useVariables';
import { Suspense, useDeferredValue } from 'react';
import { REPORT_PAGES } from '../../constants';
import { OvrTargetDimension } from '@groundwater/shared-ui';
import { OvrSpreadChart } from './OvrSpreadChart';
import { OvrExportAllButton } from './OvrExportAllButton';
import { AnalysisGuideButton, ChartFrame } from '@groundwater/app/ui';
import { WithCompanyOnlyPrompt } from '../../components/WithCompanyOnlyPrompt';
import { useGetOvrQuery } from '../../../gql-generated';
import { omit } from 'lodash-es';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Observed vs. Reported';

const OvrPageLoadingSkeleton = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3 }}
    >
      <Grid item xs={12}>
        <OvrStatBoxesLoadingSkeleton />
      </Grid>
      <Grid item xs={12}>
        <OvrReportedRevenueChartLoadingSkeleton />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={370}
          data-testid="ovr-spread-chart-skeleton"
        />
      </Grid>
      <Grid item xs={12}>
        <OvrPercentObservedChartLoadingSkeleton />
      </Grid>
    </Grid>
  );
};

export const OvrPage: React.FC<{}> = () => {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <WithCompanyOnlyPrompt page_title={page_title} publicOnly>
        <ReportLayout
          page_title={page_title}
          filterSubHeaderActionContent={
            <Stack direction="row" alignItems="center" spacing={2}>
              <AnalysisGuideButton fileName="vs_reported.pdf" />
              <OvrExportAllButton />
            </Stack>
          }
        >
          <Suspense fallback={<OvrPageLoadingSkeleton />}>
            <OvrPageContent />
          </Suspense>
        </ReportLayout>
      </WithCompanyOnlyPrompt>
    </WithCompanyIdPrompt>
  );
};

const OvrPageContent = () => {
  const variables = useVariables(REPORT_PAGES.ovr);
  const deferredVariables = useDeferredValue(variables);
  const response = useGetOvrQuery(omit(deferredVariables, 'request.y_zoom'));
  const isLoading = variables !== deferredVariables;

  if (!response.data)
    throw new Error('Dataset is not present in the response payload.');

  const ovr = response.data.ovr_v2;

  if (ovr.__typename === 'DataError') {
    return isLoading ? (
      <OvrPageLoadingSkeleton />
    ) : (
      <Alert severity="info">{ovr.message}</Alert>
    );
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3 }}
    >
      <Grid item xs={12}>
        <OvrStatBoxes ovr={ovr} />
      </Grid>

      {[
        OvrTargetDimension.YoyGrowth,
        OvrTargetDimension.ObservedVolume,
      ].includes(deferredVariables.request.target_dimension) && (
        <Grid item xs={12}>
          <ChartFrame
            chartTitle={
              ovr.target_dimension === OvrTargetDimension.ObservedVolume
                ? 'Observed Sales (scaled) vs. Reported Revenue'
                : 'YoY Growth'
            }
            isLoading={isLoading}
          >
            <OvrReportedRevenueChart ovr={ovr} />
          </ChartFrame>
        </Grid>
      )}

      {deferredVariables.request.target_dimension ===
      OvrTargetDimension.YoySpread ? (
        <Grid item xs={12}>
          <ChartFrame chartTitle="YoY Spread" isLoading={isLoading}>
            <OvrSpreadChart ovr={ovr} />
          </ChartFrame>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <ChartFrame chartTitle="Percent Observed Model" isLoading={isLoading}>
          <OvrPercentObservedChartContainer />
        </ChartFrame>
      </Grid>
    </Grid>
  );
};
