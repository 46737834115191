export function sharedUtil(): string {
  return 'shared-util';
}

/** Removes non alpha numeric chars, except spaces which are to be preserved in the search index */
export function normalize_label(input: string): string {
  // if text has "foo/bar" we want to output "foo bar foobar" to allow searching both ways

  // case 1 - transforms "foo/bar" to "foobar"
  const output1 = input
    .replace(/[^a-zA-Z0-9 ]/gi, '')
    .toLowerCase()
    .split(' ')
    .map((word) => word.trim())
    .filter((word) => word !== '')
    .join(' ')
    .trim();

  // case 2 - transforms "foo/bar" to "foo bar"
  const output2 = input
    .replace(/[^a-zA-Z0-9 ]/gi, ' ')
    .toLowerCase()
    .split(' ')
    .map((word) => word.trim())
    .filter((word) => word !== '')
    .join(' ')
    .trim();

  // if case 1 and case 2 are equal, avoid doubling the storage space
  if (output1 === output2) return output1;

  return `${output1} ${output2}`;
}

export function parse_query(input: string): string {
  // This payload is passed to to_tsquery(),
  // see 12.3.2. Parsing Queries https://www.postgresql.org/docs/14/textsearch-controls.html#TEXTSEARCH-PARSING-QUERIES
  return (
    normalize_label(input)
      // Passing a " " to to_tsquery() crashes the query, this makes things sane
      .trim()
      // Each word the user entered becomes a "token" in the tsquery data structure that to_tsquery will create
      .split(' ')
      // The map strips special characters from each token [word]
      .map((word) => word.replace(/[^a-zA-Z0-9]+/g, '').trim())
      .filter((word) => word.length !== 0)
      // dollar quotes it, and appends the "prefix match" operator
      // 4.1.2.4. Dollar-Quoted String Constants, https://www.postgresql.org/docs/9.0/sql-syntax-lexical.html#SQL-SYNTAX-CONSTANTS
      // 8.11.1. tsvector [dollar quoting example] https://www.postgresql.org/docs/14/datatype-textsearch.html#DATATYPE-TSVECTOR
      // 8.11.2. tsquery [prefix matching] https://www.postgresql.org/docs/14/datatype-textsearch.html#DATATYPE-TSQUERY
      .map((word) => `$$'${word}'$$:*`)
      // If you search for "Microsoft Unattributed", it doesn't even show up in the first results if you use "OR", so this uses
      // "AND" to reduce noise, very important!
      .join(' & ')
      .toLowerCase()
  );
}

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}
