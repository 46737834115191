import { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import React from 'react';
import { MockTransition } from './Fade';

export const Grow = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement;
    in: boolean;
    style?: React.CSSProperties;
  }
>(({ children, in: _in, style }, forwardRef) => {
  const [state, setState] = useState<
    'opened' | 'closed' | 'opening' | 'closing'
  >('closed');

  const props = useSpring({
    transform: `scale(${_in ? 1 : 0}, ${_in ? 1 : 0})`,
    opacity: _in ? 1 : 0,
    onRest: (a) => {
      if ((a.value as any).opacity === 1) {
        setState('opened');
      } else if ((a.value as any).opacity === 0) {
        setState('closed');
      }
    },
    config: config.stiff,
  });

  if (state === 'closed' && _in) {
    setState('opening');
  } else if (state === 'opened' && !_in) {
    setState('closing');
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (window.Cypress !== undefined) {
    return <MockTransition in={_in}>{children}</MockTransition>;
  }

  return (
    <animated.div
      ref={forwardRef}
      style={{
        ...props,
        ...style,
        transformOrigin: 'top left',
        pointerEvents: _in ? 'all' : 'none',
      }}
    >
      {/* 
        Create a new block to insulate the animation styling from affecting margin collapse of the components
        being animated
        
        https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
        https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Block_formatting_context
        */}
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>{state !== 'closed' && children}</div>
      </div>
    </animated.div>
  );
});
