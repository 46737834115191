import {
  RetentionSummaryRollup,
  RetentionSummaryYSeries,
} from '@groundwater/shared-ui';

export const getXAxisLabel = (rollup: RetentionSummaryRollup) => {
  switch (rollup) {
    case RetentionSummaryRollup.Week:
      return 'Week';
    case RetentionSummaryRollup.Month:
      return 'Month';
    case RetentionSummaryRollup.Quarter:
      return 'Quarter';
    case RetentionSummaryRollup.Year:
      return 'Year';
    default:
      throw new Error('Chart does not support selected rollup');
  }
};

const getPeriodOverPeriodTitle = (
  rollup: RetentionSummaryRollup,
  isDollar: boolean
) => {
  const dollarString = isDollar ? 'dollar' : 'customer';
  switch (rollup) {
    case RetentionSummaryRollup.Week:
      return `Week-over-Week ${dollarString} Retention`;
    case RetentionSummaryRollup.Month:
      return `Month-over-Month ${dollarString} Retention`;
    case RetentionSummaryRollup.Quarter:
      return `Quarter-over-Quarter ${dollarString} Retention`;
    case RetentionSummaryRollup.Year:
      return `Year-over-Year ${dollarString} Retention`;
    default:
      throw new Error('Chart does not support selected rollup');
  }
};

export const getChartTitle = (id: string, rollup: RetentionSummaryRollup) => {
  switch (id) {
    case 'period_over_period_retention':
      return getPeriodOverPeriodTitle(rollup, false);
    case 'period_over_period_dollar_retention':
      return getPeriodOverPeriodTitle(rollup, true);

    default:
      throw new Error('Did not expect to get here.');
  }
};

export const getChartTooltip = (id: string) => {
  switch (id) {
    case 'period_over_period_retention':
      return 'Percentage of customers from previous period who returned in this period.';
    case 'period_over_period_dollar_retention':
      return 'Percentage of dollars from previous period who returned in this period.';

    default:
      throw new Error('Did not expect to get here.');
  }
};

export const getYSerieData = (
  id: string,
  y_series: RetentionSummaryYSeries
) => {
  switch (id) {
    case 'period_over_period_retention':
      return y_series.period_over_period_retention;
    case 'period_over_period_dollar_retention':
      return y_series.period_over_period_dollar_retention;

    default:
      throw new Error('Did not expect to get here.');
  }
};
