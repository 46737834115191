import axios from 'axios';

export const createApiClient = ({
  baseURL,
  onLogout,
}: {
  baseURL: string;
  onLogout: () => void;
}) => {
  const apiClient = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  /**
   * When making an API call it is possible the server determines we're not authenticated. In this case
   * we should not have been making an API call, so it means the local cookie is stale and needs to be deleted.
   * This callback can be supplied to run logic to cleanup the cookie, so the clients correctly reflects the server
   * (we are not authorized)
   */
  apiClient.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err: any) => {
      if (
        err?.response?.status === 401 &&
        err?.response?.config?.url !== '/login'
      ) {
        onLogout();
        return Promise.reject(err);
      }
      throw err;
    }
  );

  return apiClient;
};
