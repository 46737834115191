import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { MeQuery } from '../../gql-generated';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { AccountMenuOptions, AccountPopover } from '.';
import { MHidden } from '../@material-extend';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  // This minHeight should be kept in sync with the paddingTop values in PrivateLayout.tsx
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

interface DashboardNavbarProps {
  onOpenSidebar: () => void;
  user: NonNullable<MeQuery['me']>;
  onLogout: () => Promise<void>;
  accountPopoverMenuItems: AccountMenuOptions[];
  children?: React.ReactChild;
}

export function DashboardNavbar({
  onOpenSidebar,
  user,
  onLogout,
  accountPopoverMenuItems,
  children,
}: DashboardNavbarProps) {
  const menuItems = accountPopoverMenuItems.filter((item) => {
    return item.role.includes(user.role);
  });

  return (
    <RootStyle>
      <ToolbarStyle>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
          width="100%"
        >
          <MHidden width="lgUp">
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: 'text.primary' }}
            >
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>

          <Box sx={{ flexGrow: 1 }}>{children}</Box>

          <AccountPopover
            user={user}
            onLogout={onLogout}
            accountPopoverMenuItems={menuItems}
          />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
