import { useMemo } from 'react';
import { Variables } from '../../utils';
import { REPORT_PAGES } from '../constants';
import { useCoercedQueryParams } from './useCoercedQueryParams';

/**
 * Gets variables for the trends query for passing to react-query
 *
 * Internally it coerces the URL params, and formats them to match the
 * expected GQL input variables
 */
export function useVariables<T extends REPORT_PAGES>(
  page: T
): { request: Readonly<Variables[T]> } {
  const request = useCoercedQueryParams(page);
  return useMemo(() => Object.freeze({ request }), [request]);
}
