import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { VariableKeys } from '../../../../utils';
import { entriesFromRecord } from '../../../../utils/record-map';
import { COHORT_TYPE_LABELS, COHORT_VIEW } from '../../../constants';
import { useApplyFilters } from '../../../hooks/useApplyFilters';
import { useReportPage } from '../../../hooks/useReportPage';
import { useFilterContext } from '../../FilterSubHeader/hooks/controller';

export const CohortTypeFilter: React.FC<{}> = () => {
  const page = useReportPage();

  const { draftParams } = useFilterContext(page);
  const apply = useApplyFilters(useReportPage());

  if (!(VariableKeys.cohort_view in draftParams)) {
    throw new Error();
  }

  const selected = draftParams[VariableKeys.cohort_view];

  const onClick = (cohort_view: COHORT_VIEW) => apply({ cohort_view });

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      onChange={(_event, value: COHORT_VIEW) => onClick(value)}
      size="small"
    >
      {entriesFromRecord(COHORT_TYPE_LABELS).map(([value, label]) => (
        <ToggleButton key={value} value={value} selected={selected === value}>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
