// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography } from '@mui/material';

// components
import { RequestPasswordResetForm, useToast } from '@groundwater/shared-ui';
import { useNavigateSearch } from '../../hooks';
import {
  useRequestResetPasswordMutation,
  RequestResetPasswordMutationVariables,
} from '@groundwater/shared-ui';
import { PublicLayout } from '../../layouts/PublicLayout';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export function RequestPasswordResetPage() {
  const requestResetPassword = useRequestResetPasswordMutation();
  const { setToast } = useToast();
  const navigateSearch = useNavigateSearch();

  const showErrorToast = () => {
    setToast({
      id: 'reset_password_request_failed',
      open: true,
      severity: 'error',
      message:
        'Failed to request password reset, please try again later or contact support.',
    });
  };

  const handlePasswordReset = async (email: string) => {
    try {
      const req: RequestResetPasswordMutationVariables = {
        email,
      };
      await requestResetPassword.mutateAsync(req);
    } catch (err) {
      showErrorToast();
      throw err;
    }
    navigateSearch({
      pathname: '/request-password-reset-success',
      params: { email },
    });
  };

  return (
    <PublicLayout>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Forgot your password?
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Please enter the email address associated with your account and
                we will email you a link to reset your password.
              </Typography>
            </Stack>

            <RequestPasswordResetForm
              loginRoute="/"
              onSubmit={handlePasswordReset}
              isSubmitting={requestResetPassword.isLoading}
            />
          </ContentStyle>
        </Container>
      </RootStyle>
    </PublicLayout>
  );
}
