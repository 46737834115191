import { Box } from '@mui/material';

export const BusinessSearchResultNotFound: React.FC<{}> = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
    }}
  >
    No results found
  </Box>
);
