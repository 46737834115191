import {
  useTable,
  useSortBy,
  useFilters,
  Column,
  Renderer,
  CellProps,
} from 'react-table';
import { TableCell, TableSortLabel } from '@mui/material';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  GridCellRenderer,
  MultiGrid,
} from 'react-virtualized';
import { useCallback, useLayoutEffect, useRef } from 'react';

export interface VirtualTableProps<D extends object> {
  columns: ReadonlyArray<Column<D>>;
  data: readonly D[];
  width: number;
  height: number;
  fixedColumnCount: number;
}

export interface VirtualTableCellUserProps {
  isScrolling: boolean;
  width: number;
  row_number: number;
}

export type VirtualReactTableCell<T extends object> = Renderer<
  CellProps<T> & VirtualTableCellUserProps
>;

export const VirtualTableWithAutoSizer = <D extends object>(
  props: Omit<VirtualTableProps<D>, 'width' | 'height'>
) => {
  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <VirtualTable
            {...{
              ...props,
              // override the width and height in jest tests
              // this test code in production is a code smell
              // but should get compiled out, it minimally
              // impacts production vs test parity, assuming
              // we can manually e2e test to verify the auto sizer
              width: process.env['NODE_ENV'] === 'test' ? 500 : width,
              height: process.env['NODE_ENV'] === 'test' ? 500 : height,
            }}
          />
        );
      }}
    </AutoSizer>
  );
};

export const VirtualTable = <D extends object>({
  data,
  columns,
  width,
  height,
  fixedColumnCount,
}: VirtualTableProps<D>) => {
  const { headers, rows, prepareRow, state } = useTable<D>(
    { columns, data },
    useFilters,
    useSortBy
  );

  const grid = useRef<any>();

  const _cache = useRef(
    new CellMeasurerCache({
      defaultHeight: 44,
      fixedWidth: true,
    })
  );

  useLayoutEffect(() => {
    _cache.current.clearAll();
    grid.current.recomputeGridSize();
    grid.current.forceUpdate();
  }, [state]);

  const headerCellRenderer: GridCellRenderer = useCallback(
    ({ rowIndex, columnIndex, style, parent }) => {
      const header_key = `header-${columnIndex}`;
      const column = headers[columnIndex]!;
      return (
        <CellMeasurer
          cache={_cache.current}
          columnIndex={columnIndex}
          key={header_key}
          parent={parent}
          rowIndex={rowIndex}
        >
          <div key={header_key} style={{ ...style, display: 'flex' }}>
            <TableCell
              component="div"
              variant="head"
              style={{ height: '100%', width: '100%' }}
            >
              <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {!column.disableSortBy ? (
                  <TableSortLabel
                    active={column.isSorted}
                    // react-table has a unsorted state which is not treated here
                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                  />
                ) : null}
              </span>
              {/* Render the columns filter UI */}
              <div>{column.canFilter ? column.render('Filter') : null}</div>
            </TableCell>
          </div>
        </CellMeasurer>
      );
    },
    [headers]
  );

  const cellRenderer: GridCellRenderer = useCallback(
    (props) => {
      const { rowIndex, columnIndex, style, isScrolling, parent } = props;
      // index 0 is to react-virtualized the header,
      // but react-table treats the header as a special case
      // and not as a row of data. Check if this is index 0 and
      // render the header instead
      if (rowIndex === 0) {
        return headerCellRenderer(props);
      }

      // index 1 to react-virtualized is actually index 0 to react-table, subtract one
      const row = rows[rowIndex - 1];
      if (!row) {
        throw new Error('empty row' + (rowIndex - 1));
      }

      const key = `${row.id}-${columnIndex}`;
      prepareRow(row);
      const cell = row.cells[columnIndex]!;

      const userProps: VirtualTableCellUserProps = {
        isScrolling,
        width: style.width as number,
        row_number: rowIndex, // not subtracting one because we want this to be 1 based, which it already is due to header hack
      };
      return (
        <CellMeasurer
          cache={_cache.current}
          columnIndex={columnIndex}
          key={key}
          parent={parent}
          rowIndex={rowIndex}
        >
          <div key={key} style={style}>
            {cell.render('Cell', userProps)}
          </div>
        </CellMeasurer>
      );
    },
    [headerCellRenderer, prepareRow, rows]
  );

  const columnWidth = useCallback(
    ({ index }) => {
      return columns[index]!.width as number;
    },
    [columns]
  );

  return (
    <MultiGrid
      ref={(ref) => {
        grid.current = ref;
      }}
      deferredMeasurementCache={_cache.current}
      state={state} // force updates when filtering/sorting
      cellRenderer={cellRenderer}
      columnCount={columns.length}
      columnWidth={columnWidth}
      height={height}
      width={width}
      rowHeight={_cache.current.rowHeight}
      rowCount={rows.length + 1} // add one for header hack
      fixedRowCount={1}
      // TODO - decide if we need it? its laggy, causes double scrollbar
      fixedColumnCount={fixedColumnCount}
      enableFixedColumnScroll
    />
  );
};
