import { MenuList } from '@mui/material';
import { isEqual } from 'lodash-es';
import React, { Key } from 'react';
import { FilterMenuOption, FilterMenuOptionProps } from './FilterMenuOption';

export interface FilterButtonMenuOption<OptionValue> {
  label: string;
  value: OptionValue;
  secondaryValue?: React.ReactNode;
  disabled?: boolean;
  /**
   * Use to replace the default <FilterMenuOption /> component with the custom one.
   */
  component?: React.ComponentType<FilterMenuOptionProps<OptionValue>>;
  tooltip?: JSX.Element;
}

export interface FilterMenuListProps<OptionValue> {
  menuOptions: FilterButtonMenuOption<OptionValue>[];
  onClick: (value: OptionValue) => void;
  selected: OptionValue;
}
export const FilterMenuList = <OptionValue,>({
  menuOptions,
  onClick: handleClick,
  selected,
}: FilterMenuListProps<OptionValue>): React.ReactElement => (
  <MenuList autoFocus dense variant="selectedMenu">
    {menuOptions.map((option: FilterButtonMenuOption<OptionValue>) => {
      let ComponentToRender: React.ComponentType<
        FilterMenuOptionProps<OptionValue>
      > = FilterMenuOption;
      if (option.component !== undefined) {
        ComponentToRender = option.component;
      }
      return (
        <ComponentToRender
          key={`${option.value}-${option.label}` as Key}
          selected={isEqual(option.value, selected)}
          menuOption={option}
          onClick={handleClick}
        />
      );
    })}
  </MenuList>
);
