import { useTheme } from '@mui/material';
import { StyledReportedRevenueChart } from './ReportedRevenue.styles';
import { reportedRevenueTooltip } from './reportedRevenueTooltip';
import {
  OvrResponse,
  OvrScalingModel,
  OvrTargetDimension,
  OvrYSerieName,
} from '@groundwater/shared-ui';
import { drawCustomAnnotiations, OvrChartConfig } from '../ovrChartUtils';
import {
  extentOfDataset,
  optimize,
  formatDollarCompact,
  formatPercent,
} from '../../../utils/chartUtils';

export interface ReportedRevenueChartProps {
  group: string;
  dataset: OvrResponse;
  yZoom: boolean;
}

export const ReportedRevenueChart: React.FC<ReportedRevenueChartProps> = ({
  group,
  dataset,
  yZoom,
}) => {
  const muiTheme = useTheme();
  const spreadView = dataset.target_dimension === OvrTargetDimension.YoySpread;
  const scaled = dataset.scaling_model !== OvrScalingModel.Unscaled;
  const salesSeries = dataset.y_series[OvrYSerieName.ForecastSales].every(
    (i) => i == null
  )
    ? [OvrYSerieName.ScaledSales]
    : [OvrYSerieName.ScaledSales, OvrYSerieName.ForecastSales];

  const reportedRevenueSerie = spreadView
    ? []
    : [OvrYSerieName.ReportedRevenue];

  const seriesOrder = scaled
    ? [
        ...reportedRevenueSerie,
        ...salesSeries,
        OvrYSerieName.ConsensusLow,
        OvrYSerieName.ConsensusMean,
        OvrYSerieName.ConsensusHigh,
      ]
    : [...reportedRevenueSerie, ...salesSeries];

  const colors: { [key: string]: string } = {
    [OvrYSerieName.ReportedRevenue]: muiTheme.palette.common.black,
    [OvrYSerieName.ScaledSales]: String(
      scaled ? muiTheme.palette.chart.base[6] : muiTheme.palette.chart.base[1]
    ),
    [OvrYSerieName.ForecastSales]: String(muiTheme.palette.chart.base[20]),
    [OvrYSerieName.ConsensusMean]: String(muiTheme.palette.chart.base[7]),
    [OvrYSerieName.ConsensusLow]: 'transparent',
    [OvrYSerieName.ConsensusHigh]: 'transparent',
  };

  const yAxisValueFormat =
    dataset.target_dimension === OvrTargetDimension.ObservedVolume
      ? 'dollar'
      : 'percent';

  const ySerieFormat = (val: number | { valueOf(): number }) =>
    yAxisValueFormat === 'percent'
      ? formatPercent(val as number)
      : formatDollarCompact(val as number);

  const xSeries = dataset.x_series;

  const ySeries = seriesOrder.map((serie) => ({
    name: serie,
    color: colors[serie],
    data: dataset.y_series[serie],
  }));

  const scaled_sales_labels = {
    [OvrTargetDimension.ObservedVolume]: scaled
      ? 'Scaled Sales'
      : 'Observed sales',
    [OvrTargetDimension.YoySpread]: scaled
      ? 'Scaled Spread'
      : 'YoY sales spread',
    [OvrTargetDimension.YoyGrowth]: scaled
      ? 'Scaled Sales'
      : 'YoY sales growth',
  };

  const forecast_sales_labels = {
    [OvrTargetDimension.ObservedVolume]: scaled
      ? 'Forecast Sales'
      : 'Observed sales',
    [OvrTargetDimension.YoySpread]: scaled
      ? 'Forecast Spread'
      : 'YoY sales spread',
    [OvrTargetDimension.YoyGrowth]: scaled
      ? 'Forecast Sales'
      : 'YoY sales growth',
  };

  const reported_revenue_labels = {
    [OvrTargetDimension.ObservedVolume]: 'Reported Revenue',
    [OvrTargetDimension.YoySpread]: 'Reported',
    [OvrTargetDimension.YoyGrowth]: 'Reported',
  };

  const labels = {
    consensus_low: 'Consensus Low',
    consensus_mean: 'Mean Consensus',
    consensus_high: 'Consensus High',
  };

  const scaled_sales_label = scaled_sales_labels[dataset.target_dimension];
  const forecast_sales_label = forecast_sales_labels[dataset.target_dimension];
  const reported_revenue_label =
    reported_revenue_labels[dataset.target_dimension];

  const seriesLabels = {
    ...labels,
    [OvrYSerieName.ScaledSales]: scaled_sales_label,
    [OvrYSerieName.ReportedRevenue]: reported_revenue_label,
    [OvrYSerieName.ForecastSales]: forecast_sales_label,
  };

  const { minFromDataset, maxFromDataset } = extentOfDataset(ySeries);

  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    yZoom,
    minFromDataset,
    maxFromDataset
  );

  return (
    <StyledReportedRevenueChart
      type="line"
      height={350}
      series={ySeries}
      options={{
        theme: {
          palette: 'palette6',
        },
        // Configure the chart lines (width, curve, etc.)
        stroke: {
          width: 2,
          curve: 'straight',
          // If forecast_sales serie exists make it dashed
          ...(seriesOrder.includes(OvrYSerieName.ForecastSales) && {
            dashArray: seriesOrder.map((i) =>
              // Creates dashes in borders of Forecast Sales serie line. The number represents the distance between dashes in px (e.g. 7px)
              i === OvrYSerieName.ForecastSales ? 7 : 0
            ),
          }),
        },
        fill: {
          opacity: 1,
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
            shadeIntensity: 0,
            stops: [0, 100],
            type: 'vertical',
          },
        },
        grid: {
          borderColor: muiTheme.palette.grey[300],
          strokeDashArray: 3,
        },
        states: {
          active: {
            filter: {
              type: 'darken',
              value: 0.88,
            },
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.04,
            },
          },
        },
        tooltip: {
          custom: function (opts: any) {
            return reportedRevenueTooltip({
              ...opts,
              seriesLabels,
              scaledModel: scaled,
              annotations: dataset.annotations,
              yAxisValueFormat,
            });
          },
          marker: {
            show: true,
          },
          x: {
            // Hide tooltip header
            show: false,
          },
          y: {
            formatter: ySerieFormat,
          },
        },
        markers: {
          size: 3,
          strokeWidth: 0,
          hover: {
            size: 4,
          },
        },
        chart: {
          id: 'reported-revenue',
          group,
          foreColor: muiTheme.palette.grey[500],
          animations: {
            enabled: false,
          },
          events: {
            mounted: function (chart: OvrChartConfig) {
              drawCustomAnnotiations({
                chart,
                scaled,
                lowerBoundSerieName: OvrYSerieName.ConsensusLow,
                upperBoundSerieName: OvrYSerieName.ConsensusHigh,
              });
              (chart as any).windowResizeHandler();
            },
            updated: function (chart: OvrChartConfig) {
              drawCustomAnnotiations({
                chart,
                scaled,
                lowerBoundSerieName: OvrYSerieName.ConsensusLow,
                upperBoundSerieName: OvrYSerieName.ConsensusHigh,
              });
            },
          },
          // Disable toolbar functionality for trends charts
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount,
          labels: {
            formatter: ySerieFormat,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: xSeries,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '12',
          fontWeight: 500,
          offsetX: 5,
          formatter: function (seriesName: OvrYSerieName) {
            return seriesLabels[seriesName];
          },
          labels: {
            colors: muiTheme.palette.text.primary,
          },
        },
      }}
    />
  );
};
