import {
  FilterMenuOptionProps,
  FilterMenuOption,
} from '@groundwater/shared-ui';
import { Suspense } from 'react';
import { useCompanyOrBrands } from '../../../hooks';
import { useCompanyId } from '../../../hooks/useCompanyId';
import { GlobalRollupOption } from '../../../constants';
import { Box, Skeleton } from '@mui/material';

const FiscalQuarterOptionValue: React.FC<
  FilterMenuOptionProps<GlobalRollupOption>
> = (props) => {
  const company_id = useCompanyId();
  const [company] = useCompanyOrBrands([company_id]);

  if (company === undefined)
    throw new Error('should have suspended and not gotten here');

  // Disable Rollup.fiscal_quarter for non-public companies/brands.
  // e.g. any company/brand that does not have a ticker
  if (
    company.__typename === 'Company' &&
    company.public &&
    company.reports_quarterly
  ) {
    return <FilterMenuOption {...props} />;
  }
  return (
    <FilterMenuOption
      {...{ ...props, menuOption: { ...props.menuOption, disabled: true } }}
    />
  );
};

export const FiscalQuarterOptionValueLoader: React.FC<
  FilterMenuOptionProps<GlobalRollupOption>
> = (props) => (
  <Suspense
    fallback={
      <Box
        sx={{ width: 100, ml: 6 }}
        data-testid="fiscal-quarter-rollup-fallback"
      >
        <Skeleton />
      </Box>
    }
  >
    <FiscalQuarterOptionValue {...props} />
  </Suspense>
);
