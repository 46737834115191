import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import type { RawVariables } from './useNavigateSearch';

/**
 * Hook to retrieve the current URL search query and parse into a key value object
 * TODO - rename to useRawVariables!
 */
export function useQuery(): RawVariables {
  const { search } = useLocation();

  const parsedQuery = useMemo(
    () =>
      queryString.parse(search, {
        arrayFormat: 'bracket',
        parseNumbers: true,
      }),
    [search]
  );

  return parsedQuery;
}
