import { NavLink, useMatch } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  ListSubheader,
} from '@mui/material';
import { NestedSidebarConfig, SidebarConfig } from '.';
import React from 'react';

const ListItemStyle = styled((props: any) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'none',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface NavItemProps {
  item: SidebarConfig;
  linkComponent?: typeof NavLink;
}

function NavItem(props: NavItemProps) {
  const theme = useTheme();
  const { title, path, icon } = props.item;

  const isActive = useMatch(props.item.path);

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(
      theme.palette.primary.light,
      theme.palette.action.activeBackgroundOpacity
    ),
    pointerEvents: 'none',
    '&:before': { display: 'block' },
  };

  const LinkComponent = props.linkComponent ?? NavLink;

  return (
    <ListItemStyle
      component={LinkComponent}
      to={path}
      sx={{
        ...(isActive && activeRootStyle),
      }}
    >
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

interface NavSectionProps {
  navConfig: SidebarConfig[];
  linkComponent?: typeof NavLink;
}
interface NestedNavSectionProps {
  navConfig: NestedSidebarConfig[];
  linkComponent?: typeof NavLink;
}

export function NavSection({ navConfig, linkComponent }: NavSectionProps) {
  return (
    <Box>
      <List disablePadding>
        {navConfig.map((item: SidebarConfig) => (
          <NavItem key={item.title} item={item} linkComponent={linkComponent} />
        ))}
      </List>
    </Box>
  );
}

export function NestedNavSection({
  navConfig,
  linkComponent,
}: NestedNavSectionProps) {
  return (
    <Box>
      <List disablePadding>
        {navConfig.map((item: NestedSidebarConfig) => (
          <React.Fragment key={item.title}>
            <ListSubheader>{item.title}</ListSubheader>
            {item.children.map((child) => (
              <NavItem
                key={child.title}
                item={child}
                linkComponent={linkComponent}
              />
            ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}
