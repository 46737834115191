import { Stack } from '@mui/material';
import { BrandType, MetadataChildFragment } from '../../../gql-generated';
import { useMemo } from 'react';
import { BusinessTypeRow } from './BusinessTypeRow';

export const BusinessTypeSection: React.FC<{
  brands: MetadataChildFragment[];
}> = ({ brands }) => {
  const businessTypes = useMemo(() => {
    return brands.reduce(
      (acc, relationship) => {
        if (relationship.brand.type !== BrandType.PrimaryMerchant) {
          acc.intermediaries = [...acc.intermediaries, relationship];
        } else if (relationship.brand.type === BrandType.PrimaryMerchant) {
          acc.primaryMerchants = [...acc.primaryMerchants, relationship];
        }
        return acc;
      },
      {
        primaryMerchants: [] as MetadataChildFragment[],
        intermediaries: [] as MetadataChildFragment[],
      }
    );
  }, [brands]);

  return (
    <Stack direction="column">
      {businessTypes.primaryMerchants.length ? (
        <BusinessTypeRow
          title="Primary Brands"
          brands={businessTypes.primaryMerchants}
        />
      ) : null}
      {businessTypes.intermediaries.length ? (
        <BusinessTypeRow
          title="Intermediary Brands"
          brands={businessTypes.intermediaries}
        />
      ) : null}
    </Stack>
  );
};
