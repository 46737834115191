import { select, Selection } from 'd3-selection';
import { createWhiskers, QTDChartConfig } from './qtdChartUtils';

const removeInvisibleLegendItems = (
  chartArea: Selection<any, unknown, null, undefined>
) => {
  chartArea
    .selectAll('.apexcharts-legend-series[seriesname="revenuexestimatexlow"]')
    .remove();
  chartArea
    .selectAll('.apexcharts-legend-series[seriesname="revenuexestimatexhigh"]')
    .remove();
};

export const drawProjectedErrorAnnotation = (
  chart: QTDChartConfig,
  drawNewWhiskers: boolean,
  lastProjectedDayIndex: number
) => {
  const chartArea = select(chart.el);

  // Remove consensus_low and consensus_high legend items as these series only used to produce whiskers and are not appearing on the chart
  removeInvisibleLegendItems(chartArea);

  if (
    !chartArea.selectAll(`${chart.opts.chart.id}-ibeam-projected-error`).empty()
  )
    // Remove old instances of whiskers when chart re-renders
    chartArea
      .selectAll(`${chart.opts.chart.id}-ibeam-projected-error`)
      .remove();

  // Create consensus range indicator only for scaled models
  if (drawNewWhiskers)
    createWhiskers(chart, {
      lastProjectedDayIndex,
      lowerBoundSerieName:
        chart.opts.chart.id === 'sales'
          ? 'revenue_estimate_low'
          : 'revenue_estimate_growth_low',
      upperBoundSerieName:
        chart.opts.chart.id === 'sales'
          ? 'revenue_estimate_high'
          : 'revenue_estimate_growth_high',
    });
};
