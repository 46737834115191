import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { BrandOrCompany, Company } from '../types';
import { Icon } from '@iconify/react';
import MissingIcon from '@iconify/icons-eva/alert-circle-outline';
import React from 'react';
import { useScrollIntoView } from '../hooks/useScrollIntoView';
import { BusinessLogo } from '../../BusinessLogo';

export const BusinessSearchResultCompany: React.FC<{
  onSelect: (companyOrBrand: BrandOrCompany) => void;
  isSelected: boolean;
  index: number;
  company: Company;
  api_url: string;
}> = React.memo(({ onSelect, isSelected, index, company, api_url }) => {
  const ref = useScrollIntoView(isSelected);

  return (
    <>
      {index !== 0 ? <Divider /> : null}
      <ListItemButton
        ref={ref}
        onClick={() => onSelect(company)}
        sx={{
          pl: 2,
          backgroundColor: isSelected ? 'lightgray' : 'inherit',
        }}
        data-testid={isSelected ? 'isSelected' : ''}
      >
        <ListItemIcon
          sx={{
            minWidth: 25,
            minHeight: 25,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BusinessLogo api_url={api_url} business={company} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>{company.title}</div>
              {company.ticker && company.exchange
                ? [company.exchange, company.ticker].join(':')
                : null}
            </div>
          }
          sx={{ fontWeight: 'bold' }}
        />
      </ListItemButton>
    </>
  );
});
