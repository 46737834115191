import { DownloadAllCsvButton } from '@groundwater/app/ui';
import { useToast } from '@groundwater/shared-ui';
import { useDeferredValue, useState } from 'react';
import { useGetQpsExportAllQuery } from '../../../gql-generated';
import { triggerFileDownload } from '../../../utils';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';

export const QpsExportAllButton: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const variables = useVariables(REPORT_PAGES.qps);
  const deferredVariables = useDeferredValue(variables);

  const csvDownload = useGetQpsExportAllQuery.fetcher(deferredVariables);

  const { setToast } = useToast();

  const showToast = () => {
    setToast({
      id: `csv-download-${Math.random()}`,
      open: true,
      severity: 'error',
      message: `Failed to download CSVs.`,
    });
  };

  const handleCSVDownload = async () => {
    try {
      setIsError(false);
      setIsLoading(true);

      const blob = await csvDownload();

      triggerFileDownload(
        blob.qps_export_all.filename,
        blob.qps_export_all.data
      );
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);

      return showToast();
    }
  };

  return (
    <DownloadAllCsvButton
      isLoading={isLoading}
      isError={isError}
      tooltipTitle="Download Quarterly performance report as CSV"
      handleOnClick={handleCSVDownload}
    />
  );
};
