import { Button, ClickAwayListener, Paper, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { PopperUnstyled } from '@mui/base';
import { Collapse, MHidden } from '@groundwater/shared-ui';
import {
  types,
  useBusinessSearch,
  BusinessSearchInput,
  BusinessSearchResults,
} from '@groundwater/shared-ui';
import { environment } from '../../../../environments/environment';

export const CompareByFilterSearch: React.FC<{
  doHandleSelect: (i: types.BrandOrCompany) => void;
  initialInputValue?: string;
  hiddenIds: string[];
}> = React.forwardRef(
  ({ doHandleSelect, initialInputValue, hiddenIds }, forwardRef) => {
    // Ref is used to anchor the Popper to the bottom of the input, and for focusing the input imperatively
    const inputRef = useRef<HTMLInputElement>();

    // auto-focus the input on mount
    useEffect(() => {
      if (!inputRef.current) {
        console.warn('inputRef missing! not auto-focusing!');
        return;
      }
      inputRef.current.focus();
    });

    const {
      isSelected,
      query,
      handleSelect,
      handleKeyDown,
      searchResult,
      handleInputChange,
      isOpen,
      setIsOpen,
      clearInput,
    } = useBusinessSearch({ doHandleSelect, inputRef, hiddenIds });

    return (
      <>
        <MHidden width="mdDown" key="mdDown">
          {/* desktop version, inline w. popper */}
          <BusinessSearchInput
            {...{
              handleKeyDown,
              handleInputChange,
              inputValue: query ?? initialInputValue ?? '',
              ref: inputRef,
              clearInput,
            }}
          />
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <PopperUnstyled
              open={Boolean(inputRef.current)}
              anchorEl={inputRef.current}
              popperOptions={{ placement: 'bottom-end' }}
              style={{ zIndex: 9999, width: 500 }}
            >
              <Collapse in={isOpen}>
                <Paper elevation={4} sx={{ maxHeight: 500, overflow: 'auto' }}>
                  <BusinessSearchResults
                    query={query ?? ''}
                    searchResult={searchResult}
                    isSelected={isSelected}
                    onSelect={handleSelect}
                    api_url={environment.api_url}
                  />
                </Paper>
              </Collapse>
            </PopperUnstyled>
          </ClickAwayListener>
        </MHidden>
        <MHidden width="mdUp" key="mdUp">
          {/* Mobile version, full screen takeover */}
          <Button variant="contained" onClick={() => setIsOpen(true)}>
            Add
          </Button>
          <Collapse in={isOpen}>
            <Paper
              elevation={4}
              sx={{
                position: 'fixed',
                inset: 0,
                overflow: 'auto',
                zIndex: 9999,
                p: 4,
              }}
            >
              <Stack spacing={1}>
                <Stack direction={'row'}>
                  <BusinessSearchInput
                    {...{
                      handleKeyDown,
                      handleInputChange,
                      inputValue: query ?? initialInputValue ?? '',
                      ref: inputRef,
                      clearInput,
                    }}
                  />
                  <Button onClick={() => setIsOpen(false)}>Close</Button>
                </Stack>
                <BusinessSearchResults
                  query={query ?? ''}
                  searchResult={searchResult}
                  isSelected={isSelected}
                  onSelect={handleSelect}
                  api_url={environment.api_url}
                />
              </Stack>
            </Paper>
          </Collapse>
        </MHidden>
      </>
    );
  }
);
