import { useMemo } from 'react';
import { useQuery } from '.';
import { validateURLQuery, Variables } from '../../utils';
import { REPORT_PAGES } from '../constants';

/**
 * Gets coerced version of the params from the current URL
 * See coerceURLQuery() for docs on what "coercing" means
 */
// TODO - rename to applied params
export const useCoercedQueryParams = <T extends REPORT_PAGES>(
  page: T
): Variables[T] => {
  const queryParams = useQuery();
  const validQueryParams = useMemo(
    () => validateURLQuery(page, queryParams),
    [page, queryParams]
  );
  return validQueryParams;
};
