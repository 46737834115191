import { yupResolver } from '@hookform/resolvers/yup';
import type { AnyObjectSchema } from 'yup';
import {
  DefaultValues,
  FieldValues,
  SubmitHandler,
  useForm as _useForm,
  UseFormProps,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';
import type { FormEventHandler } from 'react';

/** This describes the props argument you can pass into useForm */
interface OurProps<Fields, ValidationSchema> extends UseFormProps<Fields> {
  validationSchema?: ValidationSchema;
  defaultValues: DefaultValues<Fields>;
  onSubmit: SubmitHandler<Fields>;
}

/**
 * This describes the type of the `formProps` that is returned by the hook
 * useful for spreading into the <form> element
 */
interface FormProps {
  autoComplete: 'off';
  noValidate: true;
  onSubmit: FormEventHandler;
}

/** Our own useForm wrapper/abstraction to house any default values and wrapper logic. */
export const useForm =
  // when consuming, these type params should be inferred automatically
  <Fields extends FieldValues, ValidationSchema extends AnyObjectSchema>(
    ourProps: OurProps<Fields, ValidationSchema>
  ): UseFormReturn<Fields> & {
    formProps: FormProps;
  } => {
    // call react-hook-form itself
    const props = _useForm<Fields>({
      ...ourProps,
      // default to "all" but allow overriding
      mode: ourProps.mode ?? 'all',
      // do the wrapping here in the shared ui so we don't repeat it
      resolver: ourProps.validationSchema
        ? yupResolver(ourProps.validationSchema)
        : undefined,
    });

    // append the prop(s) for material UI's `FormControl`  such as the error/helper message
    const register: UseFormRegister<Fields> = (field) => ({
      ...props.register(field),
      helperText: props.formState.errors[field]?.message,
    });

    // return the "enhanced" props that a user of @groundwater/shared-ui expects when calling useForm wrapper
    return {
      ...props,
      register,
      formProps: {
        autoComplete: 'off',
        noValidate: true,
        // Allows passing in `onSubmit` to avoid manually wiring submit handlers to the <form> element
        onSubmit: props.handleSubmit(ourProps.onSubmit),
      },
    };
  };
