import { BreakoutBy } from '../../gql-generated';
import { VariableKeys, VariablesUnion } from '../reportPageConfigs';

/** @returns boolean is is a conflict? */
export const geo_rule = (params: VariablesUnion) => {
  if (!(VariableKeys.compare_by_company_ids in params)) return false;
  if (!(VariableKeys.breakout_by in params)) return false;
  if (!(VariableKeys.geos in params)) return false;
  const { compare_by_company_ids, breakout_by, geos } = params;
  return !!(
    compare_by_company_ids &&
    compare_by_company_ids.length > 0 &&
    breakout_by === BreakoutBy.Geo &&
    geos &&
    geos.length > 1
  );
};

/** @returns boolean is a conflict? */
export const default_rule = (params: VariablesUnion) => {
  if (!(VariableKeys.compare_by_company_ids in params)) return false;
  if (!(VariableKeys.breakout_by in params)) return false;
  const { compare_by_company_ids, breakout_by } = params;
  return (
    !!compare_by_company_ids &&
    compare_by_company_ids.length > 0 &&
    ![BreakoutBy.None, BreakoutBy.Geo].includes(breakout_by)
  );
};

/** @returns boolean is a conflict? */
export const breakout_by_conflicts_compare_to = (params: VariablesUnion) => {
  return geo_rule(params) || default_rule(params);
};
