import {
  Icon,
  Stack,
  Typography,
  TypographyTypeMap,
  useTheme,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { percent } from '../pages/BrowseBusinessesPage/formatters';

export const TypographyPercent: React.FC<
  TypographyTypeMap['props'] & { value: number }
> = (props) => {
  const theme = useTheme();
  const color =
    props.value > 0 ? theme.palette.success.main : theme.palette.error.main;
  return (
    <Typography sx={{ color }} component="span" {...props}>
      <Stack direction="row" alignItems="center">
        <Icon
          sx={{
            color,
          }}
          component={props.value > 0 ? ArrowUpwardIcon : ArrowDownwardIcon}
        />
        <span>{percent.format(props.value)}</span>
      </Stack>
    </Typography>
  );
};
