import moment from 'moment';
import { useTheme } from '@mui/material';
import { RetentionSummaryYSerie } from '@groundwater/shared-ui';
import { extentOfDataset, optimize } from '../../utils/chartUtils';
import { StyledApexChart } from '../../components/StyledApexChart';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

interface RetentionSummaryChartProps {
  data: {
    x: string[];
    y: RetentionSummaryYSerie[];
  };
  id: string;
  group: string;
  xAxisTitle: string;
  yZoom: boolean;
}
export const RetentionSummaryChart: React.FC<RetentionSummaryChartProps> = ({
  id,
  group,
  data,
  xAxisTitle,
  yZoom,
}) => {
  const { minFromDataset, maxFromDataset } = extentOfDataset(data.y);
  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    yZoom,
    minFromDataset,
    maxFromDataset
  );

  const muiTheme = useTheme();
  const chartConfig = {
    colors: muiTheme.palette.chart.base,
    fill: {
      opacity: 1,
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0,
        shadeIntensity: 0,
        stops: [0, 100],
        type: 'vertical',
      },
    },
    grid: {
      borderColor: muiTheme.palette.grey[300],
      strokeDashArray: 3,
    },
    states: {
      active: {
        filter: {
          type: 'darken',
          value: 0.88,
        },
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04,
        },
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        // Hide tooltip header
        show: false,
        formatter: (
          val: number,
          { dataPointIndex }: { dataPointIndex: number }
        ): string => moment(data.x[dataPointIndex]).format('MMM D, yyyy'),
      },
      y: {
        formatter: formatter.format,
      },
    },
    chart: {
      id,
      group,
      foreColor: muiTheme.palette.grey[500],
      events: {
        legendClick: function (
          chartContext: any,
          seriesIndex: number,
          config: any
        ) {
          const serieID = config.globals.initialSeries[seriesIndex].name;
          const syncedCharts = chartContext.getSyncedCharts();
          syncedCharts.forEach(
            (chart: { toggleSeries: (arg0: unknown) => void }) => {
              chart.toggleSeries(serieID);
            }
          );
        },
      },
      // Disable toolbar functionality
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    markers: {
      size: 2.5,
      strokeWidth: 0,
      hover: {
        size: 4,
      },
    },
    // Configure the chart lines (width, curve, etc.)
    stroke: {
      width: 2,
      curve: 'straight',
    },
    yaxis: {
      min: yMin,
      max: yMax,
      tickAmount,
      labels: {
        formatter: formatter.format,
      },
    },
    xaxis: {
      type: 'category',
      categories: data.x,
      title: {
        text: xAxisTitle,
      },
      tickAmount: 6,
      labels: {
        rotate: 0,
        hideOverlappingLabels: true,
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      floating: false,
      fontSize: '13',
      fontWeight: 500,
      offsetX: 5,
      onItemClick: {
        // disable native legend click event as we are triggering it manually above
        // https://github.com/secondmeasure/groundwater/blob/7cec3508a6ee32631b47f9e8466c391f189283d8/libs/app/ui/src/lib/pages/TrendsPresentation.tsx#L165
        toggleDataSeries: false,
      },
    },
    responsive: [
      {
        breakpoint: 675,
        options: {
          xaxis: {
            tickAmount: 4,
          },
        },
      },
    ],
  };
  return (
    <StyledApexChart
      type="line"
      height={350}
      series={data.y}
      options={chartConfig}
    />
  );
};
