import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  DefaultErrorBoundary,
  DefaultErrorMessage,
  ErrorBoundaryProps,
} from '.';
import { ErrorBoundaryIcon } from '../../../assets';

const ErrorContent: React.FC<{
  error: unknown;
}> = ({ error }) => {
  let message: string = (error as any)?.message;
  if ((error as any).response?.data?.errors[0]?.message) {
    message = (error as any).response.data.errors[0].message;
  }
  return (
    <Stack
      justifyContent="center"
      alignContent="center"
      minHeight="100%"
      px={5}
    >
      <Stack spacing={3} alignItems="center">
        <Box component={ErrorBoundaryIcon} />
        {message ? (
          <Typography variant="body1">{message}</Typography>
        ) : (
          <DefaultErrorMessage />
        )}
        <Stack direction="row" spacing={4}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.back()}
          >
            Go Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.location.replace('/')}
          >
            Go to Home
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

/**
 * This error boundary can be used as a fallback UI to replace the content of the top level page within the layout
 */
export const PageErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  ...props
}) => {
  return (
    <DefaultErrorBoundary
      fallbackRender={({ error }) => <ErrorContent error={error} />}
      {...props}
    >
      {children}
    </DefaultErrorBoundary>
  );
};
