import { format } from 'd3-format';

export type RawBuckets = string | null;
export type ParsedBucket = string | null;
export type ParsedBuckets = ParsedBucket[];

// Single transaction value (e.g. 100.00)
export const simpleNumberPattern = '([0-9]+([.][0-9]+)?)';

// Single transaction value with "+" (all above) (e.g. 100.00+)
export const plusPattern = `^${simpleNumberPattern}\\+$`;

// Transaction value range (100.00-200.00)
export const rangePattern = `^${simpleNumberPattern}-${simpleNumberPattern}$`;

export const validationRegex = new RegExp(
  [`^${simpleNumberPattern}$`, plusPattern, rangePattern].join('|')
);

// Validate if the selected range is correct (lhs must be smaller than rhs)
// Will return true for any non-range value
export const validateRange = (value: string) => {
  const matches = new RegExp(rangePattern).exec(value);

  if (!matches || matches.length !== 5) return true;
  if (matches[1] === undefined || matches[3] === undefined) return true;

  const lhs = parseFloat(matches[1]);
  const rhs = parseFloat(matches[3]);

  // The left-hand side value can not be larger or equal to the right-hand side value
  if (lhs >= rhs) return false;

  return true;
};

export const normalizeBucketString = (tags: string[]) => {
  const transactionRangeRegex =
    /^(([0-9]+)(\.(\d{1,2}))?)-(([0-9]+)(\.(\d{1,2}))?)$/;

  const singleDigitRegex = /^([0-9]+)(\.(\d{1,2}))?\+$/;

  // Iterates over the selected transaction values and outputs it as a string in the desired format
  const normalizedString = tags
    .map((t) => {
      // Matches decimal transaction range values.
      // If one of the sides is float (e.g. 1-1.55) it converts it to decimal (e.g. 1.00-1.55)
      if (transactionRangeRegex.test(t)) {
        const matches = t.match(transactionRangeRegex) as RegExpMatchArray;

        let lhs = matches[2];
        const lhsDec = matches[4];

        let rhs = matches[6];
        const rhsDec = matches[8];

        if (!lhsDec) lhs = `${lhs}.00`;
        else if (lhsDec.length === 1) lhs = `${lhs}.${lhsDec}0`;
        else lhs = `${lhs}.${lhsDec}`;

        if (!rhsDec) rhs = `${rhs}.00`;
        else if (rhsDec.length === 1) rhs = `${rhs}.${rhsDec}0`;
        else rhs = `${rhs}.${rhsDec}`;
        return [lhs, rhs].join('-');
      }
      // Matches single decimal values with "+"
      // It makes sure that the output value always has two decimals (.00).
      // For the input 1.0+ the output will be 1.00+
      else if (singleDigitRegex.test(t)) {
        const matches = t.match(singleDigitRegex) as RegExpMatchArray;
        let oneSidedValue = matches[1];
        const oneSidedDecimal = matches[3];

        if (!oneSidedDecimal) oneSidedValue = `${oneSidedValue}.00`;
        else if (oneSidedDecimal.length === 1)
          oneSidedValue = `${oneSidedValue}.${oneSidedDecimal}0`;
        else oneSidedValue = `${oneSidedValue}.${oneSidedDecimal}`;
        return `${oneSidedValue}+`;
      }
      // Whole values
      else {
        return t;
      }
    })
    .join(',');

  return normalizedString;
};

export const formatTransactionValueTag = (bucket: ParsedBucket): string => {
  if (bucket === null || bucket === '') {
    return '';
  }

  const formatString = '$,.2f';

  const formatter = format(formatString);
  // Range value (e.g. 23-24)
  if (new RegExp(rangePattern).test(bucket)) {
    const parts = bucket.split('-');

    if (parts[0] === undefined || parts[1] === undefined) return '';

    return `${formatter(parseFloat(parts[0]))} - ${formatter(
      parseFloat(parts[1])
    )}`;
  }

  // 23+
  if (bucket.indexOf('+') > -1)
    return `${formatter(parseFloat(bucket.slice(0, -1)))}+`;

  // 23
  return formatter(parseFloat(bucket));
};

export const parseBuckets = (buckets: RawBuckets): ParsedBuckets =>
  null === buckets ? [] : buckets.split(',');
