import { Icon } from '@iconify/react';
import MissingIcon from '@iconify/icons-eva/alert-circle-outline';
import { types } from '../index';
import { Box } from '@mui/material';

export const BusinessLogo: React.FC<{
  business: Pick<types.BrandOrCompany, 'logo'>;
  width?: number;
  height?: number;
  api_url: string;
}> = ({ business, width, height, api_url }) => (
  /**
   * By wrapping it in a box we simply ensure it always has the correct width and height since we
   * cannot rely upon the icon component to render at the correct size when it first mounts
   *
   * Otherwise, it mounts initially with
   * 0px width / height, interfering with measurements made in JavaScript
   * such as when the logo is rendered inside of the VirtualTable, which causes text to overflow.
   */
  <Box width={width ?? 25} height={height ?? 25}>
    {business.logo ? (
      <img
        src={`${api_url}/logo?md5=${business.logo}`}
        style={{ maxWidth: width ?? 25, maxHeight: height ?? 25 }}
        alt="business logo"
      />
    ) : (
      <Icon icon={MissingIcon} width={width ?? 25} height={height ?? 25} />
    )}
  </Box>
);
