import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

// Teal palette
const colorBrandTeal = '#26b6c0';
const colorBrandTeal50 = '#E1F7F9';
const colorBrandTeal100 = '#AFEFF4';
const colorBrandTeal200 = '#78E7EF';
const colorBrandTeal300 = '#2CDCE9';
const colorBrandTeal400 = '#29d0de';
const colorBrandTeal500 = '#28c5d0';
const colorBrandTeal600 = '#26b6c0';
const colorBrandTeal700 = '#00a7b3';
const colorBrandTeal800 = '#007F88';
const colorBrandTeal900 = '#006066';

// Fuchsia palette
const colorBrandFuchsia50 = '#f9e4ef';
const colorBrandFuchsia100 = '#f1bad7';
const colorBrandFuchsia200 = '#e98dbc';
const colorBrandFuchsia300 = '#e460a1';
const colorBrandFuchsia400 = '#E03C8A';
const colorBrandFuchsia500 = '#de0d75';
const colorBrandFuchsia600 = '#cd0f71';
const colorBrandFuchsia700 = '#b7116a';
const colorBrandFuchsia800 = '#a11164';
const colorBrandFuchsia900 = '#7b0f58';

// Navy palette
const colorBrandNavy50 = '#E6EBFA';
const colorBrandNavy100 = '#bcc9dd';
const colorBrandNavy200 = '#9cabc5';
const colorBrandNavy300 = '#788aa8';
const colorBrandNavy400 = '#5f7293';
const colorBrandNavy500 = '#455c7e';
const colorBrandNavy600 = '#384f6e';
const colorBrandNavy700 = '#293d59';
const colorBrandNavy800 = '#1b2c44';
const colorBrandNavy900 = '#08192d';

// Neutral palette
const colorBrandNeutral50 = '#FAFAFA';
const colorBrandNeutral100 = '#F8F8F8';
const colorBrandNeutral200 = '#F3F3F3';
const colorBrandNeutral300 = '#EEEEEE';
const colorBrandNeutral400 = '#CFCFCF';
const colorBrandNeutral500 = '#B2B2B2';
const colorBrandNeutral600 = '#888888';
const colorBrandNeutral700 = '#737373';
const colorBrandNeutral800 = '#535353';
const colorBrandNeutral900 = '#303030';
const colorNeutralLighter = colorBrandNeutral200;

// Brand & neutral colors
// https://zeroheight.com/3c3eb186d/p/814974-colors/t/07366d
const colorBrandFuchsia = colorBrandFuchsia400;
const colorBrandNavy = colorBrandNavy900;
const colorBrandDarken = colorBrandTeal800;
const colorBrandOnDark = colorBrandTeal600;
const colorBrandOnLight = colorBrandTeal300;
const colorBlack = '#000000';
const colorNeutralDarker = colorBrandNeutral900;
const colorNeutralDark = colorBrandNeutral700;
const colorNeutralLight = colorBrandNeutral400;
const colorWhite = '#ffffff';

const PRIMARY = {
  lighter: colorBrandNavy100,
  light: colorBrandNavy300,
  main: colorBrandNavy500,
  dark: colorBrandNavy900,
  contrastText: '#fff',
};
const SECONDARY = {
  lighter: colorBrandNeutral100,
  light: colorBrandNeutral300,
  main: colorBrandNeutral500,
  dark: colorBrandNeutral700,
  darker: colorBrandNeutral900,
  contrastText: '#fff',
};
const INFO = {
  lighter: colorBrandTeal100,
  light: colorBrandTeal300,
  main: colorBrandTeal500,
  dark: colorBrandTeal700,
  darker: colorBrandTeal900,
  contrastText: '#fff',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const BASE_CHART_COLORS = {
  colorGraph1: '#396AB1',
  colorGraph2: '#00AB55',
  colorGraph3: '#FFE700',
  colorGraph4: '#2D99FF',
  colorGraph5: '#826AF9',
  colorGraph6: '#2CD9C5',
  colorGraph7: '#FF6C40',
  colorGraph8: '#B72136',
  colorGraph9: '#A6CEE3',
  colorGraph10: '#FDC086',
  colorGraph11: '#7FC97F',
  colorGraph12: '#535154',
  colorGraph13: '#CCCCCC',
  colorGraph14: '#BEAED4',
  colorGraph15: '#FB9A99',
  colorGraph16: '#E5D8BD',
  colorGraph17: '#A65628',
  colorGraph18: '#FFD92F',
  colorGraph19: '#CCEBC5',
  colorGraph20: '#FFFF99',
  colorGraph21: colorBrandFuchsia400,
  colorGraph22: colorBrandFuchsia900,
};

const CHART_COLORS = {
  base: Object.values(BASE_CHART_COLORS),
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
  ...BASE_CHART_COLORS,
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: GREY[200],
    dark: colorBrandNavy900,
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    activeBackgroundOpacity: 0.25,
    disabledOpacity: 0.48,
  },
};

export default palette;
