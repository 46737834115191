import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography } from '@mui/material';
// utils
import { MeQuery } from '../../gql-generated';
// components
import { Logo, Scrollbar } from './';
import { MHidden } from '../@material-extend';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

export interface SidebarConfig {
  title: string;
  path: string;
  icon?: JSX.Element;
}

export interface NestedSidebarConfig {
  title: string;
  children: SidebarConfig[];
}

interface DashboardSidebarProps {
  isOpenSidebar?: boolean;
  onCloseSidebar?: () => void;
  navSection: React.ReactElement | null;
  user: NonNullable<MeQuery['me']>;
  logoVariation: 'onDark' | 'onLight';
}

export function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  navSection,
  user,
  logoVariation,
}: DashboardSidebarProps) {
  const renderContent = (
    <>
      <Box>
        <Box sx={{ px: 2.5, py: 3 }}>
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo variation={logoVariation} />
          </Box>
        </Box>

        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {user.role}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box>
      </Box>
      <Scrollbar
        sx={{
          height: '100%',
          overflowY: 'auto',
          '& .simplebar-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {navSection}
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, overflowY: 'unset' },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              overflowY: 'unset',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
