import { VirtualTableWithAutoSizer } from '@groundwater/app/ui';
import { Variables } from '../../../utils/reportPageConfigs';
import { useMemo } from 'react';
import { REPORT_PAGES } from '../../constants';
import { Column } from '../../react-table-column-types';
import { makeColumns, Node } from './columns';

export const QpsTable: React.FC<{
  deferredVariables: { request: Variables[REPORT_PAGES.qps] };
  nodes: Node[];
}> = ({ deferredVariables, nodes }) => {
  const columns: Column<Node>[] = useMemo(() => {
    return makeColumns(deferredVariables.request.selected_quarter);
  }, [deferredVariables.request.selected_quarter]);

  return (
    <VirtualTableWithAutoSizer
      columns={columns}
      data={nodes}
      fixedColumnCount={1}
    />
  );
};
