import { ASSETS_BASE_URL } from '../../constants';

interface HelpSectionContent {
  id: string;
  title: string;
  path: string;
}

interface HelpSection {
  sectionId: string;
  sectionTitle: string;
  content: HelpSectionContent[];
}

export const helpContentConfig: HelpSection[] = [
  {
    sectionId: 'Help',
    sectionTitle: 'Help',
    content: [
      {
        id: 'data-dictionary',
        title: 'Data Dictionary',
        path: `${ASSETS_BASE_URL}/data_dictionary.pdf`,
      },
      {
        id: 'data-feed-schema',
        title: 'Data Feed Schemas',
        path: `${ASSETS_BASE_URL}/data_feed_schema.pdf`,
      },
      {
        id: 'analysis-guides',
        title: 'Analysis Guides',
        path: `${ASSETS_BASE_URL}/groundwater_analysis_guides.pdf`,
      },
      {
        id: 'faq',
        title: 'FAQ',
        path: `${ASSETS_BASE_URL}/bsm_faq.pdf`,
      },
    ],
  },
];
