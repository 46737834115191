import { flattenDeep } from 'lodash-es';
import { useDeferredValue } from 'react';
import { VariableKeys } from '../../utils/reportPageConfigs';
import { useReportPage } from './useReportPage';
import { useVariables } from './useVariables';
import { omit } from 'lodash-es';

// TODO - rename useDeferrefResetKeys, delegate to useResetKeys
export const useChartResetKeys = (): string[] => {
  const variables = useVariables(useReportPage());
  const deferredRequest = omit(
    useDeferredValue(variables).request,
    VariableKeys.y_zoom
  );
  return flattenDeep(Object.values(deferredRequest)).map(
    (value) => value ?? ''
  );
};

// TODO - rename useResetKeys
export const useFilterResetKeys = (): string[] => {
  const variables = useVariables(useReportPage());
  return flattenDeep(Object.values(variables.request)).map(
    (value) => value ?? ''
  );
};
