import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import { shape } from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: typeof customShadows;
    shape: typeof shape;
    palette: typeof palette;
  }
  interface ThemeOptions {
    customShadows?: typeof customShadows;
    shape?: typeof shape;
    // palette?: typeof palette; // TODO - doesn't work...
  }
}

const customTheme = {
  palette,
  shape,
  typography,
  shadows,
  customShadows,
} as const;

export const ThemeConfig: React.FC = ({ children }) => {
  const themeOptions = useMemo(() => customTheme, []);

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export * from './globalStyles';
