import {
  QpsNodeLastReportedFragment,
  QpsNodeUnreportedOrCurrent,
  QpsNodeUnreportedOrCurrentFragment,
  QpsSelectedQuarter,
} from '../../../gql-generated';
import { Column } from '../../react-table-column-types';
import { company_title_column } from '../../components/Cells/BusinessTitleCell';
import { currencyColumn } from '../../components/Cells/CurrencyCell';
import { percentColumn } from '../../components/Cells/PercentCell';
import { percentChangeColumn } from '../../components/Cells/PercentChangeCell';
import { r2Column } from '../../components/Cells/R2Cell';
import { stringColumn } from '../../components/Cells/StringCell';
import { tickerColumn } from '../../components/Cells/TickerCell';
import moment from 'moment';
import { dateColumn } from '../../components/Cells/DateCell';
import { QPS_SCALING_MODEL_LABELS } from '../../constants';
import { REPORT_PAGES } from '../../constants';
import { assertUnreachable } from '@groundwater/shared-util';

export type Node =
  | QpsNodeUnreportedOrCurrentFragment
  | QpsNodeLastReportedFragment;

const scaling_model: Column<Node> = {
  ...stringColumn(),
  width: 200,
  id: 'scaling_model',
  accessor: (row) => QPS_SCALING_MODEL_LABELS[row.scaling_model],
  Header: 'Scaling Model',
};

const modeled_percent_observed: Column<Node> = {
  ...percentColumn(),
  width: 200,
  accessor: 'modeled_percent_observed',
  Header: '% Observed',
};

const fiscal_quarter: Column<Node> = {
  ...stringColumn(),
  width: 200,
  id: 'fiscal_quarter',
  Header: 'Fiscal Quarter',
  accessor: (row) =>
    `FY${row.fiscal_quarter.fiscal_year} Q${row.fiscal_quarter.fiscal_quarter}`,
  // ignoring desc because although it exists in the types
  // and is documented here https://react-table.tanstack.com/docs/api/useSortBy#column-options
  // I noticed it is not actually used here https://github.com/TanStack/react-table/blob/v7.7.0/src/plugin-hooks/useSortBy.js#L385
  // it is passed in, but the comment clearly says "This function should always return in ascending order".
  // this seems to be related to sorting being able to chain (sort by multiple fields)
  sortType: (rowA, rowB, _columnId, _desc) => {
    const a = rowA.original.fiscal_quarter;
    const b = rowB.original.fiscal_quarter;
    if (a.fiscal_year === b.fiscal_year) {
      return a.fiscal_quarter - b.fiscal_quarter;
    }

    return a.fiscal_year - b.fiscal_year;
  },
};

const quarter_end_date: Column<Node> = {
  ...dateColumn<Node>(),
  width: 200,
  id: 'quarter_end_date' as const,
  Header: 'Quarter End Date',
  accessor: (row) => moment(row.fiscal_quarter.end_date).format('YYYY-MM-DD'),
};

const percent_completed: Column<Node> = {
  ...percentColumn(),
  width: 200,
  accessor: 'percent_completed',
  Header: '% Completed',
};

const modeled_quarter_end_sales: Column<Node> = {
  ...currencyColumn(),
  width: 200,
  accessor: 'modeled_quarter_end_sales',
  Header: 'Modeled Quarter End Sales',
};

const modeled_quarter_end_yoy: Column<Node> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'modeled_quarter_end_yoy',
  Header: 'Modeled Quarter End YoY',
};

const mean_consensus_usd: Column<Node> = {
  ...currencyColumn(),
  width: 200,
  accessor: 'mean_consensus_usd',
  Header: 'Mean Consensus USD',
};

const mean_consensus_yoy: Column<Node> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'mean_consensus_yoy',
  Header: 'Mean Consensus YoY',
};

const min_consensus_yoy: Column<Node> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'min_consensus_yoy',
  Header: 'Min Consensus YoY',
};

const max_consensus_yoy: Column<Node> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'max_consensus_yoy',
  Header: 'Max Consensus YoY',
};

const modeled_yoy_mean_consensus_yoy: Column<QpsNodeUnreportedOrCurrent> = {
  ...percentChangeColumn(),
  width: 270,
  accessor: 'modeled_yoy_mean_consensus_yoy',
  Header: 'Modeled YoY less Mean Consensus YoY',
};

const in_consensus_range: Column<Node> = {
  ...stringColumn(),
  width: 200,
  id: 'in_consensus_range',
  accessor: (row) => (row.in_consensus_range ? 'True' : 'False'),
  Header: 'In Consensus Range',
};

const reported_revenue: Column<QpsNodeLastReportedFragment> = {
  ...currencyColumn(),
  width: 200,
  accessor: 'reported_revenue',
  Header: 'Reported Revenue',
};
const reported_yoy: Column<QpsNodeLastReportedFragment> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'reported_yoy',
  Header: 'Reported YoY',
};
const reported_yoy_mean_consensus_yoy: Column<QpsNodeLastReportedFragment> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'reported_yoy_mean_consensus_yoy',
  Header: 'Reported YoY less Mean Consensus YoY',
};
const reported_yoy_modeled_yoy: Column<QpsNodeLastReportedFragment> = {
  ...percentChangeColumn(),
  width: 200,
  accessor: 'reported_yoy_modeled_yoy',
  Header: 'Reported YoY less Modeled YoY',
};
const reported_yoy_modeled_yoy_absolute_value: Column<QpsNodeLastReportedFragment> =
  {
    ...percentColumn(),
    width: 200,
    accessor: 'reported_yoy_modeled_yoy_absolute_value',
    Header: 'Reported YoY less Modeled YoY Absolute Value',
  };

function widen(
  a: Column<QpsNodeUnreportedOrCurrent> | Column<QpsNodeLastReportedFragment>
): Column<Node> {
  return a as Column<Node>;
}

export const makeColumns = (
  selected_quarter: QpsSelectedQuarter
): Column<Node>[] => {
  const columns: Column<Node>[] = [
    company_title_column({ reportPage: REPORT_PAGES.ovr }),
    tickerColumn(),
    scaling_model,
    r2Column(),
    modeled_percent_observed,
    fiscal_quarter,
    quarter_end_date,
    percent_completed,
    modeled_quarter_end_sales,
    modeled_quarter_end_yoy,
    mean_consensus_usd,
    mean_consensus_yoy,
    min_consensus_yoy,
    max_consensus_yoy,
    in_consensus_range,
  ];
  switch (selected_quarter) {
    case QpsSelectedQuarter.UnreportedCompletedQuarter:
    case QpsSelectedQuarter.CurrentQuarter:
      columns.push(widen(modeled_yoy_mean_consensus_yoy));
      break;
    case QpsSelectedQuarter.LastReportedQuarter:
      columns.push(
        widen(reported_revenue),
        widen(reported_yoy),
        widen(reported_yoy_mean_consensus_yoy),
        widen(reported_yoy_modeled_yoy),
        widen(reported_yoy_modeled_yoy_absolute_value)
      );
      break;
    default:
      assertUnreachable(selected_quarter);
  }
  return columns;
};
