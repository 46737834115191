import { QtdResponse, QtdScalingModel } from '@groundwater/shared-ui';
import { useTheme, styled, Skeleton } from '@mui/material';
import { format } from 'd3-format';
import ConsensusIconSvg from '../../assets/ConsensusIcon.svg';
import { qtdGrowthTooltip } from './qtdGrowthTooltip';
import { qtdSalesTooltip } from './qtdSalesTooltip';
import {
  generateSeriesLabels,
  getAllSeries,
  getChartSeries,
  QtdChartSerie,
  QtdChartType,
} from './qtdChartUtils';
import { drawProjectedErrorAnnotation } from './projectedErrorAnnotation';
import { drawConsensusErrorAnnotation } from './consensusErrorAnnotation';
import {
  extentOfDataset,
  optimize,
  formatPercent,
  formatDollarCompact,
} from '../../utils/chartUtils';
import { StyledApexChart } from '../../components/StyledApexChart';

const QtdStyledApexChart = styled(StyledApexChart)(() => ({
  '& .qtd-custom-tooltip': {
    emptyCells: 'show',
    '& th': {
      textAlign: 'left',
      padding: '2px 5px',
      fontSize: '13px',
    },
    '& tr': {
      border: '1px solid #CCC',
    },
    '& td': {
      textAlign: 'left',
      fontSize: '12px',
      padding: '2px 5px',
      '& img': {
        display: 'unset',
        width: '12px',
        height: '12px',
      },
      '& span': {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        borderRadius: '15px',
        marginRight: '6px',
      },
    },
  },
}));

interface QuarterToDateChartProps {
  chartType: QtdChartType;
  dataset: QtdResponse;
  yZoom: boolean;
}

export const QtdChartLoadingSkeleton = () => (
  <div data-testid="qtd-chart-skeleton">
    <Skeleton variant="rectangular" height={370} />
  </div>
);

export const QuarterToDateChart: React.FC<QuarterToDateChartProps> = ({
  chartType,
  dataset,
  yZoom,
}) => {
  const muiTheme = useTheme();

  const {
    projected_start_date,
    last_projected_day_analysis,
    last_projected_day_growth,
    analysis_quarter,
    comparison_quarter,
    analysis_latest_quarter,
    y_series,
    x_series,
  } = dataset;
  const { scaling_model, last_observed_day } = dataset.annotations;

  const isScaled = scaling_model !== QtdScalingModel.Unscaled ? true : false;

  const lastProjectedDay =
    chartType === 'growth'
      ? last_projected_day_growth
      : last_projected_day_analysis;

  const lastProjectedDayIndex = lastProjectedDay - 1;

  const hasProjection = Boolean(
    isScaled &&
      last_observed_day &&
      last_observed_day < lastProjectedDay &&
      analysis_latest_quarter
  );

  const hasConsensus = Boolean(dataset.annotations['consensus']);

  const hasConsensusGrowth = Boolean(dataset.annotations['consensus_growth']);

  const [
    consensus_growth_average,
    consensus_growth_low,
    consensus_growth_high,
  ] = dataset.annotations['consensus_growth'];

  const [consensus_average, consensus_low, consensus_high] =
    dataset.annotations['consensus'];

  const [, revenue_estimate_low, revenue_estimate_high] =
    dataset.annotations['revenue_estimate'];

  const [, revenue_estimate_growth_low, revenue_estimate_growth_high] =
    dataset.annotations['revenue_estimate_growth'];

  const hasRevenueEstimateConfInterval =
    Boolean(revenue_estimate_low) && Boolean(revenue_estimate_high);

  const hasConsensusConfInterval =
    hasConsensus && Boolean(consensus_low) && Boolean(consensus_high);

  const errorBarOnProjected = Boolean(
    hasProjection && hasRevenueEstimateConfInterval
  );

  const showConsensus = Boolean(
    isScaled &&
      ((hasConsensus && chartType === 'sales') ||
        (hasConsensusGrowth && chartType === 'growth'))
  );

  const errorBarOnConsensus = showConsensus && hasConsensusConfInterval;

  const yAxisFormat = chartType === 'growth' ? 'percent' : 'dollar';

  const seriesOrder = getChartSeries(
    chartType,
    hasProjection,
    showConsensus,
    errorBarOnProjected,
    errorBarOnConsensus
  );

  const tooltipGenerator =
    chartType === 'growth' ? qtdGrowthTooltip : qtdSalesTooltip;

  const xSeries = x_series.data;

  const analysis_period =
    y_series.find(({ name }) => name === 'analysis_period')?.data ?? [];

  const comparison_period =
    y_series.find(({ name }) => name === 'comparison_period')?.data ?? [];

  const growth = y_series.find(({ name }) => name === 'growth')?.data ?? [];

  const analysis_period_dates =
    y_series.find(({ name }) => name === 'analysis_period_dates')?.data ?? [];

  const comparison_period_dates =
    y_series.find(({ name }) => name === 'comparison_period_dates')?.data ?? [];

  const consensus = {
    consensus_average: Number(consensus_average),
    consensus_low: Number(consensus_low),
    consensus_high: Number(consensus_high),
    consensus_growth_average: Number(consensus_growth_average),
    consensus_growth_low: Number(consensus_growth_low),
    consensus_growth_high: Number(consensus_growth_high),
  };
  const revenue = {
    revenue_estimate_growth_low: Number(revenue_estimate_growth_low),
    revenue_estimate_growth_high: Number(revenue_estimate_growth_high),
    revenue_estimate_low: Number(revenue_estimate_low),
    revenue_estimate_high: Number(revenue_estimate_high),
  };

  const allSeries = getAllSeries({
    colors: muiTheme.palette.chart.base,
    chartType,
    xSeries,
    last_observed_day,
    lastProjectedDay,
    analysis_period,
    comparison_period,
    growth,
    revenue,
    consensus,
  });

  const seriesLabels = generateSeriesLabels({
    comparison_quarter,
    analysis_quarter,
    projected_start_date,
    hasProjection,
    chartType,
    yAxisFormat,
    consensus,
  });

  const ySeries = seriesOrder.map((serie: QtdChartSerie) => allSeries[serie]);
  // for sales chart type references 'projected' serie
  // for gowth chart type references 'projected_growth' serie
  const lastProjectedDayAnnotation =
    ySeries[1]?.data[lastProjectedDayIndex]?.toString();

  // for sales chart type references 'observed' serie
  // for gowth chart type references 'observed_growth' serie
  const lastObservedDayAnnotation =
    ySeries[0]?.data[last_observed_day - 1]?.toString();

  let { minFromDataset, maxFromDataset } = extentOfDataset(ySeries);
  switch (chartType) {
    case 'growth':
      if (showConsensus) {
        if (consensus_growth_low) {
          minFromDataset = Math.min(minFromDataset, consensus_growth_low);
        }
        if (consensus_growth_high) {
          maxFromDataset = Math.max(maxFromDataset, consensus_growth_high);
        }
      }
      if (revenue_estimate_growth_low) {
        minFromDataset = Math.min(minFromDataset, revenue_estimate_growth_low);
      }
      if (revenue_estimate_growth_high) {
        maxFromDataset = Math.max(maxFromDataset, revenue_estimate_growth_high);
      }
      break;
    case 'sales':
      if (showConsensus) {
        if (consensus_low) {
          minFromDataset = Math.min(minFromDataset, consensus_low);
        }
        if (consensus_high) {
          maxFromDataset = Math.max(maxFromDataset, consensus_high);
        }
      }
      if (revenue_estimate_low) {
        minFromDataset = Math.min(minFromDataset, revenue_estimate_low);
      }
      if (revenue_estimate_high) {
        maxFromDataset = Math.max(maxFromDataset, revenue_estimate_high);
      }
      break;
  }

  console.log({ yZoom, minFromDataset, maxFromDataset });
  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    yZoom,
    minFromDataset,
    maxFromDataset
  );

  return (
    <QtdStyledApexChart
      type="line"
      height={500}
      series={ySeries}
      options={{
        theme: {
          palette: 'palette6',
        },
        annotations: {
          points: [
            // Last observed day annotation
            {
              x: last_observed_day,
              y: lastObservedDayAnnotation,
              marker: {
                size: 4,
                fillColor: muiTheme.palette.common.white,
                strokeColor: muiTheme.palette.chart.colorGraph4,
                radius: 2,
                cssClass: 'apexcharts-last-observed-day',
              },
              label: {
                borderColor: muiTheme.palette.chart.colorGraph4,
                offsetY: 0,
                style: {
                  color: muiTheme.palette.common.white,
                  background: muiTheme.palette.chart.colorGraph4,
                },
              },
            },
            // Last projected day annotation
            {
              x: lastProjectedDay,
              y: lastProjectedDayAnnotation,
              marker: {
                size: 4,
                fillColor: muiTheme.palette.common.white,
                strokeColor: muiTheme.palette.chart.colorGraph7,
                radius: 2,
                cssClass: 'apexcharts-last-projected-day',
              },
              label: {
                borderColor: muiTheme.palette.chart.colorGraph7,
                offsetY: 30,
                style: {
                  color: muiTheme.palette.common.white,
                  background: muiTheme.palette.chart.colorGraph7,
                },
              },
            },
            ...(showConsensus
              ? [
                  {
                    x: lastProjectedDay,
                    y: (chartType === 'sales'
                      ? consensus_average
                      : consensus_growth_average
                    )?.toString(),
                    marker: {
                      size: 0,
                    },
                    image: {
                      width: 12,
                      height: 12,
                      path: ConsensusIconSvg,
                    },
                  },
                ]
              : []),
          ],
        },
        // Configure the chart lines (width, curve, etc.)
        stroke: {
          width: chartType === 'sales' ? 2 : 4,
          curve: 'straight',
          // Each index represents a serie
          ...(hasProjection && {
            dashArray: [0, 7, 0, 0],
          }),
        },
        fill: {
          opacity: 1,
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
            shadeIntensity: 0,
            stops: [0, 100],
            type: 'vertical',
          },
        },
        grid: {
          borderColor: muiTheme.palette.grey[300],
          strokeDashArray: 3,
        },
        states: {
          active: {
            filter: {
              type: 'darken',
              value: 0.88,
            },
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.04,
            },
          },
        },
        tooltip: {
          custom: function (opts: any) {
            return tooltipGenerator({
              ...opts,
              ...consensus,
              ...revenue,
              growth,
              analysis_period_dates,
              comparison_period_dates,
            });
          },
          marker: {
            show: true,
          },
          x: {
            // Hide tooltip header
            show: false,
            formatter: function (val: any) {
              return `Day ${val}`;
            },
          },
          y: {
            formatter: (val: number | { valueOf(): number }) =>
              yAxisFormat === 'percent'
                ? format('>-0.1~%')(val)
                : format('>-$,')(val).replace(/G/, 'B'),
          },
        },
        chart: {
          id: chartType,
          group: 'qtd',
          foreColor: muiTheme.palette.grey[500],
          animations: {
            enabled: false,
          },
          events: {
            mounted: function (chart: any) {
              drawProjectedErrorAnnotation(
                chart,
                errorBarOnProjected,
                lastProjectedDayIndex
              );
              drawConsensusErrorAnnotation(
                chart,
                errorBarOnConsensus,
                lastProjectedDayIndex
              );
            },
            updated: function (chart: any) {
              drawProjectedErrorAnnotation(
                chart,
                errorBarOnProjected,
                lastProjectedDayIndex
              );
              drawConsensusErrorAnnotation(
                chart,
                errorBarOnConsensus,
                lastProjectedDayIndex
              );
            },
          },
          // Disable toolbar functionality for trends charts
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount,
          decimalsInFloat: 2,
          title: {
            text: chartType === 'growth' ? 'Growth' : 'Cumulative Sales',
          },
          labels: {
            formatter:
              yAxisFormat === 'percent' ? formatPercent : formatDollarCompact,
          },
        },
        xaxis: {
          type: 'category',
          categories: xSeries,
          title: {
            text: 'Day',
          },
          // add padding on the right side of the chart for concensus icon
          max: showConsensus ? xSeries.length - 1 + 6.0 : xSeries.length,
          tickAmount: 10,
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            },
          },
        },
        legend: {
          showForNullSeries: false,
          showForZeroSeries: false,
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '13',
          fontWeight: 500,
          offsetX: 5,
          formatter: function (seriesName: QtdChartSerie) {
            return seriesLabels[seriesName];
          },
          labels: {
            colors: muiTheme.palette.text.primary,
          },
        },
      }}
    />
  );
};
