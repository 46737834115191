/* eslint-disable */
import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from 'react-query';
import { fetchGQL } from '@groundwater/frontend-api-client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: number;
  DateTime: string;
  Void: void;
};

export enum BoilerplateType {
  Brand = 'BRAND',
  Company = 'COMPANY',
}

export type Branch = {
  __typename: 'Branch';
  url: Scalars['String'];
};

export type Brand = {
  __typename: 'Brand';
  channels: Array<Channel>;
  id: Scalars['ID'];
  industry: Maybe<Industry>;
  logo: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: BrandType;
  ultimate_parent: Maybe<Company>;
  website: Scalars['String'];
};

export enum BrandType {
  AccountFunding = 'account_funding',
  Financing = 'financing',
  Gig = 'gig',
  Marketplace = 'marketplace',
  PaymentServiceProvider = 'payment_service_provider',
  PrimaryMerchant = 'primary_merchant',
  Reservations = 'reservations',
  Saas = 'saas',
  ThirdPartyDelivery = 'third_party_delivery',
  Wallet = 'wallet',
}

export enum BrandTypes {
  Intermediary = 'intermediary',
  Primary = 'primary',
}

/** Break out by filter options */
export enum BreakoutBy {
  Brand = 'brand',
  Channel = 'channel',
  CustomerSpend = 'customer_spend',
  Geo = 'geo',
  None = 'none',
  TransactionValue = 'transaction_value',
  Veterancy = 'veterancy',
}

export type BrowseBusinessesBrandNode = {
  __typename: 'BrowseBusinessesBrandNode';
  brand: Brand;
  observed_customers: Scalars['BigInt'];
  observed_customers_ltm_chart: Array<SparklineChartSerie>;
  observed_customers_yoy_percent: Maybe<Scalars['Float']>;
  observed_sales_dollars: Scalars['BigInt'];
  observed_sales_ltm_chart: Array<SparklineChartSerie>;
  observed_sales_yoy_percent: Maybe<Scalars['Float']>;
  observed_transactions: Scalars['BigInt'];
  observed_transactions_ltm_chart: Array<SparklineChartSerie>;
  observed_transactions_yoy_percent: Maybe<Scalars['Float']>;
};

export type BrowseBusinessesCompanyNode = {
  __typename: 'BrowseBusinessesCompanyNode';
  company: Company;
  market_capitalization_dollars: Scalars['BigInt'];
  observed_customers: Scalars['BigInt'];
  observed_customers_ltm_chart: Array<SparklineChartSerie>;
  observed_customers_yoy_percent: Maybe<Scalars['Float']>;
  observed_sales_dollars: Scalars['BigInt'];
  observed_sales_ltm_chart: Array<SparklineChartSerie>;
  observed_sales_yoy_percent: Maybe<Scalars['Float']>;
  observed_transactions: Scalars['BigInt'];
  observed_transactions_ltm_chart: Array<SparklineChartSerie>;
  observed_transactions_yoy_percent: Maybe<Scalars['Float']>;
  r2_coefficient: Maybe<Scalars['Float']>;
};

export type BrowseBusinessesExportAllResponse = {
  __typename: 'BrowseBusinessesExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type BrowseBusinessesNode =
  | BrowseBusinessesBrandNode
  | BrowseBusinessesCompanyNode;

export type BrowseBusinessesRequest = {
  business_type: BusinessType;
};

export type BrowseBusinessesResponse = {
  __typename: 'BrowseBusinessesResponse';
  data_as_of_month: Scalars['String'];
  nodes: Array<BrowseBusinessesNode>;
};

export enum BusinessType {
  Brand = 'brand',
  Company = 'company',
}

export enum Channel {
  Online = 'online',
  Retail = 'retail',
}

/**
 * Channel filter options
 * @deprecated
 */
export enum Channels {
  All = 'all',
  Online = 'online',
  Retail = 'retail',
}

/** Channel filter options */
export enum ChannelsV2 {
  Online = 'online',
  Retail = 'retail',
}

export type ChildBrand = {
  __typename: 'ChildBrand';
  brand: Brand;
  expiration_date: Scalars['DateTime'];
  is_expired: Scalars['Boolean'];
};

export enum CohortIndex {
  Calendar = 'calendar',
  Cohort = 'cohort',
}

/** @deprecated */
export type CohortedRetentionDynamicSerie = {
  __typename: 'CohortedRetentionDynamicSerie';
  data: Array<Maybe<Scalars['Float']>>;
  heading: Scalars['String'];
  id: Scalars['String'];
};

export type CohortedRetentionExportAllResponse = {
  __typename: 'CohortedRetentionExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

/** @deprecated */
export type CohortedRetentionRequest = {
  cohort_index: CohortIndex;
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: CohortedRetentionRollup;
  start_date: Scalars['String'];
};

export type CohortedRetentionRequestV2 = {
  bucket: InputMaybe<Scalars['String']>;
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: CohortedRetentionRollup;
  start_date: Scalars['String'];
  xaxis: CohortIndex;
};

/** @deprecated */
export type CohortedRetentionResponse = {
  __typename: 'CohortedRetentionResponse';
  data: Array<CohortedRetentionSerie>;
};

export type CohortedRetentionResponseV3 = {
  __typename: 'CohortedRetentionResponseV3';
  /**
   * This describes the "cohorts" which are groups of customers acquired in a given period.
   * It is an array of date strings where each date represents the acquisition period of the cohort.
   * Each cohort represents a row in our HTML table, ergo these are the y-axis labels
   *
   * the number of periods and therefore cohorts depends on the selected time range and rollup granularity
   */
  cohorts: Array<Scalars['String']>;
  /**
   * Because this grid inherently visualizes customer retention, you need different start/end
   * periods to make an interesting comparison. You can think of retention in period 0 as always 100%.
   *
   * Instead of showing a column that is just always 100% we show a column with the
   * absolute number of customers in the cohort.
   *
   * Because this "period" is special (contains data with different units) from the other periods,
   * it is it's own property and type.
   */
  period_0_customers: Array<Scalars['Float']>;
  /**
   * Each period represents retention data for a given period, the number of periods
   * depends on the selected time range and rollup granularity
   */
  periods: Array<RetentionPeriod>;
};

export enum CohortedRetentionRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

/** @deprecated */
export type CohortedRetentionSerie =
  | CohortedRetentionDynamicSerie
  | CohortedRetentionStaticSerie;

/** @deprecated */
export type CohortedRetentionStaticSerie = {
  __typename: 'CohortedRetentionStaticSerie';
  data: Array<Scalars['String']>;
  heading: CohortedRetentionStaticSerieId;
  id: CohortedRetentionStaticSerieId;
};

export enum CohortedRetentionStaticSerieId {
  CohortPeriod = 'cohort_period',
  Period_0Customers = 'period_0_customers',
}

export type CommitResult = {
  __typename: 'CommitResult';
  sha: Scalars['String'];
};

export type Company = {
  __typename: 'Company';
  bloomberg_id: Maybe<Scalars['String']>;
  /** @deprecated */
  brands: Array<Brand>;
  channels: Array<Channel>;
  /** All brand children relationships for this company */
  child_brands: Array<ChildBrand>;
  exchange: Maybe<Scalars['String']>;
  figi: Maybe<Scalars['String']>;
  has_mixed_brand_types: Scalars['Boolean'];
  id: Scalars['ID'];
  logo: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  quarters: Array<Quarter>;
  reports_quarterly: Scalars['Boolean'];
  ticker: Maybe<Scalars['String']>;
  title: Scalars['String'];
  website: Scalars['String'];
};

export type CompanyOrBrand = Brand | Company;

export type ComparisonYaml = {
  entityId: Scalars['String'];
  yamlMain: InputMaybe<Scalars['String']>;
  yamlVerify: InputMaybe<Scalars['String']>;
};

export type CreatePullRequestResult = {
  __typename: 'CreatePullRequestResult';
  url: Scalars['String'];
};

export type DataError = {
  __typename: 'DataError';
  message: Scalars['String'];
};

export type DataOpsFile = {
  contents: InputMaybe<Scalars['String']>;
  entity_id: Scalars['String'];
};

export type DataOpsSession = {
  __typename: 'DataOpsSession';
  author: User;
  branch_name: Scalars['String'];
  created: Scalars['String'];
  diffToolMaxResults: Scalars['Int'];
  diffToolMinTransactionCount: Scalars['Int'];
  diffToolPivots: Scalars['String'];
  files: Array<DataOpsSessionFile>;
  id: Scalars['Int'];
  updated: Scalars['String'];
};

export type DataOpsSessionFile = {
  __typename: 'DataOpsSessionFile';
  entity_id: Scalars['String'];
};

export type DiffToolResult = {
  __typename: 'DiffToolResult';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<DiffToolRow>;
  warnings: Array<Message>;
};

export type DiffToolRow = {
  __typename: 'DiffToolRow';
  average_transaction_value: Scalars['Float'];
  dp4_merchant_category_xcd: Scalars['String'];
  dp4_merchant_city_name: Scalars['String'];
  dp4_merchant_state: Scalars['String'];
  mcc_description: Scalars['String'];
  new_channel: Scalars['String'];
  new_match: Scalars['Boolean'];
  old_channel: Scalars['String'];
  old_match: Scalars['Boolean'];
  taggable_merchant_name: Scalars['String'];
  total_sales: Scalars['Float'];
  transaction_count: Scalars['Float'];
};

export type DollarRetentionExportAllResponse = {
  __typename: 'DollarRetentionExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type DollarRetentionRequest = {
  bucket: InputMaybe<Scalars['String']>;
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: DollarRetentionRollup;
  start_date: Scalars['String'];
  xaxis: DollarRetentionXaxis;
};

export type DollarRetentionResponse = {
  __typename: 'DollarRetentionResponse';
  /**
   * This describes the "cohorts" which are groups of customers acquired in a given period.
   * It is an array of date strings where each date represents the acquisition period of the cohort.
   * Each cohort represents a row in our HTML table, ergo these are the y-axis labels
   *
   * the number of periods and therefore cohorts depends on the selected time range and rollup granularity
   */
  cohorts: Array<Scalars['String']>;
  /**
   * Because this grid inherently visualizes customer retention, you need different start/end
   * periods to make an interesting comparison. You can think of retention in period 0 as always 100%.
   *
   * Instead of showing a column that is just always 100% we show a column with the
   * absolute number of customers in the cohort.
   *
   * Because this "period" is special (contains data with different units) from the other periods,
   * it is it's own property and type.
   */
  period_0_sales: Array<Scalars['Float']>;
  /**
   * Each period represents retention data for a given period, the number of periods
   * depends on the selected time range and rollup granularity
   *
   * This corresponds to how much of the dollars / customers in "period 0" were retained
   * accordingly.
   */
  periods: Array<RetentionPeriod>;
};

export enum DollarRetentionRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export enum DollarRetentionXaxis {
  Calendar = 'calendar',
  Cohort = 'cohort',
}

export type FeatureUsageRequest = {
  feature: GroundwaterFeature;
  parameters: Scalars['String'];
};

export enum FileStatus {
  Added = 'added',
  Modified = 'modified',
  Removed = 'removed',
}

export type GeographicalArea = {
  __typename: 'GeographicalArea';
  code: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  type: GeographicalAreaType;
};

export type GeographicalAreaResponse = {
  __typename: 'GeographicalAreaResponse';
  results: Array<GeographicalArea>;
};

export enum GeographicalAreaType {
  City = 'CITY',
  Metro = 'METRO',
  State = 'STATE',
}

export type GlobalGraph = {
  __typename: 'GlobalGraph';
  edges: Array<GlobalGraphEdge>;
  nodes: Array<GlobalGraphNode>;
};

export type GlobalGraphEdge = {
  __typename: 'GlobalGraphEdge';
  child_id: Scalars['String'];
  parent_id: Scalars['String'];
};

export type GlobalGraphNode = {
  __typename: 'GlobalGraphNode';
  entity_type: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export enum GroundwaterFeature {
  BrowseBusinesses = 'browse_businesses',
  CohortedRetention = 'cohorted_retention',
  DollarRetention = 'dollar_retention',
  GrowthAccounting = 'growth_accounting',
  ObservedVsReported = 'observed_vs_reported',
  QuarterToDate = 'quarter_to_date',
  QuarterlyPerformanceScreening = 'quarterly_performance_screening',
  RetentionSummary = 'retention_summary',
  TransactionValues = 'transaction_values',
  Trends = 'trends',
}

export type GrowthAccountingExportResponse = {
  __typename: 'GrowthAccountingExportResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type GrowthAccountingPeriodCustomers = {
  __typename: 'GrowthAccountingPeriodCustomers';
  /** customers from the previous period who are no longer customers */
  churned: Scalars['Float'];
  date: Scalars['String'];
  /** customers that were acquired for the first time in this period */
  new: Scalars['Float'];
  quick_ratio: Maybe<Scalars['Float']>;
  /** customers that were not active in the previous period */
  resurrected: Scalars['Float'];
  /** customers that were active in the previous period */
  retained: Scalars['Float'];
};

export type GrowthAccountingPeriodSales = {
  __typename: 'GrowthAccountingPeriodSales';
  /** customers from the previous period who are no longer customers */
  churned: Scalars['Float'];
  contraction: Scalars['Float'];
  date: Scalars['String'];
  expansion: Scalars['Float'];
  /** customers that were acquired for the first time in this period */
  new: Scalars['Float'];
  quick_ratio: Maybe<Scalars['Float']>;
  /** customers that were not active in the previous period */
  resurrected: Scalars['Float'];
  /** customers that were active in the previous period */
  retained: Scalars['Float'];
};

export type GrowthAccountingRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: GrowthAccountingRollup;
  start_date: Scalars['String'];
};

export type GrowthAccountingResponseCustomers = {
  __typename: 'GrowthAccountingResponseCustomers';
  periods: Array<GrowthAccountingPeriodCustomers>;
};

export type GrowthAccountingResponseSales = {
  __typename: 'GrowthAccountingResponseSales';
  periods: Array<GrowthAccountingPeriodSales>;
};

export enum GrowthAccountingRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export type Iam = {
  __typename: 'Iam';
  arn: Maybe<Scalars['String']>;
  aws_account_id: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['BigInt'];
  resource: Maybe<Scalars['String']>;
  team: Team;
};

export type IamConnection = {
  __typename: 'IamConnection';
  count: Scalars['BigInt'];
  nodes: Array<Iam>;
  page_info: PageInfo;
};

export enum IamOrderBy {
  Arn = 'arn',
  AwsAccountId = 'aws_account_id',
  Created = 'created',
  Id = 'id',
  Resource = 'resource',
}

export type Industry = {
  __typename: 'Industry';
  id: Scalars['BigInt'];
  title: Scalars['String'];
};

export enum Lag {
  Days_3 = 'DAYS_3',
}

export type LatestProductionDateResponse = {
  __typename: 'LatestProductionDateResponse';
  date: Scalars['String'];
};

export enum ListSessionsFilter {
  All = 'ALL',
  Mine = 'MINE',
}

export type LocalGraph = {
  __typename: 'LocalGraph';
  edges: Array<LocalGraphEdge>;
  nodes: Array<LocalGraphNode>;
};

export type LocalGraphEdge = {
  __typename: 'LocalGraphEdge';
  child_id: Scalars['String'];
  effective_date: Maybe<Scalars['String']>;
  expiration_date: Maybe<Scalars['String']>;
  is_direct: Scalars['Boolean'];
  parent_id: Scalars['String'];
};

export type LocalGraphNode = {
  __typename: 'LocalGraphNode';
  entity_type: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is_intermediary: Scalars['Boolean'];
};

/** @deprecated */
export type MaybeCohortedRetentionResponse =
  | CohortedRetentionResponse
  | DataError;

export type MaybeCohortedRetentionResponseV3 =
  | CohortedRetentionResponseV3
  | DataError;

export type MaybeDollarRetentionResponse = DataError | DollarRetentionResponse;

export type MaybeGrowthAccountingResponseCustomers =
  | DataError
  | GrowthAccountingResponseCustomers;

export type MaybeGrowthAccountingResponseSales =
  | DataError
  | GrowthAccountingResponseSales;

export type MaybeOvrResponse = DataError | OvrResponse;

export type MaybePercentObservedResponse = DataError | PercentObservedResponse;

export type MaybeQtdResponse = DataError | QtdResponse;

export type MaybeRetentionSummaryResponse =
  | DataError
  | RetentionSummaryResponse;

export type MaybeTransactionValuesExportAllResponse =
  | DataError
  | TransactionValuesExportAllResponse;

export type MaybeTransactionValuesResponse =
  | DataError
  | TransactionValuesResponse;

export type MaybeTrendsResponse = DataError | TrendsResponse;

export type Message = {
  __typename: 'Message';
  message: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  bulk_enable_users: Scalars['String'];
  commit: CommitResult;
  createBranch: Scalars['Boolean'];
  createPullRequest: CreatePullRequestResult;
  createSession: DataOpsSession;
  feature_usage: Maybe<Scalars['Void']>;
  removeEntity: Scalars['Boolean'];
  request_2fa_code: Maybe<Scalars['Void']>;
  request_password_reset_email: Maybe<Scalars['Void']>;
  reset_password: Maybe<Scalars['Void']>;
  saveDiffToolPivots: Scalars['Boolean'];
  star: Scalars['Boolean'];
  totp_enroll: Scalars['String'];
  totp_unenroll: Maybe<Scalars['Void']>;
  totp_verify: Scalars['Boolean'];
  /** @deprecated */
  trends_export: TrendsExportResponse;
  unstar: Scalars['Boolean'];
  updateSession: Scalars['Boolean'];
  upsert_iam: Iam;
  upsert_team: Team;
  upsert_user: User;
  verify_2fa_code: Scalars['Boolean'];
  welcome_email: Maybe<Scalars['Void']>;
};

export type MutationBulk_Enable_UsersArgs = {
  emails_not_to_enable: Scalars['String'];
};

export type MutationCommitArgs = {
  branch_name: Scalars['String'];
  files: Array<DataOpsFile>;
  message: Scalars['String'];
};

export type MutationCreateBranchArgs = {
  branch_name: Scalars['String'];
};

export type MutationCreatePullRequestArgs = {
  branch_name: Scalars['String'];
};

export type MutationCreateSessionArgs = {
  branch_name: Scalars['String'];
};

export type MutationFeature_UsageArgs = {
  request: FeatureUsageRequest;
};

export type MutationRemoveEntityArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
};

export type MutationRequest_Password_Reset_EmailArgs = {
  email: Scalars['String'];
};

export type MutationReset_PasswordArgs = {
  new_password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationSaveDiffToolPivotsArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
  pivots: Scalars['String'];
};

export type MutationStarArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
};

export type MutationTotp_VerifyArgs = {
  token: Scalars['String'];
};

export type MutationTrends_ExportArgs = {
  request: TrendsExportRequest;
};

export type MutationUnstarArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
};

export type MutationUpdateSessionArgs = {
  branch_name: Scalars['String'];
  diffMaxResults: Scalars['Int'];
  diffMinTransactionCount: Scalars['Int'];
};

export type MutationUpsert_IamArgs = {
  iam: UpsertIamRequest;
};

export type MutationUpsert_TeamArgs = {
  team: UpsertTeamRequest;
};

export type MutationUpsert_UserArgs = {
  user: UpsertUserRequest;
};

export type MutationVerify_2fa_CodeArgs = {
  token: Scalars['String'];
};

export type MutationWelcome_EmailArgs = {
  id: Scalars['BigInt'];
};

export type OvrAnnotations = {
  __typename: 'OvrAnnotations';
  consensus_mean_vs_reported: Array<Maybe<Scalars['Float']>>;
  consensus_range_vs_reported: Array<Array<Maybe<Scalars['Float']>>>;
  historical_percent_observed: Array<Maybe<Scalars['Float']>>;
  r2: Maybe<Scalars['Float']>;
  scaled_sales_vs_reported: Array<Maybe<Scalars['Float']>>;
  scaling_model: OvrScalingModel;
};

export type OvrExportAllResponse = {
  __typename: 'OvrExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type OvrRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  scaling_model: OvrScalingModel;
  start_date: Scalars['String'];
  target_dimension: OvrTargetDimension;
};

export type OvrResponse = {
  __typename: 'OvrResponse';
  annotations: OvrAnnotations;
  scaling_model: OvrScalingModel;
  target_dimension: OvrTargetDimension;
  x_series: Array<Scalars['String']>;
  y_series: OvrYSerie;
};

export enum OvrScalingModel {
  AllTimeAverage = 'all_time_average',
  BestFit = 'best_fit',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

export enum OvrTargetDimension {
  ObservedVolume = 'observed_volume',
  YoyGrowth = 'yoy_growth',
  YoySpread = 'yoy_spread',
}

export type OvrYSerie = {
  __typename: 'OvrYSerie';
  consensus_high: Array<Maybe<Scalars['Float']>>;
  consensus_low: Array<Maybe<Scalars['Float']>>;
  consensus_mean: Array<Maybe<Scalars['Float']>>;
  forecast_sales: Array<Maybe<Scalars['Float']>>;
  /** reported_revenue serie is skipped in case of yoy_spread view */
  reported_revenue: Maybe<Array<Maybe<Scalars['Float']>>>;
  scaled_sales: Array<Maybe<Scalars['Float']>>;
};

export enum OvrYSerieName {
  ConsensusHigh = 'consensus_high',
  ConsensusLow = 'consensus_low',
  ConsensusMean = 'consensus_mean',
  ForecastSales = 'forecast_sales',
  ReportedRevenue = 'reported_revenue',
  ScaledSales = 'scaled_sales',
}

export type PageInfo = {
  __typename: 'PageInfo';
  has_next_page: Scalars['Boolean'];
};

export type PercentObservedRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  start_date: InputMaybe<Scalars['String']>;
};

export type PercentObservedResponse = {
  __typename: 'PercentObservedResponse';
  best_fit: PercentObservedScalingModel;
  historical_proportion_observed: Array<Maybe<Scalars['String']>>;
  quarter_end_date: Array<Scalars['String']>;
  r2: PercentObservedScalingModelR2;
  scaling_models: ScalingModelSeries;
};

export enum PercentObservedScalingModel {
  AllTimeAverage = 'all_time_average',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

/**
 * Annotation with maybe partial history.
 * Certain companies do not have sufficient history to cover the selected range resulting in NULL values.
 * eg if viewing data for 2yrs but we only have 1.5yrs, we inject NULL.
 */
export type PercentObservedScalingModelR2 = {
  __typename: 'PercentObservedScalingModelR2';
  all_time_average: Maybe<Scalars['String']>;
  exponential_smoothing: Maybe<Scalars['String']>;
  four_quarter_average: Maybe<Scalars['String']>;
  previous_quarter: Maybe<Scalars['String']>;
  previous_year: Maybe<Scalars['String']>;
  unscaled: Maybe<Scalars['String']>;
};

export type PullRequest = {
  __typename: 'PullRequest';
  base: Scalars['String'];
  isOpen: Scalars['Boolean'];
  number: Scalars['Int'];
  url: Scalars['String'];
};

export type PullRequestFile = {
  __typename: 'PullRequestFile';
  filename: Scalars['String'];
  status: Maybe<FileStatus>;
};

export type QpsExportAllResponse = {
  __typename: 'QpsExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type QpsNode = QpsNodeLastReported | QpsNodeUnreportedOrCurrent;

export type QpsNodeLastReported = {
  __typename: 'QpsNodeLastReported';
  company: Company;
  fiscal_quarter: Quarter;
  in_consensus_range: Scalars['Boolean'];
  max_consensus_yoy: Maybe<Scalars['Float']>;
  mean_consensus_usd: Maybe<Scalars['Float']>;
  mean_consensus_yoy: Maybe<Scalars['Float']>;
  min_consensus_yoy: Maybe<Scalars['Float']>;
  modeled_percent_observed: Maybe<Scalars['Float']>;
  modeled_quarter_end_sales: Maybe<Scalars['Float']>;
  modeled_quarter_end_yoy: Maybe<Scalars['Float']>;
  percent_completed: Maybe<Scalars['Float']>;
  r2_coefficient: Maybe<Scalars['Float']>;
  reported_revenue: Maybe<Scalars['Float']>;
  reported_yoy: Maybe<Scalars['Float']>;
  reported_yoy_mean_consensus_yoy: Maybe<Scalars['Float']>;
  reported_yoy_modeled_yoy: Scalars['Float'];
  reported_yoy_modeled_yoy_absolute_value: Scalars['Float'];
  scaling_model: QpsScalingModel;
};

export type QpsNodeUnreportedOrCurrent = {
  __typename: 'QpsNodeUnreportedOrCurrent';
  company: Company;
  fiscal_quarter: Quarter;
  in_consensus_range: Scalars['Boolean'];
  max_consensus_yoy: Maybe<Scalars['Float']>;
  mean_consensus_usd: Maybe<Scalars['Float']>;
  mean_consensus_yoy: Maybe<Scalars['Float']>;
  min_consensus_yoy: Maybe<Scalars['Float']>;
  modeled_percent_observed: Maybe<Scalars['Float']>;
  modeled_quarter_end_sales: Maybe<Scalars['Float']>;
  modeled_quarter_end_yoy: Maybe<Scalars['Float']>;
  modeled_yoy_mean_consensus_yoy: Maybe<Scalars['Float']>;
  percent_completed: Maybe<Scalars['Float']>;
  r2_coefficient: Maybe<Scalars['Float']>;
  scaling_model: QpsScalingModel;
};

export type QpsRequest = {
  selected_quarter: QpsSelectedQuarter;
};

export type QpsResponse = {
  __typename: 'QpsResponse';
  nodes: Array<QpsNode>;
};

export enum QpsScalingModel {
  AllTimeAverage = 'all_time_average',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

export enum QpsSelectedQuarter {
  CurrentQuarter = 'current_quarter',
  LastReportedQuarter = 'last_reported_quarter',
  UnreportedCompletedQuarter = 'unreported_completed_quarter',
}

export type QtdAnnotations = {
  __typename: 'QtdAnnotations';
  consensus: Array<Maybe<Scalars['Float']>>;
  consensus_growth: Array<Maybe<Scalars['Float']>>;
  last_observed_day: Scalars['BigInt'];
  revenue_estimate: Array<Maybe<Scalars['Float']>>;
  revenue_estimate_growth: Array<Maybe<Scalars['Float']>>;
  scaling_model: Scalars['String'];
};

export type QtdExportAllResponse = {
  __typename: 'QtdExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type QtdPercentObservedRequest = {
  analysis_quarter: Scalars['String'];
  company_id: Scalars['ID'];
};

export type QtdRequest = {
  analysis_quarter: Scalars['String'];
  company_id: Scalars['ID'];
  comparison_quarter: Scalars['String'];
  scaling_model: QtdScalingModel;
  scaling_model_custom_percent: Scalars['Float'];
};

export type QtdResponse = {
  __typename: 'QtdResponse';
  analysis_latest_quarter: Scalars['Boolean'];
  analysis_quarter: Quarter;
  annotations: QtdAnnotations;
  comparison_quarter: Quarter;
  last_projected_day_analysis: Scalars['BigInt'];
  last_projected_day_growth: Scalars['BigInt'];
  projected_start_date: Scalars['DateTime'];
  scaling_model: QtdScalingModel;
  scaling_model_custom_percent: Scalars['Float'];
  x_series: QtdXSerie;
  y_series: Array<QtdYSerie>;
};

export enum QtdScalingModel {
  AllTimeAverage = 'all_time_average',
  BestFit = 'best_fit',
  Custom = 'custom',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

export type QtdXSerie = {
  __typename: 'QtdXSerie';
  data: Array<Scalars['BigInt']>;
  name: QtdXSerieName;
};

export enum QtdXSerieName {
  DayNumber = 'day_number',
}

export type QtdYSerie = {
  __typename: 'QtdYSerie';
  data: Array<Maybe<Scalars['String']>>;
  name: QtdYSerieName;
};

export enum QtdYSerieName {
  AnalysisPeriod = 'analysis_period',
  AnalysisPeriodDates = 'analysis_period_dates',
  ComparisonPeriod = 'comparison_period',
  ComparisonPeriodDates = 'comparison_period_dates',
  Growth = 'growth',
}

export type QtipBrandTrends = {
  __typename: 'QtipBrandTrends';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipBrandTrendsRow>;
  warnings: Array<Message>;
};

export enum QtipBrandTrendsPanel {
  Frozen = 'frozen',
  Open = 'open',
}

export type QtipBrandTrendsRequest = {
  comparisonYAML: Array<ComparisonYaml>;
  end_date: Scalars['String'];
  panel: QtipBrandTrendsPanel;
  rollup: QtipBrandTrendsRollup;
  start_date: Scalars['String'];
};

export enum QtipBrandTrendsRollup {
  Month = 'month',
  Quarter = 'quarter',
}

export type QtipBrandTrendsRow = {
  __typename: 'QtipBrandTrendsRow';
  average_transaction_value_new: Maybe<Scalars['Float']>;
  average_transaction_value_old: Maybe<Scalars['Float']>;
  online_channel_proportion_new: Maybe<Scalars['Float']>;
  online_channel_proportion_old: Maybe<Scalars['Float']>;
  percent_change_sales: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  proportion_entrycode_e_new: Maybe<Scalars['Float']>;
  proportion_entrycode_e_old: Maybe<Scalars['Float']>;
  weighted_sales_new: Scalars['Float'];
  weighted_sales_old: Scalars['Float'];
  weighted_transactions_new: Scalars['Float'];
  weighted_transactions_old: Scalars['Float'];
};

export type QtipDescriptions = {
  __typename: 'QtipDescriptions';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipDescriptionsRow>;
  warnings: Array<Message>;
};

export type QtipDescriptionsRow = {
  __typename: 'QtipDescriptionsRow';
  channel: Scalars['String'];
  clean_merchant_name: Scalars['String'];
  dp4_card_entry_code: Scalars['String'];
  dp4_merchant_category_xcd: Scalars['String'];
  dp4_merchant_city_name: Scalars['String'];
  dp4_merchant_name: Scalars['String'];
  dp4_merchant_state: Scalars['String'];
  first_month_seen: Scalars['String'];
  last_month_seen: Scalars['String'];
  max_txn_amount: Scalars['Float'];
  mcc_description: Scalars['String'];
  mean_txn_amount: Scalars['Float'];
  min_txn_amount: Scalars['Float'];
  raw_sales: Scalars['Float'];
  raw_transactions: Scalars['Float'];
  taggable_merchant_name: Scalars['String'];
  unweighted_production_sales: Scalars['Float'];
  unweighted_production_transactions: Scalars['Float'];
  vendor_channel: Scalars['String'];
  vendor_intermediaries: Array<Scalars['String']>;
  vendor_matched_brands: Array<Scalars['String']>;
  vendor_primary_merchant: Maybe<Scalars['String']>;
  vendor_result_is_unique: Scalars['Boolean'];
  weighted_sales: Scalars['Float'];
  weighted_transactions: Scalars['Float'];
};

export type QtipRawVsModified = {
  __typename: 'QtipRawVsModified';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipRawVsModifiedRow>;
  warnings: Array<Message>;
};

export type QtipRawVsModifiedRow = {
  __typename: 'QtipRawVsModifiedRow';
  dp4_card_entry_code: Scalars['String'];
  dp4_is_merchant_mod: Scalars['Boolean'];
  dp4_merchant_category_xcd: Maybe<Scalars['String']>;
  dp4_merchant_city_name: Maybe<Scalars['String']>;
  dp4_merchant_state: Maybe<Scalars['String']>;
  fixed_after: Scalars['Boolean'];
  mcc_description: Scalars['String'];
  modified_brands_after_change: Array<Scalars['String']>;
  modified_brands_before_change: Array<Scalars['String']>;
  modified_merchant_name: Maybe<Scalars['String']>;
  n_txns: Scalars['Int'];
  raw_brands_after_change: Array<Scalars['String']>;
  raw_brands_before_change: Array<Scalars['String']>;
  raw_merchant_name: Maybe<Scalars['String']>;
};

export type QtipTaggingConflicts = {
  __typename: 'QtipTaggingConflicts';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipTaggingConflictsRow>;
  warnings: Array<Message>;
};

export type QtipTaggingConflictsRow = {
  __typename: 'QtipTaggingConflictsRow';
  clean_merchant_name: Scalars['String'];
  dp4_merchant_category_xcd: Scalars['String'];
  dp4_merchant_city_name: Scalars['String'];
  dp4_merchant_name: Scalars['String'];
  dp4_merchant_state: Scalars['String'];
  mcc_description: Scalars['String'];
  prod_vendor_matched_brands: Array<Scalars['String']>;
  prod_vendor_primary_merchant: Maybe<Scalars['String']>;
  proposed_primary_merchants: Array<Scalars['String']>;
  raw_transactions: Scalars['Int'];
  taggable_merchant_name: Scalars['String'];
};

export type QtipTransactionCounts = {
  __typename: 'QtipTransactionCounts';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipTransactionCountsRow>;
  warnings: Array<Message>;
};

export type QtipTransactionCountsRequest = {
  data: Array<YamlFile>;
  end_date: Scalars['String'];
  rollup: QtipBrandTrendsRollup;
  start_date: Scalars['String'];
};

export type QtipTransactionCountsRow = {
  __typename: 'QtipTransactionCountsRow';
  date: Scalars['String'];
  raw_transactions_frozen: Scalars['Int'];
  raw_transactions_open: Scalars['Int'];
  weighted_transactions_frozen: Scalars['Float'];
  weighted_transactions_open: Scalars['Float'];
};

export type Quarter = {
  __typename: 'Quarter';
  end_date: Scalars['String'];
  fiscal_quarter: Scalars['BigInt'];
  fiscal_year: Scalars['BigInt'];
  is_reported: Scalars['Boolean'];
  start_date: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  _dummy: Scalars['String'];
  boilerplateYaml: Scalars['String'];
  branch: Maybe<Branch>;
  browse_businesses: BrowseBusinessesResponse;
  browse_businesses_export_all: BrowseBusinessesExportAllResponse;
  business: CompanyOrBrand;
  /** @deprecated */
  cohorted_retention_export_all: CohortedRetentionExportAllResponse;
  cohorted_retention_export_all_v2: CohortedRetentionExportAllResponse;
  /** @deprecated */
  cohorted_retention_v2: MaybeCohortedRetentionResponse;
  cohorted_retention_v3: MaybeCohortedRetentionResponseV3;
  cohorted_retention_v4: MaybeCohortedRetentionResponseV3;
  companySearch: Array<Scalars['String']>;
  customer_growth_accounting: MaybeGrowthAccountingResponseCustomers;
  descendents: Array<Scalars['String']>;
  diffToolResults: DiffToolResult;
  dollar_retention: MaybeDollarRetentionResponse;
  dollar_retention_export_all: DollarRetentionExportAllResponse;
  export_customer_growth_accounting: GrowthAccountingExportResponse;
  export_sales_growth_accounting: GrowthAccountingExportResponse;
  file_contents: Maybe<Scalars['String']>;
  geographical_areas: GeographicalAreaResponse;
  get_brands_or_companies: Array<CompanyOrBrand>;
  globalGraph: GlobalGraph;
  iam: Maybe<Iam>;
  last_updated_date: Maybe<Scalars['String']>;
  latest_production_date: LatestProductionDateResponse;
  listSessions: Array<DataOpsSession>;
  localGraph: LocalGraph;
  me: Maybe<User>;
  ovr: OvrResponse;
  ovr_export_all: OvrExportAllResponse;
  ovr_v2: MaybeOvrResponse;
  percent_observed: PercentObservedResponse;
  percent_observed_v2: MaybePercentObservedResponse;
  pullRequest: Maybe<PullRequest>;
  pullRequestFiles: Array<PullRequestFile>;
  qps: QpsResponse;
  qps_export_all: QpsExportAllResponse;
  qtd: QtdResponse;
  qtd_export_all: QtdExportAllResponse;
  qtd_percent_observed: PercentObservedResponse;
  qtd_percent_observed_v2: MaybePercentObservedResponse;
  qtd_v2: MaybeQtdResponse;
  qtipBrandTrendsV2: Array<QtipBrandTrends>;
  qtipDescriptions: Array<QtipDescriptions>;
  qtipEarliestDescriptions: Array<QtipDescriptions>;
  qtipLatestDescriptions: Array<QtipDescriptions>;
  qtipRawVsModified: Array<QtipRawVsModified>;
  qtipTaggingConflicts: Array<QtipTaggingConflicts>;
  qtipTransactionCounts: Array<QtipTransactionCounts>;
  repo: Repo;
  researchTrends: ResearchTrendsResponse;
  retention_summary: RetentionSummaryResponse;
  retention_summary_export_all: RetentionSummaryExportAllResponse;
  retention_summary_v2: MaybeRetentionSummaryResponse;
  sales_growth_accounting: MaybeGrowthAccountingResponseSales;
  search: Array<SearchResult>;
  search_geographical_areas: GeographicalAreaResponse;
  search_iam: IamConnection;
  search_teams: TeamConnection;
  search_users: UserConnection;
  session: DataOpsSession;
  team: Maybe<Team>;
  transaction_values: TransactionValuesResponse;
  transaction_values_export_all: TransactionValuesExportAllResponse;
  transaction_values_export_all_v2: MaybeTransactionValuesExportAllResponse;
  transaction_values_v2: MaybeTransactionValuesResponse;
  /** @deprecated */
  trends: TrendsResponse;
  /** @deprecated */
  trends_export_all: TrendsExportResponse;
  /** @deprecated */
  trends_export_all_v2: TrendsExportResponseOrDataError;
  trends_export_all_v3: TrendsExportResponseOrDataError;
  /** @deprecated */
  trends_v2: MaybeTrendsResponse;
  trends_v3: MaybeTrendsResponse;
  user: Maybe<User>;
  yaml: Array<YamlResult>;
};

export type QueryBoilerplateYamlArgs = {
  entity_id: Scalars['String'];
  entity_type: BoilerplateType;
};

export type QueryBranchArgs = {
  branch_name: Scalars['String'];
};

export type QueryBrowse_BusinessesArgs = {
  request: BrowseBusinessesRequest;
};

export type QueryBrowse_Businesses_Export_AllArgs = {
  request: BrowseBusinessesRequest;
};

export type QueryBusinessArgs = {
  id: Scalars['String'];
};

export type QueryCohorted_Retention_Export_AllArgs = {
  request: CohortedRetentionRequest;
};

export type QueryCohorted_Retention_Export_All_V2Args = {
  request: CohortedRetentionRequestV2;
};

export type QueryCohorted_Retention_V2Args = {
  request: CohortedRetentionRequest;
};

export type QueryCohorted_Retention_V3Args = {
  request: CohortedRetentionRequest;
};

export type QueryCohorted_Retention_V4Args = {
  request: CohortedRetentionRequestV2;
};

export type QueryCustomer_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QueryDescendentsArgs = {
  entity_id: Scalars['String'];
};

export type QueryDiffToolResultsArgs = {
  entityId: Scalars['String'];
  maxResults: Scalars['Int'];
  minTransactionCount: Scalars['Int'];
  yamlMain: InputMaybe<Scalars['String']>;
  yamlVerify: InputMaybe<Scalars['String']>;
};

export type QueryDollar_RetentionArgs = {
  request: DollarRetentionRequest;
};

export type QueryDollar_Retention_Export_AllArgs = {
  request: DollarRetentionRequest;
};

export type QueryExport_Customer_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QueryExport_Sales_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QueryFile_ContentsArgs = {
  branch: Scalars['String'];
  id: Scalars['String'];
};

export type QueryGeographical_AreasArgs = {
  codes: Array<Scalars['String']>;
};

export type QueryGet_Brands_Or_CompaniesArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryIamArgs = {
  id: Scalars['BigInt'];
};

export type QueryListSessionsArgs = {
  filter: ListSessionsFilter;
};

export type QueryLocalGraphArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryOvrArgs = {
  request: OvrRequest;
};

export type QueryOvr_Export_AllArgs = {
  request: OvrRequest;
};

export type QueryOvr_V2Args = {
  request: OvrRequest;
};

export type QueryPercent_ObservedArgs = {
  request: PercentObservedRequest;
};

export type QueryPercent_Observed_V2Args = {
  request: PercentObservedRequest;
};

export type QueryPullRequestArgs = {
  branch_name: Scalars['String'];
};

export type QueryPullRequestFilesArgs = {
  branch_name: Scalars['String'];
};

export type QueryQpsArgs = {
  request: QpsRequest;
};

export type QueryQps_Export_AllArgs = {
  request: QpsRequest;
};

export type QueryQtdArgs = {
  request: QtdRequest;
};

export type QueryQtd_Export_AllArgs = {
  request: QtdRequest;
};

export type QueryQtd_Percent_ObservedArgs = {
  request: QtdPercentObservedRequest;
};

export type QueryQtd_Percent_Observed_V2Args = {
  request: QtdPercentObservedRequest;
};

export type QueryQtd_V2Args = {
  request: QtdRequest;
};

export type QueryQtipBrandTrendsV2Args = {
  request: QtipBrandTrendsRequest;
};

export type QueryQtipDescriptionsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipEarliestDescriptionsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipLatestDescriptionsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipRawVsModifiedArgs = {
  comparisonYAML: Array<ComparisonYaml>;
};

export type QueryQtipTaggingConflictsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipTransactionCountsArgs = {
  request: QtipTransactionCountsRequest;
};

export type QueryResearchTrendsArgs = {
  request: ResearchTrendsRequest;
};

export type QueryRetention_SummaryArgs = {
  request: RetentionSummaryRequest;
};

export type QueryRetention_Summary_Export_AllArgs = {
  request: RetentionSummaryRequest;
};

export type QueryRetention_Summary_V2Args = {
  request: RetentionSummaryRequest;
};

export type QuerySales_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QuerySearchArgs = {
  query: Scalars['String'];
};

export type QuerySearch_Geographical_AreasArgs = {
  request: SearchGeographicalAreaRequest;
};

export type QuerySearch_IamArgs = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  request: SearchIamRequest;
};

export type QuerySearch_TeamsArgs = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  request: SearchTeamsRequest;
};

export type QuerySearch_UsersArgs = {
  request: SearchUsersRequest;
};

export type QuerySessionArgs = {
  branch_name: Scalars['String'];
};

export type QueryTeamArgs = {
  id: Scalars['BigInt'];
};

export type QueryTransaction_ValuesArgs = {
  request: TransactionValuesRequest;
};

export type QueryTransaction_Values_Export_AllArgs = {
  request: TransactionValuesRequest;
};

export type QueryTransaction_Values_Export_All_V2Args = {
  request: TransactionValuesRequest;
};

export type QueryTransaction_Values_V2Args = {
  request: TransactionValuesRequest;
};

export type QueryTrendsArgs = {
  request: TrendsRequest;
};

export type QueryTrends_Export_AllArgs = {
  request: TrendsRequest;
};

export type QueryTrends_Export_All_V2Args = {
  request: TrendsRequest;
};

export type QueryTrends_Export_All_V3Args = {
  request: TrendsV3Request;
};

export type QueryTrends_V2Args = {
  request: TrendsRequest;
};

export type QueryTrends_V3Args = {
  request: TrendsV3Request;
};

export type QueryUserArgs = {
  id: Scalars['BigInt'];
};

export type QueryYamlArgs = {
  branch_name: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type Repo = {
  __typename: 'Repo';
  default_branch: Scalars['String'];
};

export type ResearchTrendLine = {
  __typename: 'ResearchTrendLine';
  label: Maybe<Scalars['String']>;
  values: Array<Maybe<Scalars['Float']>>;
};

export enum ResearchTrendsBreakout {
  Channel = 'channel',
  None = 'none',
  Panel = 'panel',
}

export enum ResearchTrendsDimension {
  Volume = 'volume',
  Yoy = 'yoy',
}

export type ResearchTrendsRequest = {
  breakout: ResearchTrendsBreakout;
  dimension: ResearchTrendsDimension;
  end_date: Scalars['String'];
  entity_id: Scalars['String'];
  rollup: ResearchTrendsRollup;
  start_date: Scalars['String'];
};

export type ResearchTrendsResponse = {
  __typename: 'ResearchTrendsResponse';
  avg_txn_value: Array<ResearchTrendLine>;
  date: Array<Scalars['String']>;
  observed_customers: Array<ResearchTrendLine>;
  observed_sales: Array<ResearchTrendLine>;
  observed_transactions: Array<ResearchTrendLine>;
  sales_per_customer: Array<ResearchTrendLine>;
  transactions_per_customer: Array<ResearchTrendLine>;
};

export enum ResearchTrendsRollup {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
}

export type RetentionPeriod = {
  __typename: 'RetentionPeriod';
  /**
   * The data contains an array of floats, where each float represents a percent
   * of customers retained for this period
   */
  data: Array<Maybe<Scalars['Float']>>;
  /**
   * The ID is the label of this "period" represented
   * as the HTML table column. It can either be
   *
   * 1) a numeric period
   * number representing the delta eg. # of months after cohort,
   * useful for answering questions like "how much retention after 6 months" by
   * aligning the retention data for period 6 into one "period" or HTML table column.
   *
   * 2) a date describing a specific period
   * useful for answering questions like "how many customers return around Christmas"
   * by aligning the retention data for "Christmas" into one "period" or HTML table column
   */
  id: Scalars['String'];
};

export type RetentionSummaryExportAllResponse = {
  __typename: 'RetentionSummaryExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type RetentionSummaryRequest = {
  company_id: Scalars['ID'];
  compare_by_company_ids: Array<Scalars['ID']>;
  end_date: Scalars['String'];
  rollup: RetentionSummaryRollup;
  start_date: Scalars['String'];
};

export type RetentionSummaryResponse = {
  __typename: 'RetentionSummaryResponse';
  periods: Array<Scalars['String']>;
  y_series: RetentionSummaryYSeries;
};

export enum RetentionSummaryRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export type RetentionSummaryYSerie = {
  __typename: 'RetentionSummaryYSerie';
  data: Array<Maybe<Scalars['Float']>>;
  name: Scalars['String'];
};

export type RetentionSummaryYSeries = {
  __typename: 'RetentionSummaryYSeries';
  period_over_period_dollar_retention: Array<RetentionSummaryYSerie>;
  period_over_period_retention: Array<RetentionSummaryYSerie>;
};

export enum Role {
  Superadmin = 'SUPERADMIN',
  User = 'USER',
}

/**
 * Dataset with maybe partial history.
 * Certain companies do not have sufficient history to cover the selected range resulting in NULL values.
 * eg if viewing data for 2yrs but we only have 1.5yrs, we inject NULL.
 */
export type ScalingModelSeries = {
  __typename: 'ScalingModelSeries';
  all_time_average: Array<Maybe<Scalars['String']>>;
  exponential_smoothing: Array<Maybe<Scalars['String']>>;
  four_quarter_average: Array<Maybe<Scalars['String']>>;
  previous_quarter: Array<Maybe<Scalars['String']>>;
  previous_year: Array<Maybe<Scalars['String']>>;
  unscaled: Array<Maybe<Scalars['String']>>;
};

export type SearchGeographicalAreaRequest = {
  desc: Scalars['Boolean'];
  limit: Scalars['BigInt'];
  query: Scalars['String'];
};

export type SearchIamRequest = {
  desc: Scalars['Boolean'];
  order_by: IamOrderBy;
  query: Scalars['String'];
};

export type SearchResult = {
  __typename: 'SearchResult';
  brands: Array<Brand>;
  company: Company;
};

export type SearchTeamsRequest = {
  desc: Scalars['Boolean'];
  order_by: TeamsOrderBy;
  query: Scalars['String'];
};

export type SearchUsersRequest = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  query: Scalars['String'];
};

export type Serie = {
  __typename: 'Serie';
  data: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
};

export type SparklineChartSerie = {
  __typename: 'SparklineChartSerie';
  x: Scalars['String'];
  y: Scalars['Float'];
};

export type Team = {
  __typename: 'Team';
  created: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['BigInt'];
  lag: Lag;
  name: Scalars['String'];
  persona: TeamPersona;
  search_iam: IamConnection;
  search_users: UserConnection;
  seats: Scalars['BigInt'];
};

export type TeamSearch_IamArgs = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  request: SearchIamRequest;
};

export type TeamSearch_UsersArgs = {
  request: SearchUsersRequest;
};

export type TeamConnection = {
  __typename: 'TeamConnection';
  count: Scalars['BigInt'];
  nodes: Array<Team>;
  page_info: PageInfo;
};

export enum TeamPersona {
  Corporate = 'CORPORATE',
  HedgeFundFundamental = 'HEDGE_FUND_FUNDAMENTAL',
  HedgeFundQuant = 'HEDGE_FUND_QUANT',
  Internal = 'INTERNAL',
  Other = 'OTHER',
  OtherPublicInvestorFundamental = 'OTHER_PUBLIC_INVESTOR_FUNDAMENTAL',
  OtherPublicInvestorQuant = 'OTHER_PUBLIC_INVESTOR_QUANT',
  PrivateMarketInvestor = 'PRIVATE_MARKET_INVESTOR',
  SellSide = 'SELL_SIDE',
}

export enum TeamsOrderBy {
  Created = 'created',
  Enabled = 'enabled',
  Id = 'id',
  Name = 'name',
}

export type TransactionValuesExportAllResponse = {
  __typename: 'TransactionValuesExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type TransactionValuesRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  /** Make the argument optional for the backwards compatibility */
  filter_brand_types: Array<BrandTypes>;
  start_date: Scalars['String'];
};

export type TransactionValuesResponse = {
  __typename: 'TransactionValuesResponse';
  data: Array<TransactionValuesRow>;
};

export type TransactionValuesRow = {
  __typename: 'TransactionValuesRow';
  cumulative_percent_sales: Scalars['Float'];
  cumulative_percent_transactions: Scalars['Float'];
  percent_sales: Scalars['Float'];
  percent_transactions: Scalars['Float'];
  transaction_value_max: Scalars['Float'];
  transaction_value_min: Scalars['Float'];
};

export type TrendsDataset = {
  __typename: 'TrendsDataset';
  data: Array<Serie>;
  id: Scalars['String'];
};

export type TrendsExportRequest = {
  arguments: TrendsRequest;
  dataset: Scalars['String'];
};

export type TrendsExportResponse = {
  __typename: 'TrendsExportResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type TrendsExportResponseOrDataError = DataError | TrendsExportResponse;

/** @deprecated */
export type TrendsRequest = {
  breakout_by: BreakoutBy;
  bucket: InputMaybe<Scalars['String']>;
  channels: Channels;
  company_id: Scalars['ID'];
  compare_by_company_ids: Array<Scalars['ID']>;
  end_date: Scalars['String'];
  /** Make the argument optional for the backwards compatibility */
  filter_brand_types: InputMaybe<Array<BrandTypes>>;
  geos: InputMaybe<Array<Scalars['ID']>>;
  rollup: TrendsRollup;
  start_date: Scalars['String'];
  target_dimension: TrendsTargetDimension;
};

export type TrendsResponse = {
  __typename: 'TrendsResponse';
  breakout_by: BreakoutBy;
  cbsas: Maybe<Array<GeographicalArea>>;
  companies_or_brands: Array<CompanyOrBrand>;
  data: Array<TrendsDataset>;
  rollup: TrendsRollup;
};

export enum TrendsRollup {
  Day = 'day',
  FiscalQuarter = 'fiscal_quarter',
  Month = 'month',
  Quarter = 'quarter',
  Trailing_7Days = 'trailing_7_days',
  Trailing_12Months = 'trailing_12_months',
  Trailing_30Days = 'trailing_30_days',
  Trailing_90Days = 'trailing_90_days',
  Week = 'week',
  Year = 'year',
}

/** Target dimension filter options */
export enum TrendsTargetDimension {
  Indexed = 'indexed',
  ObservedVolume = 'observed_volume',
  PopGrowth = 'pop_growth',
  YoyGrowth = 'yoy_growth',
}

export type TrendsV3Request = {
  breakout_by: BreakoutBy;
  bucket: InputMaybe<Scalars['String']>;
  company_id: Scalars['ID'];
  compare_by_company_ids: Array<Scalars['ID']>;
  end_date: Scalars['String'];
  /** Make the argument optional for the backwards compatibility */
  filter_brand_types: Array<BrandTypes>;
  filter_channels: Array<ChannelsV2>;
  geos: InputMaybe<Array<Scalars['ID']>>;
  rollup: TrendsRollup;
  start_date: Scalars['String'];
  target_dimension: TrendsTargetDimension;
};

export type UpsertIamRequest = {
  arn: InputMaybe<Scalars['String']>;
  aws_account_id: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: InputMaybe<Scalars['Int']>;
  resource: InputMaybe<Scalars['String']>;
  team_id: Scalars['BigInt'];
};

export type UpsertTeamRequest = {
  enabled: Scalars['Boolean'];
  id: InputMaybe<Scalars['Int']>;
  lag: Lag;
  name: Scalars['String'];
  persona: TeamPersona;
  seats: Scalars['BigInt'];
};

export type UpsertUserRequest = {
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  first_name: Scalars['String'];
  id: InputMaybe<Scalars['Int']>;
  last_name: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
  persona: UserPersona;
  role: Role;
  team_id: Scalars['BigInt'];
};

export type User = {
  __typename: 'User';
  created: Scalars['DateTime'];
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  first_name: Scalars['String'];
  has_setup_totp: Scalars['Boolean'];
  id: Scalars['BigInt'];
  last_login: Maybe<Scalars['DateTime']>;
  last_name: Scalars['String'];
  persona: UserPersona;
  require_2fa: Scalars['Boolean'];
  role: Role;
  team: Team;
  updated: Scalars['DateTime'];
  verified_2fa: Scalars['Boolean'];
};

export type UserConnection = {
  __typename: 'UserConnection';
  count: Scalars['BigInt'];
  nodes: Array<User>;
  page_info: PageInfo;
};

export enum UserPersona {
  CSuiteExec = 'C_SUITE_EXEC',
  DataScientist = 'DATA_SCIENTIST',
  InvestmentOrResearch = 'INVESTMENT_OR_RESEARCH',
  Other = 'OTHER',
  PortfolioManager = 'PORTFOLIO_MANAGER',
  QuantResearch = 'QUANT_RESEARCH',
  Trader = 'TRADER',
}

export type YamlFile = {
  entityId: Scalars['String'];
  yaml: InputMaybe<Scalars['String']>;
};

export type YamlResult = {
  __typename: 'YamlResult';
  base: Maybe<Scalars['String']>;
  branch: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type SearchQuery = {
  __typename: 'Query';
  search: Array<{
    __typename: 'SearchResult';
    company: {
      __typename: 'Company';
      id: string;
      title: string;
      website: string;
      ticker: string | null;
      exchange: string | null;
      logo: string | null;
    };
    brands: Array<{
      __typename: 'Brand';
      id: string;
      title: string;
      website: string;
      logo: string | null;
    }>;
  }>;
};

export type SearchCompanyFragment = {
  __typename: 'Company';
  id: string;
  title: string;
  website: string;
  ticker: string | null;
  exchange: string | null;
  logo: string | null;
};

export type SearchBrandFragment = {
  __typename: 'Brand';
  id: string;
  title: string;
  website: string;
  logo: string | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: number;
    role: Role;
    first_name: string;
    last_name: string;
    email: string;
    require_2fa: boolean;
    verified_2fa: boolean;
    has_setup_totp: boolean;
    team: { __typename: 'Team'; id: number };
  } | null;
};

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestResetPasswordMutation = {
  __typename: 'Mutation';
  request_password_reset_email: void | null;
};

export type Request2FaCodeMutationVariables = Exact<{ [key: string]: never }>;

export type Request2FaCodeMutation = {
  __typename: 'Mutation';
  request_2fa_code: void | null;
};

export type Verify2FaCodeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type Verify2FaCodeMutation = {
  __typename: 'Mutation';
  verify_2fa_code: boolean;
};

export type VerifyTotpCodeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyTotpCodeMutation = {
  __typename: 'Mutation';
  totp_verify: boolean;
};

export const SearchCompanyFragmentDoc = `
    fragment SearchCompany on Company {
  id
  title
  website
  ticker
  exchange
  logo
}
    `;
export const SearchBrandFragmentDoc = `
    fragment SearchBrand on Brand {
  id
  title
  website
  logo
}
    `;
export const SearchDocument = `
    query Search($query: String!) {
  search(query: $query) {
    company {
      id
      ...SearchCompany
    }
    brands {
      id
      ...SearchBrand
    }
  }
}
    ${SearchCompanyFragmentDoc}
${SearchBrandFragmentDoc}`;
export const useSearchQuery = <TData = SearchQuery, TError = unknown>(
  variables: SearchQueryVariables,
  options?: UseQueryOptions<SearchQuery, TError, TData>
) =>
  useQuery<SearchQuery, TError, TData>(
    ['Search', variables],
    fetchGQL<SearchQuery, SearchQueryVariables>(SearchDocument, variables),
    options
  );
export const MeDocument = `
    query Me {
  me {
    id
    role
    first_name
    last_name
    email
    team {
      id
    }
    require_2fa
    verified_2fa
    has_setup_totp
  }
}
    `;
export const useMeQuery = <TData = MeQuery, TError = unknown>(
  variables?: MeQueryVariables,
  options?: UseQueryOptions<MeQuery, TError, TData>
) =>
  useQuery<MeQuery, TError, TData>(
    variables === undefined ? ['Me'] : ['Me', variables],
    fetchGQL<MeQuery, MeQueryVariables>(MeDocument, variables),
    options
  );
export const RequestResetPasswordDocument = `
    mutation RequestResetPassword($email: String!) {
  request_password_reset_email(email: $email)
}
    `;
export const useRequestResetPasswordMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RequestResetPasswordMutation,
    TError,
    RequestResetPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<
    RequestResetPasswordMutation,
    TError,
    RequestResetPasswordMutationVariables,
    TContext
  >(
    ['RequestResetPassword'],
    (variables?: RequestResetPasswordMutationVariables) =>
      fetchGQL<
        RequestResetPasswordMutation,
        RequestResetPasswordMutationVariables
      >(RequestResetPasswordDocument, variables)(),
    options
  );
export const Request2FaCodeDocument = `
    mutation Request2FACode {
  request_2fa_code
}
    `;
export const useRequest2FaCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Request2FaCodeMutation,
    TError,
    Request2FaCodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    Request2FaCodeMutation,
    TError,
    Request2FaCodeMutationVariables,
    TContext
  >(
    ['Request2FACode'],
    (variables?: Request2FaCodeMutationVariables) =>
      fetchGQL<Request2FaCodeMutation, Request2FaCodeMutationVariables>(
        Request2FaCodeDocument,
        variables
      )(),
    options
  );
export const Verify2FaCodeDocument = `
    mutation Verify2FaCode($token: String!) {
  verify_2fa_code(token: $token)
}
    `;
export const useVerify2FaCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Verify2FaCodeMutation,
    TError,
    Verify2FaCodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    Verify2FaCodeMutation,
    TError,
    Verify2FaCodeMutationVariables,
    TContext
  >(
    ['Verify2FaCode'],
    (variables?: Verify2FaCodeMutationVariables) =>
      fetchGQL<Verify2FaCodeMutation, Verify2FaCodeMutationVariables>(
        Verify2FaCodeDocument,
        variables
      )(),
    options
  );
export const VerifyTotpCodeDocument = `
    mutation VerifyTotpCode($token: String!) {
  totp_verify(token: $token)
}
    `;
export const useVerifyTotpCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    VerifyTotpCodeMutation,
    TError,
    VerifyTotpCodeMutationVariables,
    TContext
  >
) =>
  useMutation<
    VerifyTotpCodeMutation,
    TError,
    VerifyTotpCodeMutationVariables,
    TContext
  >(
    ['VerifyTotpCode'],
    (variables?: VerifyTotpCodeMutationVariables) =>
      fetchGQL<VerifyTotpCodeMutation, VerifyTotpCodeMutationVariables>(
        VerifyTotpCodeDocument,
        variables
      )(),
    options
  );
