import { useTheme } from '@mui/material';
import { scalePoint } from 'd3-scale';
import { extentOfDataset, formatPercent, optimize } from '@groundwater/app/ui';
import { CohortedRetentionVisualizationProps } from '../features/CohortedRetention/types';
import { StyledCohortedRetentionLineChart } from '../features/CohortedRetention/CohortedRetentionLineChart.styles';
import {
  CohortedRetentionLineChartGlobalConfig,
  cohortedRetentionLineChartTooltip,
} from '../features/CohortedRetention/CohortedRetentionLineChartTooltip';
import { interpolateViridis } from 'd3-scale-chromatic';

export type RetentionLineProps = CohortedRetentionVisualizationProps & {
  yZoom: boolean;
  axisLabel: string;
};

export const RetentionLine: React.FC<RetentionLineProps> = ({
  xaxis,
  periods,
  cohorts,
  yZoom,
  axisLabel,
}) => {
  const muiTheme = useTheme();
  const isCalendarIndex = xaxis === 'calendar';

  // This corresponds to the "x axis" labels are either numeric periods or dates,
  // in both cases it is the array of IDs from each series
  // NOTE - this currently excludes "period 0" which is a special case
  const xAxis: string[] = periods.map((period) => period.id);

  const getColorCoefficient = scalePoint([0, 1]).domain(cohorts);

  const yAxis = cohorts.reduce(
    (
      acc: {
        color: string;
        data: (number | null)[];
        name: string;
      }[],
      period,
      cohort_period_index
    ) => {
      const serieValues: (number | null)[] = periods.map((period) => {
        const value = period.data[cohort_period_index] ?? null;
        return value;
      });

      // Map x-axis categories to colors
      const colorCoefficient = getColorCoefficient(period);

      if (colorCoefficient === undefined) {
        throw new Error('Value must be in range');
      }

      return [
        ...acc,
        {
          name: period,
          color: interpolateViridis(colorCoefficient),
          data: serieValues,
        },
      ];
    },
    []
  );

  const data = isCalendarIndex
    ? // We don't want to draw an empty serie on the chart hence omit the first index which is null for all the series in order to
      {
        xSeries: xAxis.slice(1),
        ySeries: yAxis.map((i) => ({
          name: i.name,
          color: i.color,
          data: i.data.slice(1),
        })),
      }
    : {
        xSeries: xAxis,
        ySeries: yAxis,
      };

  const { minFromDataset, maxFromDataset } = extentOfDataset(data.ySeries);

  const { tickAmount, yMin, yMax } = optimize(
    yZoom,
    yZoom,
    minFromDataset,
    maxFromDataset
  );

  return (
    <StyledCohortedRetentionLineChart
      type="line"
      height={450}
      series={data.ySeries}
      options={{
        fill: {
          opacity: 1,
          gradient: {
            opacityFrom: 0.4,
            opacityTo: 0,
            shadeIntensity: 0,
            stops: [0, 100],
            type: 'vertical',
          },
        },
        grid: {
          borderColor: muiTheme.palette.grey[300],
          strokeDashArray: 3,
        },
        states: {
          active: {
            filter: {
              type: 'darken',
              value: 0.88,
            },
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.04,
            },
          },
        },
        tooltip: {
          marker: {
            show: true,
          },
          custom: function (opts: {
            w: CohortedRetentionLineChartGlobalConfig;
            dataPointIndex: string | number;
          }) {
            return cohortedRetentionLineChartTooltip(opts);
          },
          x: {
            // Hide tooltip header
            show: false,
            format: 'dd MMM yyyy',
          },
          y: {
            formatter: formatPercent,
          },
        },
        chart: {
          id: 'cohorted-retention-line',
          animations: {
            enabled: false,
          },
          foreColor: muiTheme.palette.grey[500],
          // Disable toolbar functionality
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          events: {
            mounted: function (chart: any) {
              chart.windowResizeHandler();
            },
          },
        },
        // Configure the chart lines (width, curve, etc.)
        stroke: {
          width: 1.5,
          curve: 'straight',
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount,
          decimalsInFloat: 2,
          labels: {
            formatter: formatPercent,
          },
        },
        xaxis: {
          type: xaxis === 'calendar' ? 'datetime' : 'category',
          categories: data.xSeries,
          title: {
            text: axisLabel,
          },
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: "dd MMM 'yy",
              hour: 'HH:mm',
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '13',
          fontWeight: 500,
          offsetX: 5,
        },
      }}
    />
  );
};
