import { SearchQuery } from '../../gql-generated';
import { BrandOrCompany } from './types';
import { UseQueryResult } from 'react-query';
import React from 'react';
import { BusinessSearchResultNotFound } from './Result/NotFound';
import { BusinessSearchResultsError } from './Result/Error';
import { BusinessSearchResultsLoading } from './Result/Loading';
import { BusinessSearchResultCompany } from './Result/Company';
import { BusinessSearchResultBrand } from './Result/Brand';
import { List } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

export interface BusinessSearchResultsProps {
  searchResult: UseQueryResult<SearchQuery>;
  onSelect: (companyOrBrand: BrandOrCompany) => void;
  isSelected: (companyOrBrand: BrandOrCompany) => boolean;
  query: string;
  api_url: string;
}

/* TODO - suspense not actually used, due to starvation issue with charts https://github.com/facebook/react/issues/21668#issuecomment-1087995420 */
export const BusinessSearchResults: React.FC<BusinessSearchResultsProps> = ({
  searchResult,
  isSelected,
  onSelect,
  query,
  api_url,
}) => {
  // Loading state
  if (searchResult.isLoading) return <BusinessSearchResultsLoading />;

  // Error state
  if (searchResult.error)
    return <BusinessSearchResultsError error={searchResult.error} />;

  // No results state
  if (!searchResult.data || searchResult.data.search.length === 0)
    return <BusinessSearchResultNotFound />;

  // Nominal state
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <BusinessSearchResultsError error={error} />
      )}
      resetKeys={[query]}
    >
      <List data-testid="search-results">
        {/* map over items */}
        {searchResult.data.search.map((result, index) => (
          <React.Fragment key={result.company.id}>
            <BusinessSearchResultCompany
              {...{
                onSelect,
                isSelected: isSelected(result.company),
                index,
                company: result.company,
                api_url,
              }}
            />
            {result.brands.map((brand, index) => (
              <BusinessSearchResultBrand
                key={brand.id}
                {...{
                  onSelect,
                  isSelected: isSelected(brand),
                  index,
                  brand,
                  api_url,
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </List>
    </ErrorBoundary>
  );
};

export { BusinessSearchResultBrand, BusinessSearchResultCompany };
