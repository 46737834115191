import { Link as RouterLink } from 'react-router-dom';
import { Button, Link, Stack, Typography } from '@mui/material';
import { BssoPageFrame } from './BssoPageFrame';

export const BssoLandingPage = () => {
  return (
    <BssoPageFrame>
      <Typography variant="h3" fontWeight={700} gutterBottom paragraph>
        Unlock the power of transaction data
      </Typography>
      <Typography variant="body2" gutterBottom paragraph>
        Bloomberg Second Measure is a leading provider of transaction data
        analytics that reliably deliver valuable insights into company
        performance and consumer trends. Our exclusive dataset derives
        aggregated analytics and insights from billions of purchases, which come
        from a subset of a U.S. consumer panel that includes 20+ million
        members. Products built from this panel are on a 3-day lag to power
        faster decision making.
      </Typography>
      <Typography variant="body2" gutterBottom paragraph>
        Investors rely on Bloomberg Second Measure to track company performance
        intra-quarter, validate investment theses, gain industry insights, and
        identify fast growing consumer companies.
      </Typography>
      <Typography variant="body2" paragraph>
        <Link to="/bsso-failure/demo-request" component={RouterLink}>
          Request a demo
        </Link>
        .
      </Typography>
      <Typography variant="body2" paragraph>
        Are you a Bloomberg Second Measure subscriber, but don’t have access?
        Contact{' '}
        <Link
          href="mailto:bsmsupport@bloomberg.net?subject=Unable to login with BSSO"
          target="_blank"
        >
          support
        </Link>{' '}
        for assistance.
      </Typography>
      <Typography variant="body2" paragraph gutterBottom>
        ALTD &lt;GO&gt;: These analytics and insights are now available on the
        Bloomberg Terminal® alongside news, financials, estimates, research, and
        more. Get an early read on company performance vs. consensus estimates
        intra-quarter and dive into the drivers of company health within your
        investment research workflow at ALTD &lt;GO&gt;.
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button component={RouterLink} variant="contained" to="/">
          Client login
        </Button>
        <Button
          component={RouterLink}
          variant="contained"
          to="/bsso-failure/demo-request"
        >
          Request a demo
        </Button>
      </Stack>
    </BssoPageFrame>
  );
};
