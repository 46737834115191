import { TableCell } from '@mui/material';

export const DefaultCell: React.FC<{}> = ({ children }) => {
  return (
    <TableCell
      component="div"
      variant="body"
      style={{ height: '100%', width: '100%', display: 'block' }}
    >
      {children}
    </TableCell>
  );
};
