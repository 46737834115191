/* eslint-disable */
import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from 'react-query';
import { fetchGQL } from '@groundwater/frontend-api-client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: number;
  DateTime: string;
  Void: void;
};

export enum BoilerplateType {
  Brand = 'BRAND',
  Company = 'COMPANY',
}

export type Branch = {
  __typename: 'Branch';
  url: Scalars['String'];
};

export type Brand = {
  __typename: 'Brand';
  channels: Array<Channel>;
  id: Scalars['ID'];
  industry: Maybe<Industry>;
  logo: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: BrandType;
  ultimate_parent: Maybe<Company>;
  website: Scalars['String'];
};

export enum BrandType {
  AccountFunding = 'account_funding',
  Financing = 'financing',
  Gig = 'gig',
  Marketplace = 'marketplace',
  PaymentServiceProvider = 'payment_service_provider',
  PrimaryMerchant = 'primary_merchant',
  Reservations = 'reservations',
  Saas = 'saas',
  ThirdPartyDelivery = 'third_party_delivery',
  Wallet = 'wallet',
}

export enum BrandTypes {
  Intermediary = 'intermediary',
  Primary = 'primary',
}

/** Break out by filter options */
export enum BreakoutBy {
  Brand = 'brand',
  Channel = 'channel',
  CustomerSpend = 'customer_spend',
  Geo = 'geo',
  None = 'none',
  TransactionValue = 'transaction_value',
  Veterancy = 'veterancy',
}

export type BrowseBusinessesBrandNode = {
  __typename: 'BrowseBusinessesBrandNode';
  brand: Brand;
  observed_customers: Scalars['BigInt'];
  observed_customers_ltm_chart: Array<SparklineChartSerie>;
  observed_customers_yoy_percent: Maybe<Scalars['Float']>;
  observed_sales_dollars: Scalars['BigInt'];
  observed_sales_ltm_chart: Array<SparklineChartSerie>;
  observed_sales_yoy_percent: Maybe<Scalars['Float']>;
  observed_transactions: Scalars['BigInt'];
  observed_transactions_ltm_chart: Array<SparklineChartSerie>;
  observed_transactions_yoy_percent: Maybe<Scalars['Float']>;
};

export type BrowseBusinessesCompanyNode = {
  __typename: 'BrowseBusinessesCompanyNode';
  company: Company;
  market_capitalization_dollars: Scalars['BigInt'];
  observed_customers: Scalars['BigInt'];
  observed_customers_ltm_chart: Array<SparklineChartSerie>;
  observed_customers_yoy_percent: Maybe<Scalars['Float']>;
  observed_sales_dollars: Scalars['BigInt'];
  observed_sales_ltm_chart: Array<SparklineChartSerie>;
  observed_sales_yoy_percent: Maybe<Scalars['Float']>;
  observed_transactions: Scalars['BigInt'];
  observed_transactions_ltm_chart: Array<SparklineChartSerie>;
  observed_transactions_yoy_percent: Maybe<Scalars['Float']>;
  r2_coefficient: Maybe<Scalars['Float']>;
};

export type BrowseBusinessesExportAllResponse = {
  __typename: 'BrowseBusinessesExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type BrowseBusinessesNode =
  | BrowseBusinessesBrandNode
  | BrowseBusinessesCompanyNode;

export type BrowseBusinessesRequest = {
  business_type: BusinessType;
};

export type BrowseBusinessesResponse = {
  __typename: 'BrowseBusinessesResponse';
  data_as_of_month: Scalars['String'];
  nodes: Array<BrowseBusinessesNode>;
};

export enum BusinessType {
  Brand = 'brand',
  Company = 'company',
}

export enum Channel {
  Online = 'online',
  Retail = 'retail',
}

/**
 * Channel filter options
 * @deprecated
 */
export enum Channels {
  All = 'all',
  Online = 'online',
  Retail = 'retail',
}

/** Channel filter options */
export enum ChannelsV2 {
  Online = 'online',
  Retail = 'retail',
}

export type ChildBrand = {
  __typename: 'ChildBrand';
  brand: Brand;
  expiration_date: Scalars['DateTime'];
  is_expired: Scalars['Boolean'];
};

export enum CohortIndex {
  Calendar = 'calendar',
  Cohort = 'cohort',
}

/** @deprecated */
export type CohortedRetentionDynamicSerie = {
  __typename: 'CohortedRetentionDynamicSerie';
  data: Array<Maybe<Scalars['Float']>>;
  heading: Scalars['String'];
  id: Scalars['String'];
};

export type CohortedRetentionExportAllResponse = {
  __typename: 'CohortedRetentionExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

/** @deprecated */
export type CohortedRetentionRequest = {
  cohort_index: CohortIndex;
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: CohortedRetentionRollup;
  start_date: Scalars['String'];
};

export type CohortedRetentionRequestV2 = {
  bucket: InputMaybe<Scalars['String']>;
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: CohortedRetentionRollup;
  start_date: Scalars['String'];
  xaxis: CohortIndex;
};

/** @deprecated */
export type CohortedRetentionResponse = {
  __typename: 'CohortedRetentionResponse';
  data: Array<CohortedRetentionSerie>;
};

export type CohortedRetentionResponseV3 = {
  __typename: 'CohortedRetentionResponseV3';
  /**
   * This describes the "cohorts" which are groups of customers acquired in a given period.
   * It is an array of date strings where each date represents the acquisition period of the cohort.
   * Each cohort represents a row in our HTML table, ergo these are the y-axis labels
   *
   * the number of periods and therefore cohorts depends on the selected time range and rollup granularity
   */
  cohorts: Array<Scalars['String']>;
  /**
   * Because this grid inherently visualizes customer retention, you need different start/end
   * periods to make an interesting comparison. You can think of retention in period 0 as always 100%.
   *
   * Instead of showing a column that is just always 100% we show a column with the
   * absolute number of customers in the cohort.
   *
   * Because this "period" is special (contains data with different units) from the other periods,
   * it is it's own property and type.
   */
  period_0_customers: Array<Scalars['Float']>;
  /**
   * Each period represents retention data for a given period, the number of periods
   * depends on the selected time range and rollup granularity
   */
  periods: Array<RetentionPeriod>;
};

export enum CohortedRetentionRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

/** @deprecated */
export type CohortedRetentionSerie =
  | CohortedRetentionDynamicSerie
  | CohortedRetentionStaticSerie;

/** @deprecated */
export type CohortedRetentionStaticSerie = {
  __typename: 'CohortedRetentionStaticSerie';
  data: Array<Scalars['String']>;
  heading: CohortedRetentionStaticSerieId;
  id: CohortedRetentionStaticSerieId;
};

export enum CohortedRetentionStaticSerieId {
  CohortPeriod = 'cohort_period',
  Period_0Customers = 'period_0_customers',
}

export type CommitResult = {
  __typename: 'CommitResult';
  sha: Scalars['String'];
};

export type Company = {
  __typename: 'Company';
  bloomberg_id: Maybe<Scalars['String']>;
  /** @deprecated */
  brands: Array<Brand>;
  channels: Array<Channel>;
  /** All brand children relationships for this company */
  child_brands: Array<ChildBrand>;
  exchange: Maybe<Scalars['String']>;
  figi: Maybe<Scalars['String']>;
  has_mixed_brand_types: Scalars['Boolean'];
  id: Scalars['ID'];
  logo: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  quarters: Array<Quarter>;
  reports_quarterly: Scalars['Boolean'];
  ticker: Maybe<Scalars['String']>;
  title: Scalars['String'];
  website: Scalars['String'];
};

export type CompanyOrBrand = Brand | Company;

export type ComparisonYaml = {
  entityId: Scalars['String'];
  yamlMain: InputMaybe<Scalars['String']>;
  yamlVerify: InputMaybe<Scalars['String']>;
};

export type CreatePullRequestResult = {
  __typename: 'CreatePullRequestResult';
  url: Scalars['String'];
};

export type DataError = {
  __typename: 'DataError';
  message: Scalars['String'];
};

export type DataOpsFile = {
  contents: InputMaybe<Scalars['String']>;
  entity_id: Scalars['String'];
};

export type DataOpsSession = {
  __typename: 'DataOpsSession';
  author: User;
  branch_name: Scalars['String'];
  created: Scalars['String'];
  diffToolMaxResults: Scalars['Int'];
  diffToolMinTransactionCount: Scalars['Int'];
  diffToolPivots: Scalars['String'];
  files: Array<DataOpsSessionFile>;
  id: Scalars['Int'];
  updated: Scalars['String'];
};

export type DataOpsSessionFile = {
  __typename: 'DataOpsSessionFile';
  entity_id: Scalars['String'];
};

export type DiffToolResult = {
  __typename: 'DiffToolResult';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<DiffToolRow>;
  warnings: Array<Message>;
};

export type DiffToolRow = {
  __typename: 'DiffToolRow';
  average_transaction_value: Scalars['Float'];
  dp4_merchant_category_xcd: Scalars['String'];
  dp4_merchant_city_name: Scalars['String'];
  dp4_merchant_state: Scalars['String'];
  mcc_description: Scalars['String'];
  new_channel: Scalars['String'];
  new_match: Scalars['Boolean'];
  old_channel: Scalars['String'];
  old_match: Scalars['Boolean'];
  taggable_merchant_name: Scalars['String'];
  total_sales: Scalars['Float'];
  transaction_count: Scalars['Float'];
};

export type DollarRetentionExportAllResponse = {
  __typename: 'DollarRetentionExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type DollarRetentionRequest = {
  bucket: InputMaybe<Scalars['String']>;
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: DollarRetentionRollup;
  start_date: Scalars['String'];
  xaxis: DollarRetentionXaxis;
};

export type DollarRetentionResponse = {
  __typename: 'DollarRetentionResponse';
  /**
   * This describes the "cohorts" which are groups of customers acquired in a given period.
   * It is an array of date strings where each date represents the acquisition period of the cohort.
   * Each cohort represents a row in our HTML table, ergo these are the y-axis labels
   *
   * the number of periods and therefore cohorts depends on the selected time range and rollup granularity
   */
  cohorts: Array<Scalars['String']>;
  /**
   * Because this grid inherently visualizes customer retention, you need different start/end
   * periods to make an interesting comparison. You can think of retention in period 0 as always 100%.
   *
   * Instead of showing a column that is just always 100% we show a column with the
   * absolute number of customers in the cohort.
   *
   * Because this "period" is special (contains data with different units) from the other periods,
   * it is it's own property and type.
   */
  period_0_sales: Array<Scalars['Float']>;
  /**
   * Each period represents retention data for a given period, the number of periods
   * depends on the selected time range and rollup granularity
   *
   * This corresponds to how much of the dollars / customers in "period 0" were retained
   * accordingly.
   */
  periods: Array<RetentionPeriod>;
};

export enum DollarRetentionRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export enum DollarRetentionXaxis {
  Calendar = 'calendar',
  Cohort = 'cohort',
}

export type FeatureUsageRequest = {
  feature: GroundwaterFeature;
  parameters: Scalars['String'];
};

export enum FileStatus {
  Added = 'added',
  Modified = 'modified',
  Removed = 'removed',
}

export type GeographicalArea = {
  __typename: 'GeographicalArea';
  code: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  type: GeographicalAreaType;
};

export type GeographicalAreaResponse = {
  __typename: 'GeographicalAreaResponse';
  results: Array<GeographicalArea>;
};

export enum GeographicalAreaType {
  City = 'CITY',
  Metro = 'METRO',
  State = 'STATE',
}

export type GlobalGraph = {
  __typename: 'GlobalGraph';
  edges: Array<GlobalGraphEdge>;
  nodes: Array<GlobalGraphNode>;
};

export type GlobalGraphEdge = {
  __typename: 'GlobalGraphEdge';
  child_id: Scalars['String'];
  parent_id: Scalars['String'];
};

export type GlobalGraphNode = {
  __typename: 'GlobalGraphNode';
  entity_type: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export enum GroundwaterFeature {
  BrowseBusinesses = 'browse_businesses',
  CohortedRetention = 'cohorted_retention',
  DollarRetention = 'dollar_retention',
  GrowthAccounting = 'growth_accounting',
  ObservedVsReported = 'observed_vs_reported',
  QuarterToDate = 'quarter_to_date',
  QuarterlyPerformanceScreening = 'quarterly_performance_screening',
  RetentionSummary = 'retention_summary',
  TransactionValues = 'transaction_values',
  Trends = 'trends',
}

export type GrowthAccountingExportResponse = {
  __typename: 'GrowthAccountingExportResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type GrowthAccountingPeriodCustomers = {
  __typename: 'GrowthAccountingPeriodCustomers';
  /** customers from the previous period who are no longer customers */
  churned: Scalars['Float'];
  date: Scalars['String'];
  /** customers that were acquired for the first time in this period */
  new: Scalars['Float'];
  quick_ratio: Maybe<Scalars['Float']>;
  /** customers that were not active in the previous period */
  resurrected: Scalars['Float'];
  /** customers that were active in the previous period */
  retained: Scalars['Float'];
};

export type GrowthAccountingPeriodSales = {
  __typename: 'GrowthAccountingPeriodSales';
  /** customers from the previous period who are no longer customers */
  churned: Scalars['Float'];
  contraction: Scalars['Float'];
  date: Scalars['String'];
  expansion: Scalars['Float'];
  /** customers that were acquired for the first time in this period */
  new: Scalars['Float'];
  quick_ratio: Maybe<Scalars['Float']>;
  /** customers that were not active in the previous period */
  resurrected: Scalars['Float'];
  /** customers that were active in the previous period */
  retained: Scalars['Float'];
};

export type GrowthAccountingRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  rollup: GrowthAccountingRollup;
  start_date: Scalars['String'];
};

export type GrowthAccountingResponseCustomers = {
  __typename: 'GrowthAccountingResponseCustomers';
  periods: Array<GrowthAccountingPeriodCustomers>;
};

export type GrowthAccountingResponseSales = {
  __typename: 'GrowthAccountingResponseSales';
  periods: Array<GrowthAccountingPeriodSales>;
};

export enum GrowthAccountingRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export type Iam = {
  __typename: 'Iam';
  arn: Maybe<Scalars['String']>;
  aws_account_id: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['BigInt'];
  resource: Maybe<Scalars['String']>;
  team: Team;
};

export type IamConnection = {
  __typename: 'IamConnection';
  count: Scalars['BigInt'];
  nodes: Array<Iam>;
  page_info: PageInfo;
};

export enum IamOrderBy {
  Arn = 'arn',
  AwsAccountId = 'aws_account_id',
  Created = 'created',
  Id = 'id',
  Resource = 'resource',
}

export type Industry = {
  __typename: 'Industry';
  id: Scalars['BigInt'];
  title: Scalars['String'];
};

export enum Lag {
  Days_3 = 'DAYS_3',
}

export type LatestProductionDateResponse = {
  __typename: 'LatestProductionDateResponse';
  date: Scalars['String'];
};

export enum ListSessionsFilter {
  All = 'ALL',
  Mine = 'MINE',
}

export type LocalGraph = {
  __typename: 'LocalGraph';
  edges: Array<LocalGraphEdge>;
  nodes: Array<LocalGraphNode>;
};

export type LocalGraphEdge = {
  __typename: 'LocalGraphEdge';
  child_id: Scalars['String'];
  effective_date: Maybe<Scalars['String']>;
  expiration_date: Maybe<Scalars['String']>;
  is_direct: Scalars['Boolean'];
  parent_id: Scalars['String'];
};

export type LocalGraphNode = {
  __typename: 'LocalGraphNode';
  entity_type: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is_intermediary: Scalars['Boolean'];
};

/** @deprecated */
export type MaybeCohortedRetentionResponse =
  | CohortedRetentionResponse
  | DataError;

export type MaybeCohortedRetentionResponseV3 =
  | CohortedRetentionResponseV3
  | DataError;

export type MaybeDollarRetentionResponse = DataError | DollarRetentionResponse;

export type MaybeGrowthAccountingResponseCustomers =
  | DataError
  | GrowthAccountingResponseCustomers;

export type MaybeGrowthAccountingResponseSales =
  | DataError
  | GrowthAccountingResponseSales;

export type MaybeOvrResponse = DataError | OvrResponse;

export type MaybePercentObservedResponse = DataError | PercentObservedResponse;

export type MaybeQtdResponse = DataError | QtdResponse;

export type MaybeRetentionSummaryResponse =
  | DataError
  | RetentionSummaryResponse;

export type MaybeTransactionValuesExportAllResponse =
  | DataError
  | TransactionValuesExportAllResponse;

export type MaybeTransactionValuesResponse =
  | DataError
  | TransactionValuesResponse;

export type MaybeTrendsResponse = DataError | TrendsResponse;

export type Message = {
  __typename: 'Message';
  message: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  bulk_enable_users: Scalars['String'];
  commit: CommitResult;
  createBranch: Scalars['Boolean'];
  createPullRequest: CreatePullRequestResult;
  createSession: DataOpsSession;
  feature_usage: Maybe<Scalars['Void']>;
  removeEntity: Scalars['Boolean'];
  request_2fa_code: Maybe<Scalars['Void']>;
  request_password_reset_email: Maybe<Scalars['Void']>;
  reset_password: Maybe<Scalars['Void']>;
  saveDiffToolPivots: Scalars['Boolean'];
  star: Scalars['Boolean'];
  totp_enroll: Scalars['String'];
  totp_unenroll: Maybe<Scalars['Void']>;
  totp_verify: Scalars['Boolean'];
  /** @deprecated */
  trends_export: TrendsExportResponse;
  unstar: Scalars['Boolean'];
  updateSession: Scalars['Boolean'];
  upsert_iam: Iam;
  upsert_team: Team;
  upsert_user: User;
  verify_2fa_code: Scalars['Boolean'];
  welcome_email: Maybe<Scalars['Void']>;
};

export type MutationBulk_Enable_UsersArgs = {
  emails_not_to_enable: Scalars['String'];
};

export type MutationCommitArgs = {
  branch_name: Scalars['String'];
  files: Array<DataOpsFile>;
  message: Scalars['String'];
};

export type MutationCreateBranchArgs = {
  branch_name: Scalars['String'];
};

export type MutationCreatePullRequestArgs = {
  branch_name: Scalars['String'];
};

export type MutationCreateSessionArgs = {
  branch_name: Scalars['String'];
};

export type MutationFeature_UsageArgs = {
  request: FeatureUsageRequest;
};

export type MutationRemoveEntityArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
};

export type MutationRequest_Password_Reset_EmailArgs = {
  email: Scalars['String'];
};

export type MutationReset_PasswordArgs = {
  new_password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationSaveDiffToolPivotsArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
  pivots: Scalars['String'];
};

export type MutationStarArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
};

export type MutationTotp_VerifyArgs = {
  token: Scalars['String'];
};

export type MutationTrends_ExportArgs = {
  request: TrendsExportRequest;
};

export type MutationUnstarArgs = {
  branch_name: Scalars['String'];
  entity_id: Scalars['String'];
};

export type MutationUpdateSessionArgs = {
  branch_name: Scalars['String'];
  diffMaxResults: Scalars['Int'];
  diffMinTransactionCount: Scalars['Int'];
};

export type MutationUpsert_IamArgs = {
  iam: UpsertIamRequest;
};

export type MutationUpsert_TeamArgs = {
  team: UpsertTeamRequest;
};

export type MutationUpsert_UserArgs = {
  user: UpsertUserRequest;
};

export type MutationVerify_2fa_CodeArgs = {
  token: Scalars['String'];
};

export type MutationWelcome_EmailArgs = {
  id: Scalars['BigInt'];
};

export type OvrAnnotations = {
  __typename: 'OvrAnnotations';
  consensus_mean_vs_reported: Array<Maybe<Scalars['Float']>>;
  consensus_range_vs_reported: Array<Array<Maybe<Scalars['Float']>>>;
  historical_percent_observed: Array<Maybe<Scalars['Float']>>;
  r2: Maybe<Scalars['Float']>;
  scaled_sales_vs_reported: Array<Maybe<Scalars['Float']>>;
  scaling_model: OvrScalingModel;
};

export type OvrExportAllResponse = {
  __typename: 'OvrExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type OvrRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  scaling_model: OvrScalingModel;
  start_date: Scalars['String'];
  target_dimension: OvrTargetDimension;
};

export type OvrResponse = {
  __typename: 'OvrResponse';
  annotations: OvrAnnotations;
  scaling_model: OvrScalingModel;
  target_dimension: OvrTargetDimension;
  x_series: Array<Scalars['String']>;
  y_series: OvrYSerie;
};

export enum OvrScalingModel {
  AllTimeAverage = 'all_time_average',
  BestFit = 'best_fit',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

export enum OvrTargetDimension {
  ObservedVolume = 'observed_volume',
  YoyGrowth = 'yoy_growth',
  YoySpread = 'yoy_spread',
}

export type OvrYSerie = {
  __typename: 'OvrYSerie';
  consensus_high: Array<Maybe<Scalars['Float']>>;
  consensus_low: Array<Maybe<Scalars['Float']>>;
  consensus_mean: Array<Maybe<Scalars['Float']>>;
  forecast_sales: Array<Maybe<Scalars['Float']>>;
  /** reported_revenue serie is skipped in case of yoy_spread view */
  reported_revenue: Maybe<Array<Maybe<Scalars['Float']>>>;
  scaled_sales: Array<Maybe<Scalars['Float']>>;
};

export enum OvrYSerieName {
  ConsensusHigh = 'consensus_high',
  ConsensusLow = 'consensus_low',
  ConsensusMean = 'consensus_mean',
  ForecastSales = 'forecast_sales',
  ReportedRevenue = 'reported_revenue',
  ScaledSales = 'scaled_sales',
}

export type PageInfo = {
  __typename: 'PageInfo';
  has_next_page: Scalars['Boolean'];
};

export type PercentObservedRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  start_date: InputMaybe<Scalars['String']>;
};

export type PercentObservedResponse = {
  __typename: 'PercentObservedResponse';
  best_fit: PercentObservedScalingModel;
  historical_proportion_observed: Array<Maybe<Scalars['String']>>;
  quarter_end_date: Array<Scalars['String']>;
  r2: PercentObservedScalingModelR2;
  scaling_models: ScalingModelSeries;
};

export enum PercentObservedScalingModel {
  AllTimeAverage = 'all_time_average',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

/**
 * Annotation with maybe partial history.
 * Certain companies do not have sufficient history to cover the selected range resulting in NULL values.
 * eg if viewing data for 2yrs but we only have 1.5yrs, we inject NULL.
 */
export type PercentObservedScalingModelR2 = {
  __typename: 'PercentObservedScalingModelR2';
  all_time_average: Maybe<Scalars['String']>;
  exponential_smoothing: Maybe<Scalars['String']>;
  four_quarter_average: Maybe<Scalars['String']>;
  previous_quarter: Maybe<Scalars['String']>;
  previous_year: Maybe<Scalars['String']>;
  unscaled: Maybe<Scalars['String']>;
};

export type PullRequest = {
  __typename: 'PullRequest';
  base: Scalars['String'];
  isOpen: Scalars['Boolean'];
  number: Scalars['Int'];
  url: Scalars['String'];
};

export type PullRequestFile = {
  __typename: 'PullRequestFile';
  filename: Scalars['String'];
  status: Maybe<FileStatus>;
};

export type QpsExportAllResponse = {
  __typename: 'QpsExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type QpsNode = QpsNodeLastReported | QpsNodeUnreportedOrCurrent;

export type QpsNodeLastReported = {
  __typename: 'QpsNodeLastReported';
  company: Company;
  fiscal_quarter: Quarter;
  in_consensus_range: Scalars['Boolean'];
  max_consensus_yoy: Maybe<Scalars['Float']>;
  mean_consensus_usd: Maybe<Scalars['Float']>;
  mean_consensus_yoy: Maybe<Scalars['Float']>;
  min_consensus_yoy: Maybe<Scalars['Float']>;
  modeled_percent_observed: Maybe<Scalars['Float']>;
  modeled_quarter_end_sales: Maybe<Scalars['Float']>;
  modeled_quarter_end_yoy: Maybe<Scalars['Float']>;
  percent_completed: Maybe<Scalars['Float']>;
  r2_coefficient: Maybe<Scalars['Float']>;
  reported_revenue: Maybe<Scalars['Float']>;
  reported_yoy: Maybe<Scalars['Float']>;
  reported_yoy_mean_consensus_yoy: Maybe<Scalars['Float']>;
  reported_yoy_modeled_yoy: Scalars['Float'];
  reported_yoy_modeled_yoy_absolute_value: Scalars['Float'];
  scaling_model: QpsScalingModel;
};

export type QpsNodeUnreportedOrCurrent = {
  __typename: 'QpsNodeUnreportedOrCurrent';
  company: Company;
  fiscal_quarter: Quarter;
  in_consensus_range: Scalars['Boolean'];
  max_consensus_yoy: Maybe<Scalars['Float']>;
  mean_consensus_usd: Maybe<Scalars['Float']>;
  mean_consensus_yoy: Maybe<Scalars['Float']>;
  min_consensus_yoy: Maybe<Scalars['Float']>;
  modeled_percent_observed: Maybe<Scalars['Float']>;
  modeled_quarter_end_sales: Maybe<Scalars['Float']>;
  modeled_quarter_end_yoy: Maybe<Scalars['Float']>;
  modeled_yoy_mean_consensus_yoy: Maybe<Scalars['Float']>;
  percent_completed: Maybe<Scalars['Float']>;
  r2_coefficient: Maybe<Scalars['Float']>;
  scaling_model: QpsScalingModel;
};

export type QpsRequest = {
  selected_quarter: QpsSelectedQuarter;
};

export type QpsResponse = {
  __typename: 'QpsResponse';
  nodes: Array<QpsNode>;
};

export enum QpsScalingModel {
  AllTimeAverage = 'all_time_average',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

export enum QpsSelectedQuarter {
  CurrentQuarter = 'current_quarter',
  LastReportedQuarter = 'last_reported_quarter',
  UnreportedCompletedQuarter = 'unreported_completed_quarter',
}

export type QtdAnnotations = {
  __typename: 'QtdAnnotations';
  consensus: Array<Maybe<Scalars['Float']>>;
  consensus_growth: Array<Maybe<Scalars['Float']>>;
  last_observed_day: Scalars['BigInt'];
  revenue_estimate: Array<Maybe<Scalars['Float']>>;
  revenue_estimate_growth: Array<Maybe<Scalars['Float']>>;
  scaling_model: Scalars['String'];
};

export type QtdExportAllResponse = {
  __typename: 'QtdExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type QtdPercentObservedRequest = {
  analysis_quarter: Scalars['String'];
  company_id: Scalars['ID'];
};

export type QtdRequest = {
  analysis_quarter: Scalars['String'];
  company_id: Scalars['ID'];
  comparison_quarter: Scalars['String'];
  scaling_model: QtdScalingModel;
  scaling_model_custom_percent: Scalars['Float'];
};

export type QtdResponse = {
  __typename: 'QtdResponse';
  analysis_latest_quarter: Scalars['Boolean'];
  analysis_quarter: Quarter;
  annotations: QtdAnnotations;
  comparison_quarter: Quarter;
  last_projected_day_analysis: Scalars['BigInt'];
  last_projected_day_growth: Scalars['BigInt'];
  projected_start_date: Scalars['DateTime'];
  scaling_model: QtdScalingModel;
  scaling_model_custom_percent: Scalars['Float'];
  x_series: QtdXSerie;
  y_series: Array<QtdYSerie>;
};

export enum QtdScalingModel {
  AllTimeAverage = 'all_time_average',
  BestFit = 'best_fit',
  Custom = 'custom',
  ExponentialSmoothing = 'exponential_smoothing',
  FourQuarterAverage = 'four_quarter_average',
  PreviousQuarter = 'previous_quarter',
  PreviousYear = 'previous_year',
  Unscaled = 'unscaled',
}

export type QtdXSerie = {
  __typename: 'QtdXSerie';
  data: Array<Scalars['BigInt']>;
  name: QtdXSerieName;
};

export enum QtdXSerieName {
  DayNumber = 'day_number',
}

export type QtdYSerie = {
  __typename: 'QtdYSerie';
  data: Array<Maybe<Scalars['String']>>;
  name: QtdYSerieName;
};

export enum QtdYSerieName {
  AnalysisPeriod = 'analysis_period',
  AnalysisPeriodDates = 'analysis_period_dates',
  ComparisonPeriod = 'comparison_period',
  ComparisonPeriodDates = 'comparison_period_dates',
  Growth = 'growth',
}

export type QtipBrandTrends = {
  __typename: 'QtipBrandTrends';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipBrandTrendsRow>;
  warnings: Array<Message>;
};

export enum QtipBrandTrendsPanel {
  Frozen = 'frozen',
  Open = 'open',
}

export type QtipBrandTrendsRequest = {
  comparisonYAML: Array<ComparisonYaml>;
  end_date: Scalars['String'];
  panel: QtipBrandTrendsPanel;
  rollup: QtipBrandTrendsRollup;
  start_date: Scalars['String'];
};

export enum QtipBrandTrendsRollup {
  Month = 'month',
  Quarter = 'quarter',
}

export type QtipBrandTrendsRow = {
  __typename: 'QtipBrandTrendsRow';
  average_transaction_value_new: Maybe<Scalars['Float']>;
  average_transaction_value_old: Maybe<Scalars['Float']>;
  online_channel_proportion_new: Maybe<Scalars['Float']>;
  online_channel_proportion_old: Maybe<Scalars['Float']>;
  percent_change_sales: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  proportion_entrycode_e_new: Maybe<Scalars['Float']>;
  proportion_entrycode_e_old: Maybe<Scalars['Float']>;
  weighted_sales_new: Scalars['Float'];
  weighted_sales_old: Scalars['Float'];
  weighted_transactions_new: Scalars['Float'];
  weighted_transactions_old: Scalars['Float'];
};

export type QtipDescriptions = {
  __typename: 'QtipDescriptions';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipDescriptionsRow>;
  warnings: Array<Message>;
};

export type QtipDescriptionsRow = {
  __typename: 'QtipDescriptionsRow';
  channel: Scalars['String'];
  clean_merchant_name: Scalars['String'];
  dp4_card_entry_code: Scalars['String'];
  dp4_merchant_category_xcd: Scalars['String'];
  dp4_merchant_city_name: Scalars['String'];
  dp4_merchant_name: Scalars['String'];
  dp4_merchant_state: Scalars['String'];
  first_month_seen: Scalars['String'];
  last_month_seen: Scalars['String'];
  max_txn_amount: Scalars['Float'];
  mcc_description: Scalars['String'];
  mean_txn_amount: Scalars['Float'];
  min_txn_amount: Scalars['Float'];
  raw_sales: Scalars['Float'];
  raw_transactions: Scalars['Float'];
  taggable_merchant_name: Scalars['String'];
  unweighted_production_sales: Scalars['Float'];
  unweighted_production_transactions: Scalars['Float'];
  vendor_channel: Scalars['String'];
  vendor_intermediaries: Array<Scalars['String']>;
  vendor_matched_brands: Array<Scalars['String']>;
  vendor_primary_merchant: Maybe<Scalars['String']>;
  vendor_result_is_unique: Scalars['Boolean'];
  weighted_sales: Scalars['Float'];
  weighted_transactions: Scalars['Float'];
};

export type QtipRawVsModified = {
  __typename: 'QtipRawVsModified';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipRawVsModifiedRow>;
  warnings: Array<Message>;
};

export type QtipRawVsModifiedRow = {
  __typename: 'QtipRawVsModifiedRow';
  dp4_card_entry_code: Scalars['String'];
  dp4_is_merchant_mod: Scalars['Boolean'];
  dp4_merchant_category_xcd: Maybe<Scalars['String']>;
  dp4_merchant_city_name: Maybe<Scalars['String']>;
  dp4_merchant_state: Maybe<Scalars['String']>;
  fixed_after: Scalars['Boolean'];
  mcc_description: Scalars['String'];
  modified_brands_after_change: Array<Scalars['String']>;
  modified_brands_before_change: Array<Scalars['String']>;
  modified_merchant_name: Maybe<Scalars['String']>;
  n_txns: Scalars['Int'];
  raw_brands_after_change: Array<Scalars['String']>;
  raw_brands_before_change: Array<Scalars['String']>;
  raw_merchant_name: Maybe<Scalars['String']>;
};

export type QtipTaggingConflicts = {
  __typename: 'QtipTaggingConflicts';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipTaggingConflictsRow>;
  warnings: Array<Message>;
};

export type QtipTaggingConflictsRow = {
  __typename: 'QtipTaggingConflictsRow';
  clean_merchant_name: Scalars['String'];
  dp4_merchant_category_xcd: Scalars['String'];
  dp4_merchant_city_name: Scalars['String'];
  dp4_merchant_name: Scalars['String'];
  dp4_merchant_state: Scalars['String'];
  mcc_description: Scalars['String'];
  prod_vendor_matched_brands: Array<Scalars['String']>;
  prod_vendor_primary_merchant: Maybe<Scalars['String']>;
  proposed_primary_merchants: Array<Scalars['String']>;
  raw_transactions: Scalars['Int'];
  taggable_merchant_name: Scalars['String'];
};

export type QtipTransactionCounts = {
  __typename: 'QtipTransactionCounts';
  errors: Array<Message>;
  id: Scalars['String'];
  rows: Array<QtipTransactionCountsRow>;
  warnings: Array<Message>;
};

export type QtipTransactionCountsRequest = {
  data: Array<YamlFile>;
  end_date: Scalars['String'];
  rollup: QtipBrandTrendsRollup;
  start_date: Scalars['String'];
};

export type QtipTransactionCountsRow = {
  __typename: 'QtipTransactionCountsRow';
  date: Scalars['String'];
  raw_transactions_frozen: Scalars['Int'];
  raw_transactions_open: Scalars['Int'];
  weighted_transactions_frozen: Scalars['Float'];
  weighted_transactions_open: Scalars['Float'];
};

export type Quarter = {
  __typename: 'Quarter';
  end_date: Scalars['String'];
  fiscal_quarter: Scalars['BigInt'];
  fiscal_year: Scalars['BigInt'];
  is_reported: Scalars['Boolean'];
  start_date: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  _dummy: Scalars['String'];
  boilerplateYaml: Scalars['String'];
  branch: Maybe<Branch>;
  browse_businesses: BrowseBusinessesResponse;
  browse_businesses_export_all: BrowseBusinessesExportAllResponse;
  business: CompanyOrBrand;
  /** @deprecated */
  cohorted_retention_export_all: CohortedRetentionExportAllResponse;
  cohorted_retention_export_all_v2: CohortedRetentionExportAllResponse;
  /** @deprecated */
  cohorted_retention_v2: MaybeCohortedRetentionResponse;
  cohorted_retention_v3: MaybeCohortedRetentionResponseV3;
  cohorted_retention_v4: MaybeCohortedRetentionResponseV3;
  companySearch: Array<Scalars['String']>;
  customer_growth_accounting: MaybeGrowthAccountingResponseCustomers;
  descendents: Array<Scalars['String']>;
  diffToolResults: DiffToolResult;
  dollar_retention: MaybeDollarRetentionResponse;
  dollar_retention_export_all: DollarRetentionExportAllResponse;
  export_customer_growth_accounting: GrowthAccountingExportResponse;
  export_sales_growth_accounting: GrowthAccountingExportResponse;
  file_contents: Maybe<Scalars['String']>;
  geographical_areas: GeographicalAreaResponse;
  get_brands_or_companies: Array<CompanyOrBrand>;
  globalGraph: GlobalGraph;
  iam: Maybe<Iam>;
  last_updated_date: Maybe<Scalars['String']>;
  latest_production_date: LatestProductionDateResponse;
  listSessions: Array<DataOpsSession>;
  localGraph: LocalGraph;
  me: Maybe<User>;
  ovr: OvrResponse;
  ovr_export_all: OvrExportAllResponse;
  ovr_v2: MaybeOvrResponse;
  percent_observed: PercentObservedResponse;
  percent_observed_v2: MaybePercentObservedResponse;
  pullRequest: Maybe<PullRequest>;
  pullRequestFiles: Array<PullRequestFile>;
  qps: QpsResponse;
  qps_export_all: QpsExportAllResponse;
  qtd: QtdResponse;
  qtd_export_all: QtdExportAllResponse;
  qtd_percent_observed: PercentObservedResponse;
  qtd_percent_observed_v2: MaybePercentObservedResponse;
  qtd_v2: MaybeQtdResponse;
  qtipBrandTrendsV2: Array<QtipBrandTrends>;
  qtipDescriptions: Array<QtipDescriptions>;
  qtipEarliestDescriptions: Array<QtipDescriptions>;
  qtipLatestDescriptions: Array<QtipDescriptions>;
  qtipRawVsModified: Array<QtipRawVsModified>;
  qtipTaggingConflicts: Array<QtipTaggingConflicts>;
  qtipTransactionCounts: Array<QtipTransactionCounts>;
  repo: Repo;
  researchTrends: ResearchTrendsResponse;
  retention_summary: RetentionSummaryResponse;
  retention_summary_export_all: RetentionSummaryExportAllResponse;
  retention_summary_v2: MaybeRetentionSummaryResponse;
  sales_growth_accounting: MaybeGrowthAccountingResponseSales;
  search: Array<SearchResult>;
  search_geographical_areas: GeographicalAreaResponse;
  search_iam: IamConnection;
  search_teams: TeamConnection;
  search_users: UserConnection;
  session: DataOpsSession;
  team: Maybe<Team>;
  transaction_values: TransactionValuesResponse;
  transaction_values_export_all: TransactionValuesExportAllResponse;
  transaction_values_export_all_v2: MaybeTransactionValuesExportAllResponse;
  transaction_values_v2: MaybeTransactionValuesResponse;
  /** @deprecated */
  trends: TrendsResponse;
  /** @deprecated */
  trends_export_all: TrendsExportResponse;
  /** @deprecated */
  trends_export_all_v2: TrendsExportResponseOrDataError;
  trends_export_all_v3: TrendsExportResponseOrDataError;
  /** @deprecated */
  trends_v2: MaybeTrendsResponse;
  trends_v3: MaybeTrendsResponse;
  user: Maybe<User>;
  yaml: Array<YamlResult>;
};

export type QueryBoilerplateYamlArgs = {
  entity_id: Scalars['String'];
  entity_type: BoilerplateType;
};

export type QueryBranchArgs = {
  branch_name: Scalars['String'];
};

export type QueryBrowse_BusinessesArgs = {
  request: BrowseBusinessesRequest;
};

export type QueryBrowse_Businesses_Export_AllArgs = {
  request: BrowseBusinessesRequest;
};

export type QueryBusinessArgs = {
  id: Scalars['String'];
};

export type QueryCohorted_Retention_Export_AllArgs = {
  request: CohortedRetentionRequest;
};

export type QueryCohorted_Retention_Export_All_V2Args = {
  request: CohortedRetentionRequestV2;
};

export type QueryCohorted_Retention_V2Args = {
  request: CohortedRetentionRequest;
};

export type QueryCohorted_Retention_V3Args = {
  request: CohortedRetentionRequest;
};

export type QueryCohorted_Retention_V4Args = {
  request: CohortedRetentionRequestV2;
};

export type QueryCustomer_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QueryDescendentsArgs = {
  entity_id: Scalars['String'];
};

export type QueryDiffToolResultsArgs = {
  entityId: Scalars['String'];
  maxResults: Scalars['Int'];
  minTransactionCount: Scalars['Int'];
  yamlMain: InputMaybe<Scalars['String']>;
  yamlVerify: InputMaybe<Scalars['String']>;
};

export type QueryDollar_RetentionArgs = {
  request: DollarRetentionRequest;
};

export type QueryDollar_Retention_Export_AllArgs = {
  request: DollarRetentionRequest;
};

export type QueryExport_Customer_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QueryExport_Sales_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QueryFile_ContentsArgs = {
  branch: Scalars['String'];
  id: Scalars['String'];
};

export type QueryGeographical_AreasArgs = {
  codes: Array<Scalars['String']>;
};

export type QueryGet_Brands_Or_CompaniesArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryIamArgs = {
  id: Scalars['BigInt'];
};

export type QueryListSessionsArgs = {
  filter: ListSessionsFilter;
};

export type QueryLocalGraphArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryOvrArgs = {
  request: OvrRequest;
};

export type QueryOvr_Export_AllArgs = {
  request: OvrRequest;
};

export type QueryOvr_V2Args = {
  request: OvrRequest;
};

export type QueryPercent_ObservedArgs = {
  request: PercentObservedRequest;
};

export type QueryPercent_Observed_V2Args = {
  request: PercentObservedRequest;
};

export type QueryPullRequestArgs = {
  branch_name: Scalars['String'];
};

export type QueryPullRequestFilesArgs = {
  branch_name: Scalars['String'];
};

export type QueryQpsArgs = {
  request: QpsRequest;
};

export type QueryQps_Export_AllArgs = {
  request: QpsRequest;
};

export type QueryQtdArgs = {
  request: QtdRequest;
};

export type QueryQtd_Export_AllArgs = {
  request: QtdRequest;
};

export type QueryQtd_Percent_ObservedArgs = {
  request: QtdPercentObservedRequest;
};

export type QueryQtd_Percent_Observed_V2Args = {
  request: QtdPercentObservedRequest;
};

export type QueryQtd_V2Args = {
  request: QtdRequest;
};

export type QueryQtipBrandTrendsV2Args = {
  request: QtipBrandTrendsRequest;
};

export type QueryQtipDescriptionsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipEarliestDescriptionsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipLatestDescriptionsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipRawVsModifiedArgs = {
  comparisonYAML: Array<ComparisonYaml>;
};

export type QueryQtipTaggingConflictsArgs = {
  yamlFiles: Array<YamlFile>;
};

export type QueryQtipTransactionCountsArgs = {
  request: QtipTransactionCountsRequest;
};

export type QueryResearchTrendsArgs = {
  request: ResearchTrendsRequest;
};

export type QueryRetention_SummaryArgs = {
  request: RetentionSummaryRequest;
};

export type QueryRetention_Summary_Export_AllArgs = {
  request: RetentionSummaryRequest;
};

export type QueryRetention_Summary_V2Args = {
  request: RetentionSummaryRequest;
};

export type QuerySales_Growth_AccountingArgs = {
  request: GrowthAccountingRequest;
};

export type QuerySearchArgs = {
  query: Scalars['String'];
};

export type QuerySearch_Geographical_AreasArgs = {
  request: SearchGeographicalAreaRequest;
};

export type QuerySearch_IamArgs = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  request: SearchIamRequest;
};

export type QuerySearch_TeamsArgs = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  request: SearchTeamsRequest;
};

export type QuerySearch_UsersArgs = {
  request: SearchUsersRequest;
};

export type QuerySessionArgs = {
  branch_name: Scalars['String'];
};

export type QueryTeamArgs = {
  id: Scalars['BigInt'];
};

export type QueryTransaction_ValuesArgs = {
  request: TransactionValuesRequest;
};

export type QueryTransaction_Values_Export_AllArgs = {
  request: TransactionValuesRequest;
};

export type QueryTransaction_Values_Export_All_V2Args = {
  request: TransactionValuesRequest;
};

export type QueryTransaction_Values_V2Args = {
  request: TransactionValuesRequest;
};

export type QueryTrendsArgs = {
  request: TrendsRequest;
};

export type QueryTrends_Export_AllArgs = {
  request: TrendsRequest;
};

export type QueryTrends_Export_All_V2Args = {
  request: TrendsRequest;
};

export type QueryTrends_Export_All_V3Args = {
  request: TrendsV3Request;
};

export type QueryTrends_V2Args = {
  request: TrendsRequest;
};

export type QueryTrends_V3Args = {
  request: TrendsV3Request;
};

export type QueryUserArgs = {
  id: Scalars['BigInt'];
};

export type QueryYamlArgs = {
  branch_name: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type Repo = {
  __typename: 'Repo';
  default_branch: Scalars['String'];
};

export type ResearchTrendLine = {
  __typename: 'ResearchTrendLine';
  label: Maybe<Scalars['String']>;
  values: Array<Maybe<Scalars['Float']>>;
};

export enum ResearchTrendsBreakout {
  Channel = 'channel',
  None = 'none',
  Panel = 'panel',
}

export enum ResearchTrendsDimension {
  Volume = 'volume',
  Yoy = 'yoy',
}

export type ResearchTrendsRequest = {
  breakout: ResearchTrendsBreakout;
  dimension: ResearchTrendsDimension;
  end_date: Scalars['String'];
  entity_id: Scalars['String'];
  rollup: ResearchTrendsRollup;
  start_date: Scalars['String'];
};

export type ResearchTrendsResponse = {
  __typename: 'ResearchTrendsResponse';
  avg_txn_value: Array<ResearchTrendLine>;
  date: Array<Scalars['String']>;
  observed_customers: Array<ResearchTrendLine>;
  observed_sales: Array<ResearchTrendLine>;
  observed_transactions: Array<ResearchTrendLine>;
  sales_per_customer: Array<ResearchTrendLine>;
  transactions_per_customer: Array<ResearchTrendLine>;
};

export enum ResearchTrendsRollup {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
}

export type RetentionPeriod = {
  __typename: 'RetentionPeriod';
  /**
   * The data contains an array of floats, where each float represents a percent
   * of customers retained for this period
   */
  data: Array<Maybe<Scalars['Float']>>;
  /**
   * The ID is the label of this "period" represented
   * as the HTML table column. It can either be
   *
   * 1) a numeric period
   * number representing the delta eg. # of months after cohort,
   * useful for answering questions like "how much retention after 6 months" by
   * aligning the retention data for period 6 into one "period" or HTML table column.
   *
   * 2) a date describing a specific period
   * useful for answering questions like "how many customers return around Christmas"
   * by aligning the retention data for "Christmas" into one "period" or HTML table column
   */
  id: Scalars['String'];
};

export type RetentionSummaryExportAllResponse = {
  __typename: 'RetentionSummaryExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type RetentionSummaryRequest = {
  company_id: Scalars['ID'];
  compare_by_company_ids: Array<Scalars['ID']>;
  end_date: Scalars['String'];
  rollup: RetentionSummaryRollup;
  start_date: Scalars['String'];
};

export type RetentionSummaryResponse = {
  __typename: 'RetentionSummaryResponse';
  periods: Array<Scalars['String']>;
  y_series: RetentionSummaryYSeries;
};

export enum RetentionSummaryRollup {
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export type RetentionSummaryYSerie = {
  __typename: 'RetentionSummaryYSerie';
  data: Array<Maybe<Scalars['Float']>>;
  name: Scalars['String'];
};

export type RetentionSummaryYSeries = {
  __typename: 'RetentionSummaryYSeries';
  period_over_period_dollar_retention: Array<RetentionSummaryYSerie>;
  period_over_period_retention: Array<RetentionSummaryYSerie>;
};

export enum Role {
  Superadmin = 'SUPERADMIN',
  User = 'USER',
}

/**
 * Dataset with maybe partial history.
 * Certain companies do not have sufficient history to cover the selected range resulting in NULL values.
 * eg if viewing data for 2yrs but we only have 1.5yrs, we inject NULL.
 */
export type ScalingModelSeries = {
  __typename: 'ScalingModelSeries';
  all_time_average: Array<Maybe<Scalars['String']>>;
  exponential_smoothing: Array<Maybe<Scalars['String']>>;
  four_quarter_average: Array<Maybe<Scalars['String']>>;
  previous_quarter: Array<Maybe<Scalars['String']>>;
  previous_year: Array<Maybe<Scalars['String']>>;
  unscaled: Array<Maybe<Scalars['String']>>;
};

export type SearchGeographicalAreaRequest = {
  desc: Scalars['Boolean'];
  limit: Scalars['BigInt'];
  query: Scalars['String'];
};

export type SearchIamRequest = {
  desc: Scalars['Boolean'];
  order_by: IamOrderBy;
  query: Scalars['String'];
};

export type SearchResult = {
  __typename: 'SearchResult';
  brands: Array<Brand>;
  company: Company;
};

export type SearchTeamsRequest = {
  desc: Scalars['Boolean'];
  order_by: TeamsOrderBy;
  query: Scalars['String'];
};

export type SearchUsersRequest = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  query: Scalars['String'];
};

export type Serie = {
  __typename: 'Serie';
  data: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
};

export type SparklineChartSerie = {
  __typename: 'SparklineChartSerie';
  x: Scalars['String'];
  y: Scalars['Float'];
};

export type Team = {
  __typename: 'Team';
  created: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['BigInt'];
  lag: Lag;
  name: Scalars['String'];
  persona: TeamPersona;
  search_iam: IamConnection;
  search_users: UserConnection;
  seats: Scalars['BigInt'];
};

export type TeamSearch_IamArgs = {
  limit: Scalars['BigInt'];
  offset: Scalars['BigInt'];
  request: SearchIamRequest;
};

export type TeamSearch_UsersArgs = {
  request: SearchUsersRequest;
};

export type TeamConnection = {
  __typename: 'TeamConnection';
  count: Scalars['BigInt'];
  nodes: Array<Team>;
  page_info: PageInfo;
};

export enum TeamPersona {
  Corporate = 'CORPORATE',
  HedgeFundFundamental = 'HEDGE_FUND_FUNDAMENTAL',
  HedgeFundQuant = 'HEDGE_FUND_QUANT',
  Internal = 'INTERNAL',
  Other = 'OTHER',
  OtherPublicInvestorFundamental = 'OTHER_PUBLIC_INVESTOR_FUNDAMENTAL',
  OtherPublicInvestorQuant = 'OTHER_PUBLIC_INVESTOR_QUANT',
  PrivateMarketInvestor = 'PRIVATE_MARKET_INVESTOR',
  SellSide = 'SELL_SIDE',
}

export enum TeamsOrderBy {
  Created = 'created',
  Enabled = 'enabled',
  Id = 'id',
  Name = 'name',
}

export type TransactionValuesExportAllResponse = {
  __typename: 'TransactionValuesExportAllResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type TransactionValuesRequest = {
  company_id: Scalars['ID'];
  end_date: Scalars['String'];
  /** Make the argument optional for the backwards compatibility */
  filter_brand_types: Array<BrandTypes>;
  start_date: Scalars['String'];
};

export type TransactionValuesResponse = {
  __typename: 'TransactionValuesResponse';
  data: Array<TransactionValuesRow>;
};

export type TransactionValuesRow = {
  __typename: 'TransactionValuesRow';
  cumulative_percent_sales: Scalars['Float'];
  cumulative_percent_transactions: Scalars['Float'];
  percent_sales: Scalars['Float'];
  percent_transactions: Scalars['Float'];
  transaction_value_max: Scalars['Float'];
  transaction_value_min: Scalars['Float'];
};

export type TrendsDataset = {
  __typename: 'TrendsDataset';
  data: Array<Serie>;
  id: Scalars['String'];
};

export type TrendsExportRequest = {
  arguments: TrendsRequest;
  dataset: Scalars['String'];
};

export type TrendsExportResponse = {
  __typename: 'TrendsExportResponse';
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type TrendsExportResponseOrDataError = DataError | TrendsExportResponse;

/** @deprecated */
export type TrendsRequest = {
  breakout_by: BreakoutBy;
  bucket: InputMaybe<Scalars['String']>;
  channels: Channels;
  company_id: Scalars['ID'];
  compare_by_company_ids: Array<Scalars['ID']>;
  end_date: Scalars['String'];
  /** Make the argument optional for the backwards compatibility */
  filter_brand_types: InputMaybe<Array<BrandTypes>>;
  geos: InputMaybe<Array<Scalars['ID']>>;
  rollup: TrendsRollup;
  start_date: Scalars['String'];
  target_dimension: TrendsTargetDimension;
};

export type TrendsResponse = {
  __typename: 'TrendsResponse';
  breakout_by: BreakoutBy;
  cbsas: Maybe<Array<GeographicalArea>>;
  companies_or_brands: Array<CompanyOrBrand>;
  data: Array<TrendsDataset>;
  rollup: TrendsRollup;
};

export enum TrendsRollup {
  Day = 'day',
  FiscalQuarter = 'fiscal_quarter',
  Month = 'month',
  Quarter = 'quarter',
  Trailing_7Days = 'trailing_7_days',
  Trailing_12Months = 'trailing_12_months',
  Trailing_30Days = 'trailing_30_days',
  Trailing_90Days = 'trailing_90_days',
  Week = 'week',
  Year = 'year',
}

/** Target dimension filter options */
export enum TrendsTargetDimension {
  Indexed = 'indexed',
  ObservedVolume = 'observed_volume',
  PopGrowth = 'pop_growth',
  YoyGrowth = 'yoy_growth',
}

export type TrendsV3Request = {
  breakout_by: BreakoutBy;
  bucket: InputMaybe<Scalars['String']>;
  company_id: Scalars['ID'];
  compare_by_company_ids: Array<Scalars['ID']>;
  end_date: Scalars['String'];
  /** Make the argument optional for the backwards compatibility */
  filter_brand_types: Array<BrandTypes>;
  filter_channels: Array<ChannelsV2>;
  geos: InputMaybe<Array<Scalars['ID']>>;
  rollup: TrendsRollup;
  start_date: Scalars['String'];
  target_dimension: TrendsTargetDimension;
};

export type UpsertIamRequest = {
  arn: InputMaybe<Scalars['String']>;
  aws_account_id: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: InputMaybe<Scalars['Int']>;
  resource: InputMaybe<Scalars['String']>;
  team_id: Scalars['BigInt'];
};

export type UpsertTeamRequest = {
  enabled: Scalars['Boolean'];
  id: InputMaybe<Scalars['Int']>;
  lag: Lag;
  name: Scalars['String'];
  persona: TeamPersona;
  seats: Scalars['BigInt'];
};

export type UpsertUserRequest = {
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  first_name: Scalars['String'];
  id: InputMaybe<Scalars['Int']>;
  last_name: Scalars['String'];
  password: InputMaybe<Scalars['String']>;
  persona: UserPersona;
  role: Role;
  team_id: Scalars['BigInt'];
};

export type User = {
  __typename: 'User';
  created: Scalars['DateTime'];
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  first_name: Scalars['String'];
  has_setup_totp: Scalars['Boolean'];
  id: Scalars['BigInt'];
  last_login: Maybe<Scalars['DateTime']>;
  last_name: Scalars['String'];
  persona: UserPersona;
  require_2fa: Scalars['Boolean'];
  role: Role;
  team: Team;
  updated: Scalars['DateTime'];
  verified_2fa: Scalars['Boolean'];
};

export type UserConnection = {
  __typename: 'UserConnection';
  count: Scalars['BigInt'];
  nodes: Array<User>;
  page_info: PageInfo;
};

export enum UserPersona {
  CSuiteExec = 'C_SUITE_EXEC',
  DataScientist = 'DATA_SCIENTIST',
  InvestmentOrResearch = 'INVESTMENT_OR_RESEARCH',
  Other = 'OTHER',
  PortfolioManager = 'PORTFOLIO_MANAGER',
  QuantResearch = 'QUANT_RESEARCH',
  Trader = 'TRADER',
}

export type YamlFile = {
  entityId: Scalars['String'];
  yaml: InputMaybe<Scalars['String']>;
};

export type YamlResult = {
  __typename: 'YamlResult';
  base: Maybe<Scalars['String']>;
  branch: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type BusinessMetadataQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type BusinessMetadataQuery = {
  __typename: 'Query';
  get_brands_or_companies: Array<
    | {
        __typename: 'Brand';
        id: string;
        title: string;
        website: string;
        logo: string | null;
        channels: Array<Channel>;
        type: BrandType;
        ultimate_parent: {
          __typename: 'Company';
          id: string;
          title: string;
        } | null;
      }
    | {
        __typename: 'Company';
        id: string;
        title: string;
        website: string;
        ticker: string | null;
        exchange: string | null;
        logo: string | null;
        channels: Array<Channel>;
        child_brands: Array<{
          __typename: 'ChildBrand';
          is_expired: boolean;
          expiration_date: string;
          brand: {
            __typename: 'Brand';
            id: string;
            title: string;
            channels: Array<Channel>;
            type: BrandType;
          };
        }>;
      }
  >;
};

export type MetadataCompanyFragment = {
  __typename: 'Company';
  id: string;
  title: string;
  website: string;
  ticker: string | null;
  exchange: string | null;
  logo: string | null;
  channels: Array<Channel>;
  child_brands: Array<{
    __typename: 'ChildBrand';
    is_expired: boolean;
    expiration_date: string;
    brand: {
      __typename: 'Brand';
      id: string;
      title: string;
      channels: Array<Channel>;
      type: BrandType;
    };
  }>;
};

export type MetadataBrandFragment = {
  __typename: 'Brand';
  id: string;
  title: string;
  website: string;
  logo: string | null;
  channels: Array<Channel>;
  type: BrandType;
  ultimate_parent: { __typename: 'Company'; id: string; title: string } | null;
};

export type MetadataChildFragment = {
  __typename: 'ChildBrand';
  is_expired: boolean;
  expiration_date: string;
  brand: {
    __typename: 'Brand';
    id: string;
    title: string;
    channels: Array<Channel>;
    type: BrandType;
  };
};

export type CompanyPromptQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CompanyPromptQuery = {
  __typename: 'Query';
  business:
    | {
        __typename: 'Brand';
        id: string;
        title: string;
        logo: string | null;
        ultimate_parent: {
          __typename: 'Company';
          id: string;
          title: string;
          reports_quarterly: boolean;
          public: boolean;
          logo: string | null;
        } | null;
      }
    | {
        __typename: 'Company';
        id: string;
        title: string;
        reports_quarterly: boolean;
        public: boolean;
        logo: string | null;
        brands: Array<{
          __typename: 'Brand';
          id: string;
          title: string;
          logo: string | null;
        }>;
      };
};

export type UseCompanyFragmentFragment = {
  __typename: 'Company';
  id: string;
  title: string;
  reports_quarterly: boolean;
  public: boolean;
  logo: string | null;
};

export type UseBrandFragmentFragment = {
  __typename: 'Brand';
  id: string;
  title: string;
  logo: string | null;
};

export type FilterQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type FilterQuery = {
  __typename: 'Query';
  get_brands_or_companies: Array<
    | { __typename: 'Brand'; id: string; channels: Array<Channel> }
    | {
        __typename: 'Company';
        id: string;
        has_mixed_brand_types: boolean;
        channels: Array<Channel>;
      }
  >;
};

export type GeoAutocompleteQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type GeoAutocompleteQuery = {
  __typename: 'Query';
  search_geographical_areas: {
    __typename: 'GeographicalAreaResponse';
    results: Array<{
      __typename: 'GeographicalArea';
      id: string;
      title: string;
      code: string;
      type: GeographicalAreaType;
    }>;
  };
};

export type GetGeosQueryVariables = Exact<{
  codes: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetGeosQuery = {
  __typename: 'Query';
  geographical_areas: {
    __typename: 'GeographicalAreaResponse';
    results: Array<{
      __typename: 'GeographicalArea';
      id: string;
      type: GeographicalAreaType;
      code: string;
      title: string;
    }>;
  };
};

export type QuartersQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type QuartersQuery = {
  __typename: 'Query';
  business:
    | { __typename: 'Brand' }
    | {
        __typename: 'Company';
        id: string;
        public: boolean;
        reports_quarterly: boolean;
        quarters: Array<{
          __typename: 'Quarter';
          start_date: string;
          end_date: string;
          fiscal_year: number;
          fiscal_quarter: number;
        }>;
      };
};

export type CompanyQuarterFragment = {
  __typename: 'Quarter';
  start_date: string;
  end_date: string;
  fiscal_year: number;
  fiscal_quarter: number;
};

export type GetOvrPercentObservedQueryVariables = Exact<{
  request: PercentObservedRequest;
}>;

export type GetOvrPercentObservedQuery = {
  __typename: 'Query';
  percent_observed_v2:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'PercentObservedResponse';
        best_fit: PercentObservedScalingModel;
        quarter_end_date: Array<string>;
        historical_proportion_observed: Array<string | null>;
        r2: {
          __typename: 'PercentObservedScalingModelR2';
          all_time_average: string | null;
          exponential_smoothing: string | null;
          four_quarter_average: string | null;
          previous_quarter: string | null;
          previous_year: string | null;
          unscaled: string | null;
        };
        scaling_models: {
          __typename: 'ScalingModelSeries';
          all_time_average: Array<string | null>;
          exponential_smoothing: Array<string | null>;
          four_quarter_average: Array<string | null>;
          previous_quarter: Array<string | null>;
          previous_year: Array<string | null>;
          unscaled: Array<string | null>;
        };
      };
};

export type GetQtdPercentObservedQueryVariables = Exact<{
  request: QtdPercentObservedRequest;
}>;

export type GetQtdPercentObservedQuery = {
  __typename: 'Query';
  qtd_percent_observed_v2:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'PercentObservedResponse';
        best_fit: PercentObservedScalingModel;
        quarter_end_date: Array<string>;
        historical_proportion_observed: Array<string | null>;
        r2: {
          __typename: 'PercentObservedScalingModelR2';
          all_time_average: string | null;
          exponential_smoothing: string | null;
          four_quarter_average: string | null;
          previous_quarter: string | null;
          previous_year: string | null;
          unscaled: string | null;
        };
        scaling_models: {
          __typename: 'ScalingModelSeries';
          all_time_average: Array<string | null>;
          exponential_smoothing: Array<string | null>;
          four_quarter_average: Array<string | null>;
          previous_quarter: Array<string | null>;
          previous_year: Array<string | null>;
          unscaled: Array<string | null>;
        };
      };
};

export type GetCompanyOrBrandQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetCompanyOrBrandQuery = {
  __typename: 'Query';
  get_brands_or_companies: Array<
    | {
        __typename: 'Brand';
        id: string;
        title: string;
        website: string;
        logo: string | null;
      }
    | {
        __typename: 'Company';
        id: string;
        title: string;
        website: string;
        public: boolean;
        reports_quarterly: boolean;
        logo: string | null;
      }
  >;
};

export type GetLastUpdatedDateQueryVariables = Exact<{ [key: string]: never }>;

export type GetLastUpdatedDateQuery = {
  __typename: 'Query';
  last_updated_date: string | null;
};

export type GetLatestProductionDateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestProductionDateQuery = {
  __typename: 'Query';
  latest_production_date: {
    __typename: 'LatestProductionDateResponse';
    date: string;
  };
};

export type RecordFeatureUsageMutationVariables = Exact<{
  params: FeatureUsageRequest;
}>;

export type RecordFeatureUsageMutation = {
  __typename: 'Mutation';
  feature_usage: void | null;
};

export type BrowseBusinessesQueryVariables = Exact<{
  request: BrowseBusinessesRequest;
}>;

export type BrowseBusinessesQuery = {
  __typename: 'Query';
  browse_businesses: {
    __typename: 'BrowseBusinessesResponse';
    data_as_of_month: string;
    nodes: Array<
      | {
          __typename: 'BrowseBusinessesBrandNode';
          observed_sales_dollars: number;
          observed_sales_yoy_percent: number | null;
          observed_customers: number;
          observed_customers_yoy_percent: number | null;
          observed_transactions: number;
          observed_transactions_yoy_percent: number | null;
          observed_sales_ltm_chart: Array<{
            __typename: 'SparklineChartSerie';
            x: string;
            y: number;
          }>;
          observed_customers_ltm_chart: Array<{
            __typename: 'SparklineChartSerie';
            x: string;
            y: number;
          }>;
          observed_transactions_ltm_chart: Array<{
            __typename: 'SparklineChartSerie';
            x: string;
            y: number;
          }>;
          brand: {
            __typename: 'Brand';
            id: string;
            type: BrandType;
            logo: string | null;
            title: string;
            ultimate_parent: {
              __typename: 'Company';
              id: string;
              logo: string | null;
              title: string;
            } | null;
            industry: {
              __typename: 'Industry';
              id: number;
              title: string;
            } | null;
          };
        }
      | {
          __typename: 'BrowseBusinessesCompanyNode';
          observed_sales_dollars: number;
          observed_sales_yoy_percent: number | null;
          observed_customers: number;
          observed_customers_yoy_percent: number | null;
          observed_transactions: number;
          observed_transactions_yoy_percent: number | null;
          market_capitalization_dollars: number;
          r2_coefficient: number | null;
          observed_sales_ltm_chart: Array<{
            __typename: 'SparklineChartSerie';
            x: string;
            y: number;
          }>;
          observed_customers_ltm_chart: Array<{
            __typename: 'SparklineChartSerie';
            x: string;
            y: number;
          }>;
          observed_transactions_ltm_chart: Array<{
            __typename: 'SparklineChartSerie';
            x: string;
            y: number;
          }>;
          company: {
            __typename: 'Company';
            id: string;
            logo: string | null;
            title: string;
            ticker: string | null;
            exchange: string | null;
            figi: string | null;
            bloomberg_id: string | null;
          };
        }
    >;
  };
};

export type BrowseBusinessesCompanyNodeFragment = {
  __typename: 'BrowseBusinessesCompanyNode';
  observed_sales_dollars: number;
  observed_sales_yoy_percent: number | null;
  observed_customers: number;
  observed_customers_yoy_percent: number | null;
  observed_transactions: number;
  observed_transactions_yoy_percent: number | null;
  market_capitalization_dollars: number;
  r2_coefficient: number | null;
  observed_sales_ltm_chart: Array<{
    __typename: 'SparklineChartSerie';
    x: string;
    y: number;
  }>;
  observed_customers_ltm_chart: Array<{
    __typename: 'SparklineChartSerie';
    x: string;
    y: number;
  }>;
  observed_transactions_ltm_chart: Array<{
    __typename: 'SparklineChartSerie';
    x: string;
    y: number;
  }>;
  company: {
    __typename: 'Company';
    id: string;
    logo: string | null;
    title: string;
    ticker: string | null;
    exchange: string | null;
    figi: string | null;
    bloomberg_id: string | null;
  };
};

export type BrowseBusinessesBrandNodeFragment = {
  __typename: 'BrowseBusinessesBrandNode';
  observed_sales_dollars: number;
  observed_sales_yoy_percent: number | null;
  observed_customers: number;
  observed_customers_yoy_percent: number | null;
  observed_transactions: number;
  observed_transactions_yoy_percent: number | null;
  observed_sales_ltm_chart: Array<{
    __typename: 'SparklineChartSerie';
    x: string;
    y: number;
  }>;
  observed_customers_ltm_chart: Array<{
    __typename: 'SparklineChartSerie';
    x: string;
    y: number;
  }>;
  observed_transactions_ltm_chart: Array<{
    __typename: 'SparklineChartSerie';
    x: string;
    y: number;
  }>;
  brand: {
    __typename: 'Brand';
    id: string;
    type: BrandType;
    logo: string | null;
    title: string;
    ultimate_parent: {
      __typename: 'Company';
      id: string;
      logo: string | null;
      title: string;
    } | null;
    industry: { __typename: 'Industry'; id: number; title: string } | null;
  };
};

export type GetBrowseBusinessesExportAllQueryVariables = Exact<{
  request: BrowseBusinessesRequest;
}>;

export type GetBrowseBusinessesExportAllQuery = {
  __typename: 'Query';
  browse_businesses_export_all: {
    __typename: 'BrowseBusinessesExportAllResponse';
    data: string;
    filename: string;
  };
};

export type GetCohortedRetentionQueryVariables = Exact<{
  request: CohortedRetentionRequestV2;
}>;

export type GetCohortedRetentionQuery = {
  __typename: 'Query';
  cohorted_retention_v4:
    | {
        __typename: 'CohortedRetentionResponseV3';
        cohorts: Array<string>;
        period_0_customers: Array<number>;
        periods: Array<{
          __typename: 'RetentionPeriod';
          id: string;
          data: Array<number | null>;
        }>;
      }
    | { __typename: 'DataError'; message: string };
};

export type GetCohortedRetentionExportAllQueryVariables = Exact<{
  request: CohortedRetentionRequestV2;
}>;

export type GetCohortedRetentionExportAllQuery = {
  __typename: 'Query';
  cohorted_retention_export_all_v2: {
    __typename: 'CohortedRetentionExportAllResponse';
    data: string;
    filename: string;
  };
};

export type GetDollarRetentionQueryVariables = Exact<{
  request: DollarRetentionRequest;
}>;

export type GetDollarRetentionQuery = {
  __typename: 'Query';
  dollar_retention:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'DollarRetentionResponse';
        cohorts: Array<string>;
        period_0_sales: Array<number>;
        periods: Array<{
          __typename: 'RetentionPeriod';
          id: string;
          data: Array<number | null>;
        }>;
      };
};

export type GetDollarRetentionExportAllQueryVariables = Exact<{
  request: DollarRetentionRequest;
}>;

export type GetDollarRetentionExportAllQuery = {
  __typename: 'Query';
  dollar_retention_export_all: {
    __typename: 'DollarRetentionExportAllResponse';
    data: string;
    filename: string;
  };
};

export type GetGrowthAccountingQueryVariables = Exact<{
  request: GrowthAccountingRequest;
}>;

export type GetGrowthAccountingQuery = {
  __typename: 'Query';
  customer_growth_accounting:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'GrowthAccountingResponseCustomers';
        periods: Array<{
          __typename: 'GrowthAccountingPeriodCustomers';
          date: string;
          new: number;
          resurrected: number;
          retained: number;
          churned: number;
          quick_ratio: number | null;
        }>;
      };
  sales_growth_accounting:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'GrowthAccountingResponseSales';
        periods: Array<{
          __typename: 'GrowthAccountingPeriodSales';
          date: string;
          new: number;
          resurrected: number;
          retained: number;
          churned: number;
          quick_ratio: number | null;
          expansion: number;
          contraction: number;
        }>;
      };
};

export type ExportCustomerGrowthAccountingQueryVariables = Exact<{
  request: GrowthAccountingRequest;
}>;

export type ExportCustomerGrowthAccountingQuery = {
  __typename: 'Query';
  export_customer_growth_accounting: {
    __typename: 'GrowthAccountingExportResponse';
    filename: string;
    data: string;
  };
};

export type ExportSalesGrowthAccountingQueryVariables = Exact<{
  request: GrowthAccountingRequest;
}>;

export type ExportSalesGrowthAccountingQuery = {
  __typename: 'Query';
  export_sales_growth_accounting: {
    __typename: 'GrowthAccountingExportResponse';
    filename: string;
    data: string;
  };
};

export type GetOvrQueryVariables = Exact<{
  request: OvrRequest;
}>;

export type GetOvrQuery = {
  __typename: 'Query';
  ovr_v2:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'OvrResponse';
        scaling_model: OvrScalingModel;
        target_dimension: OvrTargetDimension;
        x_series: Array<string>;
        annotations: {
          __typename: 'OvrAnnotations';
          r2: number | null;
          scaling_model: OvrScalingModel;
          historical_percent_observed: Array<number | null>;
          scaled_sales_vs_reported: Array<number | null>;
          consensus_mean_vs_reported: Array<number | null>;
          consensus_range_vs_reported: Array<Array<number | null>>;
        };
        y_series: {
          __typename: 'OvrYSerie';
          reported_revenue: Array<number | null> | null;
          consensus_mean: Array<number | null>;
          consensus_low: Array<number | null>;
          consensus_high: Array<number | null>;
          scaled_sales: Array<number | null>;
          forecast_sales: Array<number | null>;
        };
      };
};

export type GetOvrExportAllQueryVariables = Exact<{
  request: OvrRequest;
}>;

export type GetOvrExportAllQuery = {
  __typename: 'Query';
  ovr_export_all: {
    __typename: 'OvrExportAllResponse';
    data: string;
    filename: string;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  new_password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename: 'Mutation';
  reset_password: void | null;
};

export type QpsQueryQueryVariables = Exact<{
  request: QpsRequest;
}>;

export type QpsQueryQuery = {
  __typename: 'Query';
  qps: {
    __typename: 'QpsResponse';
    nodes: Array<
      | {
          __typename: 'QpsNodeLastReported';
          scaling_model: QpsScalingModel;
          r2_coefficient: number | null;
          modeled_percent_observed: number | null;
          percent_completed: number | null;
          modeled_quarter_end_sales: number | null;
          modeled_quarter_end_yoy: number | null;
          mean_consensus_usd: number | null;
          mean_consensus_yoy: number | null;
          min_consensus_yoy: number | null;
          max_consensus_yoy: number | null;
          reported_revenue: number | null;
          reported_yoy: number | null;
          reported_yoy_mean_consensus_yoy: number | null;
          reported_yoy_modeled_yoy: number;
          reported_yoy_modeled_yoy_absolute_value: number;
          in_consensus_range: boolean;
          company: {
            __typename: 'Company';
            id: string;
            title: string;
            logo: string | null;
            exchange: string | null;
            ticker: string | null;
          };
          fiscal_quarter: {
            __typename: 'Quarter';
            end_date: string;
            fiscal_year: number;
            fiscal_quarter: number;
          };
        }
      | {
          __typename: 'QpsNodeUnreportedOrCurrent';
          scaling_model: QpsScalingModel;
          r2_coefficient: number | null;
          modeled_percent_observed: number | null;
          percent_completed: number | null;
          modeled_quarter_end_sales: number | null;
          modeled_quarter_end_yoy: number | null;
          mean_consensus_usd: number | null;
          mean_consensus_yoy: number | null;
          min_consensus_yoy: number | null;
          max_consensus_yoy: number | null;
          modeled_yoy_mean_consensus_yoy: number | null;
          in_consensus_range: boolean;
          company: {
            __typename: 'Company';
            id: string;
            title: string;
            logo: string | null;
            exchange: string | null;
            ticker: string | null;
          };
          fiscal_quarter: {
            __typename: 'Quarter';
            end_date: string;
            fiscal_year: number;
            fiscal_quarter: number;
          };
        }
    >;
  };
};

export type QpsNodeUnreportedOrCurrentFragment = {
  __typename: 'QpsNodeUnreportedOrCurrent';
  scaling_model: QpsScalingModel;
  r2_coefficient: number | null;
  modeled_percent_observed: number | null;
  percent_completed: number | null;
  modeled_quarter_end_sales: number | null;
  modeled_quarter_end_yoy: number | null;
  mean_consensus_usd: number | null;
  mean_consensus_yoy: number | null;
  min_consensus_yoy: number | null;
  max_consensus_yoy: number | null;
  modeled_yoy_mean_consensus_yoy: number | null;
  in_consensus_range: boolean;
  company: {
    __typename: 'Company';
    id: string;
    title: string;
    logo: string | null;
    exchange: string | null;
    ticker: string | null;
  };
  fiscal_quarter: {
    __typename: 'Quarter';
    end_date: string;
    fiscal_year: number;
    fiscal_quarter: number;
  };
};

export type QpsNodeLastReportedFragment = {
  __typename: 'QpsNodeLastReported';
  scaling_model: QpsScalingModel;
  r2_coefficient: number | null;
  modeled_percent_observed: number | null;
  percent_completed: number | null;
  modeled_quarter_end_sales: number | null;
  modeled_quarter_end_yoy: number | null;
  mean_consensus_usd: number | null;
  mean_consensus_yoy: number | null;
  min_consensus_yoy: number | null;
  max_consensus_yoy: number | null;
  reported_revenue: number | null;
  reported_yoy: number | null;
  reported_yoy_mean_consensus_yoy: number | null;
  reported_yoy_modeled_yoy: number;
  reported_yoy_modeled_yoy_absolute_value: number;
  in_consensus_range: boolean;
  company: {
    __typename: 'Company';
    id: string;
    title: string;
    logo: string | null;
    exchange: string | null;
    ticker: string | null;
  };
  fiscal_quarter: {
    __typename: 'Quarter';
    end_date: string;
    fiscal_year: number;
    fiscal_quarter: number;
  };
};

export type GetQpsExportAllQueryVariables = Exact<{
  request: QpsRequest;
}>;

export type GetQpsExportAllQuery = {
  __typename: 'Query';
  qps_export_all: {
    __typename: 'QpsExportAllResponse';
    data: string;
    filename: string;
  };
};

export type GetQtdQueryVariables = Exact<{
  request: QtdRequest;
}>;

export type GetQtdQuery = {
  __typename: 'Query';
  qtd_v2:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'QtdResponse';
        scaling_model: QtdScalingModel;
        scaling_model_custom_percent: number;
        projected_start_date: string;
        last_projected_day_analysis: number;
        last_projected_day_growth: number;
        analysis_latest_quarter: boolean;
        annotations: {
          __typename: 'QtdAnnotations';
          scaling_model: string;
          revenue_estimate: Array<number | null>;
          revenue_estimate_growth: Array<number | null>;
          consensus: Array<number | null>;
          consensus_growth: Array<number | null>;
          last_observed_day: number;
        };
        x_series: {
          __typename: 'QtdXSerie';
          name: QtdXSerieName;
          data: Array<number>;
        };
        y_series: Array<{
          __typename: 'QtdYSerie';
          name: QtdYSerieName;
          data: Array<string | null>;
        }>;
        analysis_quarter: {
          __typename: 'Quarter';
          is_reported: boolean;
          fiscal_year: number;
          fiscal_quarter: number;
          start_date: string;
          end_date: string;
        };
        comparison_quarter: {
          __typename: 'Quarter';
          is_reported: boolean;
          fiscal_year: number;
          fiscal_quarter: number;
          start_date: string;
          end_date: string;
        };
      };
};

export type GetQtdExportAllQueryVariables = Exact<{
  request: QtdRequest;
}>;

export type GetQtdExportAllQuery = {
  __typename: 'Query';
  qtd_export_all: {
    __typename: 'QtdExportAllResponse';
    data: string;
    filename: string;
  };
};

export type GetRetentionSummaryQueryVariables = Exact<{
  request: RetentionSummaryRequest;
}>;

export type GetRetentionSummaryQuery = {
  __typename: 'Query';
  retention_summary_v2:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'RetentionSummaryResponse';
        periods: Array<string>;
        y_series: {
          __typename: 'RetentionSummaryYSeries';
          period_over_period_retention: Array<{
            __typename: 'RetentionSummaryYSerie';
            name: string;
            data: Array<number | null>;
          }>;
          period_over_period_dollar_retention: Array<{
            __typename: 'RetentionSummaryYSerie';
            name: string;
            data: Array<number | null>;
          }>;
        };
      };
};

export type GetRetentionSummaryExportAllQueryVariables = Exact<{
  request: RetentionSummaryRequest;
}>;

export type GetRetentionSummaryExportAllQuery = {
  __typename: 'Query';
  retention_summary_export_all: {
    __typename: 'RetentionSummaryExportAllResponse';
    data: string;
    filename: string;
  };
};

export type TotpEnrollMutationVariables = Exact<{ [key: string]: never }>;

export type TotpEnrollMutation = {
  __typename: 'Mutation';
  totp_enroll: string;
};

export type TotpUnenrollMutationVariables = Exact<{ [key: string]: never }>;

export type TotpUnenrollMutation = {
  __typename: 'Mutation';
  totp_unenroll: void | null;
};

export type GetTransactionValuesQueryVariables = Exact<{
  request: TransactionValuesRequest;
}>;

export type GetTransactionValuesQuery = {
  __typename: 'Query';
  transaction_values_v2:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'TransactionValuesResponse';
        data: Array<{
          __typename: 'TransactionValuesRow';
          transaction_value_min: number;
          transaction_value_max: number;
          percent_transactions: number;
          cumulative_percent_transactions: number;
          percent_sales: number;
          cumulative_percent_sales: number;
        }>;
      };
};

export type TransactionValuesResponseFragment = {
  __typename: 'TransactionValuesResponse';
  data: Array<{
    __typename: 'TransactionValuesRow';
    transaction_value_min: number;
    transaction_value_max: number;
    percent_transactions: number;
    cumulative_percent_transactions: number;
    percent_sales: number;
    cumulative_percent_sales: number;
  }>;
};

export type GetTransactionValuesExportAllQueryVariables = Exact<{
  request: TransactionValuesRequest;
}>;

export type GetTransactionValuesExportAllQuery = {
  __typename: 'Query';
  transaction_values_export_all: {
    __typename: 'TransactionValuesExportAllResponse';
    data: string;
    filename: string;
  };
};

export type GetTrendsQueryVariables = Exact<{
  request: TrendsV3Request;
}>;

export type GetTrendsQuery = {
  __typename: 'Query';
  trends_v3:
    | { __typename: 'DataError'; message: string }
    | {
        __typename: 'TrendsResponse';
        rollup: TrendsRollup;
        breakout_by: BreakoutBy;
        cbsas: Array<{
          __typename: 'GeographicalArea';
          id: string;
          code: string;
          title: string;
          type: GeographicalAreaType;
        }> | null;
        companies_or_brands: Array<
          | { __typename: 'Brand'; id: string; title: string }
          | { __typename: 'Company'; id: string; title: string }
        >;
        data: Array<{
          __typename: 'TrendsDataset';
          id: string;
          data: Array<{
            __typename: 'Serie';
            id: string;
            data: Array<string | null>;
          }>;
        }>;
      };
};

export type TrendsQueryResponseFragment = {
  __typename: 'TrendsResponse';
  rollup: TrendsRollup;
  breakout_by: BreakoutBy;
  cbsas: Array<{
    __typename: 'GeographicalArea';
    id: string;
    code: string;
    title: string;
    type: GeographicalAreaType;
  }> | null;
  companies_or_brands: Array<
    | { __typename: 'Brand'; id: string; title: string }
    | { __typename: 'Company'; id: string; title: string }
  >;
  data: Array<{
    __typename: 'TrendsDataset';
    id: string;
    data: Array<{
      __typename: 'Serie';
      id: string;
      data: Array<string | null>;
    }>;
  }>;
};

export type TrendsCompanyFragmentFragment = {
  __typename: 'Company';
  id: string;
  title: string;
};

export type TrendsBrandFragmentFragment = {
  __typename: 'Brand';
  id: string;
  title: string;
};

export type GetTrendsExportMutationVariables = Exact<{
  params: TrendsExportRequest;
}>;

export type GetTrendsExportMutation = {
  __typename: 'Mutation';
  trends_export: {
    __typename: 'TrendsExportResponse';
    filename: string;
    data: string;
  };
};

export type GetTrendsExportAllQueryVariables = Exact<{
  request: TrendsV3Request;
}>;

export type GetTrendsExportAllQuery = {
  __typename: 'Query';
  trends_export_all_v3:
    | { __typename: 'DataError'; message: string }
    | { __typename: 'TrendsExportResponse'; data: string; filename: string };
};

export const MetadataChildFragmentDoc = `
    fragment MetadataChild on ChildBrand {
  is_expired
  expiration_date
  brand {
    id
    title
    channels
    type
  }
}
    `;
export const MetadataCompanyFragmentDoc = `
    fragment MetadataCompany on Company {
  __typename
  id
  title
  website
  ticker
  exchange
  logo
  child_brands {
    ...MetadataChild
  }
  channels
}
    `;
export const MetadataBrandFragmentDoc = `
    fragment MetadataBrand on Brand {
  __typename
  id
  title
  website
  logo
  channels
  type
  ultimate_parent {
    id
    title
  }
}
    `;
export const UseCompanyFragmentFragmentDoc = `
    fragment UseCompanyFragment on Company {
  __typename
  id
  title
  reports_quarterly
  public
  logo
}
    `;
export const UseBrandFragmentFragmentDoc = `
    fragment UseBrandFragment on Brand {
  __typename
  id
  title
  logo
}
    `;
export const CompanyQuarterFragmentDoc = `
    fragment CompanyQuarter on Quarter {
  start_date
  end_date
  fiscal_year
  fiscal_quarter
}
    `;
export const BrowseBusinessesCompanyNodeFragmentDoc = `
    fragment BrowseBusinessesCompanyNode on BrowseBusinessesCompanyNode {
  __typename
  observed_sales_dollars
  observed_sales_yoy_percent
  observed_sales_ltm_chart {
    x
    y
  }
  observed_customers
  observed_customers_yoy_percent
  observed_customers_ltm_chart {
    x
    y
  }
  observed_transactions
  observed_transactions_yoy_percent
  observed_transactions_ltm_chart {
    x
    y
  }
  market_capitalization_dollars
  r2_coefficient
  company {
    id
    logo
    title
    ticker
    exchange
    figi
    bloomberg_id
  }
}
    `;
export const BrowseBusinessesBrandNodeFragmentDoc = `
    fragment BrowseBusinessesBrandNode on BrowseBusinessesBrandNode {
  __typename
  observed_sales_dollars
  observed_sales_yoy_percent
  observed_sales_ltm_chart {
    x
    y
  }
  observed_customers
  observed_customers_yoy_percent
  observed_customers_ltm_chart {
    x
    y
  }
  observed_transactions
  observed_transactions_yoy_percent
  observed_transactions_ltm_chart {
    x
    y
  }
  brand {
    id
    type
    logo
    title
    ultimate_parent {
      id
      logo
      title
    }
    industry {
      id
      title
    }
  }
}
    `;
export const QpsNodeUnreportedOrCurrentFragmentDoc = `
    fragment QpsNodeUnreportedOrCurrent on QpsNodeUnreportedOrCurrent {
  __typename
  company {
    __typename
    id
    title
    logo
    exchange
    ticker
  }
  scaling_model
  r2_coefficient
  modeled_percent_observed
  fiscal_quarter {
    __typename
    end_date
    fiscal_year
    fiscal_quarter
  }
  percent_completed
  modeled_quarter_end_sales
  modeled_quarter_end_yoy
  mean_consensus_usd
  mean_consensus_yoy
  min_consensus_yoy
  max_consensus_yoy
  modeled_yoy_mean_consensus_yoy
  in_consensus_range
}
    `;
export const QpsNodeLastReportedFragmentDoc = `
    fragment QpsNodeLastReported on QpsNodeLastReported {
  __typename
  company {
    __typename
    id
    title
    logo
    exchange
    ticker
  }
  scaling_model
  r2_coefficient
  modeled_percent_observed
  fiscal_quarter {
    __typename
    end_date
    fiscal_year
    fiscal_quarter
  }
  percent_completed
  modeled_quarter_end_sales
  modeled_quarter_end_yoy
  mean_consensus_usd
  mean_consensus_yoy
  min_consensus_yoy
  max_consensus_yoy
  reported_revenue
  reported_yoy
  reported_yoy_mean_consensus_yoy
  reported_yoy_modeled_yoy
  reported_yoy_modeled_yoy_absolute_value
  in_consensus_range
}
    `;
export const TransactionValuesResponseFragmentDoc = `
    fragment TransactionValuesResponse on TransactionValuesResponse {
  data {
    __typename
    transaction_value_min
    transaction_value_max
    percent_transactions
    cumulative_percent_transactions
    percent_sales
    cumulative_percent_sales
  }
}
    `;
export const TrendsBrandFragmentFragmentDoc = `
    fragment TrendsBrandFragment on Brand {
  __typename
  id
  title
}
    `;
export const TrendsCompanyFragmentFragmentDoc = `
    fragment TrendsCompanyFragment on Company {
  __typename
  id
  title
}
    `;
export const TrendsQueryResponseFragmentDoc = `
    fragment TrendsQueryResponse on TrendsResponse {
  rollup
  breakout_by
  cbsas {
    __typename
    id
    code
    title
    type
  }
  companies_or_brands {
    ... on Brand {
      id
      ...TrendsBrandFragment
    }
    ... on Company {
      id
      ...TrendsCompanyFragment
    }
  }
  data {
    __typename
    id
    data {
      __typename
      id
      data
    }
  }
}
    `;
export const BusinessMetadataDocument = `
    query BusinessMetadata($ids: [String!]!) {
  get_brands_or_companies(ids: $ids) {
    __typename
    ... on Company {
      id
      ...MetadataCompany
    }
    ... on Brand {
      id
      ...MetadataBrand
    }
  }
}
    ${MetadataCompanyFragmentDoc}
${MetadataChildFragmentDoc}
${MetadataBrandFragmentDoc}`;
export const useBusinessMetadataQuery = <
  TData = BusinessMetadataQuery,
  TError = unknown
>(
  variables: BusinessMetadataQueryVariables,
  options?: UseQueryOptions<BusinessMetadataQuery, TError, TData>
) =>
  useQuery<BusinessMetadataQuery, TError, TData>(
    ['BusinessMetadata', variables],
    fetchGQL<BusinessMetadataQuery, BusinessMetadataQueryVariables>(
      BusinessMetadataDocument,
      variables
    ),
    options
  );
useBusinessMetadataQuery.fetcher = (
  variables: BusinessMetadataQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<BusinessMetadataQuery, BusinessMetadataQueryVariables>(
    BusinessMetadataDocument,
    variables,
    options
  );
export const CompanyPromptDocument = `
    query CompanyPrompt($id: String!) {
  business(id: $id) {
    __typename
    ... on Brand {
      id
      ...UseBrandFragment
      ultimate_parent {
        id
        ...UseCompanyFragment
      }
    }
    ... on Company {
      id
      ...UseCompanyFragment
      brands {
        id
        ...UseBrandFragment
      }
    }
  }
}
    ${UseBrandFragmentFragmentDoc}
${UseCompanyFragmentFragmentDoc}`;
export const useCompanyPromptQuery = <
  TData = CompanyPromptQuery,
  TError = unknown
>(
  variables: CompanyPromptQueryVariables,
  options?: UseQueryOptions<CompanyPromptQuery, TError, TData>
) =>
  useQuery<CompanyPromptQuery, TError, TData>(
    ['CompanyPrompt', variables],
    fetchGQL<CompanyPromptQuery, CompanyPromptQueryVariables>(
      CompanyPromptDocument,
      variables
    ),
    options
  );
useCompanyPromptQuery.fetcher = (
  variables: CompanyPromptQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<CompanyPromptQuery, CompanyPromptQueryVariables>(
    CompanyPromptDocument,
    variables,
    options
  );
export const FilterDocument = `
    query Filter($ids: [String!]!) {
  get_brands_or_companies(ids: $ids) {
    __typename
    ... on Company {
      id
      has_mixed_brand_types
      channels
    }
    ... on Brand {
      id
      channels
    }
  }
}
    `;
export const useFilterQuery = <TData = FilterQuery, TError = unknown>(
  variables: FilterQueryVariables,
  options?: UseQueryOptions<FilterQuery, TError, TData>
) =>
  useQuery<FilterQuery, TError, TData>(
    ['Filter', variables],
    fetchGQL<FilterQuery, FilterQueryVariables>(FilterDocument, variables),
    options
  );
useFilterQuery.fetcher = (
  variables: FilterQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<FilterQuery, FilterQueryVariables>(
    FilterDocument,
    variables,
    options
  );
export const GeoAutocompleteDocument = `
    query GeoAutocomplete($query: String!) {
  search_geographical_areas(request: {query: $query, desc: false, limit: 10}) {
    results {
      id
      title
      code
      type
    }
  }
}
    `;
export const useGeoAutocompleteQuery = <
  TData = GeoAutocompleteQuery,
  TError = unknown
>(
  variables: GeoAutocompleteQueryVariables,
  options?: UseQueryOptions<GeoAutocompleteQuery, TError, TData>
) =>
  useQuery<GeoAutocompleteQuery, TError, TData>(
    ['GeoAutocomplete', variables],
    fetchGQL<GeoAutocompleteQuery, GeoAutocompleteQueryVariables>(
      GeoAutocompleteDocument,
      variables
    ),
    options
  );
useGeoAutocompleteQuery.fetcher = (
  variables: GeoAutocompleteQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GeoAutocompleteQuery, GeoAutocompleteQueryVariables>(
    GeoAutocompleteDocument,
    variables,
    options
  );
export const GetGeosDocument = `
    query GetGeos($codes: [String!]!) {
  geographical_areas(codes: $codes) {
    results {
      id
      type
      code
      title
    }
  }
}
    `;
export const useGetGeosQuery = <TData = GetGeosQuery, TError = unknown>(
  variables: GetGeosQueryVariables,
  options?: UseQueryOptions<GetGeosQuery, TError, TData>
) =>
  useQuery<GetGeosQuery, TError, TData>(
    ['GetGeos', variables],
    fetchGQL<GetGeosQuery, GetGeosQueryVariables>(GetGeosDocument, variables),
    options
  );
useGetGeosQuery.fetcher = (
  variables: GetGeosQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetGeosQuery, GetGeosQueryVariables>(
    GetGeosDocument,
    variables,
    options
  );
export const QuartersDocument = `
    query Quarters($company_id: String!) {
  business(id: $company_id) {
    __typename
    ... on Company {
      id
      public
      reports_quarterly
      quarters {
        ...CompanyQuarter
      }
    }
  }
}
    ${CompanyQuarterFragmentDoc}`;
export const useQuartersQuery = <TData = QuartersQuery, TError = unknown>(
  variables: QuartersQueryVariables,
  options?: UseQueryOptions<QuartersQuery, TError, TData>
) =>
  useQuery<QuartersQuery, TError, TData>(
    ['Quarters', variables],
    fetchGQL<QuartersQuery, QuartersQueryVariables>(
      QuartersDocument,
      variables
    ),
    options
  );
useQuartersQuery.fetcher = (
  variables: QuartersQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<QuartersQuery, QuartersQueryVariables>(
    QuartersDocument,
    variables,
    options
  );
export const GetOvrPercentObservedDocument = `
    query getOvrPercentObserved($request: PercentObservedRequest!) {
  percent_observed_v2(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on PercentObservedResponse {
      best_fit
      r2 {
        __typename
        all_time_average
        exponential_smoothing
        four_quarter_average
        previous_quarter
        previous_year
        unscaled
      }
      scaling_models {
        __typename
        all_time_average
        exponential_smoothing
        four_quarter_average
        previous_quarter
        previous_year
        unscaled
      }
      quarter_end_date
      historical_proportion_observed
    }
  }
}
    `;
export const useGetOvrPercentObservedQuery = <
  TData = GetOvrPercentObservedQuery,
  TError = unknown
>(
  variables: GetOvrPercentObservedQueryVariables,
  options?: UseQueryOptions<GetOvrPercentObservedQuery, TError, TData>
) =>
  useQuery<GetOvrPercentObservedQuery, TError, TData>(
    ['getOvrPercentObserved', variables],
    fetchGQL<GetOvrPercentObservedQuery, GetOvrPercentObservedQueryVariables>(
      GetOvrPercentObservedDocument,
      variables
    ),
    options
  );
useGetOvrPercentObservedQuery.fetcher = (
  variables: GetOvrPercentObservedQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetOvrPercentObservedQuery, GetOvrPercentObservedQueryVariables>(
    GetOvrPercentObservedDocument,
    variables,
    options
  );
export const GetQtdPercentObservedDocument = `
    query getQtdPercentObserved($request: QtdPercentObservedRequest!) {
  qtd_percent_observed_v2(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on PercentObservedResponse {
      best_fit
      r2 {
        all_time_average
        exponential_smoothing
        four_quarter_average
        previous_quarter
        previous_year
        unscaled
      }
      scaling_models {
        all_time_average
        exponential_smoothing
        four_quarter_average
        previous_quarter
        previous_year
        unscaled
      }
      quarter_end_date
      historical_proportion_observed
    }
  }
}
    `;
export const useGetQtdPercentObservedQuery = <
  TData = GetQtdPercentObservedQuery,
  TError = unknown
>(
  variables: GetQtdPercentObservedQueryVariables,
  options?: UseQueryOptions<GetQtdPercentObservedQuery, TError, TData>
) =>
  useQuery<GetQtdPercentObservedQuery, TError, TData>(
    ['getQtdPercentObserved', variables],
    fetchGQL<GetQtdPercentObservedQuery, GetQtdPercentObservedQueryVariables>(
      GetQtdPercentObservedDocument,
      variables
    ),
    options
  );
useGetQtdPercentObservedQuery.fetcher = (
  variables: GetQtdPercentObservedQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetQtdPercentObservedQuery, GetQtdPercentObservedQueryVariables>(
    GetQtdPercentObservedDocument,
    variables,
    options
  );
export const GetCompanyOrBrandDocument = `
    query GetCompanyOrBrand($ids: [String!]!) {
  get_brands_or_companies(ids: $ids) {
    __typename
    ... on Brand {
      id
      title
      website
      logo
    }
    ... on Company {
      id
      title
      website
      public
      reports_quarterly
      logo
    }
  }
}
    `;
export const useGetCompanyOrBrandQuery = <
  TData = GetCompanyOrBrandQuery,
  TError = unknown
>(
  variables: GetCompanyOrBrandQueryVariables,
  options?: UseQueryOptions<GetCompanyOrBrandQuery, TError, TData>
) =>
  useQuery<GetCompanyOrBrandQuery, TError, TData>(
    ['GetCompanyOrBrand', variables],
    fetchGQL<GetCompanyOrBrandQuery, GetCompanyOrBrandQueryVariables>(
      GetCompanyOrBrandDocument,
      variables
    ),
    options
  );
useGetCompanyOrBrandQuery.fetcher = (
  variables: GetCompanyOrBrandQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetCompanyOrBrandQuery, GetCompanyOrBrandQueryVariables>(
    GetCompanyOrBrandDocument,
    variables,
    options
  );
export const GetLastUpdatedDateDocument = `
    query GetLastUpdatedDate {
  last_updated_date
}
    `;
export const useGetLastUpdatedDateQuery = <
  TData = GetLastUpdatedDateQuery,
  TError = unknown
>(
  variables?: GetLastUpdatedDateQueryVariables,
  options?: UseQueryOptions<GetLastUpdatedDateQuery, TError, TData>
) =>
  useQuery<GetLastUpdatedDateQuery, TError, TData>(
    variables === undefined
      ? ['GetLastUpdatedDate']
      : ['GetLastUpdatedDate', variables],
    fetchGQL<GetLastUpdatedDateQuery, GetLastUpdatedDateQueryVariables>(
      GetLastUpdatedDateDocument,
      variables
    ),
    options
  );
useGetLastUpdatedDateQuery.fetcher = (
  variables?: GetLastUpdatedDateQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetLastUpdatedDateQuery, GetLastUpdatedDateQueryVariables>(
    GetLastUpdatedDateDocument,
    variables,
    options
  );
export const GetLatestProductionDateDocument = `
    query GetLatestProductionDate {
  latest_production_date {
    date
  }
}
    `;
export const useGetLatestProductionDateQuery = <
  TData = GetLatestProductionDateQuery,
  TError = unknown
>(
  variables?: GetLatestProductionDateQueryVariables,
  options?: UseQueryOptions<GetLatestProductionDateQuery, TError, TData>
) =>
  useQuery<GetLatestProductionDateQuery, TError, TData>(
    variables === undefined
      ? ['GetLatestProductionDate']
      : ['GetLatestProductionDate', variables],
    fetchGQL<
      GetLatestProductionDateQuery,
      GetLatestProductionDateQueryVariables
    >(GetLatestProductionDateDocument, variables),
    options
  );
useGetLatestProductionDateQuery.fetcher = (
  variables?: GetLatestProductionDateQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetLatestProductionDateQuery, GetLatestProductionDateQueryVariables>(
    GetLatestProductionDateDocument,
    variables,
    options
  );
export const RecordFeatureUsageDocument = `
    mutation RecordFeatureUsage($params: FeatureUsageRequest!) {
  feature_usage(request: $params)
}
    `;
export const useRecordFeatureUsageMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RecordFeatureUsageMutation,
    TError,
    RecordFeatureUsageMutationVariables,
    TContext
  >
) =>
  useMutation<
    RecordFeatureUsageMutation,
    TError,
    RecordFeatureUsageMutationVariables,
    TContext
  >(
    ['RecordFeatureUsage'],
    (variables?: RecordFeatureUsageMutationVariables) =>
      fetchGQL<RecordFeatureUsageMutation, RecordFeatureUsageMutationVariables>(
        RecordFeatureUsageDocument,
        variables
      )(),
    options
  );
useRecordFeatureUsageMutation.fetcher = (
  variables: RecordFeatureUsageMutationVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<RecordFeatureUsageMutation, RecordFeatureUsageMutationVariables>(
    RecordFeatureUsageDocument,
    variables,
    options
  );
export const BrowseBusinessesDocument = `
    query BrowseBusinesses($request: BrowseBusinessesRequest!) {
  browse_businesses(request: $request) {
    data_as_of_month
    nodes {
      ... on BrowseBusinessesBrandNode {
        ...BrowseBusinessesBrandNode
      }
      ... on BrowseBusinessesCompanyNode {
        ...BrowseBusinessesCompanyNode
      }
    }
  }
}
    ${BrowseBusinessesBrandNodeFragmentDoc}
${BrowseBusinessesCompanyNodeFragmentDoc}`;
export const useBrowseBusinessesQuery = <
  TData = BrowseBusinessesQuery,
  TError = unknown
>(
  variables: BrowseBusinessesQueryVariables,
  options?: UseQueryOptions<BrowseBusinessesQuery, TError, TData>
) =>
  useQuery<BrowseBusinessesQuery, TError, TData>(
    ['BrowseBusinesses', variables],
    fetchGQL<BrowseBusinessesQuery, BrowseBusinessesQueryVariables>(
      BrowseBusinessesDocument,
      variables
    ),
    options
  );
useBrowseBusinessesQuery.fetcher = (
  variables: BrowseBusinessesQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<BrowseBusinessesQuery, BrowseBusinessesQueryVariables>(
    BrowseBusinessesDocument,
    variables,
    options
  );
export const GetBrowseBusinessesExportAllDocument = `
    query GetBrowseBusinessesExportAll($request: BrowseBusinessesRequest!) {
  browse_businesses_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetBrowseBusinessesExportAllQuery = <
  TData = GetBrowseBusinessesExportAllQuery,
  TError = unknown
>(
  variables: GetBrowseBusinessesExportAllQueryVariables,
  options?: UseQueryOptions<GetBrowseBusinessesExportAllQuery, TError, TData>
) =>
  useQuery<GetBrowseBusinessesExportAllQuery, TError, TData>(
    ['GetBrowseBusinessesExportAll', variables],
    fetchGQL<
      GetBrowseBusinessesExportAllQuery,
      GetBrowseBusinessesExportAllQueryVariables
    >(GetBrowseBusinessesExportAllDocument, variables),
    options
  );
useGetBrowseBusinessesExportAllQuery.fetcher = (
  variables: GetBrowseBusinessesExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    GetBrowseBusinessesExportAllQuery,
    GetBrowseBusinessesExportAllQueryVariables
  >(GetBrowseBusinessesExportAllDocument, variables, options);
export const GetCohortedRetentionDocument = `
    query GetCohortedRetention($request: CohortedRetentionRequestV2!) {
  cohorted_retention_v4(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on CohortedRetentionResponseV3 {
      periods {
        __typename
        id
        data
      }
      cohorts
      period_0_customers
    }
  }
}
    `;
export const useGetCohortedRetentionQuery = <
  TData = GetCohortedRetentionQuery,
  TError = unknown
>(
  variables: GetCohortedRetentionQueryVariables,
  options?: UseQueryOptions<GetCohortedRetentionQuery, TError, TData>
) =>
  useQuery<GetCohortedRetentionQuery, TError, TData>(
    ['GetCohortedRetention', variables],
    fetchGQL<GetCohortedRetentionQuery, GetCohortedRetentionQueryVariables>(
      GetCohortedRetentionDocument,
      variables
    ),
    options
  );
useGetCohortedRetentionQuery.fetcher = (
  variables: GetCohortedRetentionQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetCohortedRetentionQuery, GetCohortedRetentionQueryVariables>(
    GetCohortedRetentionDocument,
    variables,
    options
  );
export const GetCohortedRetentionExportAllDocument = `
    query GetCohortedRetentionExportAll($request: CohortedRetentionRequestV2!) {
  cohorted_retention_export_all_v2(request: $request) {
    data
    filename
  }
}
    `;
export const useGetCohortedRetentionExportAllQuery = <
  TData = GetCohortedRetentionExportAllQuery,
  TError = unknown
>(
  variables: GetCohortedRetentionExportAllQueryVariables,
  options?: UseQueryOptions<GetCohortedRetentionExportAllQuery, TError, TData>
) =>
  useQuery<GetCohortedRetentionExportAllQuery, TError, TData>(
    ['GetCohortedRetentionExportAll', variables],
    fetchGQL<
      GetCohortedRetentionExportAllQuery,
      GetCohortedRetentionExportAllQueryVariables
    >(GetCohortedRetentionExportAllDocument, variables),
    options
  );
useGetCohortedRetentionExportAllQuery.fetcher = (
  variables: GetCohortedRetentionExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    GetCohortedRetentionExportAllQuery,
    GetCohortedRetentionExportAllQueryVariables
  >(GetCohortedRetentionExportAllDocument, variables, options);
export const GetDollarRetentionDocument = `
    query GetDollarRetention($request: DollarRetentionRequest!) {
  dollar_retention(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on DollarRetentionResponse {
      periods {
        __typename
        id
        data
      }
      cohorts
      period_0_sales
    }
  }
}
    `;
export const useGetDollarRetentionQuery = <
  TData = GetDollarRetentionQuery,
  TError = unknown
>(
  variables: GetDollarRetentionQueryVariables,
  options?: UseQueryOptions<GetDollarRetentionQuery, TError, TData>
) =>
  useQuery<GetDollarRetentionQuery, TError, TData>(
    ['GetDollarRetention', variables],
    fetchGQL<GetDollarRetentionQuery, GetDollarRetentionQueryVariables>(
      GetDollarRetentionDocument,
      variables
    ),
    options
  );
useGetDollarRetentionQuery.fetcher = (
  variables: GetDollarRetentionQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetDollarRetentionQuery, GetDollarRetentionQueryVariables>(
    GetDollarRetentionDocument,
    variables,
    options
  );
export const GetDollarRetentionExportAllDocument = `
    query GetDollarRetentionExportAll($request: DollarRetentionRequest!) {
  dollar_retention_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetDollarRetentionExportAllQuery = <
  TData = GetDollarRetentionExportAllQuery,
  TError = unknown
>(
  variables: GetDollarRetentionExportAllQueryVariables,
  options?: UseQueryOptions<GetDollarRetentionExportAllQuery, TError, TData>
) =>
  useQuery<GetDollarRetentionExportAllQuery, TError, TData>(
    ['GetDollarRetentionExportAll', variables],
    fetchGQL<
      GetDollarRetentionExportAllQuery,
      GetDollarRetentionExportAllQueryVariables
    >(GetDollarRetentionExportAllDocument, variables),
    options
  );
useGetDollarRetentionExportAllQuery.fetcher = (
  variables: GetDollarRetentionExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    GetDollarRetentionExportAllQuery,
    GetDollarRetentionExportAllQueryVariables
  >(GetDollarRetentionExportAllDocument, variables, options);
export const GetGrowthAccountingDocument = `
    query GetGrowthAccounting($request: GrowthAccountingRequest!) {
  customer_growth_accounting(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on GrowthAccountingResponseCustomers {
      periods {
        __typename
        date
        new
        resurrected
        retained
        churned
        quick_ratio
      }
    }
  }
  sales_growth_accounting(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on GrowthAccountingResponseSales {
      periods {
        __typename
        date
        new
        resurrected
        retained
        churned
        quick_ratio
        expansion
        contraction
      }
    }
  }
}
    `;
export const useGetGrowthAccountingQuery = <
  TData = GetGrowthAccountingQuery,
  TError = unknown
>(
  variables: GetGrowthAccountingQueryVariables,
  options?: UseQueryOptions<GetGrowthAccountingQuery, TError, TData>
) =>
  useQuery<GetGrowthAccountingQuery, TError, TData>(
    ['GetGrowthAccounting', variables],
    fetchGQL<GetGrowthAccountingQuery, GetGrowthAccountingQueryVariables>(
      GetGrowthAccountingDocument,
      variables
    ),
    options
  );
useGetGrowthAccountingQuery.fetcher = (
  variables: GetGrowthAccountingQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetGrowthAccountingQuery, GetGrowthAccountingQueryVariables>(
    GetGrowthAccountingDocument,
    variables,
    options
  );
export const ExportCustomerGrowthAccountingDocument = `
    query ExportCustomerGrowthAccounting($request: GrowthAccountingRequest!) {
  export_customer_growth_accounting(request: $request) {
    filename
    data
  }
}
    `;
export const useExportCustomerGrowthAccountingQuery = <
  TData = ExportCustomerGrowthAccountingQuery,
  TError = unknown
>(
  variables: ExportCustomerGrowthAccountingQueryVariables,
  options?: UseQueryOptions<ExportCustomerGrowthAccountingQuery, TError, TData>
) =>
  useQuery<ExportCustomerGrowthAccountingQuery, TError, TData>(
    ['ExportCustomerGrowthAccounting', variables],
    fetchGQL<
      ExportCustomerGrowthAccountingQuery,
      ExportCustomerGrowthAccountingQueryVariables
    >(ExportCustomerGrowthAccountingDocument, variables),
    options
  );
useExportCustomerGrowthAccountingQuery.fetcher = (
  variables: ExportCustomerGrowthAccountingQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    ExportCustomerGrowthAccountingQuery,
    ExportCustomerGrowthAccountingQueryVariables
  >(ExportCustomerGrowthAccountingDocument, variables, options);
export const ExportSalesGrowthAccountingDocument = `
    query ExportSalesGrowthAccounting($request: GrowthAccountingRequest!) {
  export_sales_growth_accounting(request: $request) {
    filename
    data
  }
}
    `;
export const useExportSalesGrowthAccountingQuery = <
  TData = ExportSalesGrowthAccountingQuery,
  TError = unknown
>(
  variables: ExportSalesGrowthAccountingQueryVariables,
  options?: UseQueryOptions<ExportSalesGrowthAccountingQuery, TError, TData>
) =>
  useQuery<ExportSalesGrowthAccountingQuery, TError, TData>(
    ['ExportSalesGrowthAccounting', variables],
    fetchGQL<
      ExportSalesGrowthAccountingQuery,
      ExportSalesGrowthAccountingQueryVariables
    >(ExportSalesGrowthAccountingDocument, variables),
    options
  );
useExportSalesGrowthAccountingQuery.fetcher = (
  variables: ExportSalesGrowthAccountingQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    ExportSalesGrowthAccountingQuery,
    ExportSalesGrowthAccountingQueryVariables
  >(ExportSalesGrowthAccountingDocument, variables, options);
export const GetOvrDocument = `
    query GetOvr($request: OvrRequest!) {
  ovr_v2(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on OvrResponse {
      scaling_model
      target_dimension
      annotations {
        r2
        scaling_model
        historical_percent_observed
        scaled_sales_vs_reported
        consensus_mean_vs_reported
        consensus_range_vs_reported
      }
      x_series
      y_series {
        reported_revenue
        consensus_mean
        consensus_low
        consensus_high
        scaled_sales
        forecast_sales
      }
    }
  }
}
    `;
export const useGetOvrQuery = <TData = GetOvrQuery, TError = unknown>(
  variables: GetOvrQueryVariables,
  options?: UseQueryOptions<GetOvrQuery, TError, TData>
) =>
  useQuery<GetOvrQuery, TError, TData>(
    ['GetOvr', variables],
    fetchGQL<GetOvrQuery, GetOvrQueryVariables>(GetOvrDocument, variables),
    options
  );
useGetOvrQuery.fetcher = (
  variables: GetOvrQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetOvrQuery, GetOvrQueryVariables>(
    GetOvrDocument,
    variables,
    options
  );
export const GetOvrExportAllDocument = `
    query GetOvrExportAll($request: OvrRequest!) {
  ovr_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetOvrExportAllQuery = <
  TData = GetOvrExportAllQuery,
  TError = unknown
>(
  variables: GetOvrExportAllQueryVariables,
  options?: UseQueryOptions<GetOvrExportAllQuery, TError, TData>
) =>
  useQuery<GetOvrExportAllQuery, TError, TData>(
    ['GetOvrExportAll', variables],
    fetchGQL<GetOvrExportAllQuery, GetOvrExportAllQueryVariables>(
      GetOvrExportAllDocument,
      variables
    ),
    options
  );
useGetOvrExportAllQuery.fetcher = (
  variables: GetOvrExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetOvrExportAllQuery, GetOvrExportAllQueryVariables>(
    GetOvrExportAllDocument,
    variables,
    options
  );
export const ResetPasswordDocument = `
    mutation ResetPassword($token: String!, $new_password: String!) {
  reset_password(token: $token, new_password: $new_password)
}
    `;
export const useResetPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResetPasswordMutation,
    TError,
    ResetPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<
    ResetPasswordMutation,
    TError,
    ResetPasswordMutationVariables,
    TContext
  >(
    ['ResetPassword'],
    (variables?: ResetPasswordMutationVariables) =>
      fetchGQL<ResetPasswordMutation, ResetPasswordMutationVariables>(
        ResetPasswordDocument,
        variables
      )(),
    options
  );
useResetPasswordMutation.fetcher = (
  variables: ResetPasswordMutationVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    variables,
    options
  );
export const QpsQueryDocument = `
    query QpsQuery($request: QpsRequest!) {
  qps(request: $request) {
    nodes {
      ... on QpsNodeUnreportedOrCurrent {
        ...QpsNodeUnreportedOrCurrent
      }
      ... on QpsNodeLastReported {
        ...QpsNodeLastReported
      }
    }
  }
}
    ${QpsNodeUnreportedOrCurrentFragmentDoc}
${QpsNodeLastReportedFragmentDoc}`;
export const useQpsQueryQuery = <TData = QpsQueryQuery, TError = unknown>(
  variables: QpsQueryQueryVariables,
  options?: UseQueryOptions<QpsQueryQuery, TError, TData>
) =>
  useQuery<QpsQueryQuery, TError, TData>(
    ['QpsQuery', variables],
    fetchGQL<QpsQueryQuery, QpsQueryQueryVariables>(
      QpsQueryDocument,
      variables
    ),
    options
  );
useQpsQueryQuery.fetcher = (
  variables: QpsQueryQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<QpsQueryQuery, QpsQueryQueryVariables>(
    QpsQueryDocument,
    variables,
    options
  );
export const GetQpsExportAllDocument = `
    query GetQpsExportAll($request: QpsRequest!) {
  qps_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetQpsExportAllQuery = <
  TData = GetQpsExportAllQuery,
  TError = unknown
>(
  variables: GetQpsExportAllQueryVariables,
  options?: UseQueryOptions<GetQpsExportAllQuery, TError, TData>
) =>
  useQuery<GetQpsExportAllQuery, TError, TData>(
    ['GetQpsExportAll', variables],
    fetchGQL<GetQpsExportAllQuery, GetQpsExportAllQueryVariables>(
      GetQpsExportAllDocument,
      variables
    ),
    options
  );
useGetQpsExportAllQuery.fetcher = (
  variables: GetQpsExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetQpsExportAllQuery, GetQpsExportAllQueryVariables>(
    GetQpsExportAllDocument,
    variables,
    options
  );
export const GetQtdDocument = `
    query GetQtd($request: QtdRequest!) {
  qtd_v2(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on QtdResponse {
      scaling_model
      scaling_model_custom_percent
      projected_start_date
      last_projected_day_analysis
      last_projected_day_growth
      analysis_latest_quarter
      annotations {
        scaling_model
        revenue_estimate
        revenue_estimate_growth
        consensus
        consensus_growth
        last_observed_day
      }
      x_series {
        name
        data
      }
      y_series {
        name
        data
      }
      analysis_quarter {
        is_reported
        fiscal_year
        fiscal_quarter
        start_date
        end_date
      }
      comparison_quarter {
        is_reported
        fiscal_year
        fiscal_quarter
        start_date
        end_date
      }
    }
  }
}
    `;
export const useGetQtdQuery = <TData = GetQtdQuery, TError = unknown>(
  variables: GetQtdQueryVariables,
  options?: UseQueryOptions<GetQtdQuery, TError, TData>
) =>
  useQuery<GetQtdQuery, TError, TData>(
    ['GetQtd', variables],
    fetchGQL<GetQtdQuery, GetQtdQueryVariables>(GetQtdDocument, variables),
    options
  );
useGetQtdQuery.fetcher = (
  variables: GetQtdQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetQtdQuery, GetQtdQueryVariables>(
    GetQtdDocument,
    variables,
    options
  );
export const GetQtdExportAllDocument = `
    query GetQtdExportAll($request: QtdRequest!) {
  qtd_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetQtdExportAllQuery = <
  TData = GetQtdExportAllQuery,
  TError = unknown
>(
  variables: GetQtdExportAllQueryVariables,
  options?: UseQueryOptions<GetQtdExportAllQuery, TError, TData>
) =>
  useQuery<GetQtdExportAllQuery, TError, TData>(
    ['GetQtdExportAll', variables],
    fetchGQL<GetQtdExportAllQuery, GetQtdExportAllQueryVariables>(
      GetQtdExportAllDocument,
      variables
    ),
    options
  );
useGetQtdExportAllQuery.fetcher = (
  variables: GetQtdExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetQtdExportAllQuery, GetQtdExportAllQueryVariables>(
    GetQtdExportAllDocument,
    variables,
    options
  );
export const GetRetentionSummaryDocument = `
    query GetRetentionSummary($request: RetentionSummaryRequest!) {
  retention_summary_v2(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on RetentionSummaryResponse {
      periods
      y_series {
        period_over_period_retention {
          name
          data
        }
        period_over_period_dollar_retention {
          name
          data
        }
      }
    }
  }
}
    `;
export const useGetRetentionSummaryQuery = <
  TData = GetRetentionSummaryQuery,
  TError = unknown
>(
  variables: GetRetentionSummaryQueryVariables,
  options?: UseQueryOptions<GetRetentionSummaryQuery, TError, TData>
) =>
  useQuery<GetRetentionSummaryQuery, TError, TData>(
    ['GetRetentionSummary', variables],
    fetchGQL<GetRetentionSummaryQuery, GetRetentionSummaryQueryVariables>(
      GetRetentionSummaryDocument,
      variables
    ),
    options
  );
useGetRetentionSummaryQuery.fetcher = (
  variables: GetRetentionSummaryQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetRetentionSummaryQuery, GetRetentionSummaryQueryVariables>(
    GetRetentionSummaryDocument,
    variables,
    options
  );
export const GetRetentionSummaryExportAllDocument = `
    query GetRetentionSummaryExportAll($request: RetentionSummaryRequest!) {
  retention_summary_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetRetentionSummaryExportAllQuery = <
  TData = GetRetentionSummaryExportAllQuery,
  TError = unknown
>(
  variables: GetRetentionSummaryExportAllQueryVariables,
  options?: UseQueryOptions<GetRetentionSummaryExportAllQuery, TError, TData>
) =>
  useQuery<GetRetentionSummaryExportAllQuery, TError, TData>(
    ['GetRetentionSummaryExportAll', variables],
    fetchGQL<
      GetRetentionSummaryExportAllQuery,
      GetRetentionSummaryExportAllQueryVariables
    >(GetRetentionSummaryExportAllDocument, variables),
    options
  );
useGetRetentionSummaryExportAllQuery.fetcher = (
  variables: GetRetentionSummaryExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    GetRetentionSummaryExportAllQuery,
    GetRetentionSummaryExportAllQueryVariables
  >(GetRetentionSummaryExportAllDocument, variables, options);
export const TotpEnrollDocument = `
    mutation TotpEnroll {
  totp_enroll
}
    `;
export const useTotpEnrollMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TotpEnrollMutation,
    TError,
    TotpEnrollMutationVariables,
    TContext
  >
) =>
  useMutation<
    TotpEnrollMutation,
    TError,
    TotpEnrollMutationVariables,
    TContext
  >(
    ['TotpEnroll'],
    (variables?: TotpEnrollMutationVariables) =>
      fetchGQL<TotpEnrollMutation, TotpEnrollMutationVariables>(
        TotpEnrollDocument,
        variables
      )(),
    options
  );
useTotpEnrollMutation.fetcher = (
  variables?: TotpEnrollMutationVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<TotpEnrollMutation, TotpEnrollMutationVariables>(
    TotpEnrollDocument,
    variables,
    options
  );
export const TotpUnenrollDocument = `
    mutation TotpUnenroll {
  totp_unenroll
}
    `;
export const useTotpUnenrollMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TotpUnenrollMutation,
    TError,
    TotpUnenrollMutationVariables,
    TContext
  >
) =>
  useMutation<
    TotpUnenrollMutation,
    TError,
    TotpUnenrollMutationVariables,
    TContext
  >(
    ['TotpUnenroll'],
    (variables?: TotpUnenrollMutationVariables) =>
      fetchGQL<TotpUnenrollMutation, TotpUnenrollMutationVariables>(
        TotpUnenrollDocument,
        variables
      )(),
    options
  );
useTotpUnenrollMutation.fetcher = (
  variables?: TotpUnenrollMutationVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<TotpUnenrollMutation, TotpUnenrollMutationVariables>(
    TotpUnenrollDocument,
    variables,
    options
  );
export const GetTransactionValuesDocument = `
    query GetTransactionValues($request: TransactionValuesRequest!) {
  transaction_values_v2(request: $request) {
    __typename
    ... on DataError {
      message
    }
    ... on TransactionValuesResponse {
      ...TransactionValuesResponse
    }
  }
}
    ${TransactionValuesResponseFragmentDoc}`;
export const useGetTransactionValuesQuery = <
  TData = GetTransactionValuesQuery,
  TError = unknown
>(
  variables: GetTransactionValuesQueryVariables,
  options?: UseQueryOptions<GetTransactionValuesQuery, TError, TData>
) =>
  useQuery<GetTransactionValuesQuery, TError, TData>(
    ['GetTransactionValues', variables],
    fetchGQL<GetTransactionValuesQuery, GetTransactionValuesQueryVariables>(
      GetTransactionValuesDocument,
      variables
    ),
    options
  );
useGetTransactionValuesQuery.fetcher = (
  variables: GetTransactionValuesQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetTransactionValuesQuery, GetTransactionValuesQueryVariables>(
    GetTransactionValuesDocument,
    variables,
    options
  );
export const GetTransactionValuesExportAllDocument = `
    query GetTransactionValuesExportAll($request: TransactionValuesRequest!) {
  transaction_values_export_all(request: $request) {
    data
    filename
  }
}
    `;
export const useGetTransactionValuesExportAllQuery = <
  TData = GetTransactionValuesExportAllQuery,
  TError = unknown
>(
  variables: GetTransactionValuesExportAllQueryVariables,
  options?: UseQueryOptions<GetTransactionValuesExportAllQuery, TError, TData>
) =>
  useQuery<GetTransactionValuesExportAllQuery, TError, TData>(
    ['GetTransactionValuesExportAll', variables],
    fetchGQL<
      GetTransactionValuesExportAllQuery,
      GetTransactionValuesExportAllQueryVariables
    >(GetTransactionValuesExportAllDocument, variables),
    options
  );
useGetTransactionValuesExportAllQuery.fetcher = (
  variables: GetTransactionValuesExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<
    GetTransactionValuesExportAllQuery,
    GetTransactionValuesExportAllQueryVariables
  >(GetTransactionValuesExportAllDocument, variables, options);
export const GetTrendsDocument = `
    query GetTrends($request: TrendsV3Request!) {
  trends_v3(request: $request) {
    __typename
    ... on TrendsResponse {
      ...TrendsQueryResponse
    }
    ... on DataError {
      message
    }
  }
}
    ${TrendsQueryResponseFragmentDoc}
${TrendsBrandFragmentFragmentDoc}
${TrendsCompanyFragmentFragmentDoc}`;
export const useGetTrendsQuery = <TData = GetTrendsQuery, TError = unknown>(
  variables: GetTrendsQueryVariables,
  options?: UseQueryOptions<GetTrendsQuery, TError, TData>
) =>
  useQuery<GetTrendsQuery, TError, TData>(
    ['GetTrends', variables],
    fetchGQL<GetTrendsQuery, GetTrendsQueryVariables>(
      GetTrendsDocument,
      variables
    ),
    options
  );
useGetTrendsQuery.fetcher = (
  variables: GetTrendsQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetTrendsQuery, GetTrendsQueryVariables>(
    GetTrendsDocument,
    variables,
    options
  );
export const GetTrendsExportDocument = `
    mutation GetTrendsExport($params: TrendsExportRequest!) {
  trends_export(request: $params) {
    filename
    data
  }
}
    `;
export const useGetTrendsExportMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    GetTrendsExportMutation,
    TError,
    GetTrendsExportMutationVariables,
    TContext
  >
) =>
  useMutation<
    GetTrendsExportMutation,
    TError,
    GetTrendsExportMutationVariables,
    TContext
  >(
    ['GetTrendsExport'],
    (variables?: GetTrendsExportMutationVariables) =>
      fetchGQL<GetTrendsExportMutation, GetTrendsExportMutationVariables>(
        GetTrendsExportDocument,
        variables
      )(),
    options
  );
useGetTrendsExportMutation.fetcher = (
  variables: GetTrendsExportMutationVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetTrendsExportMutation, GetTrendsExportMutationVariables>(
    GetTrendsExportDocument,
    variables,
    options
  );
export const GetTrendsExportAllDocument = `
    query GetTrendsExportAll($request: TrendsV3Request!) {
  trends_export_all_v3(request: $request) {
    ... on DataError {
      message
    }
    ... on TrendsExportResponse {
      data
      filename
    }
  }
}
    `;
export const useGetTrendsExportAllQuery = <
  TData = GetTrendsExportAllQuery,
  TError = unknown
>(
  variables: GetTrendsExportAllQueryVariables,
  options?: UseQueryOptions<GetTrendsExportAllQuery, TError, TData>
) =>
  useQuery<GetTrendsExportAllQuery, TError, TData>(
    ['GetTrendsExportAll', variables],
    fetchGQL<GetTrendsExportAllQuery, GetTrendsExportAllQueryVariables>(
      GetTrendsExportAllDocument,
      variables
    ),
    options
  );
useGetTrendsExportAllQuery.fetcher = (
  variables: GetTrendsExportAllQueryVariables,
  options?: RequestInit['headers']
) =>
  fetchGQL<GetTrendsExportAllQuery, GetTrendsExportAllQueryVariables>(
    GetTrendsExportAllDocument,
    variables,
    options
  );
