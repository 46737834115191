import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from 'react-router-dom';

export const useSentry = __ENABLE_SENTRY__;

export const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://f4dd6af6ec274d65b3e29941c52d77d4@o60683.ingest.sentry.io/6512977',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    environment: process.env['NODE_ENV'],
    release: __APP_RELEASE_VERSION__,
    beforeSend(event) {
      // Use this to sanitize the events before sending to Sentry
      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1,
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
