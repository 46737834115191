import React, {
  Suspense,
  useCallback,
  useDeferredValue,
  useState,
} from 'react';
import {
  TrendsChart,
  TrendsLayout,
  TRENDS_CHART_KEYS,
} from '@groundwater/app/ui';
import { useGetTrendsQuery } from '../../../gql-generated';
import { useChartResetKeys } from '../../hooks/useResetKeys';
import { useVariables } from '../../hooks/useVariables';
import { REPORT_PAGES } from '../../constants';
import { Alert, Grid, Skeleton } from '@mui/material';
import { Variables } from '../../../utils';
import { indexOf, omit, sortBy } from 'lodash-es';
import { BusinessMetadata } from '../../components';
import { isEmptyDataset } from './helpers';

export interface TrendsChartsProps {
  variables: { request: Variables[REPORT_PAGES.trends] };
  deferredVariables: { request: Variables[REPORT_PAGES.trends] };
}

const LazyDebugger = React.lazy(() =>
  import('./Debugger').then(({ Debugger }) => ({ default: Debugger }))
);

export const TrendsLoadingSkeleton = () => (
  // The layout grid follows the one in <TrendsPresentation />
  // todo use the real layout
  <Grid
    data-testid="trends-charts-skeleton"
    container
    spacing={{ xs: 2, sm: 2, md: 3 }}
  >
    <Grid item xs={12}>
      <Skeleton variant="rectangular" width="100%" height={150} />
    </Grid>
    {Object.keys(TRENDS_CHART_KEYS).map((i) => {
      return (
        <Grid
          item
          key={i}
          xs={12}
          md={6}
          lg={6}
          xl={
            i === TRENDS_CHART_KEYS.share_of_sales ||
            i === TRENDS_CHART_KEYS.share_of_transactions
              ? 6
              : 4
          }
        >
          <Skeleton variant="rectangular" width="100%" height={350} />
        </Grid>
      );
    })}
  </Grid>
);

export function TrendsCharts() {
  const variables = useVariables(REPORT_PAGES.trends);
  const deferredVariables = useDeferredValue(variables);

  const [hiddenSerieId, setHiddenSerieId] = useState<TRENDS_CHART_KEYS[]>([]);

  const onLegendClick = useCallback((idToToggle: TRENDS_CHART_KEYS) => {
    setHiddenSerieId((ids) => {
      // if currently hidden
      if (ids.includes(idToToggle)) {
        // remove it from hidden IDs
        return ids.filter((id) => id !== idToToggle);
      } else {
        // add it to hidden IDs
        return [...ids, idToToggle];
      }
    });
  }, []);

  const resetKeys = useChartResetKeys();

  const response = useGetTrendsQuery(
    omit(deferredVariables, 'request.y_zoom'),
    {
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60,
    }
  );

  if (!response.data)
    throw new Error('Dataset is not present in the response payload.');

  const isLoading = variables !== deferredVariables;

  const { trends_v3: trends } = response.data;
  if (trends.__typename === 'DataError') {
    return isLoading ? (
      <TrendsLoadingSkeleton />
    ) : (
      <Alert severity="info">{trends.message}</Alert>
    );
  }

  // todo - delete after Koios eliminates the edge case, make it throw an error instead
  else if (isEmptyDataset(trends)) {
    return isLoading ? (
      <TrendsLoadingSkeleton />
    ) : (
      <Alert severity="info">
        No results for the selected paramaters and time period
      </Alert>
    );
  }

  const sortedData = sortBy(trends.data, (datum) =>
    indexOf(Object.keys(TRENDS_CHART_KEYS), datum.id)
  );

  return (
    <>
      {localStorage['debug_bloomberg_secondmeasure'] && (
        <LazyDebugger {...{ variables, deferredVariables }} />
      )}
      <TrendsLayout
        ids={sortedData.map((dataset) => dataset.id) as TRENDS_CHART_KEYS[]}
        metadata={<BusinessMetadata />}
        renderChart={(id) => {
          const dataset = trends.data.find((dataset) => dataset.id === id);
          if (!dataset) {
            throw new Error("unable to find expected chart's dataset!");
          }
          return (
            <TrendsChart
              key={resetKeys.join(',')}
              isLoading={isLoading}
              onLegendClick={(serieId) => {
                onLegendClick(serieId);
              }}
              hiddenSerieId={hiddenSerieId}
              request={deferredVariables.request}
              yZoom={variables.request.y_zoom === 'true'}
              id={id}
              companies_or_brands={trends.companies_or_brands}
              cbsas={trends.cbsas}
              data={dataset.data}
            />
          );
        }}
      />
    </>
  );
}
