import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from '@mui/material';
import React from 'react';
import { Brand, BrandOrCompany } from '../types';

import { useScrollIntoView } from '../hooks/useScrollIntoView';
import { BusinessLogo } from '../../BusinessLogo';

export const BusinessSearchResultBrand: React.FC<{
  onSelect: (companyOrBrand: BrandOrCompany) => void;
  isSelected: boolean;
  index: number;
  brand: Brand;
  api_url: string;
}> = React.memo(({ onSelect, isSelected, index, brand, api_url }) => {
  const ref = useScrollIntoView(isSelected);
  return (
    <React.Fragment key={brand.id}>
      <Divider />
      <List component="div" disablePadding>
        <ListItemButton
          ref={ref}
          onClick={() => onSelect(brand)}
          sx={{
            pl: 5,
            backgroundColor: isSelected ? 'lightgray' : 'inherit',
          }}
          data-testid={isSelected ? 'isSelected' : ''}
        >
          <ListItemIcon
            sx={{
              minWidth: 25,
              minHeight: 25,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BusinessLogo api_url={api_url} business={brand} />
          </ListItemIcon>

          <ListItemText disableTypography primary={brand.title} />
        </ListItemButton>
      </List>
    </React.Fragment>
  );
});
