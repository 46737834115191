import React, { Suspense, useDeferredValue } from 'react';
import {
  GetTransactionValuesQuery,
  useGetTransactionValuesQuery,
} from '../../../gql-generated';
import { useCallback, useMemo, useRef, useState } from 'react';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { ReportLayout } from '../../layouts/ReportLayout';
import { LineChart } from './LineChart';
import { Histogram } from './Histogram';
import { BrushSelection, TxnDataMap } from './types';
import { useApplyFilters } from '../../hooks/useApplyFilters';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import { useReportPage } from '../../hooks/useReportPage';
import { REPORT_PAGES } from '../../constants';
import { Alert, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { AnalysisGuideButton, ChartFrame } from '@groundwater/app/ui';
import { VariableKeys } from '../../../utils';
import { TransactionValuesExportAllButton } from './TransactionValuesExportAllButton';
import { BusinessMetadata } from '../../components/BusinessMetadata/BusinessMetadata';
import { useVariables } from '../../hooks/useVariables';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Transaction Values';

export enum TRANSACTION_VALUE_SERIES {
  TRANSACTION_VALUE_MIN = 'transaction_value_min',
  TRANSACTION_VALUE_MAX = 'transaction_value_max',
  PERCENT_TRANSACTIONS = 'percent_transactions',
  CUMULATIVE_PERCENT_TRANSACTIONS = 'cumulative_percent_transactions',
  PERCENT_SALES = 'percent_sales',
  CUMULATIVE_PERCENT_SALES = 'cumulative_percent_sales',
}

export enum CHART_IDS {
  CUMULATIVE_PERCENT_OF_TRANSACTIONS = 'cumulative_percent_transactions',
  PERCENT_OF_TRANSACTIONS = 'percent_transactions',
  CUMULATIVE_PERCENT_OF_SALES = 'cumulative_percent_sales',
  PERCENT_OF_SALES = 'percent_sales',
}

export const HISTOGRAM_DIMENSIONS = {
  HEIGHT: 260,
  WIDTH: 600,
};
export const LINECHART_DIMENSIONS = {
  HEIGHT: 220,
  WIDTH: 750,
};

const lineChartMargin = {
  top: 10,
  right: 0,
  bottom: 50,
  left: 35,
};

const histogramMargin = {
  top: 10,
  right: 10,
  bottom: 50,
  left: 40,
};

const LoadingSkeleton = () => (
  <Grid
    container
    spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    data-testid="transaction-values-skeleton"
  >
    <Grid item xs={12}>
      <Skeleton variant="rectangular" width="100%" height={150} />
    </Grid>
    <Grid item xs={12} lg={7}>
      <Skeleton variant="rectangular" width="100%" height={370} />
    </Grid>
    <Grid item xs={12} lg={5}>
      <Skeleton variant="rectangular" width="100%" height={370} />
    </Grid>
    <Grid item xs={12} lg={7}>
      <Skeleton variant="rectangular" width="100%" height={370} />
    </Grid>
    <Grid item xs={12} lg={5}>
      <Skeleton variant="rectangular" width="100%" height={370} />
    </Grid>
  </Grid>
);

export const TransactionValuesPage = React.memo(() => {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <ReportLayout
        page_title={page_title}
        filterSubHeaderActionContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <AnalysisGuideButton fileName="transaction_values.pdf" />
            <TransactionValuesExportAllButton />
          </Stack>
        }
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <TransactionValuesCharts />
        </Suspense>
      </ReportLayout>
    </WithCompanyIdPrompt>
  );
});

const NoSelectionMessage = () => (
  <Typography variant="body2" pl={4}>
    Enter a transaction value range using the "Transaction range" filter above
    or highlight a range by clicking and dragging on a chart to view more
    details.
  </Typography>
);

const TransactionValuesCharts: React.FC<{}> = () => {
  /** Selection in domain values (dollar amounts, not pixels) */
  const coercedQueryParams = useCoercedQueryParams(
    REPORT_PAGES.transaction_values
  );

  /** Selection start/end index */
  /** This contains the selection while the user is dragging, once they release it will update the URL and this will be undefined */
  const [draftSelection, doSetDraftSelection] = useState<BrushSelection>();

  /** A reference to the pending animation frame, if any */
  const frame = useRef<ReturnType<typeof requestAnimationFrame>>();

  /**
   * As we drag we generate mouse events that come in at a higher rate than the Browser can paint frames.
   * To avoid back pressure, the handler for the mouse move events will simply replace a request animation
   * frame callback. This ensures we are only applying the latest state anytime the browser is ready to paint a frame.
   * We avoid queuing up more state changes at a faster rate than the Browser can paint frames with this optimization
   */
  const setDraftSelection = useCallback(
    (targetSelection: BrushSelection | undefined) => {
      // If there is a pending frame that hasn't painted yet, it is now stale, cancel it
      // We do not want to delay painting the latest state while the browser processes stale states
      if (frame.current) {
        cancelAnimationFrame(frame.current);
      }
      // Now queue up an animation frame to paint the *latest* state
      frame.current = requestAnimationFrame(() => {
        doSetDraftSelection(targetSelection);
      });
    },
    []
  );

  const handleFilterApply = useApplyFilters(useReportPage());

  const { data } = useGetTransactionValuesQuery({
    request: {
      company_id: coercedQueryParams.company_id,
      start_date: coercedQueryParams.start_date,
      end_date: coercedQueryParams.end_date,
      filter_brand_types: coercedQueryParams.filter_brand_types,
    },
  });

  if (!data) {
    throw new Error();
  }

  const { transaction_values_v2: transaction_values } = data;

  const rows = useMemo(() => {
    if (transaction_values.__typename === 'DataError') {
      return [];
    }
    return transaction_values.data;
  }, [transaction_values]);

  /** This "dervied state" is the draft selection while they are dragging, or the value from the URL after they release */
  const selection: [number, number] | undefined = useMemo(() => {
    // If there is a draft state, that is our selection
    if (draftSelection) return draftSelection;

    // Parse the URL to get the selection (dollar amount)
    const start = coercedQueryParams.transaction_value_min;
    const end = coercedQueryParams.transaction_value_max;
    if (!start || !end) {
      return undefined;
    }
    // convert dollar amounts to indexes
    const minBucket = rows.findIndex(
      (row) => row.transaction_value_min === start
    );
    const maxBucket = rows.findIndex(
      (row) => row.transaction_value_max === end
    );

    if (-1 === minBucket || -1 === maxBucket) {
      return undefined;
    }

    return [minBucket, maxBucket];
  }, [
    coercedQueryParams.transaction_value_max,
    coercedQueryParams.transaction_value_min,
    draftSelection,
    rows,
  ]);

  /** Used to update the URL after that user releases and makes the selection */
  const updateURL = useCallback(
    (selection) => {
      if (!selection) return;
      const minDollarAmount = rows[selection[0]]!.transaction_value_min;
      const maxDollarAmount = rows[selection[1]]!.transaction_value_max;

      handleFilterApply(
        {
          [VariableKeys.transaction_value_min]: minDollarAmount,
          [VariableKeys.transaction_value_max]: maxDollarAmount,
        },
        { replace: true }
      );
      setDraftSelection(undefined);
    },
    [handleFilterApply, rows, setDraftSelection]
  );

  const rowsByMinDollarAmount: TxnDataMap = useMemo(() => {
    const map: TxnDataMap = new Map();
    if (transaction_values.__typename === 'DataError') {
      return new Map();
    }
    const rows = transaction_values.data;
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index]!;
      map.set(row.transaction_value_min, { ...row, index });
    }
    return map;
  }, [transaction_values]);

  const variables = useVariables(REPORT_PAGES.transaction_values);
  const deferredVariables = useDeferredValue(variables);

  const isLoading = variables !== deferredVariables;

  if (transaction_values.__typename === 'DataError') {
    return isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Alert severity="info">{transaction_values.message}</Alert>
    );
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    >
      <Grid item xs={12}>
        <BusinessMetadata />
      </Grid>
      <Grid item xs={12} lg={7}>
        <ChartFrame
          chartTitle="Cumulative Percent of Transactions"
          isLoading={isLoading}
        >
          <LineChart
            rows={rows}
            selection={selection}
            id={CHART_IDS.CUMULATIVE_PERCENT_OF_TRANSACTIONS}
            margin={lineChartMargin}
            width={LINECHART_DIMENSIONS.WIDTH}
            height={LINECHART_DIMENSIONS.HEIGHT}
            fill="#396AB1"
            onBrushMove={setDraftSelection}
            onBrushMoveEnd={updateURL}
          />
        </ChartFrame>
      </Grid>
      <Grid item xs={12} lg={5}>
        <ChartFrame chartTitle="Percent of Transactions" isLoading={isLoading}>
          {selection ? (
            <Histogram
              rows={rows}
              rowsByMinDollarAmount={rowsByMinDollarAmount}
              selection={selection}
              margin={histogramMargin}
              width={HISTOGRAM_DIMENSIONS.WIDTH}
              height={HISTOGRAM_DIMENSIONS.HEIGHT}
              fill="#396AB1"
              id={CHART_IDS.PERCENT_OF_TRANSACTIONS}
              label="Percent Transactions"
            />
          ) : (
            <NoSelectionMessage />
          )}
        </ChartFrame>
      </Grid>
      <Grid item xs={12} lg={7}>
        <ChartFrame
          chartTitle="Cumulative Percent of Sales"
          isLoading={isLoading}
        >
          <LineChart
            rows={rows}
            selection={selection}
            id={CHART_IDS.CUMULATIVE_PERCENT_OF_SALES}
            margin={lineChartMargin}
            width={LINECHART_DIMENSIONS.WIDTH}
            height={LINECHART_DIMENSIONS.HEIGHT}
            fill="#396AB1"
            onBrushMove={setDraftSelection}
            onBrushMoveEnd={updateURL}
          />
        </ChartFrame>
      </Grid>
      <Grid item xs={12} lg={5}>
        <ChartFrame chartTitle="Percent of Sales" isLoading={isLoading}>
          {selection ? (
            <Histogram
              rows={rows}
              rowsByMinDollarAmount={rowsByMinDollarAmount}
              selection={selection}
              margin={histogramMargin}
              width={HISTOGRAM_DIMENSIONS.WIDTH}
              height={HISTOGRAM_DIMENSIONS.HEIGHT}
              fill="#396AB1"
              id={CHART_IDS.PERCENT_OF_SALES}
              label="Percent Sales"
            />
          ) : (
            <NoSelectionMessage />
          )}
        </ChartFrame>
      </Grid>
    </Grid>
  );
};
