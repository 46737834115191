/// <reference types="react/experimental" />
import {
  MenuList,
  MenuItem,
  ListItemText,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { FilterButtonMenuOption, FilterMenuList } from './FilterMenuList';

export interface FilterButtonGroupedMenuOption<OptionValue> {
  label: string;
  groupItems: FilterButtonMenuOption<OptionValue>[];
}

export interface FilterMenuGroupedListProps<OptionValue> {
  menuOptions: FilterButtonGroupedMenuOption<OptionValue>[];
  onClick: (value: OptionValue) => void;
  selected: OptionValue;
}
export const FilterMenuGroupedList = <OptionValue,>({
  menuOptions,
  onClick,
  selected,
}: FilterMenuGroupedListProps<OptionValue>): React.ReactElement => {
  return (
    <MenuList autoFocus variant="selectedMenu" dense>
      {menuOptions.map(({ label, groupItems }, ind) => {
        return (
          <Box key={label as React.Key}>
            <MenuItem
              disableRipple
              disableTouchRipple
              data-testid={`group-${label}`}
              sx={{
                cursor: 'default',
                // Do not highlight group label
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <ListItemText>
                <Typography variant="subtitle2">{label}</Typography>
              </ListItemText>
            </MenuItem>
            <FilterMenuList
              menuOptions={groupItems}
              onClick={onClick}
              selected={selected}
            />
            {/* Do not add divider after the last option */}
            {menuOptions.length - 1 !== ind && <Divider />}
          </Box>
        );
      })}
    </MenuList>
  );
};
