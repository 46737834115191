import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { TextField, Card, Stack, Container, Typography } from '@mui/material';
import { MHidden } from '../@material-extend';
import { ExternalLink } from '../components/ExternalLink';
import { object, string } from 'yup';
import { useForm, useToast } from '../hooks';
import { useVerifyTotpCodeMutation } from '../../gql-generated';
import { useAuth } from '../auth/context';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.primary.dark,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const validationSchema = object().shape({
  code: string().required('TOTP code is required'),
});

export function TwoFactorAuthTOTP({
  switchToEmail,
}: {
  switchToEmail: () => void;
}) {
  const verifyTotp = useVerifyTotpCodeMutation();
  const { setToast } = useToast();

  const handleVerificationResponse = (verified: boolean) => {
    if (verified) {
      setToast({
        id: 'totp-verified',
        open: true,
        severity: 'info',
        message: 'TOTP was verified',
      });
      window.location.href = '/';
    } else {
      setToast({
        id: 'invalid_login_credentials',
        open: true,
        severity: 'error',
        message: `Invalid TOTP code`,
      });
    }
  };

  const verifyTotpCode = async (values: any, e: any) => {
    e.preventDefault();
    try {
      const verified = await verifyTotp.mutateAsync({
        token: getValues('code'),
      });
      handleVerificationResponse(verified.totp_verify);
    } catch (err) {
      setToast({
        id: 'network_error',
        open: true,
        severity: 'error',
        message: err as any,
      });
    }
    return false;
  };

  const { register, formProps, getValues } = useForm({
    onSubmit: verifyTotpCode,
    defaultValues: {
      code: '',
    },
    validationSchema,
  });

  const auth = useAuth();

  return (
    <RootStyle>
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack
            alignItems="start"
            justifyContent="space-between"
            sx={{ px: 5, mt: 10, mb: 5 }}
          >
            {/* <StyledTypography variant="h3">2Fa</StyledTypography>
            disclaimer */}
          </Stack>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Google Authenticator
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your Google Authenticator passcode
            </Typography>
            <a href="#" onClick={switchToEmail}>
              Switch to email
            </a>
          </Stack>
          <form {...formProps}>
            <Stack spacing={3}>
              <Stack spacing={3} direction="row">
                <TextField
                  label="2FA Code"
                  {...register('code')}
                  style={{ flexGrow: 1 }}
                />
              </Stack>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={verifyTotp.isLoading}
              >
                Verify
              </LoadingButton>
              <Stack alignItems="end">
                <div>
                  Having trouble?{' '}
                  <ExternalLink
                    light
                    href={'mailto:bsmsupport@bloomberg.net'}
                    target="_blank"
                  >
                    Contact us.
                  </ExternalLink>
                </div>
                <div>
                  <ExternalLink
                    light
                    onClick={async () => {
                      await auth.logout();
                    }}
                    target="_blank"
                  >
                    Back to sign in.
                  </ExternalLink>
                </div>
              </Stack>
            </Stack>
          </form>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
