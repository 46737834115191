import queryString from 'query-string';
import moment from 'moment';
import { Link, Tooltip, Typography } from '@mui/material';
import { History } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { MetadataChildFragment } from '../../../gql-generated';
import { createURLQuery } from '../../../utils/createURLQuery';
import { useReportPage } from '../../hooks/useReportPage';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import { REPORT_PAGES } from '../../constants';
import { isValidURLQuery } from '../../../utils';

const useNaviateTo = (company_id: string): string => {
  const page = useReportPage();
  const existingParams = useCoercedQueryParams(page);

  // try to create params for the new page, using the defaults with any valid existing URL search params overridden
  const params = createURLQuery(page, {
    ...existingParams,
    company_id,
  });

  let navigateTo = `${page}?${queryString.stringify(params, {
    arrayFormat: 'bracket',
  })}`;

  // check if its valid, one example of where this won't be valid is if we're on QPS (no ?company_id) linking to trends
  // because ?company_id has no default value
  if (!isValidURLQuery(page, params)) {
    const trendsParams = createURLQuery(REPORT_PAGES.trends, existingParams);
    navigateTo = `${page}?${queryString.stringify(trendsParams, {
      arrayFormat: 'bracket',
    })}`;
  }

  return navigateTo;
};

export const BrandRelationship: React.FC<{
  relationship: MetadataChildFragment;
}> = ({ relationship }) => {
  const { palette } = useTheme();
  const navigateTo = useNaviateTo(relationship.brand.id);

  if (relationship.is_expired) {
    return (
      <>
        <Link href={navigateTo}>
          <Typography variant="caption" color={palette['grey']['500']}>
            {relationship.brand.title}
          </Typography>
        </Link>
        <Typography variant="caption" color={palette['grey']['500']}>
          <Tooltip
            title={`Ownership of ${relationship.brand.title} ended ${moment(
              relationship.expiration_date
            ).format('LL')}`}
          >
            <History sx={{ fontSize: 16, cursor: 'pointer' }} />
          </Tooltip>
        </Typography>
      </>
    );
  }

  return (
    <Typography variant="caption" sx={{ cursor: 'pointer' }}>
      <Link href={navigateTo}>{relationship.brand.title}</Link>
    </Typography>
  );
};
