import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  AccountMenuOptions,
  PageErrorBoundary,
  useAuth,
} from '@groundwater/shared-ui';
import { VariableKeys, variablesSchema } from '../../utils';
import {
  APPBAR_DESKTOP,
  APPBAR_MOBILE,
  CircularProgressInStack,
  DashboardNavbar,
  DashboardSidebar,
  NestedNavSection,
  Page,
} from '@groundwater/shared-ui';
import { useNavigateSearch, useQuery } from '../hooks';
import { types, useBusinessSearch } from '@groundwater/shared-ui';
import { REPORT_PAGES } from '../constants';
import { PrimarySearchInputLoader } from '../components/PrimarySearch/Input';
import { PrimarySearchResults } from '../components/PrimarySearch/Results';
import React from 'react';
import { ReportNavItem } from '../components/ReportNavItem';
import { createURLQuery } from '../../utils/createURLQuery';
import { omit } from 'lodash-es';
import { environment } from '../../environments/environment';
import { Role } from '../../gql-generated';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // These paddingTop values should be kept in sync with the minHeight in DashboardNavbar.tsx
  paddingTop: APPBAR_MOBILE + 10,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP + 10,
  },
  // explicitly prevent bottom padding (seems related to scrollbar thrashing - when interacting
  // tooltips can extend beyond right hand side of the page, which also causes a vertical scrollbar
  // to appear; only then for the changed width to cause the tooltip to move which in turn hides the
  // scrollbar, and creates an "infinite loop" where the page layout is thrashing and unusable)
  paddingBottom: 0,
}));

interface PrivateLayoutProps {
  page_title: string;
}

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  page_title,
  children,
}) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const sidebarConfig = useMemo(
    () => [
      {
        title: 'Explore',
        children: [
          {
            title: 'Browse Businesses',
            path: REPORT_PAGES.browse_businesses,
          },
          {
            title: 'Trends',
            path: REPORT_PAGES.trends,
          },
          {
            title: 'Transaction Value Distribution',
            path: REPORT_PAGES.transaction_values,
          },
        ],
      },
      {
        title: 'Quarterly Tracking',
        children: [
          {
            title: 'Observed vs. Reported',
            path: REPORT_PAGES.ovr,
          },
          {
            title: 'Quarter-to-Date',
            path: REPORT_PAGES.qtd,
          },
          {
            title: 'Quarterly Performance Screening',
            path: REPORT_PAGES.qps,
          },
        ],
      },
      {
        title: 'Retention',
        children: [
          {
            title: 'Retention Summary',
            path: REPORT_PAGES.retention_summary,
          },
          {
            title: 'Cohorted Customer Retention',
            path: REPORT_PAGES.cohorted_retention,
          },
          {
            title: 'Cohorted Dollar Retention',
            path: REPORT_PAGES.dollar_retention,
          },
          {
            title: 'Growth Accounting',
            path: REPORT_PAGES.growth_accounting,
          },
        ],
      },
    ],
    []
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleLogout = useCallback(async () => {
    await logout();
    navigate('/');
  }, [logout, navigate]);

  // should we pull below search related hooks out to a custom hook?
  const navigateSearch = useNavigateSearch();

  const location = useLocation();
  const pathname: REPORT_PAGES = Object.values(REPORT_PAGES).includes(
    location.pathname as any
  )
    ? (location.pathname as REPORT_PAGES) // asserting what we just checked
    : REPORT_PAGES.trends;

  let hasCompanySupport: boolean;
  if (VariableKeys.company_id in variablesSchema[pathname].fields) {
    hasCompanySupport = true;
  } else {
    hasCompanySupport = false;
  }

  const queryParams = useQuery();

  /**
   * When the user selects a result in the primary search, this callback
   * will run. We will navigate to the trends page for the selected company.
   *
   * We will also blur the search input, so the arrow keys will scroll the page
   * after the user has pressed enter. Otherwise, the arrow keys will be received by
   * the search input and the selected item will be cycled instead of scrolling the page
   * which the user will not expect
   */
  const doHandleSelect = useCallback(
    async (brandOrCompany: types.BrandOrCompany) => {
      // Navigate to Trends page in case the feature does not support company_id parameter
      const page = hasCompanySupport ? pathname : REPORT_PAGES.trends;

      // navigate to the new page & new params
      navigateSearch({
        pathname: page,
        params: {
          ...createURLQuery(
            page,
            omit(
              { ...queryParams, company_id: brandOrCompany.id },
              // these params do NOT get preserved when switching to another business
              [
                // these 3 are all the breakout by
                VariableKeys.breakout_by,
                VariableKeys.geos,
                VariableKeys.bucket,

                // these are always company specific, self evidently
                VariableKeys.compare_by_company_ids,
                VariableKeys.transaction_value_min,
                VariableKeys.transaction_value_max,

                // Reset "filter by brand types" in case they were filtering by brand
                // type and are switching to view a brand which happens to be of the excluded type
                // this also avoids "data errors" if they were filtering for a brand type and switch
                // to viewing a company that does not have any brands of that type
                VariableKeys.filter_brand_types,

                // these 4 are all for QTD
                VariableKeys.analysis_quarter,
                VariableKeys.comparison_quarter,
                VariableKeys.scaling_model,
                VariableKeys.scaling_model_custom_percent,

                // aligned not to make this param "stick", see https://bloomberg-rnd.slack.com/archives/C02DYLPP89G/p1679510616259769
                VariableKeys.y_zoom,
              ]
            )
          ),
        },
      });

      if (!inputRef.current) {
        console.warn('inputRef missing, skipping blur of input');
        return;
      }
      inputRef.current.blur();
    },
    [hasCompanySupport, navigateSearch, pathname, queryParams]
  );

  /**
   * this will be forwarded to the search input, allows us to focus/blur
   * the input imperatively after selecting a result or toggling the hotkeys
   */
  const inputRef = useRef<HTMLInputElement | undefined>();

  /**
   * The business logic and async fetching powering the search
   */
  const {
    isSelected,
    query,
    handleSelect,
    handleKeyDown,
    searchResult,
    handleInputChange,
    clearInput,
    setIsOpen: setIsSearchOpen,
    isOpen: isSearchOpen,
  } = useBusinessSearch({
    doHandleSelect,
    inputRef,
  });

  /** hotkeys -  command+f toggles/opens search, escape closes it */
  useEffect(() => {
    const onKey = (ev: KeyboardEvent) => {
      if (ev.metaKey && ev.key === 'f') {
        setIsSearchOpen((open) => !open);
        ev.preventDefault();
      } else if (ev.key === 'Escape') {
        setIsSearchOpen(false);
      }
    };
    window.addEventListener('keydown', onKey, false);
    // very important, cleanup!
    return () => {
      window.removeEventListener('keydown', onKey, false);
    };
  }, [setIsSearchOpen]);

  /**
   * whenever the user toggles the search open, scroll to the top
   * of the page. This ensures if they user the hot key, the search results
   * and/or input won't be scrolled above the fold, which is confusing!
   */
  useLayoutEffect(() => {
    if (isSearchOpen && inputRef.current) {
      inputRef.current.focus();
      window.scrollTo(0, 0);
    }
  }, [isSearchOpen]);

  const accountPopoverMenuItems = useMemo(() => {
    const items: AccountMenuOptions[] = [
      {
        label: 'Home',
        icon: homeFill,
        linkTo: '/',
        role: [Role.User, Role.Superadmin],
      },
    ];

    if (user?.require_2fa) {
      items.push({
        label: 'Settings',
        icon: homeFill,
        linkTo: '/settings',
        role: [Role.User, Role.Superadmin],
      });
    }

    items.push({
      label: 'Admin Interface',
      icon: personFill,
      linkTo: 'https://admin.in.secondmeasure.com',
      external: true,
      role: [Role.Superadmin],
    });
    return items;
  }, [user?.require_2fa]);

  // User is not authenticated, redirect to login page.
  if (!user) return <Navigate to="/" />;

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setSidebarOpen(true)}
        user={user}
        onLogout={handleLogout}
        accountPopoverMenuItems={accountPopoverMenuItems}
      >
        {/* Search input - this thing fetches the selected company and renders its own fallback (diabled input) */}
        <PrimarySearchInputLoader
          {...{
            ref: inputRef,
            handleKeyDown,
            handleInputChange,
            inputValue: query,
            clearInput,
          }}
        />
      </DashboardNavbar>
      <DashboardSidebar
        isOpenSidebar={sidebarOpen}
        onCloseSidebar={() => setSidebarOpen(false)}
        navSection={
          <NestedNavSection
            navConfig={sidebarConfig}
            linkComponent={ReportNavItem}
          />
        }
        user={user}
        logoVariation="onLight"
      />
      <MainStyle>
        <Page
          title={page_title}
          // custom styling is required to stretch the backdrop for the PrimarySearchResults on shorter pages
          style={{
            position: 'relative',
            minHeight: '100%',
          }}
        >
          {/* the "full screen" with Backdrop experience in the primary search */}
          <PrimarySearchResults
            {...{
              searchResult,
              onSelect: handleSelect,
              isSelected,
              query: query ?? '',
              setIsSearchOpen,
              isSearchOpen,
              api_url: environment.api_url,
            }}
          />
          <PageErrorBoundary>
            <Suspense fallback={<CircularProgressInStack />}>
              {children}
            </Suspense>
          </PageErrorBoundary>
        </Page>
      </MainStyle>
    </RootStyle>
  );
};
