import { percent } from '../../pages/BrowseBusinessesPage/formatters';
import { DefaultCell } from './DefaultCell';
import { numberColumn } from './NumberCell';

export const percentColumn = () => ({
  ...numberColumn(),
  Cell: PercentCell,
});

export const PercentCell = ({ value }: { value: number }) => {
  return <DefaultCell>{percent.format(value / 100)}</DefaultCell>;
};
