import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash-es';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { ExternalLink, Menu } from '@groundwater/shared-ui';
import { helpContentConfig } from './HelpContentConfig';

export const Help: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [bounds, setBounds] = useState<number[]>([0, 0]);
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    setBounds((bounds) => {
      if (!ref.current) return bounds;
      const updated = [ref.current.offsetLeft, ref.current.offsetTop];
      if (isEqual(bounds, updated)) return bounds;
      return updated;
    });
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <span ref={ref}>
        <Button
          color="primary"
          aria-label="help"
          ref={setAnchorEl}
          onClick={toggleOpen}
        >
          <HelpOutlineIcon />
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          forceUpdate={bounds}
        >
          <List
            dense
            sx={{
              width: '100%',
              maxWidth: 360,
              overflow: 'auto',
              maxHeight: 300,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            {helpContentConfig.map(({ sectionId, sectionTitle, content }) => (
              <li key={`section-${sectionId}`}>
                <ul>
                  <ListSubheader>{sectionTitle}</ListSubheader>
                  {content.map(({ id, title, path }) => (
                    <ListItemButton
                      component={ExternalLink}
                      href={path}
                      target="_blank"
                      key={`item-${id}`}
                    >
                      <ListItemText primary={title} />
                    </ListItemButton>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        </Menu>
      </span>
    </ClickAwayListener>
  );
};
