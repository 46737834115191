const verticalLineWidth = 10;

interface WhiskersProps {
  targetNode: any;
  className: string;
  x: number;
  lower: number;
  upper: number;
  strokeColor?: string;
}
export function initializeWhiskers({
  targetNode,
  className,
  x,
  lower,
  upper,
  strokeColor = 'rgba(255, 0, 0, .5)',
}: WhiskersProps) {
  targetNode
    .insert('g', '.c3-events-rects + *')
    .append('line')
    .classed(`${className} ibeam--ibeam_upper_tick ibeam`, true)
    .attr('x1', x - verticalLineWidth)
    .attr('y1', upper)
    .attr('x2', x + verticalLineWidth)
    .attr('y2', upper)
    .style('stroke-width', 2)
    .style('stroke', strokeColor);
  targetNode
    .insert('g', '.c3-events-rects + *')
    .append('line')
    .classed(`${className} ibeam--ibeam_lower_tick ibeam`, true)
    .attr('x1', x - verticalLineWidth)
    .attr('y1', lower)
    .attr('x2', x + verticalLineWidth)
    .attr('y2', lower)
    .style('stroke-width', 2)
    .style('stroke', strokeColor);
  targetNode
    .insert('g', '.c3-events-rects + *')
    .append('line')
    .classed(`${className} ibeam--ibeam_vertical_line ibeam`, true)
    .attr('x1', x)
    .attr('y1', lower)
    .attr('x2', x)
    .attr('y2', upper)
    .style('stroke-width', 1)
    .style('stroke', strokeColor);
}
