import { unit } from '../../pages/BrowseBusinessesPage/formatters';
import { DefaultCell } from './DefaultCell';
import { NumberRangeFilter } from './NumberCell/NumberRangeFilter';

export const numberColumn = () => ({
  filter: 'between' as const,
  Filter: NumberRangeFilter,
  sortType: 'basic' as const,
  Cell: NumberCell,
});

export const NumberCell = ({ value }: { value: number }) => {
  return <DefaultCell>{unit.format(value)}</DefaultCell>;
};
