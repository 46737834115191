import { DefaultCell } from './DefaultCell';
import { numberColumn } from './NumberCell';

export const coefficientColumn = () => ({
  ...numberColumn(),
  Cell: CoefficientCell,
});

export const CoefficientCell = ({ value }: { value: number | 'n/a' }) => (
  <DefaultCell>
    {value === 'n/a' ? 'n/a' : Math.round(value * 100) / 100}
  </DefaultCell>
);
