import { format } from 'd3-format';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Grid,
  Skeleton,
  CardActions,
  useTheme,
  Icon,
} from '@mui/material';
import { isNil } from 'lodash-es';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { QtdResponse } from '../../../gql-generated';

export const QtdStatBoxesLoadingSkeleton = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    >
      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <Skeleton variant="rectangular" height={150} />
      </Grid>

      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <Skeleton variant="rectangular" height={150} />
      </Grid>
    </Grid>
  );
};

export const QtdStatBoxes: React.FC<{
  qtd: QtdResponse;
}> = ({ qtd }) => {
  const { revenue_estimate, revenue_estimate_growth, consensus_growth } =
    qtd.annotations;

  const predictionCardData = () => {
    const [revenueEstimateAverage, revenueEstimateLow, revenueEstimateHigh] =
      revenue_estimate;

    const formatDollarAmount = (val: number | undefined) =>
      format('>-$,.3~s')(Number(val)).replace(/G/, 'B');

    const value = !isNil(revenueEstimateAverage)
      ? formatDollarAmount(revenueEstimateAverage)
      : '';

    // Revenue estimate range values can be nulls when comparing historical quarters
    const footer =
      !isNil(revenueEstimateLow) && !isNil(revenueEstimateHigh)
        ? `95% Prediction Interval (${formatDollarAmount(
            revenueEstimateLow
          )} - ${formatDollarAmount(revenueEstimateHigh)})`
        : '';

    return { value, footer };
  };

  const consensusCardData = () => {
    const revenueEstimateAverage = Number(revenue_estimate_growth[0]);
    const [consensusAverage, consensusLow, consensusHigh] = consensus_growth;
    const positive = revenueEstimateAverage >= Number(consensusAverage);
    const value = format(',.2%')(
      Math.abs(revenueEstimateAverage - Number(consensusAverage))
    );

    const title = positive
      ? 'Above Mean Consensus YoY Growth'
      : 'Below Mean Consensus YoY Growth';
    const forecastWithinConsensusRange =
      revenueEstimateAverage <= Number(consensusHigh) &&
      revenueEstimateAverage >= Number(consensusLow);
    const footer = forecastWithinConsensusRange
      ? 'Projected revenue is inside consensus range'
      : 'Projected revenue is outside consensus range';

    return { value, title, footer, positive };
  };

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      rowSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
      data-testid="qtd-statbox"
    >
      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <PredictionCard {...predictionCardData()} />
      </Grid>
      <Grid item xs={12} sm={8} md={8} lg={6} xl={4}>
        <ConsensusCard {...consensusCardData()} />
      </Grid>
    </Grid>
  );
};

function PredictionCard({ value, footer }: { value: string; footer: string }) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography variant="h3" component="span">
            {value}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            End of Quarter Sales Estimate
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Stack direction="row" sx={{ mx: 2 }} alignItems="baseline">
          <Typography variant="caption">{footer}</Typography>
        </Stack>
      </CardActions>
    </Card>
  );
}

function ConsensusCard({
  value,
  title,
  footer,
  positive,
}: {
  value: string;
  title: string;
  footer: string;
  positive: boolean;
}) {
  const theme = useTheme();
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Icon
            sx={{
              color: positive
                ? theme.palette.success.main
                : theme.palette.error.main,
            }}
            fontSize={'large'}
            component={positive ? ArrowUpwardIcon : ArrowDownwardIcon}
          />
          <Typography
            variant="h3"
            component="span"
            color={
              positive ? theme.palette.success.main : theme.palette.error.main
            }
          >
            {value}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {title}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Stack direction="row" sx={{ mx: 2 }} alignItems="baseline">
          <Typography variant="caption">{footer}</Typography>
        </Stack>
      </CardActions>
    </Card>
  );
}
