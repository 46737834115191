declare global {
  interface Window {
    API_URL: string;
  }
}

export const environment = {
  production: true,
  api_url: 'https://api-gateway.in.secondmeasure.com',
  baseURL: 'https://app.secondmeasure.com',
  enable_sentry: true,
  trends_customer_spend_filter_enabled: false,
};
