import { Box, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import UseAnimation from 'react-useanimations';
import download from 'react-useanimations/lib/download';
import loading from 'react-useanimations/lib/loading';

interface DownloadButtonButtonProps {
  handleOnClick: () => void;
  isLoading: boolean;
  isError: boolean;
  tooltipTitle: string;
}
export const DownloadButton: React.FC<DownloadButtonButtonProps> = ({
  isLoading,
  isError,
  handleOnClick,
  tooltipTitle,
}) => {
  const [autoplayOn, setAutoplay] = useState(false);

  useEffect(() => {
    let handler: NodeJS.Timeout;

    if (!isLoading && autoplayOn) {
      // Disable the autoplay after the download animation completed.
      handler = setTimeout(() => setAutoplay(false), 1000);
    }
    return function () {
      clearTimeout(handler);
    };
  }, [autoplayOn, isLoading]);

  return (
    <UseAnimation
      key={isLoading ? 1 : 0}
      animation={isLoading ? loading : download}
      onClick={() => {
        handleOnClick();
        // Enable autoplay after clicking download icon in order to play the download animation
        // once after the CSV download has been completed.
        setAutoplay(true);
      }}
      speed={0.7}
      autoplay={!isError && autoplayOn}
      render={(eventProps, animationProps) => (
        <Tooltip title={tooltipTitle} sx={{ cursor: 'pointer' }}>
          {/* A disabled element does not fire events.
              Tooltip needs to listen to the child element's events to display the title. */}
          <Box>
            <IconButton
              color="primary"
              aria-label={tooltipTitle}
              disabled={isLoading}
              sx={{ p: 0.5 }}
              {...eventProps}
            >
              <div {...animationProps} />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    />
  );
};
