import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
import { MotionContainer, varBounceIn } from '../animate';
import { ExternalLink } from './ExternalLink';

const RootStyle = styled(Container)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const BssoFailurePage: React.FC<{}> = () => {
  return (
    <RootStyle>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Typography variant="h3" paragraph>
              BSSO login failed!
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary' }}>
            Please{' '}
            <ExternalLink
              light
              href="mailto:bsmsupport@bloomberg.net"
              target="_blank"
            >
              contact support.
            </ExternalLink>
          </Typography>
        </Box>
      </MotionContainer>
    </RootStyle>
  );
};
