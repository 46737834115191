import { BreakoutBy } from '../../gql-generated';
import { VariableKeys, VariablesUnion } from '../reportPageConfigs';

/** @returns boolean is a conflict? */
export const breakout_by_conflicts_rollup = (params: VariablesUnion) => {
  if (!(VariableKeys.breakout_by in params)) return false;
  if (!(VariableKeys.rollup in params)) return false;
  const { breakout_by, rollup } = params;

  // BreakoutBy.Geo + trailing rollups is not a supported combination.
  if (BreakoutBy.Geo === breakout_by && rollup.includes('trailing')) {
    return true;
  }

  // BreakoutBy.Veterancy + trailing rollups is not a supported combination.
  if (BreakoutBy.Veterancy === breakout_by && rollup.includes('trailing')) {
    return true;
  }

  return false;
};
