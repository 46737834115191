import React from 'react';
import { Grid } from '@mui/material';
import { TRENDS_CHART_KEYS } from './constants';
import { DefaultErrorBoundary } from '@groundwater/shared-ui';

export interface TrendsLayoutProps {
  ids: TRENDS_CHART_KEYS[];
  renderChart: (id: TRENDS_CHART_KEYS) => React.ReactNode;
  metadata: React.ReactNode;
}

export const TrendsLayout: React.FC<TrendsLayoutProps> = React.memo(
  ({ ids, renderChart, metadata }) => {
    const chartCount = ids.filter(
      (id) =>
        id !== TRENDS_CHART_KEYS.share_of_transactions &&
        id !== TRENDS_CHART_KEYS.share_of_sales
    ).length;

    return (
      <Grid container spacing={{ xs: 2, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          {metadata}
        </Grid>
        {ids.map((id) => {
          const isStackedAreaChart =
            id === TRENDS_CHART_KEYS.share_of_transactions ||
            id === TRENDS_CHART_KEYS.share_of_sales;
          return (
            <Grid
              key={`${id}`}
              item
              xs={12}
              md={6}
              xl={xlColCount(isStackedAreaChart, chartCount)}
            >
              <DefaultErrorBoundary>
                {renderChart(id as TRENDS_CHART_KEYS)}
              </DefaultErrorBoundary>
            </Grid>
          );
        })}
      </Grid>
    );
  }
);

const xlColCount = (isStackedAreaChart: boolean, chartCount: number) => {
  // Area charts should fill the complete row on the xl resolution as there are only 2 of them
  if (isStackedAreaChart) return 6;
  switch (chartCount) {
    case 1:
      return 12;
    case 2:
    case 4:
    case 5:
      return 6;
    case 3:
    case 6:
      return 4;
    default:
      throw new Error('The number of charts exceeds the limit');
  }
};
