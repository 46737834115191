import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Logo } from '@groundwater/shared-ui';
import { useAuth } from '@groundwater/shared-ui';
// components

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
  zIndex: 1,
}));

// ----------------------------------------------------------------------

export const PublicLayout: React.FC<{
  redirectHome?: boolean;
  logoStyle?: 'dark' | 'light';
}> = ({ children, redirectHome = true, logoStyle = 'dark' }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.user && redirectHome) {
      navigate('/');
    }
  }, [auth.user, navigate, redirectHome]);

  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo variation={logoStyle === 'light' ? 'onDark' : 'onLight'} />
        </RouterLink>
      </HeaderStyle>
      {children}
    </>
  );
};
