import { Box, styled } from '@mui/material';
import { DownloadButton } from './DownloadButton';

/**
 * This is a wrapper around DownloadButton. It is designed to be used in conjunction with "export all CSV" functionality to Download All reports on a given page.
 * The button explicitly positioned at the top right corner of the report page.
 */
const StyledDownloadAllCsvButton = styled(Box)(() => ({
  backgroundColor: 'rgb(239, 239, 239)',
  borderRadius: '50%',
  boxShadow:
    '0px 3px 5px -1px rgb(145 158 171 / 20%), 0px 6px 10px 0px rgb(145 158 171 / 14%), 0px 1px 18px 0px rgb(145 158 171 / 12%)',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '&:active': {
    boxShadow:
      '0px 7px 8px -4px rgb(145 158 171 / 20%), 0px 12px 17px 2px rgb(145 158 171 / 14%), 0px 5px 22px 4px rgb(145 158 171 / 12%)',
  },
}));

interface DownloadAllCsvButtonProps {
  handleOnClick: () => void;
  isLoading: boolean;
  isError: boolean;
  tooltipTitle: string;
}
export const DownloadAllCsvButton: React.FC<DownloadAllCsvButtonProps> = ({
  isLoading,
  isError,
  handleOnClick,
  tooltipTitle,
}) => {
  return (
    <StyledDownloadAllCsvButton>
      <DownloadButton
        isLoading={isLoading}
        isError={isError}
        tooltipTitle={tooltipTitle}
        handleOnClick={handleOnClick}
      />
    </StyledDownloadAllCsvButton>
  );
};
