import { StyledApexChart } from '@groundwater/app/ui';
import { SparklineChartSerie } from '@groundwater/shared-ui';
import { Skeleton, TableCell } from '@mui/material';
import { useState, useEffect } from 'react';
import { currency, unit } from '../formatters';

const apex_options = (format: 'unit' | 'currency') => ({
  chart: {
    type: 'bar',
    width: 100,
    height: 35,
    sparkline: {
      enabled: true,
    },
    animations: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '80%',
    },
  },
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      formatter: (value: number | bigint) =>
        format === 'currency' ? currency.format(value) : unit.format(value),
      title: {
        formatter: () => '',
      },
    },
    marker: {
      show: false,
    },
  },
});

export const ChartCell: React.FC<{
  data: SparklineChartSerie[];
  isScrolling: boolean;
  width: number;
  valueFormat: 'unit' | 'currency';
}> = (props) => {
  const { data, isScrolling, valueFormat } = props;
  /**
   * Scrolling is blocked by rendering of charts. TODO use async react
   * and make scroll high priority and chart low priority
   *
   * until then, this implements "poor mans" concurrency, where
   * it simply defers initially rendering the chart until the user
   * stops scrolling. The `isMounted` state is used to prevent unmounting
   * and remounting. After the initial mount of the chart, we want to keep it mounted
   * even if the user scroll
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // if the user is scrolling when it first mounts, defer setting "is mounted"
    // until they stop scrolling
    if (!isScrolling) setIsMounted(true);
  }, [isScrolling]);

  // skip rendering the chart if it has not yet rendered
  // and we currently scrolling. Why not just conditionally render
  // based on `isMounted`? it causes an initial delay when the user is not scrolling
  const showChart = !(!isMounted && isScrolling);

  return (
    <TableCell
      component="div"
      variant="body"
      style={{
        width: '100%',
        padding: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {showChart ? (
        <StyledApexChart
          series={[{ data }]}
          options={apex_options(valueFormat)}
          width={150}
          height={45}
          type={'bar'}
        />
      ) : (
        <Skeleton variant="rectangular" width={150} height={45} />
      )}
    </TableCell>
  );
};
