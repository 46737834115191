import { VariableKeys, VariablesUnion } from '..';
import { TrendsRollup } from '../../gql-generated';

/** @returns boolean is a conflict? */
export const rollup_conflicts_compare_by = (params: VariablesUnion) => {
  if (!(VariableKeys.compare_by_company_ids in params)) return false;
  if (!(VariableKeys.rollup in params)) return false;
  const { compare_by_company_ids, rollup } = params;
  return (
    !!compare_by_company_ids &&
    compare_by_company_ids.length > 0 &&
    rollup === TrendsRollup.FiscalQuarter
  );
};
