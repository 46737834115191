import { useState } from 'react';
import { object, string } from 'yup';
import { ExternalLink, useForm, useToast } from '@groundwater/shared-ui';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Typography } from '@mui/material';
import { environment } from '../../../environments/environment';
import { useNavigateSearch } from '../../hooks';
import { BssoPageFrame } from './BssoPageFrame';
import { apiClient } from '../../app';

export interface WebToLeadFormInput {
  FNAME: string;
  LNAME: string;
  EMAIL: string;
  ORG: string;
  JOBTITLE: string;
  MESSAGE: string;
  PAGEURL: string;
}

const validationSchema = object().shape({
  FNAME: string().required('First name is required'),
  LNAME: string().required('Last name is required'),
  EMAIL: string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  ORG: string().required('Organization name is required.'),
  JOBTITLE: string().required('Job title is required.'),
});

export const WebToLeadForm = () => {
  const { setToast } = useToast();
  const navigateSearch = useNavigateSearch();
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const request = async (data: WebToLeadFormInput) => {
    const requestBody = {
      email_address: data.EMAIL,
      status: 'subscribed',
      ...data,
    };

    const url = `${environment.api_url}/demo-request`;

    await apiClient.post(url, requestBody);
  };

  const onSubmit = async (data: WebToLeadFormInput) => {
    try {
      setFormSubmitting(true);
      await request(data);

      navigateSearch({
        pathname: '/bsso-failure/demo-request-success',
        params: {},
      });

      setFormSubmitting(false);
    } catch (error) {
      setToast({
        id: 'demo-request-failure',
        open: true,
        severity: 'error',
        message:
          'Failed to submit the form. Please retry or contact bsmsupport@bloomberg.net',
      });
      setFormSubmitting(false);
    }
  };

  const {
    formProps,
    register,
    formState: { errors },
  } = useForm({
    onSubmit,
    validationSchema,
    defaultValues: {
      FNAME: '',
      LNAME: '',
      EMAIL: '',
      ORG: '',
      JOBTITLE: '',
      MESSAGE: '',
      PAGEURL: `${environment.baseURL}/bsso-failure/demo-request`,
    },
  });

  return (
    <BssoPageFrame>
      <form {...formProps}>
        <Stack spacing={2}>
          <input type="hidden" id="PAGEURL" {...register('PAGEURL')} />
          <Stack direction="row">
            <Typography variant="subtitle2">
              Tell us a bit about yourself and how we can help.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="First name*"
              placeholder="First name*"
              {...register('FNAME')}
            />
            <TextField
              fullWidth
              label="Last name*"
              placeholder="Last name*"
              {...register('LNAME')}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              autoComplete="email"
              label="Email address*"
              placeholder="Email address*"
              {...register('EMAIL')}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Organization*"
              placeholder="Organization*"
              {...register('ORG')}
            />
            <TextField
              fullWidth
              label="Job title*"
              placeholder="Job title*"
              {...register('JOBTITLE')}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              fullWidth
              multiline
              label="Enter your message to Bloomberg Second Measure"
              {...register('MESSAGE')}
            />
          </Stack>
          <Stack>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isFormSubmitting}
              disabled={Boolean(Object.keys(errors).length)}
            >
              Submit
            </LoadingButton>
          </Stack>
          <Stack>
            <Typography variant="caption">
              By submitting your information, you agree to the{' '}
              <a
                href="https://secondmeasure.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>{' '}
              and to learn more about offers and promotions from Bloomberg.
            </Typography>
          </Stack>
        </Stack>
      </form>
    </BssoPageFrame>
  );
};
