import { Link, Stack, TableCell } from '@mui/material';
import { validateURLQuery } from '../../../utils';
import { Link as RouterLink } from 'react-router-dom';
import { REPORT_PAGES } from '../../constants';
import { createReportPageURL } from '../../hooks';
import { Column } from '../../react-table-column-types';
import { stringColumn } from './StringCell';
import { Brand, Company } from '../../../gql-generated';
import { BusinessLogo } from '@groundwater/shared-ui';
import { environment } from '../../../environments/environment';

export const company_title_column = <
  T extends { company: Pick<Company, 'id' | 'title' | 'logo'> }
>({
  reportPage,
}: {
  reportPage: REPORT_PAGES;
}): Column<T> => ({
  ...stringColumn(),
  width: 200,
  id: 'company_title',
  Header: 'Company Title',
  accessor: (row) => row.company.title,
  Cell: ({ row, column }) => (
    <BusinessTitleCell
      business={row.original.company}
      width={column.width as number}
      reportPage={reportPage}
    />
  ),
});

export const brand_title_column = <
  T extends {
    brand: Pick<Brand, 'id' | 'title' | 'logo'>;
  }
>({
  reportPage,
}: {
  reportPage: REPORT_PAGES;
}): Column<T> => ({
  ...stringColumn(),
  width: 200,
  id: 'brand_title',
  Header: 'Brand Title',
  accessor: (row) => row.brand.title,
  Cell: ({ row, column }) => (
    <BusinessTitleCell
      business={row.original.brand}
      width={column.width as number}
      reportPage={reportPage}
    />
  ),
});

export const BusinessTitleCell: React.FC<{
  business: Pick<Brand | Company, 'id' | 'title' | 'logo'>;
  width: number;
  reportPage: REPORT_PAGES;
}> = ({ business, width, reportPage }) => {
  return (
    <TableCell
      component="div"
      variant="body"
      style={{
        width,
        display: 'block',
        height: '100%',
      }}
    >
      <Link
        component={RouterLink}
        to={createReportPageURL(
          reportPage,
          validateURLQuery(reportPage, {
            company_id: business.id,
          })
        )}
      >
        <Stack direction={'row'} spacing={1}>
          <BusinessLogo business={business} api_url={environment.api_url} />
          <span>{business.title}</span>
        </Stack>
      </Link>
    </TableCell>
  );
};
