import { Paper } from '@mui/material';
import ReactDOM from 'react-dom';
import { Popper } from './Popper';
import { Grow } from './Transition/Grow';

export const Menu: React.FC<{
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  anchorEl: null | HTMLElement;

  /**
   * Trigger PopperJS update when the values in this array changes, see
   * https://popper.js.org/docs/v2/modifiers/event-listeners/#when-the-reference-element-moves-or-changes-size
   */
  forceUpdate?: number[];
}> = ({ forceUpdate, children, open, anchorEl, handleClose }) => {
  return ReactDOM.createPortal(
    <Popper anchorEl={anchorEl} forceUpdate={forceUpdate}>
      <Grow in={open}>
        <Paper elevation={16} sx={{ mt: 1 }}>
          {children}
        </Paper>
      </Grow>
    </Popper>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.querySelector('body')!
  );
};
