import React, { useCallback, useLayoutEffect } from 'react';
import { BrushSelection, TxnDataMap, TxnDataRow } from './types';
import styled from 'styled-components';
import {
  axisBottom,
  axisLeft,
  select,
  event,
  Bin,
  min,
  max,
  histogram,
  scaleLinear,
} from 'd3';
import { isNumber } from 'lodash-es';
import { ChartStyles, Tooltip } from './Histogram/styles';
import { GetTransactionValuesQuery } from '../../../gql-generated';
import { CHART_IDS, TRANSACTION_VALUE_SERIES } from './TransactionValuesPage';

const percent4Digits = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 4,
});

const percent2Digits = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

const HistogramContainer = styled.div`
  ${ChartStyles}
`;

const formatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
});

export interface IHistogramProps {
  rows: TxnDataRow[];
  rowsByMinDollarAmount: TxnDataMap;
  /** Selection in domain values (dollar amounts, not pixels) */
  selection: BrushSelection;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  width: number;
  height: number;
  fill: string;
  id: CHART_IDS.PERCENT_OF_SALES | CHART_IDS.PERCENT_OF_TRANSACTIONS; // has to match the id in the dataset!
  label: string;
}

export const Histogram: React.FC<IHistogramProps> = ({
  selection,
  rowsByMinDollarAmount,
  rows,
  margin,
  width,
  height,
  fill,
  id,
  label,
}) => {
  if (!selection.length) throw new Error('empty selection, illegal state');

  const selectedDollarAmounts = rows
    .slice(selection[0], selection[1] + 1)
    .map((row) => row.transaction_value_min);

  // Initialize and scale the x-axis
  const xAxis = scaleLinear()
    .domain([
      rows[selection[0]]!.transaction_value_min,
      rows[selection[1]]!.transaction_value_max,
    ])
    .range([0, width])
    .nice();

  // Adjust the number of bins based on the given selection so the min bin range is always 0.99$
  const getThreshold = () => {
    const threshold =
      Math.floor(xAxis.domain()[1]! - xAxis.domain()[0]!) < 20
        ? Math.floor(xAxis.domain()[1]! - xAxis.domain()[0]!)
        : 20;

    return threshold;
  };

  const histogramInstance = histogram()
    .value((d) => d)
    .domain(xAxis.domain() as [number, number])
    .thresholds(xAxis.ticks(getThreshold()));

  // Generate transaction bins, tuples of [start, end] dollar amount values on the x-axis scale
  const bins = histogramInstance(selectedDollarAmounts);

  //  Find the largest bin by searching for the biggest percent sum among TRANSACTION_VALUE_SERIES.PERCENT_SALES and TRANSACTION_VALUE_SERIES.PERCENT_TRANSACTION datasets
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const largestBin = max(bins, (bin) => {
    // bin extends array adding x0 and x1. When we reduce it, we are reducing over selected dollar amounts within this bin
    return Math.max(
      bin.reduce(
        (out, curr) => out + rowsByMinDollarAmount.get(curr)!.percent_sales,
        0
      ),
      bin.reduce(
        (out, curr) =>
          out + rowsByMinDollarAmount.get(curr)!.percent_transactions,
        0
      )
    );
  })!;

  // Deal with y-axis
  // y axis: init & set range
  const yAxis = scaleLinear().range([height, 0]);

  yAxis.domain([0, largestBin]).nice();

  const histogramChartId = `${id}-histogramChart`;

  // Change tooltip opacity to 1 upon hovering a range bin.
  // Set the text with the current datapoint values.
  const showTooltip = useCallback(
    function (bin: Bin<number, number>) {
      if (undefined === bin.x1) {
        throw new Error('x1 is undefined');
      }
      if (undefined === bin.x0) {
        throw new Error('x0 is undefined');
      }

      // The Koios bins are always $1.00 - $1.99, $2.00 - $2.99
      // however D3 gives us $1 - $2, $2 - $3
      // TODO - maybe we could take in the 2nd argument to showTooltip (the index)
      // and then look up the start/end of that bin in our own data model, instead
      // of relying on assumptions about how d3 will pass it to us? the Koios dataset
      // includes the start and end values for every bin, we should prolly use it :)
      const upperBound = bin.x1 - 0.01;

      const tooltip = select(`#${histogramChartId}-tooltip`);
      tooltip.transition().duration(100).style('opacity', 1);
      tooltip
        .style('left', event.offsetX + 'px')
        .style('top', event.offsetY - 35 + 'px');

      const percentSum = bin.reduce(
        (out: number, curr) => (out += rowsByMinDollarAmount.get(curr)![id]),
        0
      );

      tooltip
        .select('.tooltipHeader')
        .html(
          `<b>Transaction Range:</b> $${formatter.format(
            bin.x0
          )} - $${formatter.format(upperBound)}`
        );
      tooltip
        .select('.tooltipBody')
        .html(`<b>${label}:</b> ${percent4Digits.format(percentSum)}`);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      select(this).attr('style', 'fill: #17478d');
    },
    [histogramChartId, id, label, rowsByMinDollarAmount]
  );

  const moveTooltip = useCallback(() => {
    const tooltip = select(`#${histogramChartId}-tooltip`);
    const chartX = (
      select(`#${histogramChartId}`).node()! as Element
    ).getBoundingClientRect().width;
    tooltip
      .style(
        'left',
        // Offset the hovercard position to the left if there's not enough horizontal space
        chartX - event.offsetX < 260
          ? event.offsetX - 270 + 'px'
          : event.offsetX + 'px'
      )
      .style('top', event.offsetY - 95 + 'px');
  }, [histogramChartId]);

  const hideTooltip = useCallback(
    function () {
      const tooltip = select(`#${histogramChartId}-tooltip`);
      tooltip.transition().duration(100).style('opacity', 0);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      select(this).attr('style', `fill: ${fill}`);
    },
    [fill, histogramChartId]
  );

  const getBinSum = useCallback(
    (bin: Bin<number, number>) =>
      // bin extends array adding x0 and x1. When we reduce it, we are reducing over selected dollar amounts within this bin
      bin.reduce((out, curr) => {
        if (isNumber(rowsByMinDollarAmount.get(curr)![id]))
          return out + rowsByMinDollarAmount.get(curr)![id];

        return out;
      }, 0),
    [id, rowsByMinDollarAmount]
  );

  useLayoutEffect(() => {
    const histogramChart = select(`#${histogramChartId}`);
    // create a new <g> for the x and y to be added to the ‘histogramChart’ group element
    histogramChart.selectAll('g').remove();

    const xAxisGroupNode = histogramChart
      .append('g')
      .lower()
      .attr('class', 'x-axis');
    const yAxisGroupNode = histogramChart
      .append('g')
      .lower()
      .attr('class', 'y-axis');

    // Draw x-axis
    xAxisGroupNode.attr('transform', `translate(0,${height})`).call(
      axisBottom(xAxis)
        .tickFormat((d) => `$${formatter.format(d as number)}`)
        .ticks(bins.length < 10 ? Math.floor(bins.length - 1) : 10)
    );

    yAxisGroupNode.call(
      axisLeft(yAxis)
        .tickFormat((val) => percent2Digits.format(val as number))
        .tickSize(-width)
    );

    // join rect with bins
    const barsNode = histogramChart
      .selectAll<SVGRectElement, number[]>('rect')
      .data(bins);

    // Deal with the bars and as well as new ones on redraw
    barsNode
      .enter()
      .append('rect')
      .merge(barsNode) // get existing elements
      .attr('xAxis', 1)
      .attr('transform', function transform(d, i) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return `translate(${xAxis(d.x0)},${yAxis(getBinSum(d))})`;
      })
      .attr('width', function widthFunc(d) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return Math.abs(xAxis(d.x1) - xAxis(d.x0) - 1);
      })
      .attr('height', function heightFunc(d) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return Math.abs(height - yAxis(getBinSum(d)));
      })
      .style('fill', fill)
      .on('mouseover', showTooltip)
      .on('mousemove', moveTooltip)
      .on('mouseleave', hideTooltip);

    // Append background as the first child of histogramChart
    histogramChart
      .append('g')
      .lower()
      .attr('class', 'chart-bg')
      .append('g')
      .attr('class', 'chart-background-region')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height);
    barsNode.exit().remove();
  }, [
    fill,
    getBinSum,
    height,
    hideTooltip,
    histogramChartId,
    moveTooltip,
    showTooltip,
    bins,
    width,
    xAxis,
    yAxis,
  ]);

  // this can happen if they select a portion of the line chart that contains no transactions,
  // eg a company sells items for $1 and $1M, and the user asks to see $500-$550, but no one bought
  // more than 500 $5 items at one time :)
  if (!selectedDollarAmounts.length) {
    return (
      <>No transaction data for the selected range, please try zooming out</>
    );
  }

  return (
    <HistogramContainer>
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`}
      >
        <text className={'chartLabel'} x={width / 2} y={height + margin.bottom}>
          Transaction Amount
        </text>
        <g
          id={histogramChartId}
          className="histogramChart"
          transform={`translate(${margin.left},${margin.top})`}
        />
      </svg>
      <Tooltip id={`${histogramChartId}-tooltip`}>
        <div className="tooltipHeader" />
        <div className="tooltipBody" />
      </Tooltip>
    </HistogramContainer>
  );
};
