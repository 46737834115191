import { VariableKeys } from '../../utils';
import { useCoercedQueryParams } from './useCoercedQueryParams';
import { useReportPage } from './useReportPage';

export const useCompanyId = (): string => {
  const params = useCoercedQueryParams(useReportPage());
  if (!(VariableKeys.company_id in params)) throw new Error();
  const { company_id } = params;
  return company_id;
};
