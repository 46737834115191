import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, Stack } from '@mui/material';
import { MotionContainer, PageLayout } from '@groundwater/shared-ui';
import { PublicLayout } from '../../layouts/PublicLayout';

const RootStyle = styled(PageLayout)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const PasswordResetSuccessPage: React.FC<{}> = () => (
  <PublicLayout>
    <RootStyle title="Reset Password Success">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Stack spacing={0} sx={{ my: 3 }}>
              <Typography variant="h3" paragraph>
                Password reset
              </Typography>
              <Typography>
                Your password was reset. Please proceed to the login screen and
                login using your new credentials.
              </Typography>
            </Stack>
            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Login
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  </PublicLayout>
);
