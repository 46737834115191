import { useCompanyPromptQuery } from '../../gql-generated';
import { PrivateLayout } from '../layouts/PrivateLayout';
import { Box, Chip, Typography } from '@mui/material';
import { useApplyFilters } from '../hooks/useApplyFilters';
import { useReportPage } from '../hooks/useReportPage';
import { useCoercedQueryParams } from '../hooks/useCoercedQueryParams';
import { VariableKeys } from '../../utils';
import { BusinessLogo } from '@groundwater/shared-ui';
import { environment } from '../../environments/environment';

export const WithCompanyOnlyPrompt: React.FC<{
  children: React.ReactNode;
  page_title: string;
  publicOnly?: boolean;
}> = ({ children, page_title, publicOnly }) => {
  const page = useReportPage();

  const parameters = useCoercedQueryParams(page);

  if (!(VariableKeys.company_id in parameters))
    throw new Error('This page does not support company_id parameter');

  const business = useCompanyPromptQuery({ id: parameters.company_id });

  if (!business?.data?.business) throw new Error('Business entity not found');

  const businessEntity = business.data.business;

  // Handle public company only case
  if (
    publicOnly &&
    businessEntity.__typename === 'Company' &&
    (!businessEntity.public || !businessEntity.reports_quarterly)
  ) {
    return (
      <CompanyPrompt page_title={page_title}>
        <Typography variant="subtitle1">
          This analysis is only available for public companies that report
          quarterly.
        </Typography>
        <Typography variant="subtitle2">
          Please switch to a public company that reports quarterly.
        </Typography>
      </CompanyPrompt>
    );
  }

  // Selected the brand check if its ultimate parent is a public company
  if (publicOnly && businessEntity.__typename === 'Brand') {
    const ultimateParent = businessEntity.ultimate_parent;

    return (
      <CompanyPrompt page_title={page_title}>
        <Typography variant="subtitle1">
          This page only supports public companies that report quarterly, not
          brands.
        </Typography>
        <Typography variant="subtitle2">
          Please search for a public company in a search above
          {ultimateParent?.public && ultimateParent.reports_quarterly ? (
            <UltimateParentLink business={ultimateParent} />
          ) : null}
        </Typography>
      </CompanyPrompt>
    );
  }

  // Handle company only case
  if (businessEntity.__typename === 'Brand') {
    const ultimateParent = businessEntity.ultimate_parent;

    return (
      <CompanyPrompt page_title={page_title}>
        <Typography variant="subtitle1">
          This page only supports companies, not brands.
        </Typography>
        <Typography variant="subtitle2">
          Please search for a company in a search above
          {ultimateParent ? (
            <UltimateParentLink business={ultimateParent} />
          ) : null}
        </Typography>
      </CompanyPrompt>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const CompanyPrompt: React.FC<{ page_title: string }> = ({
  page_title,
  children,
}) => {
  return (
    <PrivateLayout page_title={page_title}>
      <Box px={1} data-testid="withCompanyOnlyPromptText">
        {children}
      </Box>
    </PrivateLayout>
  );
};

const UltimateParentLink: React.FC<{
  business: {
    __typename: 'Company';
    id: string;
    title: string;
    logo: string | null;
  };
}> = ({ business }) => {
  const page = useReportPage();

  const parameters = useCoercedQueryParams(page);

  if (!(VariableKeys.company_id in parameters))
    throw new Error('This page does not support company_id parameter');

  const handleFilterApply = useApplyFilters(page);

  return (
    <>
      &nbsp;or select the suggested brand parent:&nbsp;
      <Chip
        sx={{
          px: 0.5,
        }}
        avatar={
          <BusinessLogo
            api_url={environment.api_url}
            business={business}
            width={15}
            height={15}
          />
        }
        color="default"
        label={business.title}
        size="small"
        variant="outlined"
        onClick={() =>
          handleFilterApply({
            company_id: business.id,
          })
        }
      />
    </>
  );
};
