import {
  LoginLayout,
  TwoFactorAuthEmail,
  TwoFactorAuthTOTP,
} from '@groundwater/shared-ui';
import { useAuth } from '@groundwater/shared-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../environments/environment';
import { PublicLayout } from '../layouts/PublicLayout';

export function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [forceEmail, setForceEmail] = useState<boolean>(false);
  // Need to perform 2fa?
  if (auth.user && auth.user.require_2fa && !auth.user.verified_2fa) {
    return (
      <PublicLayout logoStyle="light">
        {auth.user.has_setup_totp && !forceEmail ? (
          <TwoFactorAuthTOTP switchToEmail={() => setForceEmail(true)} />
        ) : (
          <TwoFactorAuthEmail switchToTotp={() => setForceEmail(false)} />
        )}
      </PublicLayout>
    );
  }

  if (auth.user) {
    navigate('/', { replace: true });
    return null;
  }
  return (
    <PublicLayout logoStyle="light">
      <LoginLayout
        onSubmit={async (email, password) => {
          await auth.login({
            username: email,
            password: password,
          });
          navigate('/', { replace: true });
        }}
        resetPasswordRoute="/reset"
        withContactDisclaimer
        isSubmitting={auth.isSubmitting}
        bssoLink={`${environment.api_url}/login/oauth2/redirect?whence=${environment.baseURL}`}
      />
    </PublicLayout>
  );
}
