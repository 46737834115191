import moment from 'moment';
import { isNil } from 'lodash-es';
import {
  TrendsRollup,
  TrendsResponse,
  TrendsTargetDimension,
  Brand,
  Company,
  BreakoutBy,
  Serie,
  TrendsV3Request,
} from '@groundwater/shared-ui';
import { Tooltip, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { StyledApexChart } from '../../components/StyledApexChart';
import {
  extentOfDataset,
  getFormatters,
  optimize,
} from './../../utils/chartUtils';
import { AXIS_LABELS, TRENDS_CHART_KEYS } from './constants';
import { ChartFrame } from '../../components/ChartFrame';
import { chartConfig } from './chartConfig';

export interface TrendsChartProps {
  isLoading: boolean;
  onLegendClick: (serieId: TRENDS_CHART_KEYS) => void;
  hiddenSerieId: TRENDS_CHART_KEYS[];

  companies_or_brands: Array<
    Pick<Brand, 'id' | 'title'> | Pick<Company, 'id' | 'title'>
  >;
  id: string;
  data: Serie[];
  request: TrendsV3Request;
  cbsas: TrendsResponse['cbsas'];
  yZoom: boolean;
}

export const TrendsChart = ({
  companies_or_brands,
  isLoading,
  onLegendClick,
  hiddenSerieId,
  id,
  data,
  request,
  yZoom,
  cbsas,
}: TrendsChartProps) => {
  const { rollup, breakout_by, target_dimension } = request;
  const QUARTER_LABEL = 'quarter_label';

  const muiTheme = useTheme();

  const fiscalQuarterRollup = rollup === TrendsRollup.FiscalQuarter;

  const xAxisTitle = rollup !== null ? AXIS_LABELS[rollup] : 'Time period';

  const formatRollupEndDate = (val: number) =>
    moment(val).format('MMM DD, yyyy');

  const chartTitle = chartConfig[id as TRENDS_CHART_KEYS].title;

  const { tooltipTitle, defaultType: chartDefaultType } =
    chartConfig[id as TRENDS_CHART_KEYS];

  let xSeries;

  // Use quarter labels to generate axis in case of Fiscal Quarter Rollup
  if (fiscalQuarterRollup) {
    xSeries = data.filter(({ id }) => id === QUARTER_LABEL)[0]?.data;
  } else {
    const serie = data.filter(({ id }) => id === rollup)[0];

    xSeries = serie?.data.map((dateStr) => moment(dateStr).toISOString());
  }

  // Exclude rollup and quarter_label series from the y-axis dataset
  const ySeries: {
    name: string;
    data: (number | null)[];
  }[] = data
    .filter(({ id }) => id !== QUARTER_LABEL && id !== rollup)
    .map(({ id, data }) => {
      let name: string | undefined;

      // In the case of the geo breakout we are displaying the CBSA titles.
      // By default Koios returns CBSA codes as the dataset headings.
      // Instead we want to map codes to the appropriate CBSA title.
      if (breakout_by === BreakoutBy.Geo && !isNil(cbsas)) {
        name = cbsas.find((cbsa) => cbsa.code === id)?.title;
      }
      // In all the rest of the cases we are displaying the business titles hense
      // need to map the generic Koios heading that represents business id to the approproiate
      // business title.
      else {
        name = companies_or_brands.find(
          (business) => business.id === id
        )?.title;
      }

      return {
        // falls back to id to allow for backwards compatibility
        // with the old instances of the API as this rolls out. It
        // is also needed to ensure there is no crash if Koios ever
        // returned a brand/company we don't yet have in the index on our end
        // which is possible since the GW indexer implements eventual consistency
        name: name ?? id,

        // Ensures that the series values are numbers
        data: data.map((i) => (i === null ? null : Number(i))),
      };
    });

  const isStackedAreaChart =
    id === TRENDS_CHART_KEYS.share_of_transactions ||
    id === TRENDS_CHART_KEYS.share_of_sales ||
    id === TRENDS_CHART_KEYS.share_of_primary_sales ||
    id === TRENDS_CHART_KEYS.share_of_intermediary_sales;

  const isPercentChart =
    isStackedAreaChart ||
    [
      TrendsTargetDimension.PopGrowth,
      TrendsTargetDimension.YoyGrowth,
      TrendsTargetDimension.Indexed,
    ].includes(target_dimension);

  let tickAmount: number, yMin: number, yMax: number;
  if (isStackedAreaChart) {
    yMin = 0;
    yMax = 1;
    tickAmount = 10;
  } else {
    const { minFromDataset, maxFromDataset } = extentOfDataset(
      ySeries,
      hiddenSerieId
    );

    ({ tickAmount, yMin, yMax } = optimize(
      yZoom,
      yZoom,
      minFromDataset,
      maxFromDataset
    ));
  }

  /**
   * Define formatters based on chart type
   */
  const {
    tooltipYFormatter,
    yAxisFormatter,
  }: {
    tooltipYFormatter: (n: number) => string;
    yAxisFormatter: (n: number) => string;
  } = getFormatters(target_dimension, isPercentChart, chartDefaultType);

  return (
    <ChartFrame
      actionContent={
        <>
          <Tooltip title={tooltipTitle}>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </>
      }
      chartTitle={chartTitle}
      isLoading={isLoading}
    >
      <StyledApexChart
        hiddenSerieId={hiddenSerieId}
        type={isStackedAreaChart ? 'area' : 'line'}
        height={350}
        series={ySeries}
        options={{
          colors: muiTheme.palette.chart.base,
          fill: {
            opacity: 1,
            gradient: {
              opacityFrom: 0.4,
              opacityTo: 0,
              shadeIntensity: 0,
              stops: [0, 100],
              type: 'vertical',
            },
          },
          grid: {
            borderColor: muiTheme.palette.grey[300],
            strokeDashArray: 3,
          },

          states: {
            active: {
              filter: {
                type: 'darken',
                value: 0.88,
              },
            },
            hover: {
              filter: {
                type: 'lighten',
                value: 0.04,
              },
            },
          },
          tooltip: {
            marker: {
              show: true,
            },
            x: {
              // Hide tooltip header
              show: false,
              ...(!fiscalQuarterRollup && {
                formatter: formatRollupEndDate,
              }),
            },
            y: {
              formatter: tooltipYFormatter,
            },
          },
          chart: {
            id,
            group: 'trends',
            foreColor: muiTheme.palette.grey[500],
            events: {
              legendClick: function (
                chartContext: any,
                seriesIndex: number,
                config: any
              ) {
                const serieID = config.globals.initialSeries[seriesIndex].name;
                onLegendClick(serieID);
              },
              mounted: function (chart: any) {
                chart.windowResizeHandler();
              },
            },
            // Disable toolbar functionality for trends charts
            toolbar: {
              show: false,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
              },
            },
            ...(isStackedAreaChart &&
              ySeries.length > 1 && {
                stacked: true,
              }),
          },
          ...(isStackedAreaChart && {
            dataLabels: {
              enabled: false,
            },
          }),
          // Configure the chart lines (width, curve, etc.)
          stroke: {
            width: 3,
            curve: 'straight',
          },
          yaxis: {
            min: yMin,
            max: yMax,
            tickAmount,
            labels: {
              formatter: yAxisFormatter,
            },
          },
          xaxis: {
            type: fiscalQuarterRollup ? 'category' : 'datetime',
            categories: xSeries,
            title: {
              text: xAxisTitle,
            },
            labels: {
              datetimeUTC: true,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm',
              },
            },
          },
          legend: {
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'left',
            floating: false,
            fontSize: '13',
            fontWeight: 500,
            offsetX: 5,
            onItemClick: {
              // disable native legend click event as we are triggering it manually above
              // https://github.com/secondmeasure/groundwater/blob/7cec3508a6ee32631b47f9e8466c391f189283d8/libs/app/ui/src/lib/pages/TrendsPresentation.tsx#L165
              toggleDataSeries: false,
            },
          },
        }}
      />
    </ChartFrame>
  );
};
