import { Announcement } from './Announcement';

export const OpenPanelAnnouncement = () => {
  return (
    <Announcement
      localStorageKey={'OPEN_PANEL_ALERT_DISMISSED'}
      endDate={'2024-09-01'}
      message={
        <>
          Starting August 14, 2024, we are implementing a new panel methodology
          that significantly enhances data quality by:
          <ul>
            <li>
              Including new panel members as they join the underlying dataset
            </li>
            <li>
              Improving geographic representation of the population resulting in
              minimal deviations from US census data
            </li>
            <li>
              Increasing representation of younger panel members, especially
              those born in the early 2000s
            </li>
          </ul>
          All pages and metrics will automatically reflect this new methodology
          from August 14, 2024, and historical data will be updated accordingly.
        </>
      }
    />
  );
};
