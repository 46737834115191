import {
  Paper,
  Stack,
  Typography,
  CircularProgress,
  Theme,
  SxProps,
} from '@mui/material';
import { ChartActionContent } from './StyledApexChart';

interface BaseChartLayoutProps {
  chartTitle: string;
  isLoading: boolean;
  actionContent?: React.ReactChild | null;
  paperStyles?: SxProps<Theme>;
}

/**
 * This component is meant solely for wrapping charts in standard boilerplate.
 * It is specifically devoid of any layout/grid related code.
 */
export const ChartFrame: React.FC<BaseChartLayoutProps> = ({
  chartTitle,
  isLoading,
  actionContent,
  children,
  paperStyles,
}) => {
  return (
    <Paper elevation={8} sx={{ py: 3, px: 1, ...paperStyles }}>
      <Stack direction="row" alignItems="center">
        <Typography
          variant="subtitle1"
          textTransform="capitalize"
          sx={{ ml: 4 }}
        >
          {chartTitle}
          {isLoading && <CircularProgress size={15} sx={{ mx: 1 }} />}
        </Typography>
        <ChartActionContent>{actionContent}</ChartActionContent>
      </Stack>
      {children}
    </Paper>
  );
};
