import React, { Suspense } from 'react';
import { useCompanyOrBrands, useQuery } from '../../hooks';
import { BusinessSearchInput, SearchInputProps } from '@groundwater/shared-ui';

/* Search input - this thing fetches the selected company and renders its own fallback (diabled input) */
export const PrimarySearchInputLoader: React.FC<
  Omit<SearchInputProps, 'inputValue'> & { inputValue: string | undefined }
> = React.forwardRef((props, ref) => (
  <Suspense
    fallback={
      // When suspended, render a disabled input
      <BusinessSearchInput
        {...{
          ...props,
          disabled: true,
          inputValue: 'Loading...',
        }}
      />
    }
  >
    {/* this is the actual input */}
    <PrimarySearchInput
      {...props}
      // very important to forward the ref to ensure focusing/blurring works as intended
      ref={ref}
    />
  </Suspense>
));

/**
 * the PrimarySearchInput renders a BusinessSearchInput,
 * it fetches the selected ?company_id and displays it in the input
 * when there is no query from the user yet.
 *
 * This component suspends, its meant to be consumed via PrimarySearchInputLoader
 * which renders a fallback
 */
const PrimarySearchInput: React.FC<
  Omit<SearchInputProps, 'inputValue'> & { inputValue: string | undefined }
> = React.forwardRef((props, ref) => {
  // fetch object for selected company_id
  const { company_id } = useQuery();
  if (company_id != null && typeof company_id !== 'string') throw new Error();
  const companyOrBrands = useCompanyOrBrands(company_id ? [company_id] : []);

  return (
    <BusinessSearchInput
      {...props}
      // render search input with selected company as default input value
      inputValue={props.inputValue ?? companyOrBrands[0]?.title ?? ''}
      // very important to forward the ref to ensure focusing/blurring works as intended
      ref={ref}
    />
  );
});
