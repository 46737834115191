import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, useToast } from '@groundwater/shared-ui';
import {
  ResetPasswordMutationVariables,
  useResetPasswordMutation,
} from '../../../gql-generated';
import { useQuery } from '../../hooks';
import { object, ref, string } from 'yup';
import { PublicLayout } from '../../layouts/PublicLayout';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const validationSchema = object().shape({
  new_password: string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  new_password_repeat: string()
    .required('Password is required')
    .oneOf([ref('new_password')], 'Passwords must match'),
});

export function PasswordResetPage() {
  const { token } = useQuery();
  if (!token || typeof token !== 'string')
    throw new Error('token is missing or not a valid string');

  const resetPassword = useResetPasswordMutation();
  const { setToast } = useToast();

  const navigate = useNavigate();

  const showErrorToast = () => {
    setToast({
      id: 'reset_password_request_failed',
      open: true,
      severity: 'error',
      message:
        'Failed to request password reset, please try again later or contact support.',
    });
  };

  const onSubmit = async (new_password: string) => {
    try {
      const req: ResetPasswordMutationVariables = {
        token,
        new_password,
      };
      await resetPassword.mutateAsync(req);
      navigate('/password-reset-success');
    } catch (err) {
      showErrorToast();
      throw err;
    }
  };

  const {
    register,
    formProps,
    formState: { errors },
  } = useForm({
    defaultValues: {
      new_password: '',
      new_password_repeat: '',
    },

    validationSchema,
    onSubmit: ({ new_password }) => onSubmit(new_password),
  });

  return (
    <PublicLayout>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Reset Password
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Please enter the new password you'd like associated with your
                account.
              </Typography>
            </Stack>

            <form {...formProps}>
              <Stack direction="column" spacing={3} sx={{ my: 2 }}>
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  type="password"
                  label="Password"
                  {...register('new_password')}
                />
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  type="password"
                  label="Password (repeat)"
                  {...register('new_password_repeat')}
                />
              </Stack>
              <Stack justifyContent="center" spacing={1} sx={{ my: 3 }}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={resetPassword.isLoading}
                  disabled={Boolean(Object.keys(errors).length)}
                >
                  Reset Password
                </LoadingButton>
                <Button
                  fullWidth
                  size="large"
                  component={RouterLink}
                  variant="text"
                  to={'/'}
                >
                  Back
                </Button>
              </Stack>
            </form>
          </ContentStyle>
        </Container>
      </RootStyle>
    </PublicLayout>
  );
}
