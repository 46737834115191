import { Route, Routes } from 'react-router-dom';
import { Login } from './containers/Login';
import { RequestPasswordResetPage } from './pages/RequestPasswordResetPage/RequestPasswordResetPage';
import { TrendsPage } from './pages/TrendsPage/TrendsPage';
import { PasswordResetSuccessPage } from './pages/PasswordResetSuccessPage/PasswordResetSuccessPage';
import { PasswordResetPage } from './pages/PasswordResetPage/PasswordResetPage';
import { RequestPasswordResetSuccessPage } from './pages/RequestPasswordResetSuccessPage/RequestPasswordResetSuccessPage';
import { HomePage } from './pages/HomePage/HomePage';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { useAuth } from '@groundwater/shared-ui';
import { QpsPage } from './pages/QpsPage/QpsPage';
import { QtdPage } from './pages/QtdPage/QtdPage';
import { OvrPage } from './pages/OvrPage/OvrPage';
import { BrowseBusinessesPage } from './pages/BrowseBusinessesPage/BrowseBusinessesPage';
import { TransactionValuesPage } from './pages/TransactionValues/TransactionValuesPage';
import { CohortedRetentionPage } from './pages/CohortedRetentionPage/CohortedRetentionPage';
import { RetentionSummaryPage } from './pages/RetentionSummary/RetentionSummaryPage';
import { SentryRoutes, useSentry } from '../sentry';
import { BssoLandingPage } from './components/BssoLanding/BssoLandingPage';
import { DemoRequestSuccessPage } from './components/BssoLanding/DemoRequestSuccessPage';
import { WebToLeadForm } from './components/BssoLanding/WebToLeadForm';
import { DollarRetentionPage } from './pages/DollarRetentionPage/DollarRetentionPage';
import { GrowthAccountingPage } from './pages/GrowthAccountingPage/GrowthAccountingPage';
import { SettingsPage } from './pages/SettingsPage/SettingsPage';

const Router = () => {
  // If Sentry is enabled use SentryRoutes integration
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v6
  const RoutesWrapper = useSentry ? SentryRoutes : Routes;

  const { isAuthenticated } = useAuth();

  return (
    <RoutesWrapper>
      <Route path="/reports">
        <Route path="trends" element={<TrendsPage />} />
        <Route path="qps" element={<QpsPage />} />
        <Route path="qtd" element={<QtdPage />} />
        <Route path="ovr" element={<OvrPage />} />
        <Route path="browse_businesses" element={<BrowseBusinessesPage />} />
        <Route path="transaction_values" element={<TransactionValuesPage />} />
        <Route path="cohorted_retention" element={<CohortedRetentionPage />} />
        <Route path="dollar_retention" element={<DollarRetentionPage />} />
        <Route path="retention_summary" element={<RetentionSummaryPage />} />
        <Route path="growth_accounting" element={<GrowthAccountingPage />} />
      </Route>
      <Route path="/">
        <Route path="settings" element={<SettingsPage />} />
        <Route
          path="request-password-reset"
          element={<RequestPasswordResetPage />}
        />
        <Route
          path="request-password-reset-success"
          element={<RequestPasswordResetSuccessPage />}
        />
        <Route path="password-reset" element={<PasswordResetPage />} />
        <Route
          path="password-reset-success"
          element={<PasswordResetSuccessPage />}
        />
        <Route path="/" element={isAuthenticated ? <HomePage /> : <Login />} />
        <Route
          path="/login"
          element={isAuthenticated ? <HomePage /> : <Login />}
        />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="bsso-failure">
          <Route index element={<BssoLandingPage />} />
          <Route path="demo-request" element={<WebToLeadForm />} />
          <Route
            path="demo-request-success"
            element={<DemoRequestSuccessPage />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </RoutesWrapper>
  );
};

export default Router;
