/** Convert a record to an es6 map */
function recordToMap<K extends string, V>(record: Record<K, V>): Map<K, V> {
  const map = new Map();
  Object.entries(record).forEach(([key, value]) => {
    map.set(key, value);
  });
  return map;
}

/** Get map's entries as array of [K, V] tuples */
function entriesFromMap<K, V>(map: Map<K, V>): Array<[K, V]> {
  return Array.from(map.entries());
}

/**
 * A type safe alternative to Object.entries()
 *
 * Gets a record's entries as an array of [K, V] tuples
 *
 * Why do we need this?
 *
 * Normally Object.entries() widens the key of a Record<K, V>
 * from K to type `string`.
 *
 * This works around the issue by converting the record to an es6
 * map, and sourcing the entries with Array.from(Map.entries).
 *
 * This allows us to define our mappings as Record<K, V>, which
 * exhaustively checks that all K are defined. It also allows
 * us to iterate over tuples of [K, V] without widening
 * it to K to `string` which begets type assertions when you need
 * to index into reportPageConfig's configurations.
 */
export function entriesFromRecord<K extends string, V>(
  record: Record<K, V>
): [K, V][] {
  return entriesFromMap(recordToMap(record));
}
