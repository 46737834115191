import {
  FilterButtonMenu,
  FilterButtonMenuOption,
} from '@groundwater/shared-ui';
import { VariableKeys, variablesSchema } from '../../../utils';
import { reach } from 'yup';
import {
  GlobalTargetDimension,
  TARGET_DIMENSION_LABELS,
} from '../../constants';
import { useReportPage } from '../../hooks/useReportPage';
import { entriesFromRecord } from '../../../utils/record-map';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

export const TargetDimensionFilter: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (!(VariableKeys.target_dimension in draftParams)) {
    throw new Error();
  }

  const selectedOption = draftParams[VariableKeys.target_dimension];

  const onClick = (target_dimension: GlobalTargetDimension) =>
    handleDraftChange((prevParams) => ({
      ...prevParams,
      target_dimension,
    }));

  const page = useReportPage();
  const menuOptions: FilterButtonMenuOption<GlobalTargetDimension>[] =
    entriesFromRecord(TARGET_DIMENSION_LABELS)
      .filter(([value, label]) =>
        reach(variablesSchema[page], VariableKeys.target_dimension).isValidSync(
          value
        )
      )
      .map(([value, label]) => {
        return {
          label,
          value,
          // TODO: state will be based on the combination of selected filters
          disabled: false,
        };
      });

  return (
    <FilterButtonMenu
      autoClose
      id="target_dimension-filter"
      filterLabel={`View: ${TARGET_DIMENSION_LABELS[selectedOption]}`}
      menuOptions={menuOptions}
      onClick={onClick}
      selected={selectedOption}
    />
  );
};
