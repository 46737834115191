import {
  FilterButtonMenu,
  FilterButtonMenuOption,
} from '@groundwater/shared-ui';
import { Button } from '@mui/material';
import { VariableKeys } from '../../../utils';
import React, { useEffect, useMemo } from 'react';
import {
  CompanyQuarterFragment,
  useQuartersQuery,
} from '../../../gql-generated';
import { useCompanyId } from '../../hooks/useCompanyId';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';
import { useReportPage } from '../../hooks/useReportPage';
import { useApplyFilters } from '../../hooks/useApplyFilters';

type Quarter = CompanyQuarterFragment;

interface QuarterFilterProps {
  /**
   * Default quarter index tuple. Indexes ordered by priority.
   *
   * In case of comparison quarter filter we want to default the selection to the previous year quarter (analysis_quarter - 4 quarters).
   * However, it is possible that the company has less than a year of historical data. In that case we want to default to a previous quarter (analysis_quarter - 1 quarter)
   */
  // defaultIndex: [number, number?];
  param: VariableKeys.comparison_quarter | VariableKeys.analysis_quarter;
}

export function quarterToString(quarter: Quarter) {
  return `FY${quarter.fiscal_year} Q${quarter.fiscal_quarter}`;
}

export const QuarterFilter: React.FC<QuarterFilterProps> = ({ param }) => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (
    !(VariableKeys.analysis_quarter in draftParams) ||
    !(VariableKeys.comparison_quarter in draftParams)
  ) {
    throw new Error();
  }

  const selected = draftParams[param];

  const defaultIndex: [number, number] | [number] =
    param === VariableKeys.comparison_quarter ? [4, 1] : [0];

  const company_id = useCompanyId();
  const res = useQuartersQuery({ company_id });
  if (res.data?.business.__typename !== 'Company')
    throw new Error(
      'The Quarter-to-Date page only supports a Company, not a Brand.'
    );

  if (!res.data.business.public || !res.data.business.reports_quarterly)
    throw new Error(
      'This analysis is only available for public companies that report quarterly'
    );

  const { quarters } = res.data.business;

  const isOptionDisabled = (index: number) => {
    // Disable last analysis quarter option because there is no matching comparison quarter period
    if (param === VariableKeys.analysis_quarter) {
      return index === quarters.length - 1;
    } else if (param === VariableKeys.comparison_quarter) {
      // Disable the first comparison quarter option because there is no matching analysis quarter period
      return index === 0;
    }
    return false;
  };

  const menuOptions: FilterButtonMenuOption<Quarter>[] = quarters.map(
    (quarter, index) => {
      const label = quarterToString(quarter);
      return {
        label,
        value: quarter,
        disabled: isOptionDisabled(index),
      };
    }
  );

  const selectedQuarter: Quarter | undefined = menuOptions.find(
    (option) => option.label === selected
  )?.value;

  // Use priority index as a default
  let defaultQuarter = quarters[defaultIndex[0]];

  // If there is no matching quarter for the priority index use secondary index
  if (!defaultQuarter && defaultIndex[1] !== undefined) {
    defaultQuarter = quarters[defaultIndex[1]];
  }

  // If no matching quarter throw an error
  if (!defaultQuarter) throw new Error('Fiscal quarter data not present.');

  const selectedOrDefaultQuarter = selectedQuarter ?? defaultQuarter;

  const handleFilterApply = useApplyFilters(useReportPage());

  /**
   * Supply the default initial quarter selections to the URL once the async
   * data is fetched from the backed
   */
  useEffect(() => {
    if (!selectedQuarter && defaultQuarter) {
      // Without this assignment Typescript validator assumes that defaultQuarter could be undefined
      const quarter = quarterToString(defaultQuarter);
      // timeout is needed otherwise navigating from QTD to trends to QTD breaks
      // https://github.com/remix-run/react-router/issues/7460
      setTimeout(() => {
        // replace the history, instead of push, so the back button doesn't go
        // back to the interim URL has a blank filter, which
        // would trigger the effect to populate the URL again
        if (param === VariableKeys.comparison_quarter) {
          handleFilterApply(
            {
              comparison_quarter: quarter,
            },
            { replace: true }
          );
        } else {
          handleFilterApply(
            {
              analysis_quarter: quarter,
            },
            { replace: true }
          );
        }
      });
    }
  }, [defaultQuarter, handleFilterApply, param, selectedQuarter]);

  const getLabel = useMemo(() => {
    const selectedValue = quarterToString(selectedOrDefaultQuarter);

    if (param === VariableKeys.analysis_quarter) {
      return `Analyzing: ${selectedValue}`;
    } else if (param === VariableKeys.comparison_quarter) {
      return `Comparing: ${selectedValue}`;
    } else {
      throw new Error('Type is not supported');
    }
  }, [selectedOrDefaultQuarter, param]);

  return (
    // TODO - group by year <FilterMenuGroupedList
    <FilterButtonMenu
      autoClose
      id="quarter-filter" // todo - don't hard code IDs
      filterLabel={getLabel}
      menuOptions={menuOptions}
      onClick={(quarter: Quarter) => {
        if (VariableKeys.analysis_quarter === param) {
          // Upon analysis quarter selection we need to adjust the comparison quarter value to prevent the period overlap.
          const index = quarters.findIndex(
            (q) => q.end_date === quarter.end_date
          );
          const getComparisonQuarter = (
            quarters: {
              __typename: 'Quarter';
              start_date: string;
              end_date: string;
              fiscal_year: number;
              fiscal_quarter: number;
            }[],
            index: number
          ) => {
            // Default to year-over-year range
            let match = quarters[index + 4];

            // If there is no matching year-over-year quarter search for the quarter-over-quarter match
            if (!match) {
              match = quarters[index + 1];
            }

            // If no matching quarter throw an error
            if (!match)
              throw new Error('Matching comparison quarter does not exist.');

            return quarterToString(match);
          };
          handleDraftChange((prevParams) => ({
            ...prevParams,
            analysis_quarter: quarterToString(quarter),
            comparison_quarter: getComparisonQuarter(quarters, index),
          }));
        } else {
          handleDraftChange((prevParams) => ({
            ...prevParams,
            comparison_quarter: quarterToString(quarter),
          }));
        }
      }}
      selected={selectedOrDefaultQuarter}
    />
  );
};

export const QuarterFilterFallback: React.FC<{}> = () => (
  <Button disabled variant="contained" size="medium" color="primary">
    loading...
  </Button>
);
