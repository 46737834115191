import { Box, CircularProgress } from '@mui/material';

export const BusinessSearchResultsLoading: React.FC<{}> = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
    }}
  >
    <CircularProgress size={60} />
  </Box>
);
