import { TextField } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';

interface CustomModelValueInputProps {
  register: UseFormRegister<{
    scaling_model_custom_percent: number;
  }>;
}

export const scaling_model_custom_percent = 'scaling_model_custom_percent';

export const CustomModelValueInput: React.FC<CustomModelValueInputProps> = ({
  register,
}) => {
  return (
    <TextField
      autoFocus
      fullWidth
      label="Custom % observed"
      placeholder="Enter value between 0 and 100"
      {...register(scaling_model_custom_percent)}
    />
  );
};
