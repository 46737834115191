import styled, { css } from 'styled-components';

export const ChartStyles = css`
  position: relative;
  svg {
    .chart-background-region {
      fill: #f3f3f3;
      opacity: 1;
    }
    .y-axis {
      .tick line {
        stroke: #fff;
        stroke-dasharray: none;
        opacity: 1;
      }
    }
    .domain {
      color: transparent;
    }
    .chartLabel {
      text-anchor: middle;
      fill: #666;
      font-weight: bold;
      font-size: 11px;
    }
    .tick text {
      font-size: 11px;
      fill: rgba(0, 0, 0, 0.6);
    }
    line {
      opacity: 0.1;
    }
  }
`;

const colorBackgroundLight = 'white;';
const colorTextPrimaryOnLight = 'black';

export const Tooltip = styled.div`
  pointer-events: none;
  opacity: 0;
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 33%);
  background-color: ${colorBackgroundLight};
  width: fit-content;
  color: ${colorTextPrimaryOnLight};
  border-radius: 5px;
  padding: 10px;
  position: absolute;
`;
