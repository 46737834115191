import {
  FilterButtonMenu,
  FilterButtonMenuOption,
} from '@groundwater/shared-ui';
import { VariableKeys, variablesSchema } from '../../../utils';
import { reach } from 'yup';
import { SELECET_QUARTER_LABELS } from '../../constants';
import { useReportPage } from '../../hooks/useReportPage';
import { entriesFromRecord } from '../../../utils/record-map';
import { QpsSelectedQuarter } from '../../../gql-generated';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

export const SelectedQuarterFilter: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (!(VariableKeys.selected_quarter in draftParams)) {
    throw new Error();
  }

  const selectedOption = draftParams[VariableKeys.selected_quarter];
  const onClick = (selected_quarter: QpsSelectedQuarter) => {
    handleDraftChange((prevParams) => ({
      ...prevParams,
      selected_quarter,
    }));
  };

  const page = useReportPage();
  const menuOptions: FilterButtonMenuOption<QpsSelectedQuarter>[] =
    entriesFromRecord(SELECET_QUARTER_LABELS)
      .filter(([value, label]) =>
        reach(variablesSchema[page], VariableKeys.selected_quarter).isValidSync(
          value
        )
      )
      .map(([value, label]) => {
        return {
          label,
          value,
          // TODO: state will be based on the combination of selected filters
          disabled: false,
        };
      });

  return (
    <FilterButtonMenu
      autoClose
      id="target_dimension-filter"
      filterLabel={`View: ${SELECET_QUARTER_LABELS[selectedOption]}`}
      menuOptions={menuOptions}
      onClick={onClick}
      selected={selectedOption}
    />
  );
};
