export interface ErrorBoundaryIconProps
  extends React.SVGAttributes<SVGElement> {}

export const ErrorBoundaryIcon = (props: ErrorBoundaryIconProps) => {
  return (
    <svg
      width="200px"
      height="200px"
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <polygon
          id="path-1"
          points="0.1254 0.838 13.979 0.838 13.979 14.691 0.1254 14.691"
        />
        <polygon
          id="path-3"
          points="0.1254 0.3036 13.979 0.3036 13.979 14.1566 0.1254 14.1566"
        />
        <polygon
          id="path-5"
          points="0.1254 0.3036 13.979 0.3036 13.979 14.1566 0.1254 14.1566"
        />
        <polygon
          id="path-7"
          points="0.1254 0.3036 13.979 0.3036 13.979 14.1566 0.1254 14.1566"
        />
        <polygon
          id="path-9"
          points="0.1254 0.3036 13.979 0.3036 13.979 14.1566 0.1254 14.1566"
        />
        <polygon
          id="path-11"
          points="0.0199 0.98 13.8729 0.98 13.8729 14.8328 0.0199 14.8328"
        />
        <polygon
          id="path-13"
          points="0 0.1468 13.8534 0.1468 13.8534 13.9998 0 13.9998"
        />
        <polygon
          id="path-15"
          points="0 0.1468 13.8534 0.1468 13.8534 13.9998 0 13.9998"
        />
        <polygon
          id="path-17"
          points="0 0.1468 13.8534 0.1468 13.8534 13.9998 0 13.9998"
        />
        <polygon
          id="path-19"
          points="0 0.1468 13.8534 0.1468 13.8534 13.9998 0 13.9998"
        />
        <polygon
          id="path-21"
          points="0 0.6124 13.8534 0.6124 13.8534 14.4654 0 14.4654"
        />
      </defs>
      <g
        id="Logged-Out-Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icon">
          <path
            d="M87.927,116 C91.752,116 94.854,119.101 94.854,122.927 C94.854,126.752 91.752,129.853 87.927,129.853 C84.102,129.853 81,126.752 81,122.927 C81,119.101 84.102,116 87.927,116"
            id="Fill-1"
            fill="#26b6c0"
          />
          <path
            d="M84.927,33 C88.752,33 91.854,36.101 91.854,39.927 C91.854,43.752 88.752,46.853 84.927,46.853 C81.102,46.853 78,43.752 78,39.927 C78,36.101 81.102,33 84.927,33"
            id="Fill-3"
            fill="#E03C8A"
          />
          <path
            d="M46.927,15 C50.752,15 53.854,18.101 53.854,21.927 C53.854,25.752 50.752,28.853 46.927,28.853 C43.102,28.853 40,25.752 40,21.927 C40,18.101 43.102,15 46.927,15"
            id="Fill-3-Copy"
            fill="#E03C8A"
          />
          <path
            d="M46.926,98 C50.752,98 53.853,101.101 53.853,104.927 C53.853,108.752 50.752,111.853 46.926,111.853 C43.101,111.853 40,108.752 40,104.927 C40,101.101 43.101,98 46.926,98"
            id="Fill-5"
            fill="#26b6c0"
          />
          <path
            d="M115.926,88 C119.752,88 122.853,91.101 122.853,94.927 C122.853,98.752 119.752,101.853 115.926,101.853 C112.101,101.853 109,98.752 109,94.927 C109,91.101 112.101,88 115.926,88"
            id="Fill-7"
            fill="#08192d"
          />
          <g id="Group-11" transform="translate(109.000000, 50.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-10" />
            <path
              d="M7.0524,0.838 C10.8774,0.838 13.9794,3.939 13.9794,7.765 C13.9794,11.59 10.8774,14.691 7.0524,14.691 C3.2274,14.691 0.1254,11.59 0.1254,7.765 C0.1254,3.939 3.2274,0.838 7.0524,0.838"
              id="Fill-9"
              fill="#E03C8A"
              mask="url(#mask-2)"
            />
          </g>
          <g id="Group-14" transform="translate(119.000000, 147.000000)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M7.0524,0.3036 C10.8774,0.3036 13.9794,3.4046 13.9794,7.2306 C13.9794,11.0556 10.8774,14.1566 7.0524,14.1566 C3.2274,14.1566 0.1254,11.0556 0.1254,7.2306 C0.1254,3.4046 3.2274,0.3036 7.0524,0.3036"
              id="Fill-12"
              fill="#08192d"
              mask="url(#mask-4)"
            />
          </g>
          <g id="Group-14-Copy-2" transform="translate(7.000000, 123.000000)">
            <mask id="mask-6" fill="white">
              <use xlinkHref="#path-5" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M7.0524,0.3036 C10.8774,0.3036 13.9794,3.4046 13.9794,7.2306 C13.9794,11.0556 10.8774,14.1566 7.0524,14.1566 C3.2274,14.1566 0.1254,11.0556 0.1254,7.2306 C0.1254,3.4046 3.2274,0.3036 7.0524,0.3036"
              id="Fill-12"
              fill="#08192d"
              mask="url(#mask-6)"
            />
          </g>
          <g id="Group-14-Copy" transform="translate(154.000000, 76.000000)">
            <mask id="mask-8" fill="white">
              <use xlinkHref="#path-7" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M7.0524,0.3036 C10.8774,0.3036 13.9794,3.4046 13.9794,7.2306 C13.9794,11.0556 10.8774,14.1566 7.0524,14.1566 C3.2274,14.1566 0.1254,11.0556 0.1254,7.2306 C0.1254,3.4046 3.2274,0.3036 7.0524,0.3036"
              id="Fill-12"
              fill="#08192d"
              mask="url(#mask-8)"
            />
          </g>
          <g id="Group-14-Copy-3" transform="translate(60.000000, 67.000000)">
            <mask id="mask-10" fill="white">
              <use xlinkHref="#path-9" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M7.0524,0.3036 C10.8774,0.3036 13.9794,3.4046 13.9794,7.2306 C13.9794,11.0556 10.8774,14.1566 7.0524,14.1566 C3.2274,14.1566 0.1254,11.0556 0.1254,7.2306 C0.1254,3.4046 3.2274,0.3036 7.0524,0.3036"
              id="Fill-12"
              fill="#08192d"
              mask="url(#mask-10)"
            />
          </g>
          <path
            d="M48.927,148 C52.752,148 55.853,151.101 55.853,154.927 C55.853,158.752 52.752,161.853 48.927,161.853 C45.101,161.853 42,158.752 42,154.927 C42,151.101 45.101,148 48.927,148"
            id="Fill-15"
            fill="#26b6c0"
          />
          <path
            d="M153.927,42 C157.752,42 160.853,45.101 160.853,48.927 C160.853,52.752 157.752,55.853 153.927,55.853 C150.101,55.853 147,52.752 147,48.927 C147,45.101 150.101,42 153.927,42"
            id="Fill-15-Copy"
            fill="#26b6c0"
          />
          <g id="Group-19" transform="translate(83.000000, 0.000000)">
            <mask id="mask-12" fill="white">
              <use xlinkHref="#path-11" />
            </mask>
            <g id="Clip-18" />
            <path
              d="M6.9469,0.9798 C10.7719,0.9798 13.8729,4.0808 13.8729,7.9068 C13.8729,11.7318 10.7719,14.8328 6.9469,14.8328 C3.1209,14.8328 0.0199,11.7318 0.0199,7.9068 C0.0199,4.0808 3.1209,0.9798 6.9469,0.9798"
              id="Fill-17"
              fill="#08192d"
              mask="url(#mask-12)"
            />
          </g>
          <g id="Group-22" transform="translate(151.000000, 122.000000)">
            <mask id="mask-14" fill="white">
              <use xlinkHref="#path-13" />
            </mask>
            <g id="Clip-21" />
            <path
              d="M6.9264,0.1468 C10.7514,0.1468 13.8534,3.2478 13.8534,7.0738 C13.8534,10.8988 10.7514,13.9998 6.9264,13.9998 C3.1014,13.9998 -0.0006,10.8988 -0.0006,7.0738 C-0.0006,3.2478 3.1014,0.1468 6.9264,0.1468"
              id="Fill-20"
              fill="#E03C8A"
              mask="url(#mask-14)"
            />
          </g>
          <g id="Group-22-Copy-3" transform="translate(82.000000, 155.000000)">
            <mask id="mask-16" fill="white">
              <use xlinkHref="#path-15" />
            </mask>
            <g id="Clip-21" />
            <path
              d="M6.9264,0.1468 C10.7514,0.1468 13.8534,3.2478 13.8534,7.0738 C13.8534,10.8988 10.7514,13.9998 6.9264,13.9998 C3.1014,13.9998 -0.0006,10.8988 -0.0006,7.0738 C-0.0006,3.2478 3.1014,0.1468 6.9264,0.1468"
              id="Fill-20"
              fill="#E03C8A"
              mask="url(#mask-16)"
            />
          </g>
          <g id="Group-22-Copy" transform="translate(14.000000, 36.000000)">
            <mask id="mask-18" fill="white">
              <use xlinkHref="#path-17" />
            </mask>
            <g id="Clip-21" />
            <path
              d="M6.9264,0.1468 C10.7514,0.1468 13.8534,3.2478 13.8534,7.0738 C13.8534,10.8988 10.7514,13.9998 6.9264,13.9998 C3.1014,13.9998 -0.0006,10.8988 -0.0006,7.0738 C-0.0006,3.2478 3.1014,0.1468 6.9264,0.1468"
              id="Fill-20"
              fill="#26b6c0"
              mask="url(#mask-18)"
            />
          </g>
          <g id="Group-22-Copy-2" transform="translate(123.000000, 16.000000)">
            <mask id="mask-20" fill="white">
              <use xlinkHref="#path-19" />
            </mask>
            <g id="Clip-21" />
            <path
              d="M6.9264,0.1468 C10.7514,0.1468 13.8534,3.2478 13.8534,7.0738 C13.8534,10.8988 10.7514,13.9998 6.9264,13.9998 C3.1014,13.9998 -0.0006,10.8988 -0.0006,7.0738 C-0.0006,3.2478 3.1014,0.1468 6.9264,0.1468"
              id="Fill-20"
              fill="#26b6c0"
              mask="url(#mask-20)"
            />
          </g>
          <g id="Group-25" transform="translate(0.000000, 73.000000)">
            <mask id="mask-22" fill="white">
              <use xlinkHref="#path-21" />
            </mask>
            <g id="Clip-24" />
            <path
              d="M6.9264,0.6124 C10.7514,0.6124 13.8534,3.7134 13.8534,7.5394 C13.8534,11.3644 10.7514,14.4654 6.9264,14.4654 C3.1014,14.4654 -0.0006,11.3644 -0.0006,7.5394 C-0.0006,3.7134 3.1014,0.6124 6.9264,0.6124"
              id="Fill-23"
              fill="#E03C8A"
              mask="url(#mask-22)"
            />
          </g>
          <polyline
            id="Stroke-28"
            stroke="#08192d"
            strokeWidth="4.5"
            points="125.9264 154.9265 116.0524 94.9265 164.5524 81.7301"
          />
          <polyline
            id="Stroke-28"
            stroke="#26b6c0"
            strokeWidth="4.5"
            points="49.0524 155.1468 46.9265 104.9265 87.927 123.3036"
          />
          <path
            d="M20.9264,42.9265 L46.5,104.5"
            id="Stroke-28"
            stroke="#26b6c0"
            strokeWidth="4.5"
          />
          <path
            d="M67.0524,74.2301 L116.0524,94.9265"
            id="Stroke-28"
            stroke="#08192d"
            strokeWidth="4.5"
          />
          <path
            d="M53.5,88.5 L67.0524,74.5"
            id="Stroke-28"
            stroke="#08192d"
            strokeWidth="3.5"
            strokeDasharray="4.5,3"
          />
          <path
            d="M78,55.853 L67.0524,74.5"
            id="Stroke-28"
            stroke="#08192d"
            strokeWidth="3.5"
            strokeDasharray="4.5,3"
          />
          <path
            d="M104.840258,104.5 L87.5,122.5"
            id="Stroke-28"
            stroke="#26b6c0"
            strokeWidth="3.5"
            strokeDasharray="4.5,3"
          />
          <path
            d="M130.5,76.5 L116.5,57.5"
            id="Stroke-28"
            stroke="#E03C8A"
            strokeWidth="3.5"
            strokeDasharray="4.5,3"
          />
          <path
            d="M33.7132,36.1468 L46.5,21.5"
            id="Stroke-28"
            stroke="#E03C8A"
            strokeWidth="3.5"
            strokeDasharray="4.5,3"
          />
          <polyline
            id="Stroke-28"
            stroke="#E03C8A"
            strokeWidth="4.5"
            points="46.5 21.9265 85 39.96325 116.0524 58"
          />
        </g>
      </g>
    </svg>
  );
};
