import { Card, Container, Stack, styled, Typography } from '@mui/material';
import { PublicLayout } from '../../layouts/PublicLayout';
import { MHidden } from '@groundwater/shared-ui';
import { Box } from '@mui/system';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.primary.dark,
}));

const ContentStyle = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const BssoPageFrame: React.FC<{}> = ({ children }) => {
  return (
    <PublicLayout logoStyle="light" redirectHome={false}>
      <RootStyle>
        <MHidden width="mdDown">
          <SectionStyle>
            <Stack
              alignItems="start"
              justifyContent="space-between"
              sx={{ px: 5, mt: 10, mb: 5 }}
            >
              <StyledTypography variant="h4">
                Hi, Welcome To Bloomberg Second Measure!
              </StyledTypography>
            </Stack>
          </SectionStyle>
        </MHidden>
        <Container>
          <ContentStyle>{children}</ContentStyle>
        </Container>
      </RootStyle>
    </PublicLayout>
  );
};
