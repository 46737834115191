import { TableCell, TableCellProps } from '@mui/material';
import { omit } from 'lodash-es';

export const DefaultCell: React.FC<
  {
    backgroundColor?: string;
    fontColor?: string;
    minWidth?: number;
  } & TableCellProps
> = (props) => {
  const { children, backgroundColor, fontColor, minWidth } = props;
  return (
    <TableCell
      style={{
        minWidth: minWidth ?? 100,
        backgroundColor,
        color: fontColor,
      }}
      {...omit(props, 'backgroundColor', 'fontColor', 'minWidth')}
    >
      {children}
    </TableCell>
  );
};
