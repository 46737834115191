/// <reference types="react-dom/experimental" />

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

import { createRoot } from 'react-dom/client';

import App from './app/app';
import { initializeSentry, useSentry } from './sentry';

if (useSentry) {
  initializeSentry();
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(<App />);
