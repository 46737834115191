import {
  DATE_SHORTCUT_KEYS,
  DEFAULT_DATE_FORMAT,
} from '@groundwater/shared-util';
import moment from 'moment';
import { DATE_SHORTCUT_LABELS } from '../app/constants';

/**
 * either a tuple of start & end date,
 * or a tuple with valid shortcut combos
 *
 * validated at runtime in `libs/shared/util/src/index.ts`, see
 * `start_date_schema` and `end_date_schema`!
 */
export type DateRange = [string, string];

export const isShortcutSelected = (key: DATE_SHORTCUT_KEYS, range: DateRange) =>
  key === range[0] && 'latest' === range[1];

export const generateDateRangeLabel = (dateRange: DateRange) => {
  // check if shortcuts are selected
  for (const key of Object.values(DATE_SHORTCUT_KEYS)) {
    if (isShortcutSelected(key, dateRange)) {
      // if we got a match, break the loop & return the matching label
      return DATE_SHORTCUT_LABELS[key];
    }
  }
  // otherwise format as custom date selection (no shortcuts matched)
  return `${moment(dateRange[0]).format(DEFAULT_DATE_FORMAT)} to ${moment(
    dateRange[1]
  ).format(DEFAULT_DATE_FORMAT)}`;
};

export const addOneDay = (date: string) =>
  moment(date).utc().add(1, 'day').toDate();

export const subtractOneDay = (date: string) =>
  moment(date).utc().subtract(1, 'day').toDate();
