import { TRENDS_CHART_KEYS } from './constants';

export const chartConfig = {
  [TRENDS_CHART_KEYS.observed_transactions]: {
    tooltipTitle: 'Number of observed transactions made in the period.',
    defaultType: 'unit' as const,
    title: 'Observed Transactions',
  },
  [TRENDS_CHART_KEYS.observed_sales]: {
    tooltipTitle: 'Total value of observed transactions (USD) in the period.',
    defaultType: 'dollar' as const,
    title: 'Observed Sales',
  },
  [TRENDS_CHART_KEYS.observed_customers]: {
    tooltipTitle:
      'Number of observed customers who made at least one transaction in the period.',
    defaultType: 'unit' as const,
    title: 'Observed Customers',
  },
  [TRENDS_CHART_KEYS.observed_primary_sales]: {
    tooltipTitle:
      'Total value of observed transactions (USD) of primary brand(s) in the period.',
    defaultType: 'dollar' as const,
    title: 'Observed Sales (Primary)',
  },
  [TRENDS_CHART_KEYS.share_of_primary_sales]: {
    tooltipTitle:
      'Percentage of total sales by primary brand(s) in the period belonging to the indicated portion.',
    defaultType: 'percent' as const,
    title: 'Share Of Sales (Primary)',
  },
  [TRENDS_CHART_KEYS.observed_intermediary_sales]: {
    tooltipTitle:
      'Total value of observed transactions (USD) of intermediary brand(s) in the period.',
    defaultType: 'dollar' as const,
    title: 'Observed Sales (Intermediary)',
  },
  [TRENDS_CHART_KEYS.share_of_intermediary_sales]: {
    tooltipTitle:
      'Percentage of total sales by intermediary brand(s) in the period belonging to the indicated portion.',
    defaultType: 'percent' as const,
    title: 'Share Of Sales (Intermediary)',
  },
  [TRENDS_CHART_KEYS.average_transaction_value]: {
    tooltipTitle: 'Average value of a transaction (USD) in the period.',
    defaultType: 'dollar' as const,
    title: 'Average Transaction Value',
  },
  [TRENDS_CHART_KEYS.sales_per_customer]: {
    tooltipTitle: 'Average sales generated per customer (USD) in the period.',
    defaultType: 'dollar' as const,
    title: 'Sales Per Customer',
  },
  [TRENDS_CHART_KEYS.transactions_per_customer]: {
    tooltipTitle:
      'Average number of transactions made per customer that made a purchase in the period.',
    defaultType: 'unit' as const,
    title: 'Transactions Per Customer',
  },
  [TRENDS_CHART_KEYS.share_of_sales]: {
    tooltipTitle:
      'Percentage of total sales in the period belonging to the indicated portion.',
    defaultType: 'percent' as const,
    title: 'Share Of Sales',
  },
  [TRENDS_CHART_KEYS.share_of_transactions]: {
    tooltipTitle:
      "Percentage of the company's transactions in the selected period.",
    defaultType: 'percent' as const,
    title: 'Share Of Transactions',
  },
};
