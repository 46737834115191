import { AnalysisGuideButton } from '@groundwater/app/ui';
import { Stack } from '@mui/material';
import { Suspense } from 'react';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { ReportLayout } from '../../layouts/ReportLayout';
import { TrendsCharts, TrendsLoadingSkeleton } from './TrendsCharts';
import { TrendsExportAllButton } from './TrendsExportAllButton';

const page_title = 'Bloomberg Second Measure - Trends';

export function TrendsPage() {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <ReportLayout
        page_title={page_title}
        filterSubHeaderActionContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <AnalysisGuideButton fileName="trends.pdf" />
            <TrendsExportAllButton />
          </Stack>
        }
      >
        <Suspense fallback={<TrendsLoadingSkeleton />}>
          <TrendsCharts />
        </Suspense>
      </ReportLayout>
    </WithCompanyIdPrompt>
  );
}
