import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { currency } from '../../pages/BrowseBusinessesPage/formatters';
import { AnalysisGuideButton, ChartFrame } from '@groundwater/app/ui';
import { Alert, Skeleton, Stack, Tooltip } from '@mui/material';
import { useGetDollarRetentionQuery } from '../../../gql-generated';
import { formatTransactionValueTag, VariableKeys } from '../../../utils';
import { omit } from 'lodash-es';
import { Suspense, useDeferredValue } from 'react';
import { RetentionLine } from '../../components/RetentionLine';
import { RetentionHeatmap } from '../../components/RetentionHeatmap';
import { WithCompanyIdPrompt } from '../../components/WithCompanyIdPrompt';
import { REPORT_PAGES, ROLLUP_LABELS } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { ReportLayout } from '../../layouts/ReportLayout';
import { getAxisLabel, getPeriod0Label } from '../CohortedRetentionPage/utils';
import { DollarRetentionExportAllButton } from './DollarRetentionExportAllButton';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';

const page_title = 'Bloomberg Second Measure - Dollar Retention';

export const DollarRetentionPage = () => {
  return (
    <WithCompanyIdPrompt page_title={page_title}>
      <ReportLayout
        page_title={page_title}
        filterSubHeaderActionContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <AnalysisGuideButton fileName="dollar_retention.pdf" />
            <DollarRetentionExportAllButton />
          </Stack>
        }
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <DollarRetentionPageContent />
        </Suspense>
      </ReportLayout>
    </WithCompanyIdPrompt>
  );
};

const LoadingSkeleton = () => (
  <Skeleton
    data-testid="dollar-retention-charts-skeleton"
    variant="rectangular"
    width="100%"
    height={370}
  />
);

export const DollarRetentionPageContent = () => {
  const variables = useVariables(REPORT_PAGES.dollar_retention);
  const deferredVariables = useDeferredValue(variables);

  const isLoading = variables !== deferredVariables;

  const { request } = deferredVariables;
  const { rollup, xaxis, y_zoom, bucket } = request;

  const response = useGetDollarRetentionQuery({
    request: omit(request, VariableKeys.cohort_view, VariableKeys.y_zoom),
  });

  if (!response.data) {
    throw new Error('Dataset is not present in the response payload.');
  }

  const period_0_label = getPeriod0Label(rollup, 'dollars');

  const { dollar_retention } = response.data;

  if (dollar_retention.__typename === 'DataError') {
    return isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Alert severity="info">{dollar_retention.message}</Alert>
    );
  }

  const { cohorts, periods, period_0_sales } = dollar_retention;

  return (
    <>
      {bucket !== null && bucket.trim() !== '' ? (
        <Alert color="warning" sx={{ my: 1 }}>
          Cohorts are filtered to members whose first transaction was in the
          range {formatTransactionValueTag(bucket)}
        </Alert>
      ) : null}
      <ChartFrame
        actionContent={
          <Tooltip title="Proportion of dollars that returned in the Nth period after the first purchase period.">
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        }
        chartTitle={'Dollar Retention'}
        isLoading={isLoading}
      >
        {request.cohort_view === 'line' ? (
          <RetentionLine
            cohorts={cohorts}
            periods={periods}
            period_0={period_0_sales.map((amount) => currency.format(amount))}
            xaxis={xaxis}
            yZoom={variables.request.y_zoom === 'true'}
            axisLabel={
              xaxis === 'calendar'
                ? ROLLUP_LABELS[rollup]
                : getAxisLabel(rollup)
            }
          />
        ) : (
          <RetentionHeatmap
            period_0_label={period_0_label}
            cohorts={dollar_retention.cohorts}
            periods={dollar_retention.periods}
            period_0={period_0_sales.map((amount) => currency.format(amount))}
            xaxis={request.xaxis}
            y_zoom={y_zoom.toLowerCase().trim() === 'true'}
          />
        )}
      </ChartFrame>
    </>
  );
};
