import { styled, alpha } from '@mui/material/styles';
import { Box, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { Icon } from '@iconify/react';
import closeSquareOutline from '@iconify/icons-eva/close-square-outline';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.light, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 700,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  width: '100%',
}));

export interface SearchInputProps {
  handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  clearInput: () => void;
  inputValue: string;
  disabled?: boolean;
  ref: React.Ref<undefined | HTMLInputElement>;
}

export const BusinessSearchInput: React.FC<SearchInputProps> = React.forwardRef(
  (
    { handleInputChange, handleKeyDown, inputValue, clearInput, disabled },
    inputRef
  ) => {
    return (
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          inputRef={inputRef}
          value={inputValue}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={(e) => e.target.select()}
          endAdornment={
            inputValue.length > 0 ? (
              <IconButton onClick={clearInput}>
                <Icon icon={closeSquareOutline} width={20} height={20} />
              </IconButton>
            ) : null
          }
          disabled={disabled}
        />
      </Search>
    );
  }
);
