import { useState } from 'react';
import { object, string } from 'yup';
import { useForm } from '../hooks/';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useToast } from '../hooks';

interface LoginRequest {
  email: string;
  password: string;
}

const validationSchema = object().shape({
  email: string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  password: string().required('Password is required'),
});

export interface LoginFormProps {
  onSubmit: (username: string, password: string) => Promise<void>;
  resetPasswordRoute: string;
  isSubmitting: boolean;
  bssoLink?: string;
}

export function LoginForm(props: LoginFormProps) {
  const { setToast } = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = async (values: LoginRequest) => {
    try {
      await props.onSubmit(values.email, values.password);
    } catch (err) {
      setToast({
        id: 'invalid_login_credentials',
        open: true,
        severity: 'error',
        message: `Invalid login credentials`,
      });
    }
  };
  const { register, formProps } = useForm({
    onSubmit,
    defaultValues: {
      email: '',
      password: '',
    },
    validationSchema,
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <form {...formProps}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email address"
          {...register('email')}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          {...register('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
        {props.bssoLink && (
          <Link variant="subtitle2" href={props.bssoLink}>
            BSSO Login
          </Link>
        )}
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={props.resetPasswordRoute}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={props.isSubmitting}
      >
        Login
      </LoadingButton>
    </form>
  );
}
