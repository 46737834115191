import { useMemo } from 'react';
import { FilterButtonMenuOption } from '@groundwater/shared-ui';
import { entriesFromRecord } from '../../../../utils/record-map';
import { BrandTypes } from '../../../../gql-generated';
import { BRAND_TYPE_LABELS } from '../../../constants';

import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface BrandTypeProps {
  selected: BrandTypes[];

  // TODO - rename onToggle
  onClick: (value: BrandTypes) => void;
}

export const BrandTypeFilter: React.FC<BrandTypeProps> = ({
  selected,
  onClick,
}) => {
  const menuOptions: FilterButtonMenuOption<BrandTypes>[] = useMemo(
    () =>
      entriesFromRecord(BRAND_TYPE_LABELS).map(([value, label]) => {
        return {
          label,
          value,
        };
      }),
    []
  );

  return (
    <List dense>
      <ListItem>
        <ListItemText id="brand-type-section" primary={'Brand Type'} />
      </ListItem>
      {menuOptions.map((option) => {
        return (
          <ListItem key={option.value} dense>
            <ListItemButton
              role={undefined}
              onClick={() => {
                onClick(option.value);
              }}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.indexOf(option.value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': option.value }}
                />
              </ListItemIcon>
              <ListItemText id={option.value} primary={option.label} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
