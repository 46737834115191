import React, { useEffect, useRef } from 'react';

export const useScrollIntoView = (
  isSelected: boolean
): React.RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!ref.current) {
      console.warn('ref not defined, not scrolling into view!');
      return;
    }
    if (!ref.current.scrollIntoView) {
      console.warn('scrollIntoView not implemented, not scrolling into view!');
      return;
    }
    if (isSelected)
      ref.current.scrollIntoView(
        // only scroll the overflow on the "popper", not the whole page
        // otherwise we would scroll the search input itself off of the page
        // or scroll the "compare by filter" off the top of the page!
        { block: 'nearest', inline: 'nearest' }
      );
  }, [isSelected, ref]);
  return ref;
};
