import { OvrAnnotations, OvrYSerieName } from '@groundwater/shared-ui';
import { format } from 'd3-format';
import moment from 'moment';

const dollarFormatter = (val: number) =>
  format('>-$,.4~s')(val).replace(/G/, 'B');
const percentFormatter = (val: number) => format('>-0.2~%')(val);

const getSerieColor = (serie: { name: any; data?: number[]; color: any }) => {
  if (serie.name === OvrYSerieName.ConsensusLow) {
    return '#B72136';
  } else {
    return serie.color;
  }
};

export function reportedRevenueTooltip(props: {
  w: {
    config: any;
    globals: { initialSeries: any; categoryLabels: { [x: string]: any } };
  };
  dataPointIndex: number;
  annotations: OvrAnnotations;
  scaledModel: boolean;
  seriesLabels: { [x: string]: any };
  yAxisValueFormat: 'percent' | 'dollar';
}) {
  const series = props.w.globals.initialSeries;
  const xSerieLabel = props.w.config.xaxis.categories[props.dataPointIndex];
  const consensus_range =
    props.annotations['consensus_range_vs_reported'][props.dataPointIndex];

  let text = '';
  series.forEach((serie: { name: string; data: number[]; color: string }) => {
    const formatter =
      props.yAxisValueFormat === 'dollar' ? dollarFormatter : percentFormatter;
    const value =
      serie.data[props.dataPointIndex] === undefined
        ? 'N/A'
        : formatter(Number(serie.data[props.dataPointIndex]));

    // Tooltip header
    if (!text.length) {
      text = `
          <table class="ovr-custom-tooltip">
            <tr>
              <th>${moment(xSerieLabel).format('MMM D, YYYY')}</th>
              <th>Revenue</th>
        `;
      if (props.scaledModel) {
        text += `<th>vs. Reported</th>`;
      }
      text += `</tr>`;
    }

    // Only render forecast_serie value for the last serie index
    if (
      serie.name === OvrYSerieName.ForecastSales &&
      props.dataPointIndex !== serie.data.length - 1
    ) {
      return;
    }
    // Do not render scaled_sales or reported_revenue values if they are null
    if (
      (serie.name === OvrYSerieName.ScaledSales ||
        serie.name === OvrYSerieName.ReportedRevenue) &&
      serie.data[props.dataPointIndex] === null
    ) {
      return;
    }
    // Tooltip body
    if (serie.name === OvrYSerieName.ConsensusLow) {
      // Create custom label for consensus low serie
      text += `
        <tr>
        <td class='name'><span style='background-color:#ffffff; border: 2px solid #CC2529'></span>${
          props.seriesLabels[serie.name]
        }</td>
        <td class='value'>${value}</td>
      `;
    } else {
      // Otherwise use the default Apex styles
      text += `
          <tr>
          <td class='name'><span style='background-color:${getSerieColor(
            serie
          )}'></span>${props.seriesLabels[serie.name]}</td>
          <td class='value'>${value}</td>
        `;
    }

    if (props.scaledModel) {
      let percent_difference_value;
      switch (serie.name) {
        case OvrYSerieName.ReportedRevenue:
          break;
        case OvrYSerieName.ConsensusLow:
          if (consensus_range?.[0])
            text += `<td>${percentFormatter(consensus_range[0])}</td>`;

          break;
        case OvrYSerieName.ConsensusHigh:
          if (consensus_range?.[1])
            text += `<td>${percentFormatter(consensus_range[1])}</td>`;

          break;
        case OvrYSerieName.ScaledSales:
          percent_difference_value =
            props.annotations['scaled_sales_vs_reported'][props.dataPointIndex];
          break;
        case OvrYSerieName.ConsensusMean:
          percent_difference_value =
            props.annotations['consensus_mean_vs_reported'][
              props.dataPointIndex
            ];
          break;

        default:
          break;
      }

      if (percent_difference_value) {
        text += `<td class='test'>${percentFormatter(
          percent_difference_value
        )}</td>`;
      }
    }

    text += `
        </td></tr>
      `;
  });

  return text + '</table>';
}
