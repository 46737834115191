import { variablesSchema } from '.';
import { REPORT_PAGES } from '../app/constants';
import { RawVariables } from '@groundwater/shared-ui';
import { Variables } from './reportPageConfigs';

/**
 * Validates the provided query params, defaulting any omitted params (used to consume the actual URL)
 *
 * - If a param is provided, it will override the default value for that param
 * - If any required params are missing and dont have defaults, it will throw an error
 * - If any param that is provided is invalid it will throw an error.
 * - If any required params are missing but have defaults, the default will be used
 * - If any extra params are supplied that don't apply to the page, it will strip them
 * - If any param is supplied an option that don't apply to the page, it will throw an error!
 * - Coerces wrong data types when possible
 * - mutual exclusion
 *
 * Why is this a type assertion? This is un-assignable due a
 * {@link  https://stackoverflow.com/a/61399457 | known limitation with higher level type params in Typescript}
 *
 * however, we know that:
 *
 * 1) `filterSchema` is annotated with the
 *  {@link https://www.typescriptlang.org/docs/handbook/2/mapped-types.html | mapped type}
 *  `{ [T in REPORT_PAGES]: SchemaOf<QueryFilters[T]> }`
 *
 * 2) `filterSchema[page]` is of type `SchemaOf<QueryFilters[T]>` since we're just indexing the mapped type
 *
 * 3) yup's {@link https://github.com/jquense/yup/blob/pre-v1/docs/typescript.md#typescript-support | SchemaOf}
 *    will ensure the yup schema conforms to the higher level type param `QueryFilters[T]`
 *
 * 4) accordingly, the return value of validateSync() will be QueryFilters[T]
 */
export const validateURLQuery = <T extends REPORT_PAGES>(
  page: T,
  currentURLquery: RawVariables
): Variables[T] => {
  const schema = variablesSchema[page];
  return Object.freeze(
    schema.validateSync(currentURLquery, { stripUnknown: true })
  ) as Variables[T];
};

/**
 * Used to validate a generated URL, requires perfect params or it return false.
 *
 * TODO - move this inside createURLQuery? have it return false if it cannot generate the URL?
 */
export const isValidURLQuery = <T extends REPORT_PAGES>(
  page: T,
  currentURLquery: RawVariables
): boolean => {
  const schema = variablesSchema[page];
  return schema.isValidSync(currentURLquery);
};
