import React from 'react';
import { Input, Stack } from '@mui/material';

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: any) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <Stack direction="column">
      <Input
        value={typeof filterValue[0] === 'number' ? filterValue[0] : ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val.length > 0 ? parseFloat(val) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min`}
      />
      <Input
        value={typeof filterValue[1] === 'number' ? filterValue[1] : ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val.length > 0 ? parseFloat(val) : undefined,
          ]);
        }}
        placeholder={`Max`}
      />
    </Stack>
  );
}
