import { useCallback, useMemo } from 'react';
import { reach } from 'yup';
import {
  FilterButtonMenu,
  FilterButtonMenuOption,
} from '@groundwater/shared-ui';
import { Alert, Box, Button, Grid, Stack } from '@mui/material';
import {
  BREAKOUT_BY_LABELS,
  DEFAULT_TRANSACTION_SPEND_VALUE,
  GlobalBreakoutByOption,
  ROLLUP_LABELS,
} from '../../constants';
import { BreakoutBy, ChannelsV2 } from '../../../gql-generated';
import {
  TransactionValueSelection,
  TransactionValueSelector,
} from './TransactionValueSelector';
import { entriesFromRecord } from '../../../utils/record-map';
import { VariableKeys, variablesSchema, VariablesUnion } from '../../../utils';
import { breakout_by_conflicts_rollup } from '../../../utils/reportPageHelpers/breakout_by_conflicts_rollup';
import { breakout_by_conflicts_filter } from '../../../utils/reportPageHelpers/breakout_by_conflicts_filter';
import { breakout_by_conflicts_compare_to } from '../../../utils/reportPageHelpers/breakout_by_conflicts_compare_to';
import { useReportPage } from '../../hooks/useReportPage';
import { GeoFilterControl } from './GeoFilter/GeoFilterControl';
import { useCompanyOrBrands } from '../../hooks';
import { environment } from '../../../environments/environment';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

const useConflicts = (draftParams: VariablesUnion) => {
  const breakoutByConflicts: boolean =
    breakout_by_conflicts_compare_to(draftParams);

  const rollupConflicts: boolean = breakout_by_conflicts_rollup(draftParams);

  const filterConflicts: boolean = breakout_by_conflicts_filter(draftParams);

  return {
    breakoutByConflicts,
    filterConflicts,
    rollupConflicts,
  };
};

export const BreakoutByFilter: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (
    // !(VariableKeys.bucket in draftParams) ||
    // !(VariableKeys.company_id in draftParams) ||
    !(VariableKeys.breakout_by in draftParams)
  ) {
    throw new Error(
      'Rendering <BreakoutbyFilter /> in a context where it is not supported'
    );
  }

  const page = useReportPage();

  // todo check all businesses being viewed, not just the main
  // const coercedQueryParams = useCoercedQueryParams(page);

  const companyOrBrands = useCompanyOrBrands(
    draftParams.company_id ? [draftParams.company_id] : []
  );

  const customerSpendFilterEnabled =
    environment.trends_customer_spend_filter_enabled;

  const sanitizeBreakoutByOptions = useCallback(
    (opts: [BreakoutBy, string][]) => {
      let allOptions = [...opts];
      /**
       * Customer Spend is an optional filter and is currently not supported by Koios.
       * However, the support for it could be implemented in the future. With that in mind we are wrapping this option in a feature flag.
       * The option could be enabled by setting TRENDS_CUSTOMER_SPEND_FILTER_ENABLED to true.
       */
      if (!customerSpendFilterEnabled) {
        allOptions = allOptions.filter(
          ([value]) => value !== BreakoutBy.CustomerSpend
        );
      }

      /**
       * Breaking out by brand type or brand only allowed for companies, not brands.
       */
      if (companyOrBrands[0]?.__typename !== 'Company') {
        allOptions = allOptions.filter(([value]) => value !== BreakoutBy.Brand);
      }

      return allOptions;
    },
    [companyOrBrands, customerSpendFilterEnabled]
  );

  const transactionValueOptionContent = useMemo(() => {
    return (
      <Grid container alignItems="top" rowGap={3}>
        <Grid item xs={12} rowGap={2}>
          <TransactionValueSelector
            buckets={draftParams.bucket}
            onBucketsChange={(bucket) => {
              handleDraftChange((prevParams) => ({ ...prevParams, bucket }));
            }}
          />
          <Box sx={{ my: 1 }} />
          <Stack direction="row" rowGap={1} spacing={1}>
            {draftParams.bucket !== '' && draftParams.bucket !== null ? (
              <Button
                variant="text"
                size="small"
                onClick={() =>
                  handleDraftChange((prevParams) => ({
                    ...prevParams,
                    bucket: null,
                  }))
                }
              >
                Clear all
              </Button>
            ) : null}
            {draftParams.bucket !== DEFAULT_TRANSACTION_SPEND_VALUE ? (
              <Button
                variant="text"
                size="small"
                onClick={() =>
                  handleDraftChange((prevParams) => ({
                    ...prevParams,
                    bucket: DEFAULT_TRANSACTION_SPEND_VALUE,
                  }))
                }
              >
                Reset to default
              </Button>
            ) : null}
          </Stack>
          <Box sx={{ my: 2 }} />
          <Stack direction="row" spacing={1} rowGap={2} flexWrap="wrap">
            <TransactionValueSelection
              buckets={draftParams.bucket}
              onBucketsChange={(bucket) => {
                handleDraftChange((prevParams) => ({ ...prevParams, bucket }));
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  }, [draftParams, handleDraftChange]);

  const { breakoutByConflicts, filterConflicts, rollupConflicts } =
    useConflicts(draftParams);

  const geoOptionContent = useMemo(
    () => (
      <GeoFilterControl
        value={draftParams.geos ?? []}
        onChange={(geos) =>
          handleDraftChange((prevParams) => ({ ...prevParams, geos }))
        }
      />
    ),
    [draftParams.geos, handleDraftChange]
  );

  const menuOptionContentSection = useMemo(() => {
    return (
      <Stack spacing={1}>
        {draftParams.breakout_by === BreakoutBy.Geo ? geoOptionContent : null}
        {[BreakoutBy.CustomerSpend, BreakoutBy.TransactionValue].includes(
          draftParams.breakout_by
        )
          ? transactionValueOptionContent
          : null}
        {/* prompt the user to reset their conflicting "compare by" selection, if applicable */}
        {filterConflicts ? (
          <Alert severity="error">
            'Filter By' channel conflicts with 'Break Out By' selection{' '}
            {BREAKOUT_BY_LABELS[draftParams.breakout_by]}. Adjust 'Break Out By'
            to supported option, or reset 'Filter By'. Filtering by channel
            cannot be combined with breaking out by{' '}
            {BREAKOUT_BY_LABELS[draftParams.breakout_by]}.
            <br />
            <Button
              onClick={() =>
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  filter_channels: Object.values(ChannelsV2),
                }))
              }
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Channel' to default
            </Button>
          </Alert>
        ) : null}
        {rollupConflicts ? (
          <Alert severity="error">
            Selected 'Break Out By' conflicts with 'Roll Up'{' '}
            {ROLLUP_LABELS[draftParams.rollup]}. Adjust 'Roll Up' to supported
            option, or reset selection.
            <br />
            <Button
              onClick={() =>
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  rollup: reach(variablesSchema[page], 'rollup').getDefault(),
                }))
              }
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Roll Up' to default
            </Button>
          </Alert>
        ) : null}
        {breakoutByConflicts ? (
          <Alert severity="error">
            Can't combine 'Breakout By' with 'Compare To' unless breaking out by
            a single geographical area. Adjust your 'Break Out By' selection to
            contain 1 geographical area, or reset selection.
            <br />
            <Button
              onClick={() => {
                handleDraftChange((prevParams) => ({
                  ...prevParams,
                  compare_by_company_ids: [],
                }));
              }}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset 'Compare To' to default
            </Button>
          </Alert>
        ) : null}
      </Stack>
    );
  }, [
    draftParams.breakout_by,
    draftParams.rollup,
    geoOptionContent,
    transactionValueOptionContent,
    filterConflicts,
    rollupConflicts,
    breakoutByConflicts,
    handleDraftChange,
    page,
  ]);

  const allOptions = useMemo(
    () =>
      entriesFromRecord(BREAKOUT_BY_LABELS).filter(([value]) =>
        reach(variablesSchema[page], VariableKeys.breakout_by).isValidSync(
          value
        )
      ),
    [page]
  );

  const options = useMemo(
    () => sanitizeBreakoutByOptions(allOptions),
    [allOptions, sanitizeBreakoutByOptions]
  );

  const menuOptions: FilterButtonMenuOption<GlobalBreakoutByOption>[] =
    options.map(([value, label]) => {
      return {
        label,
        value,
        disabled: false,
      };
    });

  const getLabel = useMemo(() => {
    return draftParams.breakout_by === BreakoutBy.None
      ? `Break Out By`
      : `Break Out By: ${BREAKOUT_BY_LABELS[draftParams.breakout_by]}`;
  }, [draftParams.breakout_by]);

  const isError = breakoutByConflicts || filterConflicts || rollupConflicts;

  return (
    <FilterButtonMenu
      isError={isError}
      id="compare-by-filter"
      filterLabel={getLabel}
      menuOptions={menuOptions}
      // TODO - why is this inferred as any (without the annotation)? can we do better?
      onClick={(breakout_by: GlobalBreakoutByOption) => {
        handleDraftChange((prevParams) => ({ ...prevParams, breakout_by }));
      }}
      selected={draftParams.breakout_by}
      menuOptionContentSection={menuOptionContentSection}
    />
  );
};
