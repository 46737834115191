import { isEqual } from 'lodash-es';
import { BreakoutBy, ChannelsV2 } from '../../gql-generated';
import { VariableKeys, VariablesUnion } from '../reportPageConfigs';

/** @returns boolean is a conflict? */
export const breakout_by_conflicts_filter = (params: VariablesUnion) => {
  if (!(VariableKeys.filter_channels in params)) return false;
  if (!(VariableKeys.breakout_by in params)) return false;
  const { filter_channels, breakout_by } = params;

  // cannot filter by channel + breakout by [channel, geo, or veterancy]
  const isFilteringByChannel = !isEqual(
    new Set(filter_channels),
    new Set(Object.values(ChannelsV2))
  );
  return (
    isFilteringByChannel &&
    VariableKeys.breakout_by in params &&
    [BreakoutBy.Channel, BreakoutBy.Geo, BreakoutBy.Veterancy].includes(
      breakout_by
    )
  );
};
