/* eslint-disable react/jsx-no-useless-fragment */
import { DefaultErrorBoundary } from '@groundwater/shared-ui';
import { Box } from '@mui/material';
import moment from 'moment';
import { useGetLastUpdatedDateQuery } from '../../../gql-generated';
import { OpenPanelAnnouncement } from '../../components/OpenPanelAnnouncement';
import { PrivateLayout } from '../../layouts/PrivateLayout';
import 'moment-timezone';

export const HomePage: React.FC<{}> = () => {
  return (
    <PrivateLayout page_title="Home">
      <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
        <OpenPanelAnnouncement />
      </Box>
      <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
        Welcome. Please search for a company above to start viewing data from
        Bloomberg Second Measure!
        <DefaultErrorBoundary fallbackRender={() => <></>}>
          <LastUpdated />
        </DefaultErrorBoundary>
      </Box>
    </PrivateLayout>
  );
};

const LastUpdated: React.FC<{}> = () => {
  const result = useGetLastUpdatedDateQuery();
  console.log(result);
  if (!result.data?.last_updated_date) return null;
  return (
    <>
      {' '}
      Last update{' '}
      {moment
        .utc(result.data.last_updated_date)
        .tz(moment.tz.guess())
        .format('MMM D, hh:mma zz')}
    </>
  );
};
