import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

export const Popper: React.FC<{
  children: React.ReactElement;
  anchorEl: null | HTMLElement;

  /**
   * Trigger PopperJS update when the values in this array changes, see
   * https://popper.js.org/docs/v2/modifiers/event-listeners/#when-the-reference-element-moves-or-changes-size
   */
  forceUpdate?: number[];
}> = ({ forceUpdate = [], anchorEl, children }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(anchorEl, popperElement, {
    placement: 'bottom-start',
  });

  /**
   * Trigger PopperJS update when the values in this array changes, see
   * https://popper.js.org/docs/v2/modifiers/event-listeners/#when-the-reference-element-moves-or-changes-size
   */
  useEffect(() => {
    update && update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...forceUpdate, update]);

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ref={setPopperElement}
      {...attributes['popper']}
      style={{ ...styles['popper'] }}
    >
      {children}
    </div>
  );
};
