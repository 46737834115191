import { Default404 } from '@groundwater/shared-ui';
import { PublicLayout } from '../../layouts/PublicLayout';

export const NotFoundPage: React.FC<{}> = () => {
  return (
    <PublicLayout redirectHome={false}>
      <Default404 />
    </PublicLayout>
  );
};
