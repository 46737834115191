import { styled, TableCell, TableCellProps } from '@mui/material';

interface FixedTableCell extends TableCellProps {
  minWidth: number;
  left: number;
}
export const FixedTableCell: React.FC<FixedTableCell> = ({
  children,
  minWidth,
  left,
  ...cellProps
}) => {
  const StyledCell = styled(TableCell)(({ theme }) => ({
    position: 'sticky',
    left,
    background: theme.palette.background.paper,
    minWidth,
  }));
  return (
    <StyledCell component="th" scope="row" {...cellProps}>
      {children}
    </StyledCell>
  );
};
