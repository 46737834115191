import { end_date_schema, start_date_schema } from '@groundwater/shared-util';
import { array, mixed, object, SchemaOf, number, string } from 'yup';
import {
  COHORT_VIEW,
  DEFAULT_TRANSACTION_SPEND_VALUE,
  REPORT_PAGES,
} from '../app/constants';
import {
  BrandTypes,
  BreakoutBy,
  BrowseBusinessesRequest,
  BusinessType,
  ChannelsV2,
  CohortedRetentionRequestV2,
  CohortedRetentionRollup,
  CohortIndex,
  DollarRetentionRequest,
  GrowthAccountingRequest,
  GrowthAccountingRollup,
  OvrRequest,
  OvrScalingModel,
  OvrTargetDimension,
  QpsRequest,
  QpsSelectedQuarter,
  QtdRequest,
  QtdScalingModel,
  RetentionSummaryRequest,
  RetentionSummaryRollup,
  TransactionValuesRequest,
  TrendsRollup,
  TrendsTargetDimension,
  TrendsV3Request,
} from '../gql-generated';
import {
  default_rule,
  geo_rule,
} from './reportPageHelpers/breakout_by_conflicts_compare_to';
import { breakout_by_conflicts_rollup } from './reportPageHelpers/breakout_by_conflicts_rollup';
import { rollup_conflicts_compare_by } from './reportPageHelpers/rollup_conflicts_compare_by';
import { breakout_by_conflicts_filter } from './reportPageHelpers/breakout_by_conflicts_filter';
import {
  parseBuckets,
  validateRange,
  validationRegex,
} from './transactionValueValidationUtils';

export const COMPARE_BY_LIMIT = 30;

export enum VariableKeys {
  company_id = 'company_id',
  bucket = 'bucket',
  filter_channels = 'filter_channels',
  compare_by_company_ids = 'compare_by_company_ids',
  end_date = 'end_date',
  start_date = 'start_date',
  target_dimension = 'target_dimension',
  rollup = 'rollup',
  breakout_by = 'breakout_by',
  scaling_model = 'scaling_model',
  scaling_model_custom_percent = 'scaling_model_custom_percent',
  analysis_quarter = 'analysis_quarter',
  comparison_quarter = 'comparison_quarter',
  selected_quarter = 'selected_quarter',
  businesses_type = 'business_type',
  cohort_view = 'cohort_view',
  cohort_index = 'cohort_index',
  xaxis = 'xaxis',
  y_zoom = 'y_zoom',
  transaction_value_min = 'transaction_value_min',
  transaction_value_max = 'transaction_value_max',
  geos = 'geos',
  filter_brand_types = 'filter_brand_types',
}

type FakeBool = 'true' | 'false';

export type VariablesUnion =
  | Variables[REPORT_PAGES.qps]
  | Variables[REPORT_PAGES.qtd]
  | Variables[REPORT_PAGES.ovr]
  | Variables[REPORT_PAGES.trends]
  | Variables[REPORT_PAGES.browse_businesses]
  | Variables[REPORT_PAGES.transaction_values]
  | Variables[REPORT_PAGES.retention_summary]
  | Variables[REPORT_PAGES.growth_accounting]
  | Variables[REPORT_PAGES.cohorted_retention]
  | Variables[REPORT_PAGES.dollar_retention];

/** Maps the page URL to the type for the variables */
export interface Variables {
  [REPORT_PAGES.qps]: QpsRequest;
  [REPORT_PAGES.qtd]: QtdRequest & {
    [VariableKeys.y_zoom]: FakeBool;
  };
  [REPORT_PAGES.ovr]: OvrRequest & {
    [VariableKeys.y_zoom]: FakeBool;
  };
  [REPORT_PAGES.trends]: TrendsV3Request & {
    [VariableKeys.y_zoom]: FakeBool;
  };
  [REPORT_PAGES.browse_businesses]: BrowseBusinessesRequest;
  [REPORT_PAGES.transaction_values]: TransactionValuesRequest & {
    // UI-only params
    [VariableKeys.transaction_value_min]: number | null;
    [VariableKeys.transaction_value_max]: number | null;
  };
  [REPORT_PAGES.retention_summary]: RetentionSummaryRequest & {
    [VariableKeys.y_zoom]: FakeBool;
  };
  [REPORT_PAGES.cohorted_retention]: CohortedRetentionRequestV2 & {
    // UI-only parameters that are ommited from the graphQL request
    cohort_view: COHORT_VIEW;
    [VariableKeys.y_zoom]: FakeBool;
  };
  [REPORT_PAGES.dollar_retention]: DollarRetentionRequest & {
    // UI-only parameters that are ommited from the graphQL request
    cohort_view: COHORT_VIEW;
    [VariableKeys.y_zoom]: FakeBool;
  };
  [REPORT_PAGES.growth_accounting]: GrowthAccountingRequest;
}

function validateBucket(
  bucket: string | null,
  cardinality: 'single' | 'multiple'
) {
  if (!bucket) return true;
  let isValid = true;
  const parsedBuckets = parseBuckets(bucket);
  if (cardinality === 'single' && parsedBuckets.length !== 1) {
    return false;
  }
  for (const parsedBucket of parsedBuckets) {
    if (
      !parsedBucket ||
      !validateRange(parsedBucket) ||
      !validationRegex.test(parsedBucket)
    ) {
      isValid = false;
    }
  }
  return isValid;
}

export const commonSchema = {
  [VariableKeys.company_id]: string().required(),
  [VariableKeys.bucket]: string()
    .default('')
    .optional()
    .nullable()
    .test('validate-bucket', 'invalid buckets', (value) => {
      return validateBucket(value ?? null, 'single');
    }),
  [VariableKeys.filter_channels]: array()
    .of(mixed().oneOf(Object.values(ChannelsV2)).required())
    .default(Object.values(ChannelsV2))
    //  a validation function must return true when the current value is valid and false or a ValidationError otherwise.
    .test(
      'channel-filter-mutually-exclusive-with-breakout-by-channel',
      'Channel filter is mutually exclusive with breakout by channel/geo',
      (_: void, { parent }: { parent: VariablesUnion }) => {
        return !breakout_by_conflicts_filter(parent);
      }
    ),
  [VariableKeys.compare_by_company_ids]: array()
    .of(string().required())
    .max(COMPARE_BY_LIMIT)
    .test(
      'rollup-conflicts-compare-by',
      '"Fiscal quarter" rollup conflicts with compare by',
      (_, { parent }: { parent: VariablesUnion }) => {
        if (!parent) return true;
        return !rollup_conflicts_compare_by(parent);
      }
    )
    .test(
      'breakout-by-conflicts-rollup',
      'Breakout by conflicts with rollup',
      (_, { parent }: { parent: VariablesUnion }) => {
        if (!parent) return true;
        return !breakout_by_conflicts_rollup(parent);
      }
    )
    .default([])
    .required(),
  [VariableKeys.geos]: array().of(string().required()).default([]),
  [VariableKeys.start_date]: start_date_schema.optional(),
  [VariableKeys.end_date]: end_date_schema.optional(),
  [VariableKeys.scaling_model_custom_percent]: number()
    .moreThan(0, 'value must be greater than 0')
    .max(100, 'value must be less than or equal to 100')
    .typeError('value must be a `number` type')
    .default(1)
    .required(),
  [VariableKeys.y_zoom]: mixed().oneOf(['true', 'false']).default('false'),
};

/** Maps the page URL to the Yup schema used to validate/coerce the variables */
export const variablesSchema: { [T in REPORT_PAGES]: SchemaOf<Variables[T]> } =
  {
    [REPORT_PAGES.qps]: object({
      [VariableKeys.selected_quarter]: mixed()
        .oneOf(Object.values(QpsSelectedQuarter))
        .default(QpsSelectedQuarter.CurrentQuarter),
    }),
    [REPORT_PAGES.qtd]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.analysis_quarter]: string().default(''),
      [VariableKeys.comparison_quarter]: string().default(''),
      [VariableKeys.scaling_model]: mixed()
        .oneOf(Object.values(QtdScalingModel))
        .default(QtdScalingModel.BestFit),
      [VariableKeys.scaling_model_custom_percent]:
        commonSchema[VariableKeys.scaling_model_custom_percent],
      [VariableKeys.y_zoom]: commonSchema[VariableKeys.y_zoom],
    }),
    [REPORT_PAGES.ovr]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.scaling_model]: mixed()
        .oneOf(Object.values(OvrScalingModel))
        .default(OvrScalingModel.BestFit),
      // "view"
      [VariableKeys.target_dimension]: mixed()
        .oneOf(Object.values(OvrTargetDimension))
        .default(OvrTargetDimension.ObservedVolume),
      [VariableKeys.y_zoom]: commonSchema[VariableKeys.y_zoom],
    }),
    [REPORT_PAGES.trends]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.bucket]: string()
        .default(DEFAULT_TRANSACTION_SPEND_VALUE)
        .optional()
        .nullable()
        .test('validate-bucket', 'invalid buckets', (value) => {
          return validateBucket(value, 'multiple');
        }),
      [VariableKeys.filter_channels]:
        commonSchema[VariableKeys.filter_channels],
      [VariableKeys.compare_by_company_ids]:
        commonSchema[VariableKeys.compare_by_company_ids],
      [VariableKeys.geos]: commonSchema[VariableKeys.geos],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.breakout_by]: mixed()
        .oneOf(Object.values(BreakoutBy))
        .test(
          'breakout-by-geo-with-channel-not-supported',
          'Break out by geo in combination with channel is currently not supported',
          (_, { parent }: { parent: VariablesUnion }) => {
            if (!parent) return true;
            return !breakout_by_conflicts_filter(parent);
          }
        )
        .test(
          'no-breakout-by-with-compare-to-rule',
          'Breakout not supported for multiple businesses.',
          (_, { parent }: { parent: VariablesUnion }) => {
            if (!parent) return true;
            return !default_rule(parent);
          }
        )
        .test(
          'breakout-by-geo-with-trailing-rollup-not-supported',
          'Break out by geo in combination with trailing rollup is currently not supported',
          (_, { parent }: { parent: VariablesUnion }) => {
            if (!parent) return true;
            return !breakout_by_conflicts_rollup(parent);
          }
        )
        .test(
          'no-compare-by-with-multiple-geos-rule',
          'Cannot compare multiple companies and multiple geographic areas.',
          (_, { parent }: { parent: VariablesUnion }) => {
            if (!parent) return true;
            return !geo_rule(parent);
          }
        )
        .default(BreakoutBy.None)
        .optional(),
      [VariableKeys.filter_brand_types]: array()
        .of(mixed().oneOf(Object.values(BrandTypes)).required())
        .default(Object.values(BrandTypes))
        .optional(),
      [VariableKeys.rollup]: mixed()
        .oneOf(Object.values(TrendsRollup))
        .default(TrendsRollup.Month)
        .test(
          'rollup-conflicts-compare-by',
          '"Fiscal quarter" rollup conflicts with compare by',
          (_, { parent }: { parent: VariablesUnion }) => {
            if (!parent) return true;
            return !rollup_conflicts_compare_by(parent);
          }
        )
        .test(
          'rollup-conflicts-breakout-by',
          '"Trailing" rollups conflicts with breakout by "geographic areas"',
          (_, { parent }: { parent: VariablesUnion }) => {
            if (!parent) return true;
            return !breakout_by_conflicts_rollup(parent);
          }
        ),
      // "view"
      [VariableKeys.target_dimension]: mixed()
        .oneOf(Object.values(TrendsTargetDimension))
        .default(TrendsTargetDimension.ObservedVolume),
      [VariableKeys.y_zoom]: commonSchema[VariableKeys.y_zoom],
    }),
    [REPORT_PAGES.browse_businesses]: object({
      [VariableKeys.businesses_type]: mixed()
        .oneOf(Object.values(BusinessType))
        .default(BusinessType.Brand),
    }),
    [REPORT_PAGES.transaction_values]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.transaction_value_min]: number()
        .nullable()
        .default(null)
        // convert empty string from URLs into NULL
        .transform(function (value: unknown, originalValue: unknown) {
          return typeof originalValue === 'string' &&
            originalValue.trim() === ''
            ? null
            : value;
        }),
      [VariableKeys.transaction_value_max]: number()
        .nullable()
        .default(null)
        // convert empty string from URLs into NULL
        .transform(function (value: unknown, originalValue: unknown) {
          return typeof originalValue === 'string' &&
            originalValue.trim() === ''
            ? null
            : value;
        }),
      [VariableKeys.filter_brand_types]: array()
        .of(mixed().oneOf(Object.values(BrandTypes)).required())
        .default(Object.values(BrandTypes))
        .optional(),
    }),
    [REPORT_PAGES.cohorted_retention]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.rollup]: mixed()
        .oneOf(Object.values(CohortedRetentionRollup))
        .default(CohortedRetentionRollup.Month),
      [VariableKeys.cohort_view]: mixed()
        .oneOf(Object.values(COHORT_VIEW))
        .default(COHORT_VIEW.Table),
      [VariableKeys.xaxis]: mixed()
        .oneOf(Object.values(CohortIndex))
        .default(CohortIndex.Cohort),
      [VariableKeys.bucket]: commonSchema[VariableKeys.bucket],
      [VariableKeys.y_zoom]: commonSchema[VariableKeys.y_zoom],
    }),
    [REPORT_PAGES.dollar_retention]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.rollup]: mixed()
        .oneOf(Object.values(CohortedRetentionRollup))
        .default(CohortedRetentionRollup.Month),
      [VariableKeys.cohort_view]: mixed()
        .oneOf(Object.values(COHORT_VIEW))
        .default(COHORT_VIEW.Table),
      [VariableKeys.xaxis]: mixed()
        .oneOf(Object.values(CohortIndex))
        .default(CohortIndex.Cohort),
      [VariableKeys.bucket]: commonSchema[VariableKeys.bucket],
      [VariableKeys.y_zoom]: commonSchema[VariableKeys.y_zoom],
    }),
    [REPORT_PAGES.retention_summary]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.compare_by_company_ids]:
        commonSchema[VariableKeys.compare_by_company_ids],
      [VariableKeys.rollup]: mixed()
        .oneOf(Object.values(RetentionSummaryRollup))
        .default(RetentionSummaryRollup.Month),
      [VariableKeys.y_zoom]: commonSchema[VariableKeys.y_zoom],
    }),
    [REPORT_PAGES.growth_accounting]: object({
      [VariableKeys.company_id]: commonSchema[VariableKeys.company_id],
      [VariableKeys.end_date]: commonSchema[VariableKeys.end_date],
      [VariableKeys.start_date]: commonSchema[VariableKeys.start_date],
      [VariableKeys.rollup]: mixed()
        .oneOf(Object.values(GrowthAccountingRollup))
        .default(GrowthAccountingRollup.Month),
    }),
  };
