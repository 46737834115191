import isNil from 'lodash-es/isNil';
import moment from 'moment';
import { createContext, useContext } from 'react';
import { useGetLatestProductionDateQuery } from '../../gql-generated';

export interface LatestProducitonDateContextValue {
  date: Date;
}

export const latestProductionDateContext =
  createContext<LatestProducitonDateContextValue | null>(null);

interface LatestProducitonDateProviderProps {
  children: React.ReactNode;
}

export function ProvideLatestProductionDate({
  children,
}: LatestProducitonDateProviderProps) {
  const res = useGetLatestProductionDateQuery();

  if (isNil(res.data)) {
    throw new Error('Failed to fetch latest production date');
  }

  return (
    <latestProductionDateContext.Provider
      value={{
        date: moment(res.data.latest_production_date.date).toDate(),
      }}
    >
      {children}
    </latestProductionDateContext.Provider>
  );
}

/**
 * Hook for child components to get the latest production date object and re-render when it changes.
 */
export const useLatestProductionDate = () => {
  const productionDate = useContext(latestProductionDateContext);
  if (!productionDate)
    throw new Error(
      'Tried to consume latestProductionDate context. Wrap your component in a <ProvideLatestProductionDate />'
    );
  return productionDate;
};
