import { Card, Grid, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useMemo } from 'react';
import { Channel, useBusinessMetadataQuery } from '../../../gql-generated';
import { VariableKeys } from '../../../utils';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import { useReportPage } from '../../hooks/useReportPage';
import { BRAND_TYPE_LABEL } from '../../constants';
import { BusinessTypeSection } from './BusinessTypeSection';
import { capitalize } from 'lodash-es';
import { BusinessLogo } from '@groundwater/shared-ui';
import { environment } from '../../../environments/environment';

const BusinessMetadataStack = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '& > :not(first-child)': {
    // adds space between sections
    marginRight: theme.spacing(1.5),
  },
  flexDirection: 'row',
  '& > :first-child': {
    // align business logo in the center
    alignSelf: 'center',
  },
}));

export const BusinessMetadata: React.FC<{}> = () => {
  const params = useCoercedQueryParams(useReportPage());
  const ids = useMemo(() => {
    const ids = [];
    if (VariableKeys.company_id in params) {
      ids.push(params[VariableKeys.company_id]);
    }
    if (VariableKeys.compare_by_company_ids in params) {
      const compare_by_company_ids =
        params[VariableKeys.compare_by_company_ids];
      ids.push(...compare_by_company_ids);
    }
    return ids;
  }, [params]);
  const result = useBusinessMetadataQuery({ ids });

  if (!result.data) throw new Error('data missing');

  return (
    <Grid container spacing={{ xs: 2, sm: 2, md: 3 }}>
      {result.data.get_brands_or_companies.map((business) => {
        return (
          <Grid
            item
            key={business.id}
            xs={12}
            // if there is only 1 item, make it full width, otherwise if multiple items go 50% width
            md={result.data.get_brands_or_companies.length > 1 ? 6 : 12}
          >
            <Card sx={{ /*mt: 2, mb: 2,*/ p: 2 }}>
              <BusinessMetadataStack>
                <BusinessLogo
                  business={business}
                  width={50}
                  height={50}
                  api_url={environment.api_url}
                />
                <Stack direction="column" sx={{ width: '40%', flexShrink: 0 }}>
                  <Stack direction="row" flexWrap="wrap" alignItems="baseline">
                    <Typography variant="subtitle2">
                      {business.title} &nbsp;
                    </Typography>
                    <Typography variant="caption">
                      {business.__typename === 'Brand'
                        ? business.ultimate_parent?.title
                        : null}
                    </Typography>
                  </Stack>
                  {business.channels.length > 0 ? (
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="baseline"
                    >
                      <Typography variant="subtitle2">
                        Channels: &nbsp;
                      </Typography>
                      <Typography variant="caption">
                        {business.channels.map(capitalize).join(', ')}
                      </Typography>
                    </Stack>
                  ) : null}
                  {business.website ? (
                    <Typography variant="caption">
                      <Link href={business.website} target="_blank">
                        {business.website}
                      </Link>
                    </Typography>
                  ) : null}
                  {business.__typename === 'Company' && business.ticker ? (
                    <Typography variant="body2">
                      {[business.exchange, business.ticker].join(':')}
                    </Typography>
                  ) : null}
                  {business.__typename === 'Brand' ? (
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="baseline"
                    >
                      <Typography variant="subtitle2">
                        Brand type: &nbsp;
                      </Typography>
                      <Typography variant="caption">
                        {BRAND_TYPE_LABEL[business.type]}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>

                {business.__typename === 'Company' ? (
                  <BusinessTypeSection brands={business.child_brands} />
                ) : null}
              </BusinessMetadataStack>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
