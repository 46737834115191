import { useState, useMemo, useCallback } from 'react';
import { useDebounce } from '../../../';
import {
  SearchBrandFragment,
  SearchCompanyFragment,
  SearchQuery,
  useSearchQuery,
} from '../../../gql-generated';
import { BrandOrCompany } from '../types';
import { IsSelected } from '../types';
import { UseQueryResult } from 'react-query';

export const useBusinessSearch = ({
  doHandleSelect,
  inputRef,
  hiddenIds,
}: {
  doHandleSelect: (i: BrandOrCompany) => void;
  inputRef: React.RefObject<HTMLInputElement | undefined>; // allows this hook to focus the input after clearing
  hiddenIds?: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>();

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    setSelectedIndex(0);
    setIsOpen(true);
    setQuery(event.currentTarget.value);
  };

  const debouncedQuery = useDebounce(query, 200);
  const searchResult = useSearchQuery(
    { query: debouncedQuery ?? '' },
    { enabled: !!debouncedQuery, suspense: false, keepPreviousData: true }
  );

  /**
   * If hiddenIds are passed, we will filter the search results.
   * This is for the use case of the CompareToFilter.
   *
   * TODO - there is an unhandled edge case if a brand is hidden (because it is selected)
   * we don't disable the parent company from being selected. For example if someone
   * compares "Apple, Inc." to "Apple app store", it will allow them to do this in some cases
   * which could produce a nonsensical analysis. Options to solve this are to 1) disable the
   * parent from being selected if a brand is already selected; or 2) unselect the brand
   * for them if they select a parent
   */
  const filteredSearchResult: UseQueryResult<SearchQuery> = useMemo(() => {
    if (!hiddenIds || !searchResult.data?.search) {
      return searchResult;
    }
    return {
      ...searchResult,
      data: {
        ...searchResult.data,
        search: searchResult.data.search
          .filter(({ company }) => !hiddenIds.includes(company.id))
          .map((result) => ({
            ...result,
            brands: result.brands.filter(
              (brand) => !hiddenIds.includes(brand.id)
            ),
          })),
      },
    };
  }, [hiddenIds, searchResult]);

  const flatResult: (SearchCompanyFragment | SearchBrandFragment)[] =
    useMemo(() => {
      // Store the flattened list of brand and company IDs for the arrow keys functionality
      const flatResult = [];
      for (const result of filteredSearchResult?.data?.search ?? []) {
        flatResult.push(result.company);
        for (const brand of result.brands) {
          flatResult.push(brand);
        }
      }
      return flatResult;
    }, [filteredSearchResult?.data?.search]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    /**
     * Stop propogation stops event bubbling, because when mounted inside certain
     * MUI Menu or something within the CompareByFilter, the arrow keys will move
     * focus away from the input and into the "clean" button (end adornment)
     */
    event.stopPropagation();

    /**
     * Because we stop propogation, it will not bubble, and command+f
     * will enter the browser's native search, however our intent is to override
     * command+f to use our search. if the search is already open when the user hits it
     * prevent the native browser search from stealing the focus
     */
    if (event.metaKey && event.key === 'f') {
      event.preventDefault();
    }

    switch (event.key) {
      case 'Enter': {
        const item = flatResult[selectedIndex];
        if (!item) return;

        handleSelect(item);
        break;
      }
      case 'ArrowUp':
        setSelectedIndex((index) => {
          if (index === 0) {
            return flatResult.length - 1;
          }
          return index - 1;
        });
        break;
      case 'ArrowDown':
        setSelectedIndex((index) => {
          if (index >= flatResult.length - 1) {
            return 0;
          }
          return index + 1;
        });
        break;
      case 'Escape':
        setIsOpen(false);
        break;
    }
  };

  const handleSelect = useCallback(
    (brandOrCompany: BrandOrCompany) => {
      setIsOpen(false);
      setQuery(undefined);
      doHandleSelect(brandOrCompany);
    },
    [doHandleSelect]
  );

  const isSelected: IsSelected = useCallback(
    (brandOrCompany: BrandOrCompany) => {
      const selectedItem =
        selectedIndex >= 0 ? flatResult[selectedIndex] : undefined;
      if (!selectedItem) {
        return false;
      }
      return selectedItem.id === brandOrCompany.id;
    },
    [flatResult, selectedIndex]
  );

  const clearInput = useCallback(() => {
    // set to empty string, so the user can actually clear the input
    // if it were instead set to undefined, it would show the selected company
    // even after the user clicked clear
    setQuery('');
    if (!inputRef.current) {
      console.warn('inputRef not set, not focusing input!');
      return;
    }
    inputRef.current.focus();
  }, [inputRef]);

  return {
    query,
    isSelected,
    handleSelect,
    handleKeyDown,
    searchResult: filteredSearchResult,
    handleInputChange,
    isOpen,
    setIsOpen,
    clearInput,
  };
};
