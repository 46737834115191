import { upperFirst } from 'lodash-es';
import consensusIcon from '../../assets/ConsensusIcon.svg';
import { QTDChartGlobalConfig } from './qtdChartUtils';

export function qtdGrowthTooltip(props: {
  w: QTDChartGlobalConfig;
  dataPointIndex: string | number;
  revenue_estimate_growth_low: number;
  revenue_estimate_growth_high: number;
  consensus_growth_low: number;
  consensus_growth_high: number;
}) {
  const series = props.w.globals.initialSeries;

  const valueFormat = props.w.config.tooltip.y.formatter;

  const revenueEstimateInterval = `${valueFormat(
    props.revenue_estimate_growth_low
  )} - ${valueFormat(props.revenue_estimate_growth_high)}`;

  const consensusGrowthInterval = `${valueFormat(
    props.consensus_growth_low
  )} - ${valueFormat(props.consensus_growth_high)}`;

  // Header
  let text = `
    <table class="qtd-custom-tooltip">
      <tr>
        <th></th>
        <th>% Growth</th>
        <th>Prediction Interval</th>
      </tr>
  `;

  series.forEach(
    (serie: { data: { [x: string]: any }; name: string; color: string }) => {
      if (!serie.data[props.dataPointIndex]) {
        return;
      }
      // Derive the serie name from the Apex config.
      // The serie definition could be located in https://github.com/secondmeasure/groundwater/blob/14e5d37acf9284d19b7fbafebed9f08344e3aa33/apps/api/src/modules/qtd/qtd-schema.graphql#L45
      const serieName = upperFirst(serie.name.split('_').join(' '));
      const value = valueFormat(serie.data[props.dataPointIndex]);

      if (serie.name === 'consensus') {
        text += `<tr>
            <td class='title'><img src='${consensusIcon}'></img> Consensus</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'>${consensusGrowthInterval}</td>
          </tr>
        `;
      }

      // at point where observed series swaps to projected series, only show observed series in legend
      if (
        serie.name === 'projected_growth' &&
        series.find(
          (serie: { name: string }) => serie.name === 'observed_growth'
        ).data[props.dataPointIndex] === null
      ) {
        text += `<tr>
            <td class='name'><span style='background-color:${serie.color}'></span>${serieName}</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'>${revenueEstimateInterval}</td>
          </tr>
        `;
      }

      if (serie.name === 'observed_growth') {
        text += `<tr>
            <td class='name'><span style='background-color:${serie.color}'></span>${serieName}</td>
            <td class='value'>${value}</td>
            <td class='percent_diff'></td>
          </tr>
        `;
      }
    }
  );
  return text;
}
