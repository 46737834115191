import { Box, styled } from '@mui/material';
import { ApexChart } from './ApexChart';

export const ChartActionContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}));

export const StyledApexChart = styled(ApexChart)(({ theme }) => ({
  // chart tooltip overrides
  '& .apexcharts-tooltip.apexcharts-theme-light': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: 0,
    boxShadow: theme.shadows[10],
    borderRadius: '12px',
  },
  // legend overrides.
  // This is needed because the default `toggleDataSeries` event is turned off hense adding `.apexcharts-no-click`.
  // The default toggle event is disabled in order to allow synchronizing the legend toggling throughout all the charts on the page.
  '& .apexcharts-legend-series.apexcharts-no-click': {
    cursor: 'pointer',
  },
  // x-axis tooltip overrides
  '& .apexcharts-canvas .apexcharts-xaxistooltip': {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: 0,
    color: theme.palette.grey[800],
    boxShadow: theme.shadows[10],
    borderRadius: '12px',
    padding: '9px 10px',
    pointerEvents: 'none',
    fontSize: '13px',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 10,

    '& :before': {
      borderBottomColor: 'transparent',
      borderColor: 'rgba(144, 164, 174, 0)',
      borderWidth: '7px',
      marginLeft: '-7px',
      left: '50%',
      border: 'solid transparent',
      content: "''",
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
    },

    '& :after': {
      borderBottomColor: 'rgba(255, 255, 255, 0.8)',
      bottom: '100%',
      borderColor: 'rgba(236, 239, 241, 0)',
      borderWidth: '6px',
      marginLeft: '-6px',
    },

    '.apexcharts-xaxistooltip-text': {
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  '& .apexcharts-xaxistooltip-bottom:before': {
    borderBottomColor: 'transparent',
  },
}));
