import { useState } from 'react';
import {
  Button,
  ClickAwayListener,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {
  addOneDay,
  DateRange,
  generateDateRangeLabel,
  isShortcutSelected,
  isValidURLQuery,
  subtractOneDay,
  VariableKeys,
} from '../../../utils';
import { Menu } from '@groundwater/shared-ui';
import { DATE_SHORTCUT_LABELS } from '../../constants';
import { useCoercedQueryParams } from '../../hooks/useCoercedQueryParams';
import { useReportPage } from '../../hooks/useReportPage';
import { useLatestProductionDate } from '../../hooks/useLatestProductionDate';
import { Check } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import Box from '@mui/system/Box';
import {
  DATE_SHORTCUT_KEYS,
  DEFAULT_DATE_FORMAT,
  MIN_START_DATE,
} from '@groundwater/shared-util';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

export const DateRangeFilter: React.FC<{}> = () => {
  const { handleDraftChange, draftParams } = useFilterContext(useReportPage());

  if (
    !(VariableKeys.start_date in draftParams) ||
    !(VariableKeys.end_date in draftParams)
  ) {
    throw new Error();
  }

  const startDate = draftParams[VariableKeys.start_date];

  const onStartDateChange = (start_date: string) =>
    handleDraftChange((prevParams) => ({ ...prevParams, start_date }));

  const endDate = draftParams[VariableKeys.end_date];
  const onEndDateChange = (end_date: string) =>
    handleDraftChange((prevParams) => ({ ...prevParams, end_date }));

  const value: DateRange = [startDate, endDate];
  const { date: latestProductionDate } = useLatestProductionDate();

  const valueIsShortcut = Object.values(DATE_SHORTCUT_KEYS).some((key) =>
    isShortcutSelected(key, value)
  );

  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] =
    useState<null | HTMLElement>(null);

  const selectedRangeLabel = generateDateRangeLabel(value);

  const reportPage = useReportPage();
  const params = useCoercedQueryParams(reportPage);

  const draftIsValid = isValidURLQuery(reportPage, {
    ...params,
    [VariableKeys.start_date]: value[0],
    [VariableKeys.end_date]: value[1],
  });

  return (
    <ClickAwayListener onClickAway={() => setIsDateRangeOpen(false)}>
      <span>
        <Button
          variant="contained"
          size="medium"
          color={draftIsValid ? 'primary' : 'error'}
          onClick={(e) => {
            setDateRangeAnchorEl(e.currentTarget);
            setIsDateRangeOpen((o) => !o);
          }}
        >
          {selectedRangeLabel}
        </Button>
        <Menu
          anchorEl={dateRangeAnchorEl}
          open={isDateRangeOpen}
          handleClose={() => setIsDateRangeOpen(false)}
        >
          <Box sx={{ width: 400 }}>
            <Stack spacing={2} padding={0} direction="column">
              <List>
                {Object.values(DATE_SHORTCUT_KEYS).map((key) => (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      onStartDateChange(key);
                      onEndDateChange('latest' as const);
                      setIsDateRangeOpen(false);
                    }}
                    selected={isShortcutSelected(key, value)}
                  >
                    {isShortcutSelected(key, value) && (
                      <ListItemIcon sx={{ mr: 0 }}>
                        <Check />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      inset={!isShortcutSelected(key, value)}
                      primary={DATE_SHORTCUT_LABELS[key]}
                    />
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    onStartDateChange('');
                    onEndDateChange('');
                  }}
                  selected={!valueIsShortcut}
                >
                  {!valueIsShortcut && (
                    <ListItemIcon sx={{ mr: 0 }}>
                      <Check />
                    </ListItemIcon>
                  )}
                  <ListItemText inset={valueIsShortcut} primary={'Custom'} />
                </MenuItem>
              </List>
              {!valueIsShortcut && (
                <Stack sx={{ p: 2, m: 0 }} spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      autoFocus
                      label="From"
                      value={moment(value[0]).toDate()}
                      orientation="portrait"
                      onChange={(fromDate) => {
                        if (fromDate !== null) {
                          onStartDateChange(
                            moment(fromDate).format(DEFAULT_DATE_FORMAT)
                          );
                        }
                      }}
                      minDate={moment(MIN_START_DATE).toDate()}
                      maxDate={
                        value[1]
                          ? subtractOneDay(value[1])
                          : // Set to maxDate to the latest production date if "to_date" value is null
                            latestProductionDate
                      }
                      renderInput={(params) => <TextField {...params} />}
                      views={['year', 'month', 'day']}
                    />
                    <DatePicker
                      label="To"
                      value={moment(value[1]).toDate()}
                      orientation="portrait"
                      onChange={(toDate) => {
                        if (toDate !== null) {
                          onEndDateChange(
                            moment(toDate).format(DEFAULT_DATE_FORMAT)
                          );
                        }
                      }}
                      minDate={addOneDay(value[0])}
                      maxDate={latestProductionDate}
                      renderInput={(params) => <TextField {...params} />}
                      views={['year', 'month', 'day']}
                    />
                  </LocalizationProvider>
                </Stack>
              )}
            </Stack>
          </Box>
        </Menu>
      </span>
    </ClickAwayListener>
  );
};
