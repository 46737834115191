import { Box } from '@mui/material';
import { useQuery } from '../hooks';
import { PrivateLayout } from '../layouts/PrivateLayout';

export const WithCompanyIdPrompt: React.FC<{
  children: React.ReactNode;
  page_title: string;
}> = ({ children, page_title }) => {
  const { company_id } = useQuery();
  if (!company_id) {
    return (
      <PrivateLayout page_title={page_title}>
        <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
          Please select a company
        </Box>
      </PrivateLayout>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
