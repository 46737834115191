import {
  FilterButtonMenu,
  FilterButtonMenuOption,
  BusinessType,
} from '@groundwater/shared-ui';
import { VariableKeys, variablesSchema } from '../../../utils';
import { reach } from 'yup';
import { BUSINESS_TYPE_LABELS } from '../../constants';
import { useReportPage } from '../../hooks/useReportPage';
import { entriesFromRecord } from '../../../utils/record-map';
import { useFilterContext } from '../FilterSubHeader/hooks/controller';

export const BusinessTypeFilter: React.FC<{}> = () => {
  const page = useReportPage();

  const { handleDraftChange, draftParams } = useFilterContext(page);

  if (!(VariableKeys.businesses_type in draftParams)) {
    return null;
  }

  const selectedOption = draftParams[VariableKeys.businesses_type];
  const onClick = (business_type: BusinessType) =>
    handleDraftChange((prevParams) => ({
      ...prevParams,
      business_type,
    }));

  const menuOptions: FilterButtonMenuOption<BusinessType>[] = entriesFromRecord(
    BUSINESS_TYPE_LABELS
  )
    .filter(([value, label]) =>
      reach(variablesSchema[page], VariableKeys.businesses_type).isValidSync(
        value
      )
    )
    .map(([value, label]) => {
      return {
        label,
        value,
        // TODO: state will be based on the combination of selected filters
        disabled: false,
      };
    });

  return (
    <FilterButtonMenu
      autoClose
      id="business-type-filter"
      filterLabel={`View: ${BUSINESS_TYPE_LABELS[selectedOption]}`}
      menuOptions={menuOptions}
      onClick={onClick}
      selected={selectedOption}
    />
  );
};
