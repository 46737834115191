import {
  createApiClient,
  setGlobalApiClient,
} from '@groundwater/frontend-api-client';
import { CircularProgressInStack, ProvideAuth } from '@groundwater/shared-ui';
import {
  DefaultErrorBoundary,
  GlobalStyles,
  ThemeConfig,
} from '@groundwater/shared-ui';
import { Stack } from '@mui/material';
import { ComponentType, FunctionComponent, StrictMode, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { environment } from '../environments/environment';
import { ReactQueryProvider } from './components/ReactQueryProvider';
import Router from './Router';
import * as Sentry from '@sentry/react';
import { useSentry } from '../sentry';
import { ProvideLatestProductionDate } from './hooks/useLatestProductionDate';

export const apiClient = createApiClient({
  baseURL: environment.api_url,
  onLogout: () => (window.location.href = '/'),
});

setGlobalApiClient(apiClient);

export function App() {
  return (
    // strict mode disabled due to issues with dependencies, such as
    // https://github.com/reactjs/react-transition-group/issues/817

    // <StrictMode>
    <DefaultErrorBoundary>
      <HelmetProvider>
        <ReactQueryProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <Suspense fallback={<CircularProgressInStack />}>
            <ProvideAuth apiClient={apiClient}>
              <ProvideLatestProductionDate>
                <BrowserRouter>
                  <ThemeConfig>
                    <GlobalStyles />
                    <DefaultErrorBoundary>
                      <Router />
                    </DefaultErrorBoundary>
                  </ThemeConfig>
                </BrowserRouter>
              </ProvideLatestProductionDate>
            </ProvideAuth>
          </Suspense>
        </ReactQueryProvider>
      </HelmetProvider>
    </DefaultErrorBoundary>
    // </StrictMode>
  );
}

const enchancedApp = (
  app: ComponentType<Record<string, FunctionComponent>>
) => {
  if (useSentry) {
    // Enables React Profiler
    // https://docs.sentry.io/platforms/javascript/guides/react/components/profiler/
    return Sentry.withProfiler(app);
  }
  return app;
};

export default enchancedApp(App);
