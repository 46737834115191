import queryString from 'query-string';
import React from 'react';
import { NavLink, To } from 'react-router-dom';
import { isValidURLQuery } from '../../utils';
import { createURLQuery } from '../../utils/createURLQuery';
import { REPORT_PAGES } from '../constants';
import { useQuery } from '../hooks';

function isReportPage(to: To): to is REPORT_PAGES {
  const pages: string[] = Object.values(REPORT_PAGES);
  return typeof to === 'string' && pages.includes(to);
}

/**
 * A component that wraps NavLink (for rendering the "active" link in react-router-dom)
 * and adds logic to append query params to the end of the URL
 */
export const ReportNavItem: typeof NavLink = React.forwardRef((props, ref) => {
  const existingParams = useQuery();
  if (isReportPage(props.to)) {
    // try to create params for the new page, using the defaults with any valid existing URL search params overridden
    const params = createURLQuery(props.to, existingParams);

    // check if its valid, one example of where this won't be valid is if we're on QPS (no ?company_id) linking to trends
    // because ?company_id has no default value
    const valid = isValidURLQuery(props.to, params);
    if (!valid) {
      return <NavLink {...props} ref={ref} to={props.to} />;
    }
    const navigateTo = `${props.to}?${queryString.stringify(params, {
      arrayFormat: 'bracket',
    })}`;

    return <NavLink {...props} ref={ref} to={navigateTo} />;
  }
  return <NavLink {...props} ref={ref} />;
});
