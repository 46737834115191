import { Typography, Stack } from '@mui/material';
import { Box, styled } from '@mui/system';

interface ChartLegendProps {
  data: {
    id: string | number;
    label: string | number;
    color: string;
  }[];
}

const Marker = styled('span')(({ theme }) => ({
  height: '12px',
  width: '12px',
  display: 'inline-flex',
  borderRadius: '50px',
  marginRight: '5px',
}));

export const ChartLegend: React.FC<ChartLegendProps> = ({ data }) => {
  return (
    <Stack direction="row" flexWrap={'wrap'} sx={{ ml: 3 }}>
      {data.map((legend) => {
        return (
          <Box
            key={legend.id}
            sx={{ mx: 1 }}
            flexWrap="wrap"
            display="flex"
            alignItems="center"
          >
            <Marker sx={{ backgroundColor: legend.color }} />
            <Typography variant="caption" key={legend.id}>
              {legend.id}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
