import {
  GetOvrPercentObservedQuery,
  useGetOvrPercentObservedQuery,
} from '../../../gql-generated';
import { useDeferredValue } from 'react';
import { REPORT_PAGES } from '../../constants';
import { useVariables } from '../../hooks/useVariables';
import { PercentObservedChart } from '@groundwater/app/ui';
import { OvrScalingModel } from '@groundwater/shared-ui';
import { Alert, Skeleton } from '@mui/material';
import { useChartResetKeys } from '../../hooks/useResetKeys';
import { UseQueryResult } from 'react-query';
import { Variables } from '../../../utils';

export const OvrPercentObservedChartLoadingSkeleton = () => (
  <Skeleton variant="rectangular" width="100%" height={370} />
);

export function OvrPercentObservedChartContainer() {
  const variables = useVariables(REPORT_PAGES.ovr);
  const deferredVariables = useDeferredValue(variables);

  const response = useGetOvrPercentObservedQuery({
    request: {
      company_id: deferredVariables.request.company_id,
      start_date: deferredVariables.request.start_date,
      end_date: deferredVariables.request.end_date,
    },
  });

  const resetKeys = useChartResetKeys();
  const keys = resetKeys.join(',');

  return (
    <OvrPercentObservedChartPresentation
      data={response.data}
      variables={variables}
      key={keys}
    />
  );
}

export const OvrPercentObservedChartPresentation: React.FC<{
  data: UseQueryResult<GetOvrPercentObservedQuery>['data'];
  variables: { request: Variables[REPORT_PAGES.ovr] };
}> = ({ data, variables }) => {
  if (!data) throw new Error('Dataset is not present in the response payload.');

  const { percent_observed_v2: percent_observed } = data;

  if (percent_observed.__typename === 'DataError') {
    // TODO - loading
    return <Alert severity="info">{percent_observed.message}</Alert>;
  }

  /**
   * At this point we fetched the dataset that contains series for all scaling models.
   * That's why we want the chart to update instantly upon model change
   * hense we are using variables instead of deferredVariables.
   */
  const selectedScalingModel = variables.request.scaling_model;
  const {
    best_fit,
    scaling_models,
    quarter_end_date,
    historical_proportion_observed,
  } = percent_observed;

  if (historical_proportion_observed.every((val) => val === null)) {
    return (
      <Alert severity="info">
        There is no data for historical proportion observed.
      </Alert>
    );
  }

  let chartSeries;

  // 1. Map the best fit model value to the respective scaling model serie
  if (selectedScalingModel === OvrScalingModel.BestFit) {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
      modeled: scaling_models[best_fit],
    };
  }

  // 2. Unscaled model only represents historical observed serie
  else if (selectedScalingModel === OvrScalingModel.Unscaled) {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
    };
  }

  // 3. Directly map scaling model
  else {
    chartSeries = {
      quarter_end_date,
      historical: historical_proportion_observed,
      modeled: scaling_models[selectedScalingModel],
    };
  }

  return (
    <PercentObservedChart
      dataset={chartSeries}
      group="ovr"
      yZoom={variables.request.y_zoom === 'true'}
    />
  );
};
