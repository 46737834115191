import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { FilterButtonMenuOption, FilterMenuList } from './FilterMenuList';
import {
  FilterButtonGroupedMenuOption,
  FilterMenuGroupedList,
} from './FilterMenuGroupedList';
import React from 'react';
import { Menu } from './Menu';
import { isEqual, isNil } from 'lodash-es';

interface Base<OptionValue> {
  id: string;
  filterLabel: string;
  onClick: (value: OptionValue) => void;
  selected: OptionValue;
  actionButtons?: ({
    handleClose,
  }: {
    handleClose: () => void;
  }) => React.ReactElement | null;
  autoClose?: boolean;
  menuOptionContentSection?: React.ReactElement | null;
  isError?: boolean;
}

interface Groups<OptionValue> extends Base<OptionValue> {
  menuOptions: FilterButtonGroupedMenuOption<OptionValue>[];
  withGroups: true;
}

interface Flat<OptionValue> extends Base<OptionValue> {
  menuOptions: FilterButtonMenuOption<OptionValue>[];
  withGroups?: false;
}

export type FilterButtonMenuProps<OptionValue> =
  | Flat<OptionValue>
  | Groups<OptionValue>;

export const FilterButtonMenu = <OptionValue,>(
  props: FilterButtonMenuProps<OptionValue>
): React.ReactElement => {
  const {
    id,
    filterLabel,
    autoClose,
    menuOptions,
    onClick,
    selected,
    actionButtons,
    withGroups,
    menuOptionContentSection,
    isError,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);

  const [bounds, setBounds] = useState<number[]>([0, 0]);
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * The lint warning is expected.
   *
   * Trigger PopperJS update when position changes, see
   * https://popper.js.org/docs/v2/modifiers/event-listeners/#when-the-reference-element-moves-or-changes-size
   *
   * The state updater function prevents updating if the bounds have not changed. This will measure the offsetLeft
   * and offsetTop and update the `bounds` which gets passed to the <Popper />, so that when it changes it forces
   * the update in PopperJS
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setBounds((bounds) => {
      if (!ref.current) return bounds;
      const updated = [ref.current.offsetLeft, ref.current.offsetTop];
      if (isEqual(bounds, updated)) return bounds;
      return updated;
    });
  });

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <span ref={ref}>
        <Button
          ref={setAnchorEl}
          data-testid={`filter-button-${id}`}
          aria-controls={open ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          size="medium"
          color={isError ? 'error' : 'primary'}
          onClick={toggleOpen}
          aria-invalid={isError}
          sx={{
            ...(isError ? { backgroundColor: 'error.dark' } : {}),
          }}
        >
          {filterLabel}
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          forceUpdate={bounds}
        >
          <Box width="400px">
            {props.withGroups ? (
              <FilterMenuGroupedList
                menuOptions={props.menuOptions}
                selected={selected}
                onClick={(val) => {
                  onClick(val);
                  if (autoClose) setOpen(false);
                }}
              />
            ) : (
              <FilterMenuList
                menuOptions={props.menuOptions}
                selected={selected}
                onClick={(val) => {
                  onClick(val);
                  if (autoClose) setOpen(false);
                }}
              />
            )}
            {!isNil(menuOptionContentSection) && open ? (
              <Box sx={{ py: 2, mx: 2 }}>{menuOptionContentSection}</Box>
            ) : null}
            {actionButtons ? actionButtons({ handleClose }) : null}
          </Box>
        </Menu>
      </span>
    </ClickAwayListener>
  );
};
