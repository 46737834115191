import {
  CohortedRetentionRequest,
  CohortedRetentionRollup,
  CohortedRetentionStaticSerieId,
  DollarRetentionRollup,
} from '@groundwater/shared-ui';
import { scaleSequential } from 'd3-scale';
import { interpolateBlues } from 'd3-scale-chromatic';

export type CohortedRetentionDynamicSerie = {
  __typename: 'CohortedRetentionDynamicSerie';
  dynamic_serie_id: string;
  dynamic_series: (number | null)[];
  dynamic_serie_heading: string;
};

export type CohortedRetentionStaticSerie = {
  __typename: 'CohortedRetentionStaticSerie';
  id: CohortedRetentionStaticSerieId;
  data: string[];
  heading: CohortedRetentionStaticSerieId;
};

export const getAxisLabel = (
  rollup: CohortedRetentionRollup | DollarRetentionRollup
) => {
  switch (rollup) {
    case CohortedRetentionRollup.Year:
      return 'Years Since First Purchase';
    case CohortedRetentionRollup.Quarter:
      return 'Quarters Since First Purchase';
    case CohortedRetentionRollup.Month:
      return 'Months Since First Purchase';
    case CohortedRetentionRollup.Week:
      return 'Weeks Since First Purchase';

    default:
      throw new Error(
        `Did not expect to get here. Provided rollup: (${rollup}) is not supported.`
      );
  }
};

export const getPeriod0Label = (
  rollup: CohortedRetentionRollup | DollarRetentionRollup,
  units: 'customers' | 'dollars'
) => {
  switch (rollup) {
    case CohortedRetentionRollup.Year:
      return `Year 0 ${units}`;
    case CohortedRetentionRollup.Quarter:
      return `Quarter 0 ${units}`;
    case CohortedRetentionRollup.Month:
      return `Month 0 ${units}`;
    case CohortedRetentionRollup.Week:
      return `Week 0 ${units}`;

    default:
      throw new Error(
        `Did not expect to get here. Provided rollup: (${rollup}) is not supported.`
      );
  }
};
